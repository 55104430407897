﻿'use strict';

angular.module('mapsApp')
    .service('commentFactory', ['$q', '$http', '$mdToast', '$translate', 'ngAuthSettings',
        function ($q, $http, $mdToast, $translate, ngAuthSettings) { 

      return {
          getComments: _getComments,
          addOrUpdateComment: _addOrUpdateComment
      };

      function _getComments(from, to) {
          var deferred = $q.defer();
          $http({
              method: 'GET',
              url: ngAuthSettings.API_URL + 'Comment/GetCommentsByDate',
              params: { from: from, to: to }

          }).then(function successCallback(response) {
              if (response && response.data) {
                  var res = response.data;

                  for (var i = 0; i < res.length; i++) {
                      res[i].Created = Date.parse(res[i].Created);
                      if (res[i].LastModified) {
                          res[i].LastModified = Date.parse(res[i].LastModified);
                      }
                  }

                  deferred.resolve(response.data);
              }
          }, function errorCallback(response) {
              var errorMessage = response && response.data && response.data.ExceptionMessage
                     ? response.data.ExceptionMessage
                     : "A error occured while trying to get the comments.";
              $mdToast.show($mdToast.simple().textContent(errorMessage).position('right top'));
              deffered.reject(response);
          });
          return deferred.promise;
      }

      function _addOrUpdateComment(comment) {
          var deferred = $q.defer();

          $http({
              method: 'POST',
              url: ngAuthSettings.API_URL + 'Comment/AddOrUpdateComment',
              data: comment
          }).then(function successCallback(response) {              
              $mdToast.show($mdToast.simple().textContent($translate.instant('comment_succesfully_updated_message')).position('right top'));
              deferred.resolve(response);
          }, function errorCallback(response) {
              var errorMessage = response && response.data && response.data.ExceptionMessage
                     ? response.data.ExceptionMessage
                     : "A error occured while trying to update the comment.";
              $mdToast.show($mdToast.simple().textContent(errorMessage).position('right top'));
              deferred.reject();
          });

          return deferred.promise;
      }

  }]);

