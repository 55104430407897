﻿'use strict';




angular.module('mapsApp').service('GisDefaultsService', function () {

    var kmsusr = "InforMeticsTst";
    var kmspwd = "Hauser10";

    this.Icons = {
        '1': {
            type: 'awesomeMarker',
            icon: 'glyphicon-import',
            prefix: 'glyphicon',
            markerColor: 'green',
            name: 'Indløb'
        },
        '2': {
            type: 'awesomeMarker',
            icon: 'glyphicon-share-alt',
            prefix: 'glyphicon',
            markerColor: 'red',
            name: 'Overløb'
        },
        '3': {
            type: 'awesomeMarker',
            icon: 'glyphicon-tint',
            prefix: 'glyphicon',
            markerColor: 'blue',
            name: 'Regnmålere'
        },
        '4': {
            type: 'awesomeMarker',
            icon: 'glyphicon-menu-hamburger',
            prefix: 'glyphicon',
            markerColor: 'beige',
            name: 'Niveau'
        },
        '5': {
            type: 'awesomeMarker',
            icon: 'glyphicon-export',
            prefix: 'glyphicon',
            markerColor: 'pink',
            name: 'Udløb'
        },
        '6': {
            type: 'awesomeMarker',
            icon: 'glyphicon-transfer',
            prefix: 'glyphicon',
            markerColor: 'orange',
            name: 'Flow'
        },
        '7': {
            type: 'awesomeMarker',
            icon: 'glyphicon-cloud',
            prefix: 'glyphicon',
            markerColor: 'gray',
            name: 'Vejr'
        },
        '8': {
            type: 'awesomeMarker',
            icon: 'glyphicon-dashboard',
            prefix: 'glyphicon',
            markerColor: 'green',
            name: 'Vandkvalitet'
        },        
        '9': {
            type: 'awesomeMarker',
            icon: 'glyphicon-eye-close',
            prefix: 'glyphicon',
            markerColor: 'orange',
            name: 'Planlagte'
        }
    };

    this.defaultZoom = 5;
    this.AppCustomCrs = new L.Proj.CRS.TMS('EPSG:32632', '+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs', [120000, 5900000, 1000000, 6500000], {
        resolutions: [1638.4, 819.2, 409.6, 204.8, 102.4, 51.2, 25.6, 12.8, 6.4, 3.2, 1.6, 0.8, 0.4, 0.2, 0.1]
    });

    this.BaseLayers = {
        Ortofoto: {
            requestUrl: 'http://{s}.services.kortforsyningen.dk/orto_foraar?login=' + kmsusr + '&password=' + kmspwd + '&request=GetTile&version=1.0.0&service=WMTS&Layer=orto_foraar&style=default&format=image/jpeg&TileMatrixSet=View1&TileMatrix={zoom}&TileRow={y}&TileCol={x}',
            settings: {
                attribution: 'Geodatastyrelsen',
                continuousWorld: true,
                maxZoom: 13
            }
        },
        Skaermkort: {
            requestUrl: 'http://{s}.services.kortforsyningen.dk/topo_skaermkort?login=' + kmsusr + '&password=' + kmspwd + '&request=GetTile&version=1.0.0&service=WMTS&Layer=dtk_skaermkort&style=default&format=image/jpeg&TileMatrixSet=View1&TileMatrix={zoom}&TileRow={y}&TileCol={x}',
            settings: {
                attribution: 'Geodatastyrelsen',
                continuousWorld: true,
                maxZoom: 13
            }
        },
        Skaermkortgrey: {
            requestUrl: 'http://{s}.services.kortforsyningen.dk/topo_skaermkort_daempet?login=' + kmsusr + '&password=' + kmspwd + '&request=GetTile&version=1.0.0&service=WMTS&Layer=dtk_skaermkort_daempet&style=default&format=image/jpeg&TileMatrixSet=View1&TileMatrix={zoom}&TileRow={y}&TileCol={x}',
            settings: {
                attribution: 'Geodatastyrelsen',
                continuousWorld: true,
                maxZoom: 14          
            }
        }
    }

    this.OverlayGis = {
        geo_riste: {
            requestUrl: 'http://{s}.services.kortforsyningen.dk/service',
            settings: {
                type: 'WMS',
                service: 'WMS',
                transparent: true,
                servicename: 'topo_geo',
                layers: 'Nedloebsrist',
                login: kmsusr,
                password: kmspwd,
                format: 'image/png',
                attribution: "Geodatastyrelsen",
                continuousWorld: true,
                minZoom: 9
            }
       }
    }

    this.GisDefaultsService = {
        Odense: {
            lat: 55.4,
            lng: 10.4,
            zoom: this.defaultZoom
        },
        defaults: {
            crs: this.AppCustomCrs
        },
        layers: {
            baselayers: {},
            overlays: {
                'l1': {
                    type: 'group',
                    name: ' Indløb' + renderLegendIconHtml(this.Icons[1]),
                    visible: true
                },
                'l2': {
                    type: 'group',
                    name: 'Overløb' + renderLegendIconHtml(this.Icons[2]),
                    visible: true
                },
                'l3': {
                    type: 'group',
                    name: 'Regnmålere' + renderLegendIconHtml(this.Icons[3]),
                    visible: true
                },
                'l4': {
                    type: 'group',
                    name: 'Niveau' + renderLegendIconHtml(this.Icons[4]),
                    visible: true
                },
                'l5': {
                    type: 'group',
                    name: 'Udløb' + renderLegendIconHtml(this.Icons[5]),
                    visible: true
                },
                'l6': {
                    type: 'group',
                    name: 'Flow' + renderLegendIconHtml(this.Icons[6]),
                    visible: true
                },
                'l7': {
                    type: 'group',
                    name: 'Vejr' + renderLegendIconHtml(this.Icons[7]),
                    visible: true
                },
                'l8': {
                    type: 'group',
                    name: 'Vandkvalitet' + renderLegendIconHtml(this.Icons[8]),
                    visible: true
                },
                /*,
                'l9': {
                    type: 'group',
                    name: 'Planlagte' + renderLegendIconHtml(this.Icons[9]),
                    visible: true
                }*/
            },
            gisoverlays: {}
        },
        markers: {
        },
    };

    function renderLegendIconHtml(icon) {
        return '<span class="half awesome-marker-icon-' + icon.markerColor + ' awesome-marker leaflet-clickable"><i class=" glyphicon ' + icon.icon + ' icon-white"></i></span>';
    }
});
