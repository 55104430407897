'use strict';


angular.module('mapsApp')
    .factory('west2dFactory', ['$q', '$http', '$mdToast', 'ngAuthSettings',
        function ($q, $http, $mdToast, ngAuthSettings) {

    // Public API here
    return {
      getHomeModel: _getHomeModel,
      getUPMTables: _getUPMTables,
      exportUPMTables: _exportUPMTables
    };

   
    
    function _getHomeModel() {
      var deferred = $q.defer();

      $http({
        method: 'GET',
        url: ngAuthSettings.API_URL + 'west2d/GetHomeModel',
      }).then(function successCallback(response) {
        deferred.resolve(response.data);
      }, function errorCallback(response) {
        var errorMessage = response && response.data && response.data.Message
          ? response.data.Message
          : "A error occured while trying to get the west2d home model.";
        $mdToast.show($mdToast.simple().textContent(errorMessage).position('right top'));
        deferred.reject(response);
      });

      return deferred.promise;
    }
    
    function _exportUPMTables(scenarioId, name) {
      var deferred = $q.defer();

      $http({
        method: 'GET',
        url: ngAuthSettings.API_URL + 'west2d/ExportUPMTables',
        params: {
          scenarioId: scenarioId,
          scenarioName: name
        }
        ,responseType: 'arraybuffer'
      }).then(function successCallback(response) {
        deferred.resolve(response);
      }, function errorCallback(response) {
        var errorMessage = response && response.data && response.data.Message
          ? response.data.Message
          : "A error occured while trying to get the west GeoJSON.";
        $mdToast.show($mdToast.simple().textContent(errorMessage).position('right top'));
        deferred.reject(response);
      });

      return deferred.promise;
    }
    function _getUPMTables(scenarioId, parameterId) {
      var deferred = $q.defer();

      $http({
        method: 'GET',
        url: ngAuthSettings.API_URL + 'west2d/GetUPMTables',
        params: {
          scenarioId: scenarioId,
          parameterId: parameterId
        }
      }).then(function successCallback(response) {
        deferred.resolve(response.data);
      }, function errorCallback(response) {
        var errorMessage = response && response.data && response.data.Message
          ? response.data.Message
          : "A error occured while trying to get the west2d home model.";
        $mdToast.show($mdToast.simple().textContent(errorMessage).position('right top'));
        deferred.reject(response);
      });

      return deferred.promise;
    }
    
    

  }]);
