'use strict';

angular.module('mapsApp')
    .service('localizationService', ['$q', '$http', '$mdToast', 'ngAuthSettings',
        function ($q, $http, $mdToast, ngAuthSettings) {
      // AngularJS will instantiate a singleton by calling "new" on this function     




      var serv = {
          languages: [],
          languagesDictionary: {},
          translations: [],
          languageTranslationsDictionary: {},

          parameterTranslations: [],
          refreshLanguages: refreshLanguages,
          refreshTranslations: refreshTranslations,
          refreshParameterTranslations: refreshParameterTranslations,
          updateLanguage: updateLanguage,
          addLanguage: addLanguage,
          removeLanguage: removeLanguage,

          manageTranslation: manageTranslation,

          updateParameterTranslation: updateParameterTranslation,
          addParameterTranslation: addParameterTranslation,
          removeParameterTranslation: removeParameterTranslation
      };
      
      refreshLanguages().then(function () {
          refreshTranslations();
          //refreshParameterTranslations();
      });

      return serv;

      function refreshLanguages() {
          var deferred = $q.defer();
          _getAllLanguages().then(function (response) {

              serv.languages.length = 0;

              for (var i = 0; i < response.length; i++) {
                  serv.languagesDictionary[response[i].id] = response[i];
                  serv.languages.push(response[i]);

                  if (serv.languageTranslationsDictionary[response[i].id] === undefined) {
                      serv.languageTranslationsDictionary[response[i].id] = {};
                  }
              }

              deferred.resolve();
          });
          return deferred.promise;
      }

      function _getAllLanguages() {
          var deferred = $q.defer();
          $http({
              method: 'GET',
              url: ngAuthSettings.API_URL + 'language/GetAllLanguages'
          }).then(function successCallback(response) {
              deferred.resolve(response.data);
          }, function errorCallback(response) {
              $mdToast.show($mdToast.simple().textContent('The languages could not be retrieved from the api.'));
              deferred.reject(response);
          });
          return deferred.promise;
      }

      function updateLanguage(language) {
          var deferred = $q.defer();
          _updateLanguage(language).then(function (response) {
              for (var i = 0; i < serv.languages.length; i++) {
                  if (serv.languages[i].Id == response.Id) {
                      serv.languages[i] = response;
                      serv.languagesDictionary[languages[i].Id] = serv.languages[i];
                      break;
                  }
              }
              deferred.resolve(response);
          }, function errorCallback(error) {
              $mdToast.show($mdToast.simple().textContent('The language could not be updated.'));
          });
          return deferred.promise;
      }

      function _updateLanguage(newLanguage) {
          var deferred = $q.defer();

          $http({
              method: 'POST',
              url: ngAuthSettings.API_URL + 'language/UpdateLanguage',
              data: newLanguage
          }).then(function successCallback(response) {
              $mdToast.show($mdToast.simple().textContent("The language was succesfully updated.").position('right top'));
              deferred.resolve(response);
          }, function errorCallback(response) {
              var errorMessage = response && response.data && response.data.ExceptionMessage
                     ? response.data.ExceptionMessage
                     : "A error occured while trying to update the language.";
              $mdToast.show($mdToast.simple().textContent(errorMessage).position('right top'));
              deferred.reject();
          });

          return deferred.promise;
      }

      function addLanguage(language) {
          var deferred = $q.defer();

          $http({
              method: 'POST',
              url: ngAuthSettings.API_URL + 'language/AddLanguage',
              data: language
          }).then(function successCallback(response) {
              serv.languages.push(response.data);
              serv.languagesDictionary[response.data.Id] = response.data;
              serv.languageTranslationsDictionary[response.data.Id] = {};

              $mdToast.show($mdToast.simple().textContent("The language was succesfully added.").position('right top'));

              deferred.resolve(response.data);
          }, function errorCallback(response) {
              var errorMessage = response && response.data && response.data.ExceptionMessage
                     ? response.data.ExceptionMessage
                     : "A error occured while trying to add the new language.";

              $mdToast.show($mdToast.simple().textContent(errorMessage).position('right top'));

              deferred.reject();
          });

          return deferred.promise;
      }

      function removeLanguage(language) {

          var deferred = $q.defer();

          $http({
              method: 'POST',
              url: ngAuthSettings.API_URL + 'language/RemoveLanguage',
              data: language
          }).then(function successCallback(response) {
              serv.languages.removeItem(language, function (first, second) { return first.Id == second.Id });

              delete serv.languagesDictionary[language.Id];
              delete serv.languageTranslationsDictionary[language.Id];

              $mdToast.show($mdToast.simple().textContent("The language was succesfully removed.").position('right top'));
              deferred.resolve(response);
          }, function errorCallback(response) {
              var errorMessage = response && response.data && response.data.ExceptionMessage
                     ? response.data.ExceptionMessage
                     : "A error occured while trying to remove the language.";
              $mdToast.show($mdToast.simple().textContent(errorMessage).position('right top'));
              deferred.reject();
          });

          return deferred.promise;
      }
      
      function refreshTranslations() {
          var deferred = $q.defer();
          _getAllTranslations().then(function (response) {
              serv.translations.length = 0;

              for (var i = 0; i < response.length; i++) {
                  serv.translations.push(response[i]);
                  serv.languageTranslationsDictionary[response[i].LanguageId][response[i].Keyword] = response[i].Translation;
              }

              deferred.resolve();
          });
          return deferred.promise;
      }

      function _getAllTranslations() {
          var deferred = $q.defer();
          $http({
              method: 'GET',
              url: ngAuthSettings.API_URL + 'language/GetLanguageTranslations'
          }).then(function successCallback(response) {
              deferred.resolve(response.data);
          }, function errorCallback(response) {
              $mdToast.show($mdToast.simple().textContent('The languages translations could not be retrieved from the api.'));
              deferred.reject(response);
          });
          return deferred.promise;
      }

      function manageTranslation(item) {
          var deferred = $q.defer();

          $http({
              method: 'POST',
              url: ngAuthSettings.API_URL + 'language/ManageLanguageTranslation',
              data: item
          }).then(function successCallback(response) {
              
              if (item.Translation === null || item.Translation === undefined || item.Translation === '') {
                  serv.translations.removeItem(item, function (first, second) {
                      return first.LanguageId == second.LanguageId && first.Keyword == second.Keyword
                  });
              }
              else {

                  var found = false;

                  for (var i = 0; i < serv.translations.length; i++) {
                      if (serv.translations[i].LanguageId == response.LanguageId && serv.translations[i].Keyword == response.Keyword) {
                          serv.translations[i] = response;
                          found = true;
                          break;
                      }
                  }

                  if (found === false) {
                      serv.translations.push(response.data);
                  }
              }


              $mdToast.show($mdToast.simple().textContent("The translation was succesfully updated.").position('right top'));
              deferred.resolve(response);
          }, function errorCallback(response) {
              var errorMessage = response && response.data && response.data.ExceptionMessage
                     ? response.data.ExceptionMessage
                     : "A error occured while trying to update the translation.";
              $mdToast.show($mdToast.simple().textContent(errorMessage).position('right top'));
              deferred.reject();
          });

          return deferred.promise;

      }

      function updateTranslation(item) {
          var deferred = $q.defer();

          $http({
              method: 'POST',
              url: ngAuthSettings.API_URL + 'language/UpdateLanguageTranslation',
              data: item
          }).then(function successCallback(response) {
              for (var i = 0; i < serv.translations.length; i++) {
                  if (serv.translations[i].LanguageId == response.LanguageId && serv.translations[i].Keyword == response.Keyword) {
                      serv.translations[i] = response;
                      break;
                  }
              }

              $mdToast.show($mdToast.simple().textContent("The translation was succesfully updated.").position('right top'));
              deferred.resolve(response);
          }, function errorCallback(response) {
              var errorMessage = response && response.data && response.data.ExceptionMessage
                     ? response.data.ExceptionMessage
                     : "A error occured while trying to update the translation.";
              $mdToast.show($mdToast.simple().textContent(errorMessage).position('right top'));
              deferred.reject();
          });

          return deferred.promise;

      }

      function addTranslation(item) {
          var deferred = $q.defer();

          $http({
              method: 'POST',
              url: ngAuthSettings.API_URL + 'language/AddLanguageTranslation',
              data: item
          }).then(function successCallback(response) {
              serv.translations.push(response.data);
              $mdToast.show($mdToast.simple().textContent("The translation was succesfully added.").position('right top'));
              deferred.resolve(response);
          }, function errorCallback(response) {
              var errorMessage = response && response.data && response.data.ExceptionMessage
                     ? response.data.ExceptionMessage
                     : "A error occured while trying to add the new translation.";
              $mdToast.show($mdToast.simple().textContent(errorMessage).position('right top'));
              deferred.reject();
          });

          return deferred.promise;
      }

      function removeTranslation(item) {

          var deferred = $q.defer();

          $http({
              method: 'POST',
              url: ngAuthSettings.API_URL + 'language/RemoveLanguageTranslation',
              data: item
          }).then(function successCallback(response) {
              serv.translations.removeItem(item, function (first, second) {
                  return first.LanguageId == second.LanguageId && first.Keyword == second.Keyword
              });


              $mdToast.show($mdToast.simple().textContent("The translation was succesfully removed.").position('right top'));
              deferred.resolve(response);
          }, function errorCallback(response) {
              var errorMessage = response && response.data && response.data.ExceptionMessage
                     ? response.data.ExceptionMessage
                     : "A error occured while trying to remove the translation.";
              $mdToast.show($mdToast.simple().textContent(errorMessage).position('right top'));
              deferred.reject();
          });

          return deferred.promise;
      }

      function refreshParameterTranslations() {
          var deferred = $q.defer();
          _getAllParameterTranslations().then(function (response) {
              serv.parameterTranslations.length = 0;
              for (var i = 0; i < response.length; i++) {
                  serv.parameterTranslations.push(response[i]);
              }
              deferred.resolve();
          });
          return deferred.promise;
      }

      function _getAllParameterTranslations() {
          var deferred = $q.defer();
          $http({
              method: 'GET',
              url: ngAuthSettings.API_URL + 'language/GetLanguageParameterTranslations'
          }).then(function successCallback(response) {
              deferred.resolve(response.data);
          }, function errorCallback(response) {
              $mdToast.show($mdToast.simple().textContent('The languages parameter translations could not be retrieved from the api.'));
              deferred.reject();
          });
          return deferred.promise;
      }

      function updateParameterTranslation(item) {
          var deferred = $q.defer();

          $http({
              method: 'POST',
              url: ngAuthSettings.API_URL + 'language/UpdateLanguageParameterTranslation',
              data: item
          }).then(function successCallback(response) {
              for (var i = 0; i < serv.parameterTranslations.length; i++) {
                  if (serv.parameterTranslations[i].LanguageId == response.LanguageId && serv.parameterTranslations[i].ParameterId == response.ParameterId) {
                      serv.parameterTranslations[i] = response;
                      break;
                  }
              }

              $mdToast.show($mdToast.simple().textContent("The translation was succesfully updated.").position('right top'));
              deferred.resolve(response);
          }, function errorCallback(response) {
              var errorMessage = response && response.data && response.data.ExceptionMessage
                     ? response.data.ExceptionMessage
                     : "A error occured while trying to update the parameter translation.";
              $mdToast.show($mdToast.simple().textContent(errorMessage).position('right top'));
              deferred.reject();
          });

          return deferred.promise;

      }

      function addParameterTranslation(item) {
          var deferred = $q.defer();

          $http({
              method: 'POST',
              url: ngAuthSettings.API_URL + 'language/AddLanguageParameterTranslation',
              data: item
          }).then(function successCallback(response) {
              serv.parameterTranslations.push(response.data);
              $mdToast.show($mdToast.simple().textContent("The parameter translation was succesfully added.").position('right top'));
              deferred.resolve(response);
          }, function errorCallback(response) {
              var errorMessage = response && response.data && response.data.ExceptionMessage
                     ? response.data.ExceptionMessage
                     : "A error occured while trying to add the new parameter translation.";
              $mdToast.show($mdToast.simple().textContent(errorMessage).position('right top'));
              deferred.reject();
          });

          return deferred.promise;
      }

      function removeParameterTranslation(item) {

          var deferred = $q.defer();

          $http({
              method: 'POST',
              url: ngAuthSettings.API_URL + 'language/RemoveLanguageParameterTranslation',
              data: item
          }).then(function successCallback(response) {
              serv.parameterTranslations.removeItem(item, function (first, second) {
                  return first.LanguageId == second.LanguageId && first.ParameterId == second.ParameterId
              });
              
              $mdToast.show($mdToast.simple().textContent("The parameter translation was succesfully removed.").position('right top'));
              deferred.resolve(response);
          }, function errorCallback(response) {
              var errorMessage = response && response.data && response.data.ExceptionMessage
                     ? response.data.ExceptionMessage
                     : "A error occured while trying to remove the parameter translation.";



              $mdToast.show($mdToast.simple().textContent(errorMessage).position('right top'));
              deferred.reject();
          });

          return deferred.promise;
      }


  }]);

