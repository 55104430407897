﻿'use strict';

var Highcharts = require("highcharts");
require('highcharts/modules/data.src.js')(Highcharts);

angular.module('mapsApp')
    .controller('profileCtrl', ['$http', '$scope', '$q', '$timeout', '$mdMedia', '$mdBottomSheet', '$mdToast', '$interval', '$mdSidenav', '$window', '$location', 'authFactory', '$filter', '$translate', 'ChartDefaultsService', 'ngAuthSettings',
        function ($http, $scope, $q, $timeout, $mdMedia, $mdBottomSheet, $mdToast, $interval, $mdSidenav, $window, $location, authFactory, $filter, $translate, ChartDefaultsService, ngAuthSettings) {
        'use strict';
        if (!authFactory.isInRole('intern')) {
            $location.path('/home');
        }
        var statusElement = document.getElementById("status_text");
            var profileChart = {};
            var pageChart;

        statusElement.textContent = 'Henter punkter og parametre';
        $scope.autoCloseSidenav = true;
        $scope.determinateValue = 0;
        $scope.showLoading = true;
        $scope.loading = true;
        $scope.dataSelected = false;
        $scope.allParameters = [];
        $scope.pairs = [];

        $scope.availableProfiles = [];
        $scope.availableProfiles[0] = {PointId: 292, Name: 'Skolevej 52', ParameterIds : '281,282,283,284,285,286,287,288,289,290'}
        $scope.selectedProfile = $scope.availableProfiles[0];
        $scope.pointTypes = [];
        $scope.points = [];
        $scope.parameters =[];
        getPointParameters();
        var allPoints = {};
        var allParameters = {};

        $scope.markers = {};
        $scope.currentMarker = {};

        $scope.chartConfig = {};
        setTimeout(function () { initBlankChart(); }, 100);

        var layerControl;
        var markerLayers = [];
        var layersScope = { baselayers: {}, overlays: {} };

        angular.element(document).ready(function () {

           // $scope.mapstate = initMap();

        });


        $scope.refreshProfile = getProfileData;
        

        $scope.toDayDate = new Date();
        $scope.toDate = new Date();
        $scope.toDate.setDate($scope.toDayDate.getDate() - 6);
        $scope.fromDate = new Date();
        $scope.fromDate.setDate($scope.toDate.getDate() - 1);
        $scope.pointIds = "";
        $scope.paramIds = "";
        $scope.dataSourceId = "1,10";

        $scope.toggle = function (item, list) {
            var idx = list.indexOf(item);
            if (idx > -1) {
                list.splice(idx, 1);
            }
            else {
                list.push(item);
            }
            var ids = [];
            for (var j = 0; j < $scope.selectedPoints.length; j++) {
                ids[j] = $scope.selectedPoints[j].Id;
            }
            $scope.pointIds = ids.join(",");
            var Parids = [];
            $scope.pairs = [];
            var PairNo = 0;
            for (var j = 0; j < $scope.selectedPars.length; j++) {
                Parids[j] = $scope.selectedPars[j].Id;
                for (var i = 0; i < $scope.selectedPoints.length; i++) {
                    console.log($scope.selectedPoints[i]);
                    console.log($scope.selectedPars[j]);
                    $scope.pairs[PairNo] = { title: $scope.selectedPoints[i].Name + ", " + $scope.selectedPars[j].Name, Point: $scope.selectedPoints[i], Parameter: $scope.selectedPars[j], selected: true };
                    PairNo++;
                }
                
            }
            $scope.dataSelected = Boolean($scope.selectedPars.length > 0);
            statusElement.textContent = '';
            $scope.paramIds = Parids.join(",");

        };

        $scope.exists = function (item, list) {
            return list.indexOf(item) > -1;
        };

        $scope.doSecondaryAction = function (event) {
            var confirm = $mdDialog.confirm()
                 .title('Delete')
                 .textContent('Do you wish to delete this selection ?')
                 .ariaLabel('Delete Point,Parameter')
                 .targetEvent(event)
                 .ok('Delete')
                 .cancel('Keep it');

            $mdDialog.show(confirm).then(function() {
                $scope.status = 'You decided to delete the point,parameter pair.';
            }, function() {
                $scope.status = 'You decided to keep the point,parameter pair.';
            });
        }

        $scope.exportData = function () {
            statusElement.textContent = 'Henter data ...';
            console.log('Extracting ...' + $scope.fromDate + '-' + $scope.toDate);
            _exportData($scope.fromDate, $scope.toDate)
                 .then(function (data) {
                     if (data.data) {
                         console.log(data.data);
                         var now = $filter('date')(new Date(), "mediumDate") + ' ' + $filter('date')(new Date(), "mediumTime");
                         saveAs(new Blob([data.data], { type: "text/csv;charset=utf-8,%EF%BB%BF" }),
                                 'Data_download_' + now + '.csv');
                         statusElement.textContent = 'Data hentet ...';
                     }
                 }
                 );
             //   from.setDate(from.getDate() + 90);
             //   rangenum = rangenum + 1
             //}

        };

        function _exportData(from, to) {
            var deferred = $q.defer();
            getExportData(from,to)
              .then(function successCallback(response) {
                  deferred.resolve(response);
                  console.log(response);
              }/*, function errorCallback(response) { }*/);
            return deferred.promise;
        }


        function getExportData(from, to) {
            console.log("Getting data " + ngAuthSettings.API_URL + 'values/ExportData');
            $scope.loading = true;
            var deferred = $q.defer();
            $http({
                method: 'GET',
                url: ngAuthSettings.API_URL + 'values/ExportData',
                params: {
                    selectedPointIds: $scope.pointIds.toString(),
                    selectedParametersIds: $scope.paramIds.toString(),
                    from: from.toUTCString(),
                    to: to.toUTCString(),
                    dataSourceIds: $scope.dataSourceId,
                    sortByWaterWay: false
                },
                headers: {
                    'Content-type': 'application/pdf',
                },
                responseType: 'arraybuffer'
            }).then(function successCallback(response) {
                deferred.resolve(response);
                $scope.loading = false;
            }/*, function errorCallback(response) { }*/);

            return deferred.promise;
        }

        $scope.plotData = function () {
            //console.log("plotting...")
            destroyCharts();
            $scope.showLoading = true;
            $scope.loading = true;
            statusElement.textContent = 'Plotter data ...';
            var ParsArr = $scope.paramIds.split(',');
            var found = 0;
            for (var j = 0; j < ParsArr.length; j++) {
                //console.log($scope.pointTypes);
                var foundit = false;
                for (var t = 1; t < $scope.pointTypes.length; t++) {
                    var pointType = $scope.pointTypes[t].Id;
                    //console.log("Point Types : " + pointType)
                    //console.log($scope.allParameters[pointType]);
                    for (var i = 0; i < $scope.allParameters[pointType].length; i++) {
                        if ($scope.allParameters[pointType][i].Id == ParsArr[j]) {
                            //console.log("Found :" + found + " " + $scope.allParameters[pointType][i].Name);
                            $scope.selectedParameters[found] = $scope.allParameters[pointType][i];
                            found = found + 1;
                            foundit = true;
                            break;
                        }
                    }
                    if (foundit) break;
                }

            }
            getChartData();
        };

        setTimeout(function () { initBlankChart(); }, 100);

        $scope.togglePars = function (item, list) {
            var idx = list.indexOf(item);
            if (idx > -1) {
                list.splice(idx, 1);
            }
            else {
                list.push(item);
            }
        };

        $scope.exists = function (item, list) {
            return list.indexOf(item) > -1;
        };

        $scope.isIndeterminate = function () {
            return ($scope.selectedPars.length !== 0 &&
                $scope.selectedPars.length !== $scope.allParameters.length);
        };

        $scope.isChecked = function () {
            return $scope.selectedPars.length === $scope.allParameters.length;
        };

        $scope.toggleLeft = buildToggler('left');
        function buildToggler(navID) {
            return function () {
                // Component lookup should always be available since we are not using `ng-if`
                $mdSidenav(navID)
                  .toggle()
                  .then(function () {

                  });
            };
        }

        $scope.toggleAll = function () {
            if ($scope.selectedPars.length === $scope.allParameters.length) {
                $scope.selectedPars = [];
            } else if ($scope.selected.length === 0 || $scope.selected.length > 0) {
                $scope.selectedPars = $scope.allParameters.slice(0);
            }
        };

        var lockedOpen = undefined;
        $scope.triggerWindowResize = function () {
            var evt = $window.document.createEvent('UIEvents');
            evt.initUIEvent('resize', true, false, $window, 0);
            $window.dispatchEvent(evt);
        };


        $scope.isSidenavLockedOpen = function (windowSize) {

            var newLockedOpen = $scope.autoCloseSidenav
                        ? $mdMedia(windowSize)
                        : false;

            if (lockedOpen !== newLockedOpen) {
                setTimeout(function () {
                    $scope.triggerWindowResize();
                }, 3000);
            }
            lockedOpen = newLockedOpen;

            return newLockedOpen;

        }

        function refreshParameters() {
            console.log("In refreshParameters")
            $scope.checkParameters = getAllParameters();
            $scope.checkParameters.then(function (response) {
                $scope.allParameters = response;
            })
        }

        function getAllParameters() {
            var deferred = $q.defer();
            $http({
                method: 'GET',
                url: ngAuthSettings.API_URL + 'values/GetAllParameters'
            }).then(function successCallback(response) {
                deferred.resolve(response.data);
            }/*, function errorCallback(response) { }*/);
            return deferred.promise;
        }

        function getPointParameters() {
            $http({
                method: 'GET',
                url: ngAuthSettings.API_URL + 'values/GetStationsMetadata',
                params: {
                    WQonly: false
                }
            }).then(function successCallback(response) {
                $scope.loading = false;
                statusElement.textContent = 'Punkter via PunktType...'
                if (response.data) {
                    for (var j = 0; j < response.data.length; j++) {
                        var pointType = response.data[j].PointType;
                        $scope.pointTypes[j] = pointType;
                        allPoints[pointType.Id] = response.data[j].Points;
                        $scope.allParameters[pointType.Id] = response.data[j].Parameters;
                    }


                }



            }, function errorCallback(response) {

            });
        }
        function _refreshTypes() {

            $scope.points = allPoints[$scope.selectedPointType];
            console.log($scope.allParameters[$scope.selectedPointType])
            console.log($scope.allParameters);
            var unique = {};
            $scope.parameters = [];
            for (var j = 0; j < $scope.allParameters[$scope.selectedPointType].length; j++) {
                if (typeof (unique[$scope.allParameters[$scope.selectedPointType][j].Id]) == "undefined") {
                    $scope.parameters.push($scope.allParameters[$scope.selectedPointType][j]);
                }
                unique[$scope.allParameters[$scope.selectedPointType][j].Id] = 0;
            }
            $scope.pairs = [];
            $scope.selectedParameters = [];
            $scope.selectedPars = [];
            $scope.selectedPoints = [];
            $scope.pointIds = "";
            $scope.paramIds = "";


        }

        $scope.data = [];
        getProfileData();
        function getProfileData() {

            //            GetProfileData(int pointId, string selectedParametersIds, int dataSourceId, DateTime fromTimeLocal, DateTime toTimeLocal)
            var urlCalls = [];
            urlCalls.push(
                $http({
                    method: 'GET',
                    url: ngAuthSettings.API_URL + 'values/GetProfileData',
                    params: {
                        pointId: $scope.selectedProfile.PointId,
                        selectedParametersIds:  $scope.selectedProfile.ParameterIds,
                        dataSourceId: 10,
                        fromTimeLocal: $scope.fromDate,
                        toTimeLocal: $scope.toDate                    }
                }));
            $q.all(urlCalls)
            .then(function (data) {
                var csv = "Datetime,bin,value";
                for (var iDat = 0; iDat < data.length; iDat++) {
                    var responseData = data[iDat].data;
                    //console.log($('#container').highcharts());
//                    for (var k = 0; k < 100; k++) {
                    console.log("Adding data to chart");
                    var timestamp = new Date();
                    for (var k = 1; k < 100; k++) {
//                    for (var k = 0; k < responseData.length; k++) {
                        //chart.series[0].data.
//                        $scope.data[k] = [Date.parse(responseData[k].strTime), responseData[k].Bin, responseData[k].Value];
                        $scope.data[k] = [Date.parse(responseData[k].strTime), k, responseData[k].Value];

                        csv = csv + "\n" + responseData[k].strTime + "," + responseData[k].Bin + "," + responseData[k].Value

                    };
                    //console.log(profileChart);
                   // profileChart.series.setData($scope.data, true);
                    //console.log(csv);
                    initProfileCSV($scope.data);
                }
                //$('#container').highcharts().redraw();
                //angular.extend($scope.chartConfig, $scope.chartConfig.options);
                //$('#overview-chart-container').highcharts($scope.chartConfig, function callback() {
                //    $timeout(function () {
                //        $('#overview-chart-container').highcharts().redraw();
                //    }, 500);
                //})
                $scope.showLoading = false;
                $scope.loading = false;
            });

        }

        // Profile chart functions
        function initProfile(H) {
            var Series = H.Series,
                each = H.each;

            /**
             * Create a hidden canvas to draw the graph on. The contents is later copied over
             * to an SVG image element.
             */
            Series.prototype.getContext = function () {
                if (!this.canvas) {
                    this.canvas = document.createElement('canvas');
                    this.canvas.setAttribute('width', this.chart.chartWidth);
                    this.canvas.setAttribute('height', this.chart.chartHeight);
                    this.image = this.chart.renderer.image('', 0, 0, this.chart.chartWidth, this.chart.chartHeight).add(this.group);
                    this.ctx = this.canvas.getContext('2d');
                }
                return this.ctx;
            };

            /**
             * Draw the canvas image inside an SVG image
             */
            Series.prototype.canvasToSVG = function () {
                this.image.attr({ href: this.canvas.toDataURL('image/png') });
            };

            /**
             * Wrap the drawPoints method to draw the points in canvas instead of the slower SVG,
             * that requires one shape each point.
             */
            console.log(H)
            H.wrap(H.seriesTypes.heatmap.prototype, 'drawPoints', function () {

                var ctx = this.getContext();

                if (ctx) {

                    // draw the columns
                    each(this.points, function (point) {
                        var plotY = point.plotY,
                            shapeArgs,
                            pointAttr;

                        if (plotY !== undefined && !isNaN(plotY) && point.y !== null) {
                            shapeArgs = point.shapeArgs;

                            pointAttr = (point.pointAttr && point.pointAttr['']) || point.series.pointAttribs(point);

                            ctx.fillStyle = pointAttr.fill;
                            ctx.fillRect(shapeArgs.x, shapeArgs.y, shapeArgs.width, shapeArgs.height);
                        }
                    });

                    this.canvasToSVG();

                } else {
                    this.chart.showLoading('Your browser doesn\'t support HTML5 canvas, <br>please use a modern browser');
                }
            });
            H.seriesTypes.heatmap.prototype.directTouch = false; // Use k-d-tree
        };
        function initProfileCSV(dataDB) {
            console.log(dataDB);
           // initProfile(Highcharts);
            var start;
//            console.log(Date.UTC($scope.fromDate));
            profileChart = Highcharts.chart('container', {
                //data: { data: dataDB},
                //data: {
                //    //data: $scope.data
                //    //csv: csvString,
                //    csv: document.getElementById('csv').innerHTML,
                //    parsed: function () {
                //        start = +new Date();
                //    }
                //},
                chart: {
                    type: 'heatmap'
                },
                title: {
                    text: $scope.selectedProfile.Name,
                    align: 'left'
                },
                subtitle: {
                    text: 'Vandføring',
                    align: 'left'
                },
                xAxis: {
                    title: {
                        text: 'Dato, Time',
                        align: 'right',
                        x: -40
                    },
                    type: 'datetime',
  //                  min: Date.UTC($scope.fromDate),
  //                  max: Date.UTC($scope.toDate),
                    labels: {
                        align: 'left',
                        x: 5,
                        y: 14,
                        format: '{value:%d,%H}' // long month
                    },
                    showLastLabel: false,
                    tickLength: 16
                },
                yAxis: {
                    title: {
                        text: 'Bin'
                    },
                    labels: {
                        format: '{value}'
                    },
                    minPadding: 0,
                    maxPadding: 0,
                    startOnTick: false,
                    endOnTick: false,
                    tickPositions: [10, 8, 6, 4, 2],
                    tickWidth: 1,
                    min: 1,
                    max: 10,
                    reversed: false
                },
                colorAxis: {
                    stops: [
                        [0, '#3060cf'],
                        [0.5, '#fffbbc'],
                        [0.9, '#c4463a'],
                        [1, '#c4463a']
                    ],
                    min: 0,
                    max: 0.4,
                    startOnTick: false,
                    endOnTick: false,
                    labels: {
                        format: '{value}'
                    }
                },
                series: [{
                    borderWidth: 0,
                    nullColor: '#ffffff',
                    colsize: 60e3, // one minute
                    data : dataDB,
                    tooltip: {
                        headerFormat: 'Flow<br/>',
                        pointFormat: '{point.x:%d/%m %H:%M} bin:{point.y}: <b>{point.value} m/s</b>'
                    },
                    turboThreshold: Number.MAX_VALUE // #3404, remove after 4.0.5 release
                }]
            });
//            Highcharts.chart('container').redraw();
            //console.log(profileChart);
        }


 



   // Timeseries Chart functions
        //Initializes the scope with the chart
        function initBlankChart() {
            $scope.chartConfig = ChartDefaultsService.getConfig();
        }

        function initChart() {
            $scope.chartConfig = ChartDefaultsService.getConfig();
            $scope.chartConfig.title.text = $scope.currentMarker.name;
            $scope.chartConfig.title.x = 0;
            var parameters = $scope.allParameters
            var colorPallete = new ChartDefaultsService.ColorPalletes(parameters.length).colorStrings;
            console.log("In initChart");
            var axis = -1;

            console.log($scope.chartConfig.xAxis);
            $scope.chartConfig.xAxis[0].min = Date.parse($scope.fromDate);
            for (var p = 0; p < $scope.pairs.length; p++) {
                if ($scope.pairs[p].selected){
                    axis++;
                    console.log($scope.pairs[p]);
                    var parId = $scope.pairs[p].Parameter.Id;
                    var paramColor = $scope.pairs[p].Parameter.Color;
                    var NumberDecimals = $scope.pairs[p].Parameter.NoDecimals;
                    //var colorsString = !paramColor || paramColor === null || paramColor === '' ? colorPallete[axis] : paramColor;
                    var colorsString = colorPallete[axis];
                    $scope.chartConfig.yAxis[axis] = {
                        labels: {
                            NoDecimals: Math.max(0, NumberDecimals - 2),
                            style: {
                                color: colorsString
                            },
                            formatter: function () {
                                return Highcharts.numberFormat(this.value, this.axis.userOptions.labels.NoDecimals);
                            }
                        },
                        title: {
                            text: $scope.pairs[p].Point.Name +"," + $scope.pairs[p].Parameter.Name,
                        },
                        marker :{
                            enabled : true
                        },
                        min: $scope.autoscaleYaxis ? null : $scope.fixedscaleYaxis ? $scope.pairs[p].Parameter.DefaultMin : 0,
                        max: $scope.autoscaleYaxis ? null : $scope.fixedscaleYaxis ? $scope.pairs[p].Parameter.DefaultMax : null,
                        gridLineWidth: axis === 0 ? 1 : 0,
                        //minorTickInterval : 3,
                        tickInterval: null,
                        opposite: axis === 0 ? false : true
                    };

                    $scope.chartConfig.series.push({
                        parameterId: $scope.pairs[p].Parameter.Id,
                        pointId: $scope.pairs[p].Point.Id,
                        pair : p,
                        name: $scope.pairs[p].Point.Name + ', ' + $translate.instant('__short__param__' + $scope.pairs[p].Parameter.Id) + ' (' + $scope.pairs[p].Parameter.UnitName + ')',
                        stickyTracking: false,
                        tooltip: {
                            valueDecimals: NumberDecimals
                        },
                        
                        type: ([204,215].indexOf($scope.pairs[p].Parameter.Id) > -1) ? 'column':'line',
                        //type: $scope.pairs[p].Parameter.Id ===204 ? 'column':'line',
                        yAxis: axis,
                        data: [],
                        color: colorsString
                    });
                }

            }

        }

        function destroyCharts() {
            for (var i = 0; i < Highcharts.charts.length; i++) {
                if (!Highcharts.charts[i] || Highcharts.charts[i] === null) {
                    continue;
                }
                Highcharts.charts[i].destroy();
            }
            $('#overview-chart-container').html('');

        }

        // Gets the chart series for the current marker from the api and populates the chart
        function getChartData() {
            $scope.allChartsInit = false;
            initChart();
            var urlCalls = [];
            var graphEnhanceOptions = buildAutoEnhance($scope.fromDate, $scope.toDate);
            console.log($scope.selectedPoints);
            console.log(graphEnhanceOptions);
            for (var p = 0; p < $scope.pairs.length; p++) {
                if ($scope.pairs[p].selected) {
                    var dataSourceId = $scope.pairs[p].Parameter.DataSourceId.toString;
                    if ($scope.pairs[p].Parameter.DataSourceId == 10) dataSourceId = '1,10';
                    console.log("Getting plot data : Source  " + dataSourceId + " ," + $scope.pairs[p].Point.Id + "," + $scope.pairs[p].Parameter.Id + "," + $scope.fromDate + "," + $scope.toDate)
                    urlCalls.push(
                        $http({
                            method: 'GET',
                            url: ngAuthSettings.API_URL + 'values/GetGraphData',
                            params: {
                                pointId: $scope.pairs[p].Point.Id,
                                parameterId: $scope.pairs[p].Parameter.Id,
                                dataSourceIds: dataSourceId,
                                fromlocal: $scope.fromDate,
                                toLocal: $scope.toDate,
                                span: graphEnhanceOptions.groupTimeSpan,
                                multiplyer: graphEnhanceOptions.multiplyer,
                                method: graphEnhanceOptions.normalizeMethod
                            }
                        }));
                }

            }


            $q.all(urlCalls)
            .then(function (data) {
                var seriesIndex = -1;
                for (var iDat = 0; iDat < data.length; iDat++) {
                    if (typeof data[iDat].data[0] !== 'undefined') {
                        console.log("Data Point, Parameter :" + data[iDat].data[0].PointId + ", " + data[iDat].data[0].ParameterId);
                        //Find matching chart data series to paste data into...
                        for (var j = 0; j < $scope.pairs.length; j++) {
                            var responseData = data[iDat].data;
                            console.log("Looking for : " + responseData[0].ParameterId)
                            if (data[iDat].data.length > 0) {
                                for (var indxSerie = 0; indxSerie < $scope.chartConfig.series.length; indxSerie++) {
                                    console.log('Data parameter : ' + responseData[0].ParameterId + ' Chart Parameter' + $scope.chartConfig.series[indxSerie].parameterId);
                                    if ($scope.chartConfig.series[indxSerie].parameterId === responseData[0].ParameterId) {
                                        if ($scope.chartConfig.series[indxSerie].pointId === responseData[0].PointId) {
                                            var seriesIndex = indxSerie;
                                            console.log("Matched : " + seriesIndex)
                                            break;
                                        }
                                    }
                                }

                            }
                            if (seriesIndex == -1) {
                                continue;
                            }
                            $scope.chartConfig.series[seriesIndex].data.length = 0;
                            console.log("Adding data to : " + seriesIndex)
                            for (var k = 0; k < responseData.length; k++) {
                                $scope.chartConfig.series[seriesIndex].data.push([
                                    Date.parse(responseData[k].Time),
                                    responseData[k].Value
                                ]);
                            };
                            console.log($scope.chartConfig.xAxis);
                            break;
                        }
                    }

                }
                angular.extend($scope.chartConfig, $scope.chartConfig.options);

                pageChart = Highcharts.chart('overview-chart-container', $scope.chartConfig, function callback(createdChart) {
                    $timeout(function () {
                        if (pageChart) {
                            pageChart.redraw();
                        } else if (createdChart)
                            createdChart.redraw();
                    }, 500);
                });

                $scope.showLoading = false;
                $scope.loading = false;
            });

        }

        function buildAutoEnhance(from, to) {

            var expectedResultsPerGraph = Math.round(Math.abs(from - to) / 60000);
            var graphEnhanceOptions = {};

            if (expectedResultsPerGraph > 400) {
                var minutesMultiplyer = Math.round(expectedResultsPerGraph / 400 + 0.4999999);
                graphEnhanceOptions.groupTimeSpan = 2; //Minutter
                graphEnhanceOptions.normalizeMethod = 4; //MinMax
                graphEnhanceOptions.multiplyer = minutesMultiplyer;
            }
            else {
                graphEnhanceOptions.groupTimeSpan = 0;
                graphEnhanceOptions.normalizeMethod = 0;
                graphEnhanceOptions.multiplyer = 1;
            }

            return graphEnhanceOptions;
        }

//END TIMESERIES CHART






        // Initializes the scope with the leaflet map

        function initMap() {

            var kmsusr = "InforMeticsTst";
            var kmspwd = "Hauser10";

            var baselayer = "";
            var crs = new L.Proj.CRS.TMS('EPSG:32632',
                '+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs', [120000, 5900000, 1000000, 6500000], {
                    resolutions: [1638.4, 819.2, 409.6, 204.8, 102.4, 51.2, 25.6, 12.8, 6.4, 3.2, 1.6, 0.8, 0.4, 0.2, 0.1]
                });

            var map = new L.Map('map', {
                crs: crs
            });



            var ortofoto = L.tileLayer('http://{s}.services.kortforsyningen.dk/orto_foraar?login=' + kmsusr + '&password=' + kmspwd + '&request=GetTile&version=1.0.0&service=WMTS&Layer=orto_foraar&style=default&format=image/jpeg&TileMatrixSet=View1&TileMatrix={zoom}&TileRow={y}&TileCol={x}', {
                attribution: 'Geodatastyrelsen',
                continuousWorld: true,
                maxZoom: 14,
                zoom: function () {
                    var zoom = map.getZoom();
                    if (zoom < 10)
                        return 'L0' + zoom;
                    else
                        return 'L' + zoom;
                }
            });

            var skaermkort = L.tileLayer('http://{s}.services.kortforsyningen.dk/topo_skaermkort?login=' + kmsusr + '&password=' + kmspwd + '&request=GetTile&version=1.0.0&service=WMTS&Layer=dtk_skaermkort&style=default&format=image/jpeg&TileMatrixSet=View1&TileMatrix={zoom}&TileRow={y}&TileCol={x}', {
                attribution: 'Geodatastyrelsen',
                continuousWorld: true,
                maxZoom: 14,
                zoom: function () {
                    var zoom = map.getZoom();
                    if (zoom < 10)
                        return 'L0' + zoom;
                    else
                        return 'L' + zoom;
                }
            }).addTo(map);

            var skaermkortgrey = L.tileLayer('http://{s}.services.kortforsyningen.dk/topo_skaermkort_daempet?login=' + kmsusr + '&password=' + kmspwd + '&request=GetTile&version=1.0.0&service=WMTS&Layer=dtk_skaermkort_daempet&style=default&format=image/jpeg&TileMatrixSet=View1&TileMatrix={zoom}&TileRow={y}&TileCol={x}', {
                attribution: 'Geodatastyrelsen',
                continuousWorld: true,
                maxZoom: 14,
                zoom: function () {
                    var zoom = map.getZoom();
                    if (zoom < 10)
                        return 'L0' + zoom;
                    else
                        return 'L' + zoom;
                }
            });


            var fyn_vandloeb = L.tileLayer.wms('http://geoserver.vcs.informetics.com/vcs/wms?service=WMS&version=1.1.0&request=GetMap&layers=vcs:VandlobFYN_New', {
                service: 'WMS',
                transparent: true,
                servicename: 'topo_geo',
                layers: ['Vandloebsbred', 'Vandloeb_brudt', 'Vandloeb', 'Bassin', 'Soe'],
                format: 'image/png',
                attribution: "VCS",
                continuousWorld: true,
                minZoom: 4
            });

            layersScope.overlays['Vandløb'] = fyn_vandloeb;

            layersScope.baselayers = {
                "Skærmkort": skaermkort,
                "Dæmpet": skaermkortgrey,
                "Flyfoto": ortofoto
            };

            layerControl = L.control.layers(layersScope.baselayers, layersScope.overlays).addTo(map);
            //getMapPoints(map);
            map.on('load', function (e) {
                setTimeout(function () { map.invalidateSize(); }, 500);

            });
            map.setView(L.latLng(55.4, 10.4), 5);


            return { map: map };


        }



    }]);
