'use strict';

var Highcharts = require("highcharts");
var proj4 = require("proj4").default;

angular.module('mapsApp')
    .controller('radarCtrl', ['$http', '$scope', '$q', '$timeout', '$mdMedia', '$mdBottomSheet', '$mdToast', '$interval', '$mdSidenav', '$mdDialog', '$window', '$location', 'authFactory', 'localStorageService', '$filter', '$translate', 'ChartDefaultsService', 'ngAuthSettings',
        function ($http, $scope, $q, $timeout, $mdMedia, $mdBottomSheet, $mdToast, $interval, $mdSidenav, $mdDialog, $window, $location, authFactory, localStorageService, $filter, $translate, ChartDefaultsService, ngAuthSettings) {
            var dataArrayGroup = [], layerIndex = 0, numberOfFinishedRequests = 0, numberOfTotalRequests = 2; var dataArrayGroupCoordinates = []; var toggleCanvas = false;
            var reference, mouseClickEvent = {}, mouseClickLayer = [];
            $scope.loggedin = true;
            var mouseClickLayerIndex = 0;
            var radarLayer = [];
            var map, gauge3, transformfactor;

            $scope.login = function () {
                console.log("Logging in");
                //        $scope.loggedin = !$scope.loggedin;
                //        if (!$scope.loggedin)$location.path('/login');
                //$scope.loggedin = true;
            };

            $scope.dataSources = [
                { Id: 0, Name: 'C-bånd Radar', Short: 'C-bånd', BaseURL: 'https://veva-processed-files.s3.eu-west-1.amazonaws.com/plots/quality/Quality_C_WR.png', prefix: '0002', path: function (yyyy, MM, dd, hh, mm, e, prefix) { return yyyy + "/" + MM + "/" + dd + "/" + prefix + '_' + yyyy + MM + dd + '_' + hh + mm + '00_0' + e + "_02.png"; } },
                { Id: 1, Name: 'SVK Regnmålere', Short: 'SVK', BaseURL: 'https://veva-processed-files.s3.eu-west-1.amazonaws.com/plots/quality/Quality_SVK.png', prefix: '0002', path: function (yyyy, MM, dd, hh, mm, e, prefix) { return yyyy + "/" + MM + "/" + dd + "/" + prefix + '_' + yyyy + MM + dd + '_' + hh + mm + '00_0' + e + "_02.png"; } },
                { Id: 2, Name: 'Vejrradar og regnmålere', Short: 'Alle C+X+SVK', BaseURL: 'https://veva-processed-files.s3.eu-west-1.amazonaws.com/plots/quality/Quality_WR_SVK.png', prefix: '0002', path: function (yyyy, MM, dd, hh, mm, e, prefix) { return yyyy + "/" + MM + "/" + dd + "/" + prefix + '_' + yyyy + MM + dd + '_' + hh + mm + '00_0' + e + "_02.png"; } },
                { Id: 3, Name: 'C-bånd og X-bånd radar', Short: 'C+X radar', BaseURL: 'https://veva-processed-files.s3.eu-west-1.amazonaws.com/plots/quality/Quality_WR.png', prefix: '0002', path: function (yyyy, MM, dd, hh, mm, e, prefix) { return yyyy + "/" + MM + "/" + dd + "/" + prefix + '_' + yyyy + MM + dd + '_' + hh + mm + '00_0' + e + "_02.png"; } },
                { Id: 4, Name: 'X-bånd Radar', Short: 'X-bånd', BaseURL: 'https://veva-processed-files.s3.eu-west-1.amazonaws.com/plots/quality/Quality_X_WR.png', prefix: '0002', path: function (yyyy, MM, dd, hh, mm, e, prefix) { return yyyy + "/" + MM + "/" + dd + "/" + prefix + '_' + yyyy + MM + dd + '_' + hh + mm + '00_0' + e + "_02.png"; } },
            ];
            $scope.selectedDataSource = $scope.dataSources[2];
            $scope.selectedDataSourceId = $scope.selectedDataSource.Id;


            var pageChart;
            var DataLayerExtent = [250000, 5900000, 1100000, 6600000]; 	// EPSG:32632 projection (UTM 32N - WGS84)

            $scope.coverages = [{ Id: 1, Name: 'Vejrradar og SVK' }];
            $scope.toDate = new Date(); //2020-10-04T06:00
            $scope.toDate.setSeconds(0);
            $scope.toDate.setMilliseconds(0);
            $scope.toDate.setDate($scope.toDate.getDate() - 1);
            $scope.fromDate = new Date();
            $scope.fromDate.setDate($scope.toDate.getDate() - 2);
            $scope.fromDate.setSeconds(0);
            $scope.fromDate.setMilliseconds(0);

            $scope.layersState = {};
            $scope.pointTypes = {};
            $scope.parameterCollections = {};
            $scope.parameters = {};
            $scope.chartParameters = {};
            $scope.currentMarker = {};
            $scope.toDate = new Date();
            $scope.toDate.setSeconds(0);

            $scope.animToDate = new Date();
            $scope.animFromDate = new Date();
            $scope.animFromDate.setMinutes($scope.animToDate.getMinutes() - 15);
            $scope.elevation = 3;
            $scope.rainEvents = {};
            $scope.autoscaleYaxis = true;
            $scope.timeSeriesLoding = false;
            $scope.currentParameter = {};
            $scope.layers = { baselayers: {} };
            $scope.chartConfig = {};
            $scope.tableHeaders = {};
            $scope.initedChartResults = 0;
            $scope.allChartsInit = false;
            $scope.autoscaleYaxis = false;
            $scope.plotActive = false;
            $scope.dataSelected = false;
            $scope.isSideNavOpen = true;
            $scope.isPlotOpen = false;
            $scope.isInfoOpen = false;
            $scope.isForecastOpen = false;
            $scope.AnimateStep = 0;
            $scope.markers = {};
            $scope.driftmsg = "no status";
            $scope.useAccumulation = false;
            $scope.showLoading = false;
            var step = 0;
            $scope.nsteps = 15;
            var lookforBase = false;

            var utcDateTime = new Date();
            var firstDate = new Date(); //2017, 8, 17, 11, 40, 0, 0);//(););
            var showValuesCanvas = false;
            var commentsON = false;
            firstDate.setSeconds(0);
            firstDate.setMilliseconds(0);
            $scope.animateTime = { toDate: new Date(firstDate), maxDate: new Date() };
            $scope.eventdaysback = 360;
            $scope.eventmindepth = Number(0);
            $scope.eventminduration = Number(0);
            $scope.greaterThan = function (prop, val) {
                return function (item) {
                    return Number(item[prop]) > val;
                };
            };
            $scope.mapstate = {};
            $scope.mapstate.radar_dg = {};
            $scope.dateText = "";
            $scope.timeText = "--:--";
            $scope.fabT = {};
            $scope.fabT.hidden = false;
            $scope.fabT.isOpen = false;
            $scope.fabT.hover = false;

            $scope.img = {};

            $scope.updateImage = function () {
                SetImage();
            }
            $scope.imgopacity = 50;

            $scope.updateOpacity = function () {
                $scope.img.setOpacity(1-$scope.imgopacity/100);
            }

            angular.element(document).ready(function () {
                $scope.mapstate = initMap();
                //$scope.mapstate.invalidateSize();
            });

            function initMap() {

                // We do this as we want the same attributions for all layers
                var myAttributionText = '&copy; <a target="_blank" href="https://download.kortforsyningen.dk/content/vilk%C3%A5r-og-betingelser">Styrelsen for Dataforsyning og Effektivisering</a>';
                var USERNAME = 'Dryp'
                var password = 'Sundkaj72tv.'
                var kftoken = "0c7acaa8b3b4efd4dee5ad52f96cc15e"
                console.log('In init map');
                proj4.defs('EPSG:32632', '+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs');
                //map = new L.Map('map', {
                //    zoom: 8,
                //    fullscreenControl: true,
                //});
                if (window.navigator.userAgent.indexOf('Chrome') > -1) {
                    var originalInitTile = L.GridLayer.prototype._initTile;
                    L.GridLayer.include({
                        _initTile: function (tile) {
                            originalInitTile.call(this, tile);
                            var tileSize = this.getTileSize();
                            tile.style.width = tileSize.x + 1 + 'px';
                            tile.style.height = tileSize.y + 1 + 'px';
                        }
                    });
                }
                // Make the map object using the custom projection
                //proj4.defs('EPSG:25832', "+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs");
                var crs = new L.Proj.CRS('EPSG:25832',
                    '+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs', {
                    resolutions: [1638.4, 819.2, 409.6, 204.8, 102.4, 51.2, 25.6, 12.8, 6.4, 3.2, 1.6, 0.8, 0.4, 0.2]
                });

                // Make the map object using the custom projection
                const mapDiv = document.getElementById("map");
                const map = new L.Map(mapDiv, {
                    //crs: crs,
                    center: [56,11], // Set center location
                    zoom: 5, // Set zoom level
                    fullscreenControl: true,
                    minzoom: 0,
                    maxzoom: 14
                });

                const resizeObserver = new ResizeObserver(() => {
                    map.invalidateSize();
                });

                resizeObserver.observe(mapDiv);


                //var OpenStreetMap_Mapnik = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                //    maxZoom: 19,
                //    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                //});

                //var OpenTopoMap = L.tileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', {
                //    maxZoom: 17,
                //    attribution: 'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
                //}).addTo(map);;

                var topoDF = L.tileLayer.wms('https://api.dataforsyningen.dk/topo_skaermkort_DAF?service=WMS&request=GetCapabilities&token=0c7acaa8b3b4efd4dee5ad52f96cc15e', {
                    layers: 'dtk_skaermkort',
                    format: 'image/png',
                    attribution: myAttributionText
                });

                var streetmap = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                }).addTo(map);

               // var imageBounds = [[46.966635, 7.415942], [46.998849, 7.470108]];
                //imageBounds = [[DataLayerExtent[0], DataLayerExtent[1]], [DataLayerExtent[2], DataLayerExtent[3]]];

                var imageUrl = $scope.selectedDataSource.BaseURL; //"https://veva-processed-files.s3.eu-west-1.amazonaws.com/plots/quality/Quality_WR_SVK.png";
                //imageBounds = [[53.1904695, 5.2575562], [59.1126005, 19.5057463]];
                var imageBounds = [[53.1904695, 4.7575562], [59.1126005, 19.0057463]];

                var imgoptions = { "opacity": 0.5 };
                $scope.img = L.imageOverlay(imageUrl, imageBounds, imgoptions).addTo(map);

                //var opacitySlider = new L.Control.opacitySlider();

                //map.addControl(opacitySlider);

                //opacitySlider.setOpacityLayer(img);

                // Define layer groups for layer control
                var baseLayers = {
                    "Skærmkort": topoDF,
                    "StreetMap": streetmap
                };

                // Add layer control to map
                L.control.layers(baseLayers).addTo(map);

                // Add scale line to map
                L.control.scale({ imperial: false }).addTo(map); // disable feet units

                var mouseUTM;
                var mouseDVR90;
                var dvronmap = document.getElementById("dvr_onmap");
                var geoonmap = document.getElementById("geo_onmap");
                map.addEventListener('mousemove', function (ev) {
                    mouseDVR90 = proj4('WGS84', 'EPSG:32632', [ev.latlng.lng, ev.latlng.lat]);
                    dvronmap.textContent = "DVR90: " + mouseDVR90[0].toFixed(1) + "m E, " + mouseDVR90[1].toFixed(1) + "m N";
                    geoonmap.textContent = "WGS-84: " + ev.latlng.lat.toFixed(3) + "°N, " + ev.latlng.lng.toFixed(3) + "°E";
                }, { passive: true });

                map.on('load', function (e) {
                    console.log("about to .. re-inititialising map");
                    setTimeout(function () { map.invalidateSize(); console.log("re inititialising map"); }, 500);
                });

                proj4.defs('EPSG:5799', '+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs');
                
                proj4.defs('WGS84', "+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees");

                return { map: map};

            }


            //$('#main-navigation').slideUp();
            $scope.animate = animate;
            $scope.buttonPlayPress = buttonPlayPress;
            $scope.buttonSlowPress = buttonSlowPress;
            $scope.buttonFastPress = buttonFastPress;
            $scope.buttonStopPress = buttonStopPress;
            $scope.buttonBackPress = buttonBackPress;
            $scope.buttonPlotCanvasPress = buttonPlotCanvasPress;
            $scope.buttonForwardPress = buttonForwardPress;
            $scope.swapDirection = swapDirection;
            $scope.newStartDateTime = NewStartDate;
            $scope.stepLength = 1;
            $scope.sidste30min = true;
            $scope.direction = true;




            var controlNameElement = document.getElementById("control_text");
            var fieldNameElement = document.getElementById("time_text");
            var timeonmap = document.getElementById("time_onmap");
            var dvronmap = document.getElementById("dvr_onmap");
            var geoonmap = document.getElementById("geo_onmap");

            var linkElement = document.getElementById("link_text");
            var dateElement = document.getElementById("date_text");
            var state = 'stop';
            var speed = 1200;
            var direction = 1;
            var datetime = new Date(firstDate);
            var firstload = 1;
            SetImage();

            function swapDirection() {
                direction = direction * -1;
            }

            function NewStartDate() {
                datetime = new Date(Date.parse($scope.animateTime.toDate));
                SetImage();
            }

            function buttonBackPress() {
                console.log("button back invoked.");
                state = 'stop';
                datetime = new Date(datetime - $scope.stepLength * 60000);
                SetImage();
            }

            function buttonForwardPress() {
                state = 'stop';
                datetime.setMinutes(datetime.getMinutes() + $scope.stepLength);
                SetImage();
            }

            function buttonFastPress() {
                // clearInterval(timer);
                state = 'stop';
                speed = speed - 150;
                // state = 'play'
                animate();
            }
            function buttonSlowPress() {
                // clearInterval(timer);
                state = 'stop';
                speed = speed + 150;
                animate();
            }
            function buttonPlotCanvasPress(eventDate) {
                console.log("Test")
                console.log("Event akk :" + eventDate);
                var fromDate = new Date();
                var MinutesBack = 5;
                fromDate.setTime(datetime.getTime() - MinutesBack * 60 * 1000);
                requestRadarDataSquare(fromDate, datetime)
                toggleCanvas = true;
            }
            function getCurrentTimeinStrFormat() {
                var currentDate = new Date(Date.now())
                var datestring = currentDate.toLocaleString("en-gb")
                var datestringchars = datestring.split("")
                return datestringchars[6] + datestringchars[7] + datestringchars[8] + datestringchars[9] + '-' + datestringchars[3] + datestringchars[4] + '-' + datestringchars[0] + datestringchars[1] + ' ' + datestringchars[12] + datestringchars[13] + datestringchars[14] + datestringchars[15] + datestringchars[16] + datestringchars[17] + datestringchars[18] + datestringchars[19]
            }
            function getPrevious24hTimeinStrFormat() {
                var currentDate = new Date(Date.now())
                currentDate.setHours(currentDate.getHours() - 24)
                var datestring = currentDate.toLocaleString("en-gb")
                var datestringchars = datestring.split("")
                return datestringchars[6] + datestringchars[7] + datestringchars[8] + datestringchars[9] + '-' + datestringchars[3] + datestringchars[4] + '-' + datestringchars[0] + datestringchars[1] + ' ' + datestringchars[12] + datestringchars[13] + datestringchars[14] + datestringchars[15] + datestringchars[16] + datestringchars[17] + datestringchars[18] + datestringchars[19]
            }
            function buttonPlayPress() {

                if (state == 'stop') {
                    state = 'play';
                    animate();
                }
                else if (state == 'play') {
                    state = 'stop';
                }
                //console.log("button play pressed, play was " + state);

            }
            function buttonStopPress() {
                state = 'stop';
                // console.log("button stop invoked.");
            }

            function addControlsForLayers(updateMin) {
                //Called when layers are added
                var minutes = datetime.getMinutes();
                var timeshift = datetime.getMinutes() % updateMin;
                $scope.stepLength = updateMin;
                console.log("Time before : " + datetime + " shift : " + timeshift);
                datetime.setTime(datetime.getTime() - (timeshift * 60 * 1000));
                console.log("Time after : " + datetime);
                SetImage();
            }
            function removeControlsForLayers() {
                //Called when layers are added
                $scope.stepLength = 1;
                SetImage();
            }


            function SetImage() {

                if (datetime.getTime() > Date.now()) {
                    console.log("Oy - you are looking at the future ! ");
                    linkElement.textContent = $translate.instant('fremtid');
                    $scope.AnimateStep = 0;
                    return;
                }
                var utmOffset = datetime.getTimezoneOffset();
                //var preSummer = new Date();
                //preSummer.setTime(datetime.getTime() + (-240 * 60 * 60 * 1000));
                //var nOld = preSummer.getTimezoneOffset();
                //console.log("Now :" + nNew + " , pre :" + nOld);
                $scope.AnimateStep = step;


                utcDateTime.setTime(datetime.getTime() + (utmOffset * 60 * 1000));
                console.log(utcDateTime.getTime() + " vs " + Date.now());
                var dd = ("0" + utcDateTime.getDate()).slice(-2);
                var MM = ("0" + (parseInt(utcDateTime.getMonth()) + 1)).slice(-2);
                var hh = ("0" + (parseInt(utcDateTime.getHours()))).slice(-2);
                var mm = ("0" + utcDateTime.getMinutes()).slice(-2);
                var YY = ("" + utcDateTime.getFullYear()).slice(-2);
                var scnPrefix = '0047';
                var prefix0002DTscn = new Date(2018, 1, 14, 19, 48, 0);
                //print(prefix0002DTscn);

                if (utcDateTime > prefix0002DTscn) {
                    scnPrefix = '0002';
                }
                var baseurl = "https://s3-eu-west-1.amazonaws.com/informetics/ftp2/biofos/Hvidovre/png/" + utcDateTime.getFullYear() + "/" + MM + "/" + dd + "/" + scnPrefix + "_";
                var baseCarturl = "https://s3-eu-west-1.amazonaws.com/informetics/ftp2/biofos/Hvidovre/cart2png/" + utcDateTime.getFullYear() + "/" + MM + "/" + dd + "/hvid";
                var baseDMIurl = "https://s3-eu-west-1.amazonaws.com/informetics/ftp2/DK/png/dk/" + utcDateTime.getFullYear() + "/" + MM + "/" + dd + "/DK_interp_";
                var baseuRurl = "https://s3-eu-west-1.amazonaws.com/informetics/ftp2/biofos/Hvidovre/pnguR/" + utcDateTime.getFullYear() + "/" + MM + "/" + dd + "/";
                var baseWR110url = "https://s3-eu-west-1.amazonaws.com/kbh1wr110/FurunoHQ/pnguR/" + utcDateTime.getFullYear() + "/" + MM + "/" + dd + "/";

                console.log(baseCarturl);
                var timestr = utcDateTime.getFullYear() + MM + dd + '_' + hh + mm + '00';
                //fieldNameElement.textContent = ("0" + datetime.getHours()).slice(-2) + ":" + mm;
                //timeonmap.textContent = ("0" + datetime.getHours()).slice(-2) + ":" + mm;
                //$scope.timeText = ("0" + datetime.getHours()).slice(-2) + ":" + mm;
                $scope.dateText = datetime.toLocaleDateString();
                //dateElement.textContent = datetime.toLocaleDateString();

                if (state == 'play') {
                    //controlNameElement.textContent = $translate.instant('Afspildning') + " " + Math.round(10000 / speed) / 10 + " fps";
                } else {
                    //controlNameElement.textContent = $translate.instant('Animation_stopped');
                }

            }

            $scope.toggleDataSource = function () {
                
                $scope.selectedDataSource = $scope.dataSources[$scope.selectedDataSourceId]; 
                $scope.img.setUrl($scope.selectedDataSource.BaseURL);
            }

            function imageExists(url, callback) {
                var img = new Image();
                img.onload = function () { callback(true); };
                img.onerror = function () { callback(false); };
                img.src = url;
            }
            function animate() {
                var startdate = new Date(datetime);
                //startdate = $scope.animateTime.toDate;
                var minutesback = 0;
                if ($scope.sidste30min) {
                    minutesback = $scope.nsteps;
                    datetime.setMinutes(startdate.getMinutes() - minutesback * $scope.stepLength);
                }
                $scope.AnimateStep = 0;
                var elem = document.getElementById("animatedElem"),
                    left = 0, timer;
                // Move the element 10px on the right every 16ms
                timer = setInterval(function () {
                    step += 1;
                    left = step * 1;
                    $scope.AnimateStep = JSON.parse(localStorage.getItem("step"))
                    datetime.setMinutes(datetime.getMinutes() + $scope.stepLength);
                    SetImage(datetime);
                    if (state == 'stop') {
                        clearInterval(timer);
                    }
                    if ($scope.sidste30min) {
                        if (left == minutesback * 10) {
                            step = 0;
                            left = 0;
                            $scope.AnimateStep = 0;
                            var currentDateTime = new Date();
                            if ((currentDateTime.getMinutes() - startdate.getMinutes()) < 10) {
                                //Ensure that the startime is a whole number stepLenth
                                // e.g. assumes that if step length is 5 - then startdata-minute part should be devisible by step length
                                if (currentDateTime.getMinutes() % $scope.stepLength == 0) startdate.setMinutes(currentDateTime.getMinutes());
                                // $scope.animateTime.toDate = startdate;
                            }
                            datetime.setMinutes(startdate.getMinutes() - minutesback * $scope.stepLength);
                        }
                        else if (left == 0) { left = minutesback * 10; }
                    }
                }, speed);
            }


            function renderLegendIconHtml(icon) {
                return '<span class="half awesome-marker-icon-' + icon.markerColor + ' awesome-marker leaflet-clickable"><i class=" glyphicon ' + icon.icon + ' icon-white"></i></span>';
            }
        }]);

