'use strict';

/**
 * @ngdoc function
 * @name mapsApp.controller:LoginctrlCtrl
 * @description
 * # LoginctrlCtrl
 * Controller of the mapsApp
 */
angular.module('mapsApp')
  .controller('ConfirmEmailCtrl', ['$scope', '$location', 'profileFactory', '$stateParams', '$mdDialog', '$translate',
      function ($scope, $location, profileFactory, $stateParams, $mdDialog, $translate) {

          console.log($stateParams);

          var userId = $stateParams.id;
          var token = $stateParams.token;

          $scope.message = undefined;
          $scope.loading = false;

          confirmEmail();

          function confirmEmail(ev) {
              $scope.loading = true;
              profileFactory.confirmEmail(userId, token).then(function (response) {
                  $scope.loading = false;

                  var confirm = $mdDialog.confirm()
                        .title($translate.instant('email_succesfully_confirmed'))
                        .textContent($translate.instant('email_succesfully_confirmed_message'))
                        .ariaLabel('Email Confirmed Prompt')
                        .targetEvent(ev)
                        .ok('Ok');
                  $mdDialog.show(confirm).then(function () {
                      $location.path("/");
                  });
                  console.log(response);
              },
               function (error) {
                   $scope.loading = false;
                   var message = '';
                   if (error) {
                       if (error.Message) {
                           message = message + error.Message
                       }
                       else {
                           var errors = [];
                           for (var key in error) {
                               for (var i = 0; i < error[key].length; i++) {
                                   errors.push(error[key][i]);
                               }
                           }

                           message = message + ' ' + errors.join(' ');
                       }

                   }

                   $scope.message = message;
                   console.log(error);
               });
          };

      }]);

