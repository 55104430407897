'use strict';

var Highcharts = require("highcharts");
var proj4 = require("proj4").default;

angular.module('mapsApp')
    .controller('vevaCtrl', ['$http', '$scope', '$q', '$timeout', '$mdMedia', '$mdBottomSheet', '$mdToast', '$interval', '$mdSidenav', '$mdDialog', '$window', '$location', 'authFactory', 'localStorageService', '$filter', '$translate', 'ChartDefaultsService', 'ngAuthSettings', 'vevaFactory',
        function ($http, $scope, $q, $timeout, $mdMedia, $mdBottomSheet, $mdToast, $interval, $mdSidenav, $mdDialog, $window, $location, authFactory, localStorageService, $filter, $translate, ChartDefaultsService, ngAuthSettings, vevaFactory) {
            var dataArrayGroup = [], layerIndex = 0, numberOfFinishedRequests = 0, numberOfTotalRequests = 2; var dataArrayGroupCoordinates = []; var toggleCanvas = false;
            var reference, mouseClickEvent = {}, mouseClickLayer = [];
            if (!authFactory.authentication.isAuth) {
                $location.path('/login');
                return;
            }
            $scope.swagger = ngAuthSettings.API_URL + 'swagger/index.html';
            var mouseClickLayerIndex = 0;
            var radarLayer = [];
            var map, gauge3, transformfactor;
            $scope.Status_info = "Intet Valgt";
            $scope.pointSelected = {};
            $scope.showDownLoad1file = false;
            $scope.gettingFiles = false;
            $scope.jsonData = [];
            $scope.svk_markers = [];
            $scope.parameters = [
                { Color: 'blue', NoDecimals: 1, DefaultMin: 0, DefaultMax: null, Id: 204, UnitName: 'mm/time' },
                { Color: 'black', NoDecimals: 1, DefaultMin: 0, DefaultMax: null, Id: 314, UnitName: 'mm' },
                { Color: 'green', NoDecimals: 1, DefaultMin: 0, DefaultMax: null, Id: 314, UnitName: 'm3/m2' },
                { Color: 'blue', NoDecimals: 1, DefaultMin: 0, DefaultMax: null, Id: 189, UnitName: 'm' }
            ];
            //initJsonData();
            var svk_stations = [];
            //$scope.loggedin = true;
            if (!authFactory.authentication.isAuth) {
              console.log("Welcome external user");
              $scope.loggedin = false;
              $location.path('/login');
            }

            proj4.defs('EPSG:5799', '+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs');
            proj4.defs('EPSG:32632', '+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs');
            proj4.defs('WGS84', "+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees");
            proj4.defs("EPSG:3006", "+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");


            $scope.login = function () {
                console.log("Logging in");
            };

            var pageChart;
            $scope.Jobs = [];
            $scope.selectedDataformat = 0;
            $scope.selectedDataFormat = {};

            $scope.steps = [
                { Id: 0, Name: 'select_datasource', Done: false, Icon: 'looks_1' },
                { Id: 1, Name: 'select_area', Done: false, Icon: 'looks_2' },
                { Id: 2, Name: 'select_period', Done: false, Icon: 'looks_3' },
                { Id: 3, Name: 'select_output', Done: false, Icon: 'looks_4' },
                { Id: 4, Name: 'get_data', Done: false, Icon: 'looks_5' }
            ];

            $scope.geoSelectionTypes = [
                { Id: 0, Name: 'Punkt', ShowGeoJSON: true, GeoJsonExample: 'punkt', Dimension :0, Disabled: false },
                { Id: 1, Name: 'Polygon', ShowGeoJSON: true, GeoJsonExample: 'polygon', Dimension: 2, Disabled: false }
            ];
            $scope.geoSelectionTypeSelected = 1;
            $scope.showTimeseriesPlot = false;

            $scope.dataFormats = [
                //{ Id: 0, Name: 'WR2100 .scn', Type: '2D-elevation', Dimension: 2, Disabled: true, S3Src: '', S3Prefix: '', API: '' },
                //{ Id: 1, Name: 'Odim_h5', Type: '2D-3D', Dimension: 2, Disabled: true, S3Src: '', S3Prefix: '', API: '' },
                //{ Id: 2, Name: 'Billeder', Type: '2D-image', Dimension: 2, Disabled: true, S3Src: '', S3Prefix: '', API: '' },
                { Id: 3, Name: 'dfs0', Type: 'Timeseries punkt', Dimension: 0, Disabled: true, S3Src: '', S3Prefix: '', API: '' },
                { Id: 4, Name: 'dfs0', Type: 'Timeseries area average', Dimension: 2, Disabled: true, S3Src: '', S3Prefix: '', API: '' },
                { Id: 8, Name: 'json', Type: 'Timeseries punkt', Dimension: 0, Disabled: true, S3Src: '', S3Prefix: '', API: '' },
                { Id: 5, Name: 'json', Type: 'Timeseries alle celler', Dimension: 2, Disabled: true, S3Src: '', S3Prefix: '', API: '' },
                { Id: 6, Name: 'json', Type: 'Timeseries area average', Dimension: 2, Disabled: true, S3Src: '', S3Prefix: '', API: '' },
                { Id: 7, Name: 'dfs2', Type: '2D-overflade', Dimension: 2, Disabled: true, S3Src: '', S3Prefix: '', API: '' }
            ];

            var plotSeriesDefinitions = [];




            $scope.dataTypes = [
                { Id: 0, Name: 'Komposit' },
                { Id: 1, Name: 'C-bånd' },
                { Id: 2, Name: 'X-bånd' },

            ];

            $scope.mapImgOverlays = [
                { Id: 0, radarImg: {}, Active: false, Name: 'DMI Rain', dt: 1, BaseURL: 'https://s3-eu-west-1.amazonaws.com/veva-processed-files/plots/dmi/interpolated/', NoData: "dmi_interpolated_missingdata.png", geoCenter: [,], epsg: 'EPSG:32632', utmCenter: [,], utmLL: [184941, 5803829], utmUR: [1155660, 6690579], dxdy: '500', x0: 0, y0: 0, imgRadius: 0, shiftXY: [-55000, 17000], shiftWH: [0, 0], imgPath: function (yyyy, YY, MM, dd, hh, mm) { return yyyy + "/" + MM + "/" + dd + "/dmi_interpolated_" + yyyy + MM + dd + hh + mm + ".png"; } }

            ];
            calcImgBounds();

            $scope.selectedDataSourceId = 0;
            $scope.dataSources = [
//                { Id: 1, radarId: 1, typeId: 2, Active: false, Name: 'WR2100 Hvidovre', Short: 'HoforX', Description: "X-band radar data omkring København", FormatId: [0, 2, 3, 4, 5], dt: 1, Elevations: [1, 2, 3, 4, 5, 6], isApi: false, BaseURL: 'https://s3-eu-west-1.amazonaws.com/informetics/ftp2/biofos/Hvidovre/scn/', epsg: 'EPSG:32632', prefix: '0002', path: function (yyyy, MM, dd, hh, mm, e, prefix) { return yyyy + "/" + MM + "/" + dd + "/" + prefix + '_' + yyyy + MM + dd + '_' + hh + mm + '00_0' + e + "_02.scn"; } },
                { Id: 8, radarId: 8, productId:102, typeId: 2, Active: true, Name: 'DMI komposit', Short: 'DMI', Description: "Griddede data for hele DK - interpoleret", FormatId: [3, 4, 5, 6, 7, 8], dt: 1, Elevations: [], isApi: false, BaseURL: 'https://s3-eu-west-1.amazonaws.com/veva-dmi/dmi/nowcast/interpolated/', epsg: 'EPSG:32632', prefix: '', path: function (yyyy, MM, dd, hh, mm, e, prefix) { return yyyy + "/" + MM + "/" + dd + "/interpolated.dk.com" + yyyy + MM + dd + hh + mm + ".h5"; } }, // 2020 0303 0001.h5
                { Id: 103, radarId: 103, productId: 103, typeId: 2, Active: true, Name: 'DMI composite VeVa processing', Short: 'VeVa', Description: "VeVa processerede", FormatId: [3, 4, 5, 6, 7,8], dt: 1, Elevations: [], isApi: false, BaseURL: 'https://s3-eu-west-1.amazonaws.com/veva-dmi/dmi/nowcast/interpolated/', epsg: 'EPSG:32632', prefix: '', path: function (yyyy, MM, dd, hh, mm, e, prefix) { return yyyy + "/" + MM + "/" + dd + "/interpolated.dk.com" + yyyy + MM + dd + hh + mm + ".h5"; } }, // 2020 0303 0001.h5
                { Id: 301, radarId: 301, productId: 301, typeId: 2, Active: true, Name: 'X-band Frejlev', Short: 'Aa', Description: "X-band Store Restrup, Veva processerede", FormatId: [3, 4, 5, 6, 7,8], dt: 1, Elevations: [], isApi: false, BaseURL: 'https://informetics.s3.eu-west-1.amazonaws.com/ftp2/biofos/Hvidovre/processed-files/', epsg: 'EPSG:32632', prefix: '', path: function (yyyy, MM, dd, hh, mm, e, prefix) { return yyyy + "/" + MM + "/" + dd + "/ProcessedHerlev_" + yyyy + MM + dd + "_" + hh + mm + ".h5"; } }, // 2020 0303 0001.h5
                { Id: 303, radarId: 303, productId: 303, typeId: 2, Active: true, Name: 'X-band Sabro', Short: 'AAV', Description: "X-band AAV VeVa processerede", FormatId: [3, 4, 5, 6, 7,8], dt: 1, Elevations: [], isApi: false, BaseURL: 'https://veva-nsva.s3.eu-north-1.amazonaws.com/h5/', epsg: 'EPSG:32632', prefix: '', path: function (yyyy, MM, dd, hh, mm, e, prefix) { return yyyy + "/" + MM + "/" + dd + "/2022_" + yyyy + MM + dd + "_" + hh + mm + ".h5"; } }, // 2020 0303 0001.h5
                { Id: 304, radarId: 304, productId: 304, typeId: 2, Active: true, Name: 'X-band Soendersoe', Short: 'VCS', Description: "X-band VCS VeVa processerede", FormatId: [3, 4, 5, 6, 7,8], dt: 1, Elevations: [], isApi: false, BaseURL: 'https://veva-nsva.s3.eu-north-1.amazonaws.com/h5/', epsg: 'EPSG:32632', prefix: '', path: function (yyyy, MM, dd, hh, mm, e, prefix) { return yyyy + "/" + MM + "/" + dd + "/2022_" + yyyy + MM + dd + "_" + hh + mm + ".h5"; } }, // 2020 0303 0001.h5
                { Id: 305, radarId: 305, productId: 305, typeId: 2, Active: true, Name: 'X-band Herlev', Short: 'HOFOR', Description: "X-band Cph VeVa processerede", FormatId: [3, 4, 5, 6, 7,8], dt: 1, Elevations: [], isApi: false, BaseURL: 'https://informetics.s3.eu-west-1.amazonaws.com/ftp2/biofos/Hvidovre/processed-files/', epsg: 'EPSG:32632', prefix: '', path: function (yyyy, MM, dd, hh, mm, e, prefix) { return yyyy + "/" + MM + "/" + dd + "/ProcessedHerlev_" + yyyy + MM + dd + "_" + hh + mm + ".h5"; } }, // 2020 0303 0001.h5
                { Id: 321, radarId: 321, productId: 321, typeId: 2, Active: true, Name: 'X-band Dalby', Short: 'VASyd', Description: "X-band VaSyd VeVa processerede", FormatId: [3, 4, 5, 6, 7,8], dt: 1, Elevations: [], isApi: false, BaseURL: 'https://veva-vasyd.s3.eu-north-1.amazonaws.com/h5/', epsg: 'EPSG:32633', prefix: '', path: function (yyyy, MM, dd, hh, mm, e, prefix) { return yyyy + "/" + MM + "/" + dd + "/0059_" + yyyy + MM + dd + "_" + hh + mm + ".h5"; } }, // 2020 0303 0001.h5
                { Id: 322, radarId: 322, productId: 322, typeId: 2, Active: true, Name: 'X-band NSVA', Short: 'NSVA', Description: "X-band Helsingborg VeVa processerede", FormatId: [3, 4, 5, 6, 7,8], dt: 1, Elevations: [], isApi: false, BaseURL: 'https://veva-nsva.s3.eu-north-1.amazonaws.com/h5/', epsg: 'EPSG:32633', prefix: '', path: function (yyyy, MM, dd, hh, mm, e, prefix) { return yyyy + "/" + MM + "/" + dd + "/2022_" + yyyy + MM + dd + "_" + hh + mm + ".h5"; } }, // 2020 0303 0001.h5
                { Id: 351, radarId: 351, productId: 351, typeId: 2, Active: true, Name: 'C-band Sindal', Short: 'Sindal', Description: "C-band Sindal VeVa processerede", FormatId: [3, 4, 5, 6, 7,8], dt: 1, Elevations: [], isApi: false, BaseURL: 'https://veva-nsva.s3.eu-north-1.amazonaws.com/h5/', epsg: 'EPSG:32633', prefix: '', path: function (yyyy, MM, dd, hh, mm, e, prefix) { return yyyy + "/" + MM + "/" + dd + "/2022_" + yyyy + MM + dd + "_" + hh + mm + ".h5"; } }, // 2020 0303 0001.h5
                { Id: 352, radarId: 352, productId: 352, typeId: 2, Active: true, Name: 'C-band Virring', Short: 'Virring', Description: "C-band Virring VeVa processerede", FormatId: [3, 4, 5, 6, 7,8], dt: 1, Elevations: [], isApi: false, BaseURL: 'https://veva-nsva.s3.eu-north-1.amazonaws.com/h5/', epsg: 'EPSG:32633', prefix: '', path: function (yyyy, MM, dd, hh, mm, e, prefix) { return yyyy + "/" + MM + "/" + dd + "/2022_" + yyyy + MM + dd + "_" + hh + mm + ".h5"; } }, // 2020 0303 0001.h5
                { Id: 353, radarId: 353, productId: 353, typeId: 2, Active: true, Name: 'C-band Romoe', Short: 'Romoe', Description: "C-band Romoe VeVa processerede", FormatId: [3, 4, 5, 6, 7,8], dt: 1, Elevations: [], isApi: false, BaseURL: 'https://veva-nsva.s3.eu-north-1.amazonaws.com/h5/', epsg: 'EPSG:32633', prefix: '', path: function (yyyy, MM, dd, hh, mm, e, prefix) { return yyyy + "/" + MM + "/" + dd + "/2022_" + yyyy + MM + dd + "_" + hh + mm + ".h5"; } }, // 2020 0303 0001.h5
                { Id: 354, radarId: 354, productId: 354, typeId: 2, Active: true, Name: 'C-band Stevns', Short: 'Stevns', Description: "C-band Stevns VeVa processerede", FormatId: [3, 4, 5, 6, 7,8], dt: 1, Elevations: [], isApi: false, BaseURL: 'https://veva-nsva.s3.eu-north-1.amazonaws.com/h5/', epsg: 'EPSG:32633', prefix: '', path: function (yyyy, MM, dd, hh, mm, e, prefix) { return yyyy + "/" + MM + "/" + dd + "/2022_" + yyyy + MM + dd + "_" + hh + mm + ".h5"; } }, // 2020 0303 0001.h5
                { Id: 355, radarId: 355, productId: 355, typeId: 2, Active: true, Name: 'C-band Bornholm', Short: 'Bornholm', Description: "C-band Bornholm VeVa processerede", FormatId: [3, 4, 5, 6, 7,8], dt: 1, Elevations: [], isApi: false, BaseURL: 'https://veva-nsva.s3.eu-north-1.amazonaws.com/h5/', epsg: 'EPSG:32633', prefix: '', path: function (yyyy, MM, dd, hh, mm, e, prefix) { return yyyy + "/" + MM + "/" + dd + "/2022_" + yyyy + MM + dd + "_" + hh + mm + ".h5"; } }, // 2020 0303 0001.h5

//                { Id: 9, radarId: 9, typeId: 2, Active: false, Name: 'Aalborg St.Restrup', Short: 'AaX', Description: "X-band data fra Aalborg", FormatId: [0, 1], dt: 1, Elevations: [], isApi: false, BaseURL: 'https://s3-eu-west-1.amazonaws.com/veva-dmi/WR2100/Aalborg_Kloak/st.restrup/', epsg: 'EPSG:32632', prefix: '', path: function (yyyy, MM, dd, hh, mm, e, prefix) { return yyyy + "/" + MM + "/" + dd + "/furu" + hh + mm + "00.h5"; } }, // 2020 0303 0001.h5
//                { Id: 10, radarId: 10, typeId: 2, Active: false, Name: 'Aarhus Sabro', Short: 'AaVX', Description: "X-band data fra Aarhus", FormatId: [0, 1], dt: 1, Elevations: [], isApi: false, BaseURL: 'https://s3-eu-west-1.amazonaws.com/veva-aav/h5/sabro/', epsg: 'EPSG:32632', prefix: '', path: function (yyyy, MM, dd, hh, mm, e, prefix) { return yyyy + "/" + MM + "/" + dd + "/sabr" + yyyy + MM + dd + hh + mm + "00.h5"; } }, // 2020 0303 0001.h5
//                { Id: 11, radarId: 11, productId: 103, typeId: 2, Active: false, Name: 'VeVa-DMI-Composite', Short: 'VeVa', Description: "VeVa processerede C-band data from DMI", FormatId: [0, 1], dt: 1, Elevations: [], isApi: false, BaseURL: 'https://s3-eu-west-1.amazonaws.com/veva-', epsg: 'EPSG:32632', prefix: '', path: function (yyyy, MM, dd, hh, mm, e, prefix) { return yyyy + "/" + MM + "/" + dd + "/furu" + hh + mm + "00.h5"; } }, // 2020 0303 0001.h5
            ];
            $scope.selectedDataSource = $scope.dataSources[0];

            //initJson must also be updated when adding new - should be fixed...
            $scope.polygonSources = [
                { Id: 0, Name: 'None', FileName: '' },
                //{ Id: 1, Name: 'AAV Oplande', FileName: 'aav_PROD_MU_OPLANDE_AKTIV_T_region.json' },
                { Id: 1, Name: 'AAV Oplande', FileName: 'AavOplande.json' } 
            ];
            $scope.polygonSourceId = 0;
            $scope.polygon_layer = null;
            $scope.selectedPolygonLayer = null;


            $scope.allFileSources = [];
            $scope.currentFileSrc = "";

            $scope.updateCurrentFiles = function (yyyy, MM, dd, hh, mm) {
                $scope.currentFileSrc = getFileSrc(yyyy, MM, dd, hh, mm);
            };

            function getFileSrc(yyyy, MM, dd, hh, mm) {
                return $scope.selectedDataSource.BaseURL + $scope.selectedDataSource.path(yyyy, MM, dd, hh, mm, $scope.elevation, $scope.selectedDataSource.prefix);
            }

            $scope.toggleDataSource = function () {
                console.log($scope.selectedDataSourceId);
                //$scope.selectedDataSource = $.grep($scope.dataSources, function (o) { return o.Id === $scope.selectedDataSourceId; });
                $scope.selectedDataSource = $scope.dataSources.find(function (o) { return o.Id === $scope.selectedDataSourceId; });
                $scope.allFileSources = [];
                for (var i = 0; i < $scope.dataFormats.length; i++) {
                    if ($scope.selectedDataSource.FormatId.includes($scope.dataFormats[i].Id)) {
                        $scope.dataFormats[i].Disabled = false;
                        $scope.selectedDataFormat = $scope.dataFormats[i];
                    } else $scope.dataFormats[i].Disabled = true;

                }
                //if ($scope.selectedDataSource.isApi) {
                //    $scope.geoSelectionType = "Definer Location->";
                //}
                //else {
                //    $scope.geoSelectionType = "Fast lokation";
                //    SelectLocalRadar($scope.selectedDataSource.radarId);
                //}
                $scope.steps[0].Done = true;
                console.log("Toggledata source :", $scope.selectedDataSource);
                console.log("Formats", $scope.dataFormats);
            };
            $scope.togglePeriod = function () {
                $scope.minutes = ($scope.toDate - $scope.fromDate) / 60000;
                $scope.weeks = Math.round($scope.minutes * 10 / (60 * 24 * 7)) / 10;
                $scope.steps[2].Done = true;
                console.log("toggling date", $scope.fromDate);
                if ($scope.showTimeseriesPlot) $scope.updatePlot();
            };

            $scope.toggleShape = function () {
                $scope.geoSelection.GeoJSON = $scope.geoSelectionTypes[$scope.geoSelectionTypeSelected].GeoJsonExample;

            };

            $scope.toggleDataFormat = function () {
                console.log("Toggledata format :", $scope.selectedDataformat);
                $scope.steps[3].Done = true;
            };

            $scope.togglePolygon = function () {
                //$scope.geoSelection.GeoJSON = $scope.geoSelectionTypes[$scope.geoSelectionTypeSelected].GeoJsonExample;

            };

            function getGeoJSON(isPoint = false, coordinateArrPoints = [], isMultiPolygon = false) {
                //Check if the coordinateArrPoints is closed - if it is not the close it...
                if (coordinateArrPoints.length > 3) {
                    var firstPoint = coordinateArrPoints[0];
                    coordinateArrPoints.push(firstPoint);
                } 
                var json =
                    [{
                        "type": "Feature",
                        "properties": {
                            "name": "Tegnet",
                            "amenity": "Manuelt",
                            "popupContent": "Valgt feature"
                        },
                        "geometry": {
                            "type": "Polygon",
                            "coordinates": isMultiPolygon ? [coordinateArrPoints] : coordinateArrPoints
                        }
                    }];
                if (isPoint) {
                    json[0].geometry.type = "Point";
                    $scope.geoSelectionTypeSelected = 0;


                } else $scope.geoSelectionTypeSelected = 1;
                return json;

            }

            $scope.geoSelection = { Type: "Undefined", GeoJSON: {}, Coordinates: "", IOurl: "http://geojson.io"};
            $scope.geoSelectionType = "Location Undefined";
            $scope.updateGeoSelection = function (e) {
                console.log(e);
                var geojsonFeature =
                {
                    "type": "FeatureCollection"
                };
                var coordinateArr = [];
                var type = 'undefined;'
                if (e.shape == "Marker") {
                    var coords = "[" + e.marker._latlng.lng + "," + e.marker._latlng.lat + "]";
                    $scope.geoSelection.Coordinates += coords;
                    var coordinateArrPoints = [e.layer._latlng.lng, e.layer._latlng.lat];
                    $scope.pointSelected = e.layer._latlng;
                    geojsonFeature.features = getGeoJSON(true, coordinateArrPoints);
                    type = 'marker';
                    //var seriesId = AddSeriesAndUpdatePlot($scope.selectedDataSource.Short + coords, type);
                    //VeVaGetTimeSeries(true, seriesId);
                    $scope.Status_info = "Punkt valgt";
                }
                else {
                    for (var i = 0; i < e.layer._latlngs[0].length; i++) {
                        if (i > 0) $scope.geoSelection.Coordinates += ",";
                        $scope.geoSelection.Coordinates += "[" + e.layer._latlngs[0][i].lng + "," + e.layer._latlngs[0][i].lat + "]";
                        coordinateArr[i] = [e.layer._latlngs[0][i].lng, e.layer._latlngs[0][i].lat];
                    }
                    geojsonFeature.features = getGeoJSON(false, coordinateArr,true);
                    type = 'area';
                    //var seriesId = AddSeriesAndUpdatePlot("polygon", type);
                    //$scope.VeVaGetTimeSeriesPolygon(true, seriesId);
                    $scope.Status_info = "Polygon valgt";
                }
                console.log("Coordinates", $scope.geoSelection.Coordinates);
                $scope.geoSelection.Type = e.shape;
                $scope.geoSelectionType = "new";
                $scope.geoSelection.GeoJSON = JSON.stringify(geojsonFeature);
                $scope.geoSelection.IOurl = "http://geojson.io/#data=data:application/json," + encodeURIComponent(JSON.stringify(geojsonFeature));

                //if (type === 'marker') VeVaGetTimeSeries(true, seriesId);
                //else if (type === 'area') $scope.VeVaGetTimeSeriesPolygon(true, seriesId);
                $scope.steps[1].Done = true;
                //$scope.geojsonPolygon = L.geoJSON(geojsonFeature).addTo(map);
                console.log("GeoSelection", $scope.geoSelection);
            };

            var radarSpecsAll = [
                { Id: 1, Active: false, Name: 'WR2100 Hvidovre', dt: 1, Elevation: 6, Elevations: [4, 6], MaxElevation: 6, BaseURL: 'https://s3-eu-west-1.amazonaws.com/informetics/ftp2/biofos/Hvidovre/pnguR/', NoData: "WR2100-Hofor-no_data.png", geoCenter: [12.456968, 55.653227], zoomLevel: 9, epsg: 'EPSG:32632', utmCenter: [,], dxdy: '500', x0: 0, y0: 400, imgRadius: 69850, shiftXY: [-3250, 750], shiftWH: [-9000, 0], imgPath: function (yyyy, YY, MM, dd, hh, mm, e) { return yyyy + "/" + MM + "/" + dd + "/" + yyyy + MM + dd + hh + mm + '_0' + e + "_R.png"; } },
                //{ Id: 3, Active: false, Name: 'WR110 Avedøre', dt: 1, Elevation: 3, Elevations: [2, 3], MaxElevation: 4, BaseURL: 'https://s3-eu-west-1.amazonaws.com/kbh1wr110/FurunoHQ/pnguR/', NoData: "WR110-no_data.png", geoCenter: [12.480966, 55.606683], epsg: 'EPSG:32632', utmCenter: [,], dxdy: '1000', x0: 0, y0: 100, imgRadius: 98349, shiftXY: [-5150, 1250], shiftWH: [-9000, 0], imgPath: function (yyyy, YY, MM, dd, hh, mm, e) { return yyyy + "/" + MM + "/" + dd + "/" + yyyy + MM + dd + hh + mm + '_0' + e + "_R.png"; } },
                //{ Id: 6, Active: false, Name: 'WR2100 Dalby', dt: 1, Elevation: 3, Elevations: [3, 4], MaxElevation: 4, BaseURL: 'https://s3-eu-west-1.amazonaws.com/dalby-wr2100/pnguR/', NoData: "WR2100-Dalby-no_data.png", geoCenter: [13.35908, 55.67086], epsg: 'EPSG:32632', utmCenter: [,], dxdy: '500', x0: 0, y0: 400, imgRadius: 70000, shiftXY: [-3500, 1000], shiftWH: [-960 * 15, 0], imgPath: function (yyyy, YY, MM, dd, hh, mm, e) { return yyyy + "/" + MM + "/" + dd + "/" + yyyy + MM + dd + hh + mm + '_0' + e +"_R.png"; } },
                { Id: 8, Active: false, Name: 'DMI gridded', dt: 1, Elevation: 1, Elevations: [1], MaxElevation: 1, BaseURL: 'https://s3-eu-west-1.amazonaws.com/veva-processed-files/plots/dmi/interpolated/', NoData: "dmi_interpolated_missingdata.png", geoCenter: [12.2, 55.6], zoomLevel: 7, epsg: 'EPSG:32632', utmCenter: [,], utmLL: [184941, 5803829], utmUR: [1155660, 6690579], dxdy: '500', x0: 0, y0: 0, imgRadius: 0, shiftXY: [-55000, 17000], shiftWH: [0, 0], imgPath: function (yyyy, YY, MM, dd, hh, mm, e) { return yyyy + "/" + MM + "/" + dd + "/dmi_interpolated_" + yyyy + MM + dd + hh + mm + ".png"; } },
                { Id: 10, Active: false, Name: 'SMHI c-bånd', dt: 5, Elevation: 1, Elevations: [1], MaxElevation: 1, BaseURL: 'http://opendata-download-radar.smhi.se/api/version/latest/area/sweden/product/comp/', NoData: "WR2100-Hofor-no_data.png", geoCenter: [13.2, 55.8], zoomLevel: 7, epsg: 'EPSG:3006', utmCenter: [,], utmLL: [126648, 5983984], utmUR: [1075693, 7771252], dxdy: '2000', x0: 0, y0: 0, imgRadius: 69850, shiftXY: [-100000, -65000], shiftWH: [60000, 60000], imgPath: function (yyyy, YY, MM, dd, hh, mm) { return yyyy + "/" + MM + "/" + dd + "/radar_" + YY + MM + dd + hh + mm + ".png"; } },
                { Id: 11, Active: false, Name: 'Aarhus Sabro', dt: 1, Elevation: 6, Elevations: [4, 6], MaxElevation: 6, BaseURL: 'https://s3-eu-west-1.amazonaws.com/informetics/ftp2/biofos/Hvidovre/pnguR/', NoData: "WR2100-Hofor-no_data.png", geoCenter: [9.9999, 56.20703], zoomLevel: 9, epsg: 'EPSG:32632', utmCenter: [,], dxdy: '500', x0: 0, y0: 400, imgRadius: 69850, shiftXY: [-3250, 750], shiftWH: [-9000, 0], imgPath: function (yyyy, YY, MM, dd, hh, mm, e) { return yyyy + "/" + MM + "/" + dd + "/" + yyyy + MM + dd + hh + mm + '_0' + e + "_R.png"; } },
                { Id: 12, Active: false, Name: 'Aalborg St.Restrup', dt: 1, Elevation: 6, Elevations: [4, 6], MaxElevation: 6, BaseURL: 'https://s3-eu-west-1.amazonaws.com/informetics/ftp2/biofos/Hvidovre/pnguR/', NoData: "WR2100-Hofor-no_data.png", geoCenter: [9.78304, 57.00835], zoomLevel: 9, epsg: 'EPSG:32632', utmCenter: [,], dxdy: '500', x0: 0, y0: 400, imgRadius: 69850, shiftXY: [-3250, 750], shiftWH: [-9000, 0], imgPath: function (yyyy, YY, MM, dd, hh, mm, e) { return yyyy + "/" + MM + "/" + dd + "/" + yyyy + MM + dd + hh + mm + '_0' + e + "_R.png"; } },

                //{ Id: 99, Active: false, Name: 'WR2100 Hofor2', Elevation: 6, Elevations: [6], MaxElevation: 6, BaseURL: 'https://s3-eu-west-1.amazonaws.com/informetics/ftp2/biofos/Hvidovre/pnguR/', NoData: "WR2100-Hofor-no_data.png", geoCenter: [12.456968, 55.653227], epsg: 'EPSG:32632', utmCenter: [,], dxdy: '500', x0: 0, y0: 400, imgRadius: 69850, shiftXY: [-3250, 750], shiftWH: [-9000, 0], imgPath: function (yyyy, YY, MM, dd, hh, mm, e) { return yyyy + "/" + MM + "/" + dd + "/" + yyyy + MM + dd + hh + mm + '_0' + e + "_R.png"; } },
                //{ Id: 7, radarImg: {}, Active: false, Name: 'SMHI', Elevation: 1, MaxElevation: 1, BaseURL: 'https://s3-eu-west-1.amazonaws.com/informetics-vasyd/Dalby/pnguR/', NoData: "WR2100-Dalby-no_data.png", geoCenter: [16.9328655, 62.0173308], epsg: 'EPSG:3006', utmCenter: [,], dxdy: '500', x0: 0, y0: 400, imgRadius: 70000, shiftXY: [0, 0], shiftWH: [0, 0] }
            ];

            $scope.layersState = {};
            $scope.pointTypes = {};
            $scope.parameterCollections = {};
            $scope.currentMarker = {};
            $scope.toDate = new Date(); //2020-10-04T06:00
            $scope.toDate.setDate($scope.toDate.getDate() - 1);
            $scope.toDate.setSeconds(0);
            $scope.toDate.setMilliseconds(0);
            $scope.fromDate = new Date();
            $scope.fromDate.setDate($scope.toDate.getDate() - 2);
            $scope.fromDate.setSeconds(0);
            $scope.fromDate.setMilliseconds(0);


            $scope.currentDateTime = new Date(2020, 1, 1);

            $scope.responseFiles = { time: $scope.fromDate, value: '?' };
            $scope.responseValues = { time: $scope.fromDate, value: '?' };
            $scope.showResponseValues = false;

            $scope.animToDate = new Date();
            $scope.animFromDate = new Date();
            $scope.animFromDate.setMinutes($scope.animToDate.getMinutes() - 15);
            $scope.elevation = 3;
            $scope.rainEvents = {};
            $scope.autoscaleYaxis = true;
            $scope.timeSeriesLoding = false;
            $scope.currentParameter = {};
            $scope.panTimeSpan = 1;
            $scope.panTimeSpanOptions = [{ name: '1 minut', value: 1 }, { name: '10 minutter', value: 10 }, { name: '1 time', value: 60 }, { name: '1 døgn', value: 1440 }];

            $scope.layers = { baselayers: {} };
            $scope.chartConfig = {};
            $scope.tableHeaders = {};
            $scope.initedChartResults = 0;
            $scope.allChartsInit = false;
            $scope.autoscaleYaxis = false;
            $scope.plotActive = false;
            $scope.dataSelected = false;
            $scope.isSideNavOpen = true;
            $scope.isPlotOpen = false;
            $scope.isInfoOpen = false;
            $scope.isForecastOpen = false;
            $scope.AnimateStep = 0;
            $scope.markers = {};
            $scope.driftmsg = "no status";
            $scope.useAccumulation = false;
            $scope.showLoading = false;
            var step = 0;
            $scope.nsteps = 15;
            var lookforSMHI = false;
            var lookforCart = false;
            var lookforDMI = false;
            var lookforuR = false;
            var lookforWR110 = false;
            var lookforBase = false;

            var utcDateTime = new Date();
            var firstDate = new Date(); //2017, 8, 17, 11, 40, 0, 0);//(););
            var showValuesCanvas = false;
            var commentsON = false;
            $scope.selectedIds = [];
            $scope.catchments = "";
            firstDate.setSeconds(0);
            firstDate.setMilliseconds(0);
            $scope.animateTime = { toDate: new Date(firstDate), maxDate: new Date() };
            $scope.eventdaysback = 360;
            $scope.weeksProcessed = 0;
            $scope.weeks = 0;
            $scope.minutes = 0;
            $scope.eventmindepth = Number(0);
            $scope.eventminduration = Number(0);
            $scope.greaterThan = function (prop, val) {
                return function (item) {
                    return Number(item[prop]) > val;
                };
            };
            $scope.mapstate = {};
            $scope.dateText = "";
            $scope.timeText = "--:--";
            $scope.fabT = {};
            $scope.fabT.hidden = false;
            $scope.fabT.isOpen = false;
            $scope.fabT.hover = false;
            var markerLayers = [];
            var layerControl;
            var layersStateScope = [];
            var parameterCollectionsScope = [];
            var chartParametersScope = [];
            var parametersScope = [];
            //var IconsNew = {};



            $scope.toggleOpen = function (isOpen) {
                isOpen = !isOpen;
            };


            $scope.toggleRight = function () {
                $mdSidenav('right').toggle()
                    .then(function () {
                        console.log("toggle RIGHT is done");
                    });
            };
            $scope.toggleMenuEvent = function () {
                $scope.isRightSideNavnOpen = !$scope.isRightSideNavnOpen;
                console.log("Clicked menu toggle")
            };

            $scope.toggleMenuRadar = function () {
                $scope.isSideNavOpen = !$scope.isSideNavOpen;
            };

            $scope.toggleMenuPlot = function () {
                $scope.isPlotOpen = !$scope.isPlotOpen;
            };

            $scope.toggleInfo = function () {
                $scope.isInfoOpen = !$scope.isInfoOpen;
            };

            $scope.toggleForecast = function () {
                $scope.isForecastOpen = !$scope.isForecastOpen;
            };



            $scope.isSidenavLockedOpen = function (windowSize) {

                var newLockedOpen = $scope.autoCloseSidenav
                    ? $mdMedia(windowSize)
                    : false;

                if (lockedOpen !== newLockedOpen) {
                    setTimeout(function () {
                        $scope.triggerWindowResize();
                    }, 3000);
                }
                lockedOpen = newLockedOpen;

                return newLockedOpen;
            };

            $scope.updateImage = function () {
               // SetImage();
            };

            $scope.selectElement = function (id, toggleSidebar, focus) {
                console.log("Selected point : " + id + "," + toggleSidebar)
                //     $mdSidenav('nav-plot').toggle();
                $scope.isPlotOpen = true;
                $scope.currentMarker = $scope.markers[id];
                $scope.markers[id].focus = true;

                if (focus === true) {
                    $scope.mapstate.map.setView($scope.currentMarker._leafletMarker._latlng);
                    $scope.currentMarker._leafletMarker.openPopup();
                }
                $scope.updatePlot(str(id));
            };
            if ($scope.useAccumulation) {
                var dataArray = getPlotRadarData('2016-10-17 19:30:00.000');
            }


            function getPlotRadarData(time) {
                //------------------------------------------------------------------------------------
                // This function calls everything necessary to plot the image of a specific timeframe time
                //------------------------------------------------------------------------------------
                //var timeArray = ['2016-10-17 19:30:00.000', '2016-10-17 19:25:06.000'];

                requestRadarData(time, 0);

                console.log("Grabbing Data");
            }
            //------------------------------------------------------------------------------------
            $scope.callPlot = function (time) {

                getPlotRadarData(time);
            };
            $scope.callPlotSquare = function (eventIndex) {
                var time = new Date($scope.rainEvents[eventIndex].start);
                console.log(time);
                getRadarDataMySql(time);
                console.log("Grabbing Data");
            };

            function drawingOnCanvasSquare(canvasOverlay, params) {
                //------------------------------------------------------------------------------------
                // This function is responsible for the actual drawing of the canvas Radar Image
                //------------------------------------------------------------------------------------
                var tau = 2 * Math.PI;
                var coordinatesTopLeft = [56.10118539298188, 11.662568285703381],
                    coordinatesBottomRight = [55.23976793801097, 13.268154157682142],
                    coordinatesTopRight = [coordinatesTopLeft[0], coordinatesBottomRight[1]],
                    // var coordinatesTopLeft = [56.07631065528192, 11.63322207139455],
                    //coordinatesBottomRight = [55.22422412402805, 13.298777486535094],
                    coordinatesCenter = [(coordinatesTopLeft[0] + coordinatesBottomRight[0]) / 2, (coordinatesTopLeft[1] + coordinatesBottomRight[1]) / 2];


                //-----------------------------------------------------------------------------------------
                var RadarCenter = [12.456968, 55.653227]

                var RadarCenterUTM = proj4('WGS84', 'EPSG:32632', RadarCenter);
                console.log(RadarCenterUTM)//any object will do as long as it has 'x' and 'y' properties
                //var UtmBoundsLL = [RadarCenterUTM[0] + j * dxdy, RadarCenterUTM[1] + k * dxdy];
                //var UtmBoundsUR = [RadarCenterUTM[0] + (j + 1) * dxdy, RadarCenterUTM[1] + (k + 1) * dxdy];
                //console.log(UtmBoundsLL)
                //var WGSBoundsLL = proj4('EPSG:32632', 'WGS84', UtmBoundsLL);
                //var WGSBoundsUR = proj4('EPSG:32632', 'WGS84', UtmBoundsUR);
                //var bounds = [[WGSBoundsLL[1], WGSBoundsLL[0]], [WGSBoundsUR[1], WGSBoundsUR[0]]];
                //Rectangles.push(L.rectangle(bounds, { color: "#ff7800", weight: 1 }));
                //var UtmCenter = [RadarCenterUTM[0] + (j + 0.5) * dxdy, RadarCenterUTM[1] + (k + 0.5) * dxdy];
                //----------------------------------------------------------------------------------------------------------------------

                dot = canvasOverlay._map.latLngToContainerPoint(coordinatesCenter);
                var ctx = params.canvas.getContext('2d');
                console.log(params.canvas);
                ctx.globalAlpha = 0.5;
                //dataArray = $.grep(dataArrayGroup[layerIndex], function (e) {
                //return e.Range == 400
                //});
                dataArray = dataArrayGroup[layerIndex];
                //console.log(dataArray);
                ctx.clearRect(0, 0, params.canvas.width, params.canvas.height);


                var plotTimer;

                //        var coordinatesTopLeft = [55.81285742969946, 12.385711669921875],
                //coordinatesBottomRight = [55.523189221553544, 12.764739990234375],

                //svgOrigin = map.layerPointToLatLng([0,0]),
                var maxRadius = 1000,
                    innerRadius = 0,
                    color,
                    anglesRad,
                    numberOfRings,
                    stepRadius,
                    offsetx = 0,
                    offsety = 0;
                color = palette(0, 100);
                stepRadius = (maxRadius - innerRadius) / 1000;
                //Transformfactor rescales Radarplot after Zoom
                transformfactor = (canvasOverlay._map.latLngToContainerPoint(coordinatesBottomRight).x - canvasOverlay._map.latLngToContainerPoint(coordinatesTopLeft).x) / maxRadius;
                console.log(transformfactor);
                //ctx.beginPath();
                console.log(dot)
                //ctx.rect(dot.x, dot.y, 200, 200)
                //ctx.fill();
                //ctx.closePath();
                console.log(dot);
                dataArrayGroupCoordinates = [];
                for (var i = 0; i < dataArray.length; i++) {
                    ctx.fillStyle = chooseColor(dataArray[i].Value, color);

                    ctx.beginPath();
                    ctx.alpha = 0.5;
                    //ctx.lineWidth = 1 * transformfactor;
                    var coordinatesLATLNG = proj4('EPSG:32632', 'WGS84', [dataArray[i].Xcoord, dataArray[i].Ycoord])
                    dataArray[i].ID = i;
                    var pixelCoords = canvasOverlay._map.latLngToContainerPoint([coordinatesLATLNG[1], coordinatesLATLNG[0]])
                    if (commentsON) {
                        console.log([dataArray[i].Xcoord, dataArray[i].Ycoord])
                        console.log(coordinatesLATLNG)
                        console.log(pixelCoords);
                    }

                    //ctx.rect(pixelCoords[0], pixelCoords[1], 100, 100);

                    ctx.rect(pixelCoords.x, pixelCoords.y, 5 * transformfactor, 5 * transformfactor)
                    dataArrayGroupCoordinates.push({ Value: dataArray[i].Value, x: pixelCoords.x, y: pixelCoords.y, transformfactor: transformfactor, xOrg: dataArray[i].Xcoord, yOrg: dataArray[i].Ycoord })
                    //console.log(canvasOverlay._map.latLngToContainerPoint(proj4('EPSG:32632', 'WGS84', RadarCenter)))
                    //ctx.rect(canvasOverlay._map.latLngToContainerPoint(proj4('EPSG:32632', 'WGS84', [RadarCenter[1],RadarCenter[0]]))[0], canvasOverlay._map.latLngToContainerPoint(proj4('EPSG:32632', 'WGS84', [RadarCenter[1],RadarCenter[0]]))[1], 2200, 2200);
                    //context.arc(xcoord, ycoord, 400, 0, tau, true);
                    ctx.fill();
                    ctx.closePath();
                }


                function chooseColor(value, color) {
                    return color(value)
                }



                function decideColor(dat, maxDat) {
                    return rgb(0, 0, "+ dat/maxDat*10 + ");
                }
                function palette(min, max) {
                    var d = (max - min) / 9;
                    return d3.scaleThreshold()
                        .range(['#2c7bb6', '#00a6ca', '#00ccbc', '#c90eb9d', '#ffff8c', '#f9d057', '#f29e2e', '#e76818', '#d7191c'])
                        .domain([min + 1 * d, min + 2 * d, min + 3 * d, min + 4 * d, min + 5 * d, min + 6 * d, min + 7 * d, min + 8 * d, min + 9 * d]);
                }
            }
            var radarLayerIndex = 0;
            function startDrawLayerSquare() {
                //------------------------------------------------------------------------------------
                // This function handles the drawing of the layer and handles the cases of redrawing and
                // sets the event listener for the mousemove
                //------------------------------------------------------------------------------------
                //console.log(dataArray);
                //var radarLayer = [];
                dataArray = dataArrayGroup[0];
                radarLayer[0] = L.canvasOverlay(drawingOnCanvasSquare);

                radarLayer[0].addTo(map);
                if (radarLayerIndex != 0) {
                    map.fireEvent('moveend');


                }
                else {
                    radarLayerIndex++;
                }

                console.log("Radarlayer: " + radarLayerIndex);
                //mouseClickEvent.x = 0;
                //mouseClickEvent.y = 0;
                //mouseClickEvent.Value = 20;



                //radarLayer[0]._canvas.addEventListener("click", function (event) { clickListener(event) }, false);
                //map.addEventListener("mousemove", function (e) { clickListenerSquare(e.containerPoint) }, false);
                //map.addEventListener("click", function (e) { clickListenerTimeSeries(e.containerPoint) }, false);
                //var counter = 1;


                //setInterval(function () {

                //    setTimeout(function () {
                //        if (layerIndex == 0) {
                //            layerIndex++;
                //            //console.log(counter++);
                //        } else {
                //            layerIndex = 0;
                //        }
                //        console.log(layerIndex)
                //        radarLayer[counter] = L.canvasOverlay(drawingOnCanvas);
                //        radarLayer[counter].addTo(map);

                //        map.removeLayer(radarLayer[counter++ - 1]);



                //    }, 0);

                //}, 2000);

            }
            function startDrawLayer() {
                //------------------------------------------------------------------------------------
                // This function handles the drawing of the layer and handles the cases of redrawing and
                // sets the event listener for the mousemove
                //------------------------------------------------------------------------------------
                //console.log(dataArray);
                //var radarLayer = [];
                dataArray = dataArrayGroup[0];
                radarLayer[0] = L.canvasOverlay(drawingOnCanvas);

                //radarLayer[0].addTo(map);
                if (radarLayerIndex != 0) {
                    map.fireEvent('moveend');


                }
                else {
                    radarLayerIndex++;
                }

                console.log("Radarlayer: " + radarLayerIndex);
                //mouseClickEvent.x = 0;
                //mouseClickEvent.y = 0;
                //mouseClickEvent.Value = 20;



                //radarLayer[0]._canvas.addEventListener("click", function (event) { clickListener(event) }, false);
                map.addEventListener("mousemove", function (e) { clickListener(e.containerPoint) }, false);

                //var counter = 1;


                //setInterval(function () {

                //    setTimeout(function () {
                //        if (layerIndex == 0) {
                //            layerIndex++;
                //            //console.log(counter++);
                //        } else {
                //            layerIndex = 0;
                //        }
                //        console.log(layerIndex)
                //        radarLayer[counter] = L.canvasOverlay(drawingOnCanvas);
                //        radarLayer[counter].addTo(map);

                //        map.removeLayer(radarLayer[counter++ - 1]);



                //    }, 0);

                //}, 2000);

            }

            $scope.exportRadarData = function () {
                $scope.showLoading = true;
                $mdToast.show($mdToast.simple().textContent('Getting data for export').position('right top'));

                $http({
                    method: 'GET',
                    url: ngAuthSettings.API_URL + 'values/ExportVCSRadarData',
                    params: {
                        catchmentIds: $scope.selectedIds.join(","),
                        from: $scope.fromDate,
                        to: $scope.toDate
                    },
                    headers: {
                        'Content-type': 'application/pdf',
                    },
                    responseType: 'arraybuffer'
                }).then(function successCallback(data) {
                    //var FileSaver = require('file-saver');
                    var blob = new Blob([data.data], { type: "text/csv;charset=utf-8,%EF%BB%BF" });
                    var now = $filter('date')(new Date(), "mediumDate") + ' ' + $filter('date')(new Date(), "mediumTime");
                    saveAs(blob, "Data_download_" + now + ".csv");
                    $mdToast.show($mdToast.simple().textContent('Data ready').position('right top'));
                    $scope.showLoading = false;
                });
            };

            $scope.getTimeseriesOld = function () {
                $scope.showLoading = true;
                $mdToast.show($mdToast.simple().textContent('Getting data for timeseries').position('right top'));
                vevaFactory.getTimeSeries(x, y, fromUtc, toUtc, radarId, elevation, addZero, epsg)
                    .then(function successCallback(data) {
                        //var FileSaver = require('file-saver');
                        var blob = new Blob([data.data], { type: "text/csv;charset=utf-8,%EF%BB%BF" });
                        var now = $filter('date')(new Date(), "mediumDate") + ' ' + $filter('date')(new Date(), "mediumTime");
                        saveAs(blob, "Data_download_" + now + ".csv");
                        $mdToast.show($mdToast.simple().textContent('Data ready').position('right top'));
                        $scope.showLoading = false;
                    });
            };

            function getSVKstations(markersLayer) {
                vevaFactory.getSVKRainGauges()
                    .then(function successCallback(data) {
                        var myIcon = L.divIcon({ className: "leaflet-div-icon2" });
                        for (var i = 0; i < data.length; i++) {
                            var station = data[i];
                            svk_stations.push(station);
                            //var geoPoint = proj4('EPSG:32632', 'WGS84', [station.latitude, station.longitude]);
                            var obsStation = {
                                Name: station.name,
                                Id: station.idPoints,
                                //x: station.longitude,
                                //y: station.latitude, idx: i,
                                lat: station.latitude, lng: station.longitude,
                                Type: 'svk'
                            };
                            var newMarker = L.marker(obsStation, { icon: myIcon }).on('click', svkClicked); //, { icon: rainIcon });
                            newMarker.bindPopup('<h5>' + obsStation.Name + '</h5>');
                            newMarker.idx = station.idPoints;
                            newMarker.addTo(markersLayer);
                            $scope.svk_markers.push(newMarker);
                        }
                    });
            }

            function svkClicked(e) {
                var clck = this;
                var target = e.sourceTarget;
                var pointId = target.idx;
                var station = svk_stations.find(function (o) { return o.idPoints === pointId; });
                var title = station.name;
                var seriesId = addSeries($scope.chartConfig, 'svk', $scope.parameters[0], title);
                var plotSeries = plotSeriesDefinitions.find(function (o) { return o.id === seriesId; });
                plotSeries.pointId = pointId;
                getRainGaugeData(seriesId, pointId);

            }

            function getRainGaugeData(seriesId, pointId) {
                vevaFactory.getRainGaugeDataByPointId($scope.fromDate, $scope.toDate, pointId)
                    .then(function successCallback(data) {
                        $scope.responseValues = data.values;
                        $scope.Status_info = "Hentet data : " + data.values.length + " steps";
                        $scope.Status_details = JSON.stringify(data.info);
                        $scope.jsonResponse = JSON.stringify(data);
                        $scope.gettingFiles = false;
                        $scope.showTimeseriesPlot = true;
                        $scope.plotActive = true;
                        $scope.dataSelected = true;

                        var seriesData = [];
                        for (var k = 0; k < data.values.length; k++) {
                            var datetimestep = Date.parse(data.values[k].time);
                            seriesData.push([
                                datetimestep,
                                data.values[k].value
                            ]);

                        };
                        addSeriesToHighCharts(seriesId, seriesData);


                    })
                    .catch(function (msg) {
                        $scope.Status_info = msg;
                    });


            }

            // getRadarSquareSum: _getRadarSquareSum,
            $scope.VeVaGetDfs2FromPolygon = function (job) {
                var weekFromDate = new Date(job.fromDate);
                weekFromDate.setDate(weekFromDate.getDate() + job.progress * 7);
                var weekToDate = new Date(weekFromDate);
                weekToDate.setDate(weekFromDate.getDate() + 7);
                if (weekToDate > job.toDate) weekToDate = job.toDate;
                vevaFactory.getDfs2FromPolygon(weekFromDate, weekToDate, job.radarId, job.jsonstr)
                    .then(function (data) {
                        //Returns only one file ... the link ... 
                        job.result.push({ url: data, value: "Week_" + job.progress });
                        //$scope.responseFiles[$scope.weeksProcessed] = { time: weekFromDate, url: data, value: "Week_" + $scope.weeksProcessed };
                        if (data == "") { $mdToast.show($mdToast.simple().textContent("No data retrieved for this polygon for these timesteps...").position('right top').hideDelay(5000)); }
                        else {
                            $scope.currentFileSrc = data;
                            //$scope.showDownLoad1file = true;
                            job.status_details = "Hentet data : 1 fil ";
                            //$scope.Status_details = JSON.stringify(data.info);
                            job.progress = job.progress + 1;
                            if (job.progress >= job.total) {
                                job.finish = new Date();
                                job.status = 1;
                                $scope.gettingFiles = false;
                            } else {
                                $scope.VeVaGetDfs2FromPolygon(job);
                            }
                        }
                    })
                    .catch(function (msg) {
                        job.error_msg = msg;
                        job.status = -1;
                        $scope.gettingFiles = false;
                    });
            };

            $scope.VeVaGetDfs0FromPolygon = function (job) {
                vevaFactory.getDfs0FromPolygon(job.fromDate, job.toDate, job.radarId, job.jsonstr)
                    .then(function (data) {
                        job.result.push({ url: data, value: "Dfs0 file" });
                        job.finish = new Date();
                        job.status = 1;
                        job.status_details = JSON.stringify(data.info);
                        scope.gettingFiles = false;
                    })
                    .catch(function (msg) {
                        job.error_msg = msg;
                        job.status = -1;
                        $scope.gettingFiles = false;
                    });

            };





            $scope.VeVaGetAreaTimeSeriesPolygon = function (job) {
                //$scope.responseValues = { time: $scope.fromDate, value: '?' };
                vevaFactory.getAreaTimeSeriesPolygon(job.fromDate, job.toDate, job.radarId, job.jsonstr)
                    .then(function (data) {
                        //Returns only one file ... the link ... 
                        job.result.push({ url: data, value: 'download' });
                        if (data == "") { $mdToast.show($mdToast.simple().textContent("No data retrieved for this polygon for these timesteps...").position('right top').hideDelay(5000)); }
                        else {
                            $scope.currentFileSrc = data;
                            //$scope.showDownLoad1file = true;
                            job.status_details = "Hentet data : 1 fil ";
                            //$scope.Status_details = JSON.stringify(data.info);
                            job.progress = job.progress + 1;
                            if (job.progress >= job.total) {
                                job.finish = new Date();
                                job.status = 1;
                                $scope.gettingFiles = false;
                            } else {
                                $scope.VeVaGetAreaTimeSeriesPolygon(job);
                            }
                        }

                    })
                    .catch(function (msg) {
                        job.error_msg = msg;
                        job.status = -1;
                        $scope.gettingFiles = false;
                    });

            };



            $scope.VeVaGetTimeSeries = function (job) {
                $scope.responseValues = { time: $scope.fromDate, value: '?' };
                var epsg = "4326";
                vevaFactory.getJsonFromPoint(job.pointXY[0], job.pointXY[1], job.fromDate, job.toDate, job.radarId, epsg, 1, false)
                    .then(function (data) {
                        job.result.push({ url: data, value: "Json file" });
                        //$scope.responseValues = data.values; // { time: data.fileLinks[i].epoch, url: data.baseURL + data.fileLinks[i].url, value: data.fileLinks[i].url };
                        //$scope.Status_info = "Hentet data : " + data.length + " steps";
                        //$scope.jsonResponse = JSON.stringify(data);
                        job.finish = new Date();
                        job.status = 1;
                        job.status_details = JSON.stringify(data.info);
                        //$scope.Status_details = JSON.stringify(data.info);
                        $scope.gettingFiles = false;
                    })
                    .catch(function (msg) {
                        $scope.Status_info = msg;
                        job.error_msg = msg;
                        job.status = -1;
                        $scope.gettingFiles = false;
                    });

            };

            $scope.VeVaGetTimeSeriesPolygon = function (job, add2pageChart = false, seriesId = 0) {
                $scope.responseValues = { time: $scope.fromDate, value: '?' };
                vevaFactory.getTimeSeriesPolygon($scope.fromDate, $scope.toDate, $scope.selectedDataSource.radarId, GetGeoJSONPolygon())
                    .then(function (data) {
                        job.result.push({ url: data, value: "Json file" });
                        //$scope.responseValues = data.values; // { time: data.fileLinks[i].epoch, url: data.baseURL + data.fileLinks[i].url, value: data.fileLinks[i].url };
                        //$scope.Status_info = "Hentet data : " + data.length + " steps";
                        //$scope.jsonResponse = JSON.stringify(data);
                        job.finish = new Date();
                        job.status = 1;
                        job.status_details = JSON.stringify(data.info);
                        //$scope.Status_details = JSON.stringify(data.info);
                        $scope.gettingFiles = false;
                    })
                    .catch(function (msg) {
                        $scope.Status_info = msg;
                        job.error_msg = msg;
                        job.status = -1;
                        $scope.gettingFiles = false;
                    });

            };

            function GetGeoJSONPolygon() {
                return $scope.geoSelection.GeoJSON;
            }


            $scope.VeVaGetRadarH5Files = function (job) {
                vevaFactory.getRadarH5Files(job.fromDate, job.toDate, job.radarId)
                    .then(function (data) {
                        for (var i = 0; i < data.fileLinks.length; i++) {
                            var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
                            d.setUTCSeconds(data.fileLinks[i].epoch);
                            job.result.push({ url: data.fileLinks[i].url, value: d.toISOString().slice(0, 16)});
                        }
                        job.finish = new Date();
                        job.status = 1;
                        job.status_details = JSON.stringify(data.info);
                        $scope.gettingFiles = false;
                    })
                    .catch(function (msg) {
                        job.error_msg = msg;
                        job.status = -1;
                        $scope.gettingFiles = false;
                    });

            };

            $scope.VeVaGetRadarImages = function () {
                vevaFactory.getRadarImages(job.fromDate, job.toDate, job.radarId)
                    .then(function (data) {
                        for (var i = 0; i < data.values.length; i++) {
                            var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
                            d.setUTCSeconds(data.fileLinks[i].epoch);
                            job.result.push({ url: data.fileLinks[i].url, value: d.toISOString().slice(0, 16) });
                        }
                        job.finish = new Date();
                        job.status = 1;
                        job.status_details = JSON.stringify(data.info);
                        $scope.gettingFiles = false;
                    })
                    .catch(function (msg) {
                        job.error_msg = msg;
                        job.status = -1;
                        $scope.gettingFiles = false;
                    });
            };

            $scope.VeVaGetDfs0FromPoint = function (job) {
                var addZero = true;
                var epsg = "4326";
                vevaFactory.getDfs0FromPoint(job.pointXY[0], job.pointXY[1], job.fromDate, job.toDate, job.radarId, epsg)
                    .then(function (data) {
                        job.result.push({ url: data, value: "Dfs0 file" });
                        //$scope.responseFiles[0] = { time: $scope.fromDate, url: data, value: "Download file" };
                        //$scope.Status_info = "Hentet data fil ";
                        job.finish = new Date();
                        job.status = 1;
                        job.status_details = JSON.stringify(data.info);
                        //$scope.Status_details = JSON.stringify(data.info);
                        $scope.gettingFiles = false;
                    })
                    .catch(function (msg) {
                        job.error_msg = msg;
                        job.status = -1;
                        //$scope.Status_info = msg;
                        $scope.gettingFiles = false;
                    });

            };


            function VeVaGetTimeSeries(add2pageChart = false, seriesId = 0) {
                var addZero = false;
                var epsg = "4326";
                vevaFactory.getTimeSeries($scope.pointSelected.lng, $scope.pointSelected.lat, $scope.fromDate, $scope.toDate, $scope.selectedDataSource.radarId, 0, addZero, epsg)
                    .then(function (data) {
                        $scope.responseValues = data.values;
                        $scope.Status_info = "Hentet data : " + data.values.length + " steps";
                        $scope.Status_details = JSON.stringify(data.info);
                        $scope.jsonResponse = JSON.stringify(data);
                        $scope.gettingFiles = false;
                        if (add2pageChart) {
                            $scope.timeSeriesLoding = false;
                            $scope.showTimeseriesPlot = true;
                            $scope.plotActive = true;
                            $scope.dataSelected = true;

                            var seriesData = [];
                            for (var k = 0; k < data.values.length; k++) {
                                var datetimestep = Date.parse(data.values[k].time);
                                seriesData.push([
                                    datetimestep,
                                    data.values[k].value
                                ]);

                            };
                            addSeriesToHighCharts(seriesId, seriesData);

                        }
                    })
                    .catch(function (msg) {
                        $scope.Status_info = msg;
                        $scope.gettingFiles = false;
                    });

            } 

            function addSeriesToHighCharts(seriesIndex, seriesData ) {
                $scope.chartConfig.series[seriesIndex].data.length = 0; //Delete old data
                $scope.chartConfig.series[seriesIndex].data = seriesData;
                //angular.extend($scope.chartConfig, $scope.chartConfig.options);
                $scope.showTimeseriesPlot = true;
                pageChart = Highcharts.chart('overview-chart-container', $scope.chartConfig, function callback() {
                    $timeout(function () {
                        if (pageChart) {
                            pageChart.redraw();
                        }
                    }, 500);
                });

            }

            Highcharts.setOptions({
                chart: {
                    events: {
                        click: function (event) {
                            highChartClicked(this, event);
                        }
                    }
                }
            });
        
            $scope.generateFileLinks = function () {
                $scope.gettingFiles = true;
                $scope.steps[4].Done = true;
                $scope.responseValues = [];
                $scope.responseFiles = [];
                $scope.Status_info = "Henter data ... ";
                $scope.Status_details = "";
                $scope.jsonResponse = "";
                var jobid = $scope.Jobs.length + 1;
                var job = {
                    id: jobid, start: new Date(), finish : null, status: 0, result: [], status_details : "not yet ready...", error_msg : null,
                    progress: 0, total: $scope.weeks,
                    dataformatId: $scope.selectedDataformatId, jsonstr: GetGeoJSONPolygon(), pointXY: [$scope.pointSelected.lng, $scope.pointSelected.lat], fromDate: $scope.fromDate, toDate: $scope.toDate, radarId: $scope.selectedDataSource.radarId
                };
                $scope.Jobs.push(job);

                if ($scope.selectedDataformatId == 1) $scope.VeVaGetRadarH5Files(job);
                else if ($scope.selectedDataformatId == 2) $scope.VeVaGetRadarImages(job);
                else if ($scope.selectedDataformatId == 3) $scope.VeVaGetDfs0FromPoint(job);
                else if ($scope.selectedDataformatId == 4) $scope.VeVaGetDfs0FromPolygon(job);
                else if ($scope.selectedDataformatId == 5) $scope.VeVaGetAreaTimeSeriesPolygon(job);
                else if ($scope.selectedDataformatId == 6) $scope.VeVaGetTimeSeriesPolygon(job);
                else if ($scope.selectedDataformatId == 7) $scope.VeVaGetDfs2FromPolygon(job);
                else if ($scope.selectedDataformatId == 8) $scope.VeVaGetTimeSeries(job);

            };

            angular.element(document).ready(function () {
                $scope.chartConfig = ChartDefaultsService.getConfig();
                //if ($scope.loggedin)getEventData();
                //getStatus();
                //initBlankChart();
                $scope.mapstate = initMap();
                $scope.mapstate.invalidateSize();
            });


            function AddSeriesAndUpdatePlot(title, type) {
                $scope.timeSeriesLoding = true;
                var parameters = $scope.parameters[0];
                var seriesId = addSeries($scope.chartConfig, type, parameters, title);
                $scope.timeSeriesLoding = false;
                return seriesId;

            }

            $scope.updatePlot = function () {
                $scope.chartConfig.xAxis[0].min = Date.parse($scope.fromDate);
                $scope.chartConfig.xAxis[0].max = Date.parse($scope.toDate);
                for (var i = 0; i < plotSeriesDefinitions.length; i++) {
                    var seriesId = plotSeriesDefinitions[i].id;
                    if (plotSeriesDefinitions[i].type === 'marker') VeVaGetTimeSeries(true, seriesId);
                    else if (plotSeriesDefinitions[i].type === 'area') $scope.VeVaGetTimeSeriesPolygon(true, seriesId);
                    else if (plotSeriesDefinitions[i].type === 'svk') getRainGaugeData(seriesId, plotSeriesDefinitions[i].pointId);
                }
            }

            $scope.printKey = function (key) {
                console.log(key)
            }
            $scope.checkHide = function (key) {

                return $scope.isHidden[$scope.keyTable.indexOf(key)]
            }

            $scope.$on('leafletDirectiveMap.overlayremove', function (event, args) {
                $scope.layersState[args.leafletEvent.name] = false;
            });

            $scope.$on('leafletDirectiveMap.overlayadd', function (event, args) {
                $scope.layersState[args.leafletEvent.name] = true;
            });

            $scope.$on('leafletDirectiveMarker.click', function (event, args) {
                $scope.selectElement(args.modelName);
            });

            function initJsonDataFile(map) {
                var deferred = $q.defer();
                $.when(
                    $.getJSON('data/ledninger_slope.geojson', function (data) {
                        $scope.hofor_ledninger = data; //evt. angular.copy(data) ? 
                    })
                ).then(function () {
                    $scope.rivers_sections = L.Proj.geoJson($scope.hofor_ledninger, {
                        style: function (feature) {
                            // Set the river section color
                            return { color: 'grey' };
                        }
                    }).addTo(map);
                    deferred.resolve();
                }, function errorCallback(err) {
                    console.log(err);
                    deferred.reject(err);
                });
                return deferred.promise;
            }
            function initMap() {

                var kmsusr = "InforMeticsTst";
                var kmspwd = "Hauser10";
                var kftoken = 'd12107f70a3ee93153f313c7c502169a';

                //  Workaround for 1px lines appearing in some browsers due to fractional transforms
                //  and resulting anti-aliasing.
                //  https://github.com/Leaflet/Leaflet/issues/3575
                if (window.navigator.userAgent.indexOf('Chrome') > -1) {
                    var originalInitTile = L.GridLayer.prototype._initTile;
                    L.GridLayer.include({
                        _initTile: function (tile) {
                            originalInitTile.call(this, tile);
                            var tileSize = this.getTileSize();
                            tile.style.width = tileSize.x + 1 + 'px';
                            tile.style.height = tileSize.y + 1 + 'px';
                        }
                    });
                }

                // Set the attribution (the copyright statement shown in the lower right corner)
                // We do this as we want the same attributions for all layers
                var myAttributionText = '&copy; <a target="_blank" href="https://download.kortforsyningen.dk/content/vilk%C3%A5r-og-betingelser">Styrelsen for Dataforsyning og Effektivisering</a>';

                var RadarCenter = [12.456968, 55.653227];
                var RadarCenterWR110 = [12.480966, 55.606683];

                var baselayer = "";
                console.log('In init map');
                map = new L.Map('map', {
//                    crs:crs,
//                    fullscreenControl: true,
                    continuousWorld: true,
                    center: [55.8, 11.4], // Set center location
                    zoom: 7, // Set zoom level
                    minzoom: 0,
                    maxzoom: 13
                });

                var offsetx = 0, offsety = 0;

                // Hillshade overlay [WMS:dhm]
                var hillshade = L.tileLayer.wms('https://services.kortforsyningen.dk/dhm', {
                    transparent: true,
                    layers: 'dhm_terraen_skyggekort_transparent_overdrevet',
                    token: kftoken,
                    format: 'image/png',
                    attribution: myAttributionText,
                    continuousWorld: true,
                });
                //PeterRasch3L1V6@dfdprod01.sys , G3tData!
                // Hydrografi - Hy-inspire
                //username=PeterRasch3L1V6@dfdprod01.sys&password=G3tData!
                var dataFordeler = L.tileLayer.wms('https://services.datafordeler.dk/Dkskaermkort/topo_skaermkort/1.0.0/wms?username=PeterRasch3L1V6@dfdprod01.sys&password=G3tData!', {
                    transparent: true,
                    layers: 'HY.Network,HY.PhysicalWaters.WaterBodies,HY.PhysicalWaters.ManMadeObject,HY.PhysicalWaters.Wetland',
                    token: kftoken,
                    format: 'image/png',
                    attribution: myAttributionText,
                    continuousWorld: true,
                });
                var streetmap = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                }).addTo(map);
                var hyinspire = L.tileLayer.wms('https://services.kortforsyningen.dk/hy_inspire', {
                    transparent: true,
                    layers: 'HY.Network,HY.PhysicalWaters.WaterBodies,HY.PhysicalWaters.ManMadeObject,HY.PhysicalWaters.Wetland',
                    token: kftoken,
                    format: 'image/png',
                    attribution: myAttributionText,
                    continuousWorld: true,
                });
                                                  //https://geoserver.plandata.dk/geoserver/gwc/service/wmts?matrixset=standard&format=image%2Fpng&SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=theme_pdk_kloakopland_vedtaget_v&STYLE=&TILEMATRIXSET=standard&TILEMATRIX=standard%3A9&TILEROW=197&TILECOL=333

                var kloakoplande = L.tileLayer('https://geoserver.plandata.dk/geoserver/gwc/service/wmts?matrixset=standard&format=image%2Fpng&SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=theme_pdk_kloakopland_vedtaget_v&STYLE=&TILEMATRIXSET=standard&TILEMATRIX=standard%3A9&TILEROW={x}&TILECOL={y}', {
                    tms: true,
                    format: 'image/png',
                    continuousWorld: true
                });


                var geo_vandloeb = L.tileLayer.wms('http://{s}.services.kortforsyningen.dk/service', {
                    service: 'WMS',
                    transparent: true,
                    servicename: 'topo_geo',
                    layers: ['Vandloebsbred', 'Vandloeb_brudt', 'Vandloeb', 'Bassin', 'Soe'],
                    login: kmsusr,
                    password: kmspwd,
                    format: 'image/png',
                    attribution: "Geodatastyrelsen",
                    continuousWorld: true,
                    minZoom: 8
                });

                var dhm_bluespots = L.tileLayer.wms('http://{s}.services.kortforsyningen.dk/service', {
                    service: 'WMS',
                    transparent: true,
                    servicename: 'dhm',
                    //dtm_1_6m, dtm_1_6m_transparent, hillshade_1_6m, hillshade_1_6m_transparent, dhym_rain, dhym_rain_hillshade, dhym_searise, dhym_searise_hillshade, dhym_bluespot_max, dhm_kurve_0_5_m, dhm_kurve_1_0_mdhm_kurve_traditionel
                    layers: ['dhym_bluespot_max'],
                    login: kmsusr,
                    password: kmspwd,
                    format: 'image/png',
                    attribution: "Geodatastyrelsen",
                    continuousWorld: true,
                    minZoom: 5
                });

                var kms_Corine = L.tileLayer.wms('http://{s}.services.kortforsyningen.dk/corine_dissemination', {
                    service: 'WMS',
                    transparent: true,
                    servicename: 'corine_dissemination',
                    //dtm_1_6m, dtm_1_6m_transparent, hillshade_1_6m, hillshade_1_6m_transparent, dhym_rain, dhym_rain_hillshade, dhym_searise, dhym_searise_hillshade, dhym_bluespot_max, dhm_kurve_0_5_m, dhm_kurve_1_0_mdhm_kurve_traditionel
                    layers: ['CLC12_DK'],
                    login: kmsusr,
                    password: kmspwd,
                    format: 'image/png',
                    attribution: "Geodatastyrelsen",
                    continuousWorld: true
                });

                var dhm_kurve05 = L.tileLayer.wms('http://{s}.services.kortforsyningen.dk/service', {
                    service: 'WMS',
                    transparent: true,
                    servicename: 'dhm',
                    //dtm_1_6m, dtm_1_6m_transparent, hillshade_1_6m, hillshade_1_6m_transparent, dhym_rain, dhym_rain_hillshade, dhym_searise, dhym_searise_hillshade, dhym_bluespot_max, dhm_kurve_0_5_m, dhm_kurve_1_0_mdhm_kurve_traditionel
                    layers: ['dhm_kurve_0_5_m'],
                    login: kmsusr,
                    password: kmspwd,
                    format: 'image/png',
                    attribution: "Geodatastyrelsen",
                    continuousWorld: true,
                    minZoom: 8
                });

                var ortofoto = L.tileLayer.wms('http://{s}.services.kortforsyningen.dk/orto_foraar', {
                    service: 'WMS',
                    transparent: true,
                    servicename: 'orto_foraar',
                    //dtm_1_6m, dtm_1_6m_transparent, hillshade_1_6m, hillshade_1_6m_transparent, dhym_rain, dhym_rain_hillshade, dhym_searise, dhym_searise_hillshade, dhym_bluespot_max, dhm_kurve_0_5_m, dhm_kurve_1_0_mdhm_kurve_traditionel
                    layers: ['orto_foraar'],
                    login: kmsusr,
                    password: kmspwd,
                    format: 'image/png',
                    attribution: "Geodatastyrelsen",
                    continuousWorld: true
                });

                // Define layers
                
                // Skærmkort [WMTS:topo_skaermkort]
                var toposkaermkortwmts = L.tileLayer.wms('https://services.kortforsyningen.dk/topo_skaermkort', {
                    layers: 'dtk_skaermkort_daempet',
                    token: kftoken,
                    format: 'image/png',
                    attribution: myAttributionText
                });

                var Esri_WorldImagery = L.tileLayer('http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
                    attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                });

                var RadarCenterUTM = proj4('WGS84', 'EPSG:32632', RadarCenter);   //any object will do as long as it has 'x' and 'y' properties
                var RadarCenterWR110UTM = proj4('WGS84', 'EPSG:32632', RadarCenterWR110);   //any object will do as long as it has 'x' and 'y' properties
                var radarRange = L.layerGroup([
                    L.circle([radarSpecsAll[0].geoCenter[1], radarSpecsAll[0].geoCenter[0]], 40000),
                    L.circle([radarSpecsAll[3].geoCenter[1], radarSpecsAll[3].geoCenter[0]], 40000),
                    L.circle([radarSpecsAll[4].geoCenter[1], radarSpecsAll[4].geoCenter[0]], 40000)
                ]);

                var tooClose = L.circle([RadarCenter[1], RadarCenter[0]], 5000);
                var CBandRanges = L.layerGroup([
                    L.circle([55.1731, 8.552], 240000, { pmIgnore: true }),
                    L.circle([55.3262, 12.4493], 240000, { pmIgnore: true }),
                    L.circle([56.024, 10.0246], 240000, { pmIgnore: true }),
                    L.circle([57.4893, 10.1365], 240000, { pmIgnore: true }),
                    L.circle([55.1127, 14.8875], 240000, { pmIgnore: true })]);
                var CBandLocations = L.layerGroup([
                    L.circle([55.1731, 8.552], 2000),
                    L.circle([55.3262, 12.4493], 2000),
                    L.circle([56.024, 10.0246], 2000),
                    L.circle([57.4893, 10.1365], 2000),
                    L.circle([55.1127, 14.8875], 2000)]);

                var imageUrl = 'https://s3-eu-west-1.amazonaws.com/informetics/ftp2/biofos/Hvidovre/png/nodata.png';
                var imageBounds = [];
                var imageBoundsWithoutBorders = [];
                var imageBoundsUTM = [];
                var imageBoundsUTMWithoutBorders = [];
                var imageBoundsGEO = [];
                var imageBoundsGEOWithoutBorders = [];
                // console.log(RadarCenterUTM);
                var imageradius = 70000; //full image, not just circles
                var imageradiuswithoutborders = 48200;
                var shiftXY = [-3500, 1000];
                var shiftWH = [-960 * 15, 0]; //width bigger than height - can use pixels ? 

                imageBoundsUTM[0] = [RadarCenterUTM[0] - imageradius + shiftXY[0] + shiftWH[0], RadarCenterUTM[1] - imageradius + shiftXY[1]];//[55.121563702374246, 11.919884386721316];
                imageBoundsUTM[1] = [RadarCenterUTM[0] + imageradius + shiftXY[0] - shiftWH[0], RadarCenterUTM[1] + imageradius + shiftXY[1]];
                imageBoundsUTMWithoutBorders[0] = [RadarCenterUTM[0] - imageradiuswithoutborders, RadarCenterUTM[1] - imageradiuswithoutborders];//[55.121563702374246, 11.919884386721316];
                imageBoundsUTMWithoutBorders[1] = [RadarCenterUTM[0] + imageradiuswithoutborders, RadarCenterUTM[1] + imageradiuswithoutborders];

                imageBounds[0] = proj4('EPSG:32632', 'WGS84', imageBoundsUTM[0]);
                imageBounds[1] = proj4('EPSG:32632', 'WGS84', imageBoundsUTM[1]);
                imageBoundsWithoutBorders[0] = proj4('EPSG:32632', 'WGS84', imageBoundsUTMWithoutBorders[0]);
                imageBoundsWithoutBorders[1] = proj4('EPSG:32632', 'WGS84', imageBoundsUTMWithoutBorders[1]);
                imageBoundsGEO[0] = [imageBounds[0][1], imageBounds[0][0]];
                imageBoundsGEO[1] = [imageBounds[1][1], imageBounds[1][0]];
                imageBoundsGEOWithoutBorders[0] = [imageBoundsWithoutBorders[0][1], imageBoundsWithoutBorders[0][0]];
                imageBoundsGEOWithoutBorders[1] = [imageBoundsWithoutBorders[1][1], imageBoundsWithoutBorders[1][0]];

                imageradius = 63900; //full image, not just circles
                shiftXY = [-2600, 600];
                shiftWH = [0, 0]; //width bigger than height - can use pixels ? 
                imageBoundsUTM[0] = [RadarCenterUTM[0] - imageradius + shiftXY[0] + shiftWH[0], RadarCenterUTM[1] - imageradius + shiftXY[1]];//[55.121563702374246, 11.919884386721316];
                imageBoundsUTM[1] = [RadarCenterUTM[0] + imageradius + shiftXY[0] - shiftWH[0], RadarCenterUTM[1] + imageradius + shiftXY[1]];
                imageBounds[0] = proj4('EPSG:32632', 'WGS84', imageBoundsUTM[0]);
                imageBounds[1] = proj4('EPSG:32632', 'WGS84', imageBoundsUTM[1]);
                imageBoundsGEO[0] = [imageBounds[0][1], imageBounds[0][0]];
                imageBoundsGEO[1] = [imageBounds[1][1], imageBounds[1][0]];
                var imageCartUrl = 'https://s3-eu-west-1.amazonaws.com/informetics/ftp2/biofos/Hvidovre/cart2png/nodata.png';
                var radarCartImg = L.imageOverlay(imageCartUrl, imageBoundsGEO, {
                    opacity: 0.8
                })


                imageradius = 69850; //full image, not just circles
                shiftXY = [-3250, 750];
                shiftWH = [-9000, 0]; //width bigger than height - can use pixels ? 
                imageBoundsUTM[0] = [RadarCenterUTM[0] - imageradius + shiftXY[0] + shiftWH[0], RadarCenterUTM[1] - imageradius + shiftXY[1]];//[55.121563702374246, 11.919884386721316];
                imageBoundsUTM[1] = [RadarCenterUTM[0] + imageradius + shiftXY[0] - shiftWH[0], RadarCenterUTM[1] + imageradius + shiftXY[1]];
                imageBounds[0] = proj4('EPSG:32632', 'WGS84', imageBoundsUTM[0]);
                imageBounds[1] = proj4('EPSG:32632', 'WGS84', imageBoundsUTM[1]);
                imageBoundsGEO[0] = [imageBounds[0][1], imageBounds[0][0]];
                imageBoundsGEO[1] = [imageBounds[1][1], imageBounds[1][0]];
                console.log(imageBoundsGEO)
                var imageuRUrl = '';
                var radaruRImg = L.imageOverlay(imageuRUrl, imageBoundsGEO, {
                    opacity: 0.8
                });

                //WR110
                imageradius = 69850; //full image, not just circles
                shiftXY = [-3250, 750];
                shiftWH = [-9000, 0]; //width bigger than height - can use pixels ? 
                imageBoundsUTM[0] = [RadarCenterWR110UTM[0] - imageradius + shiftXY[0] + shiftWH[0], RadarCenterWR110UTM[1] - imageradius + shiftXY[1]];//[55.121563702374246, 11.919884386721316];
                imageBoundsUTM[1] = [RadarCenterWR110UTM[0] + imageradius + shiftXY[0] - shiftWH[0], RadarCenterWR110UTM[1] + imageradius + shiftXY[1]];
                imageBounds[0] = proj4('EPSG:32632', 'WGS84', imageBoundsUTM[0]);
                imageBounds[1] = proj4('EPSG:32632', 'WGS84', imageBoundsUTM[1]);
                imageBoundsGEO[0] = [imageBounds[0][1], imageBounds[0][0]];
                imageBoundsGEO[1] = [imageBounds[1][1], imageBounds[1][0]];
                console.log(imageBoundsGEO)
                var imageWR110Url = '';
                var radarWR110Img = L.imageOverlay(imageWR110Url, imageBoundsGEO, {
                    opacity: 0.8
                });

                imageBoundsGEO[0] = [52.294, 4];
                imageBoundsGEO[1] = [60, 19.5];

                shiftXY = [-100000, -65000];
                shiftWH = [60000, 60000]; //width bigger than height - can use pixels ? 
                imageBoundsUTM[0] = [126648 + shiftXY[0] + shiftWH[0], 5983984 + shiftXY[1] + shiftWH[1]];//[5983984, 126648]; //[5983984, 126648];
                imageBoundsUTM[1] = [1075693 + shiftXY[0] - shiftWH[0], 7771252 + shiftXY[1] - shiftWH[1]];//[7771252, 1075693];
                imageBounds[0] = proj4('EPSG:3006', 'WGS84', imageBoundsUTM[0]);
                imageBounds[1] = proj4('EPSG:3006', 'WGS84', imageBoundsUTM[1]);
                imageBoundsGEO[0] = [imageBounds[0][1], imageBounds[0][0]];
                imageBoundsGEO[1] = [imageBounds[1][1], imageBounds[1][0]];

                var SMHIurl = "";// "http://opendata-download-radar.smhi.se/api/version/latest/area/sweden/product/comp/2017/02/20/radar_1702201600.png";
                var radarSMHI = L.imageOverlay(SMHIurl, imageBoundsGEO, {
                    opacity: 0.5
                });

                shiftXY = [0, 0];
                shiftWH = [0, 0]; //width bigger than height - can use pixels ? 
                imageBoundsUTM[0] = [126648 + shiftXY[0] + shiftWH[0], 5983984 + shiftXY[1] + shiftWH[1]];//[5983984, 126648]; //[5983984, 126648];
                imageBoundsUTM[1] = [1075693 + shiftXY[0] - shiftWH[0], 7771252 + shiftXY[1] - shiftWH[1]];//[7771252, 1075693];
                imageBounds[0] = proj4('EPSG:3006', 'WGS84', imageBoundsUTM[0]);
                imageBounds[1] = proj4('EPSG:3006', 'WGS84', imageBoundsUTM[1]);
                imageBoundsGEO[0] = [imageBounds[0][1], imageBounds[0][0]];
                imageBoundsGEO[1] = [imageBounds[1][1], imageBounds[1][0]];

                
                //var svk_all = L.featureGroup().addTo(map);
                //getSVKstations(svk_all);

                //svk_all.on('click', function (e) {
                //    var attributes = e.layer;
                //    getRainGaugeData(attributes.idx);
                //});

                var baselayers = {
                    "Flyfoto": ortofoto,
                //    "Ortofoto WMTS": ortofotowmts,
                    "Vejkort": streetmap,
                    "Sattellit": Esri_WorldImagery,
                    "Skærmkort": toposkaermkortwmts,
                //    "SkærmDF": dataFordeler
                };
                //http://localhost:8080/geoserver/aav/wms?service=WMS&version=1.1.0&request=GetMap&layers=aav:PROD_MU_OPLANDE_AKTIV_T_region&styles=&bbox=560430.0327407509,6207103.841963952,583839.4899465791,6240651.54342634&width=535&height=768&srs=EPSG:25832&format=application/openlayers
                var overlays = {
                    //"SMHI c-bånd": radarSMHI,
                    "DMI radar placeringer": CBandLocations,
                    "40km x-bånd radar": radarRange,
                    "Vandloeb": geo_vandloeb,
                    "Højdekontur": dhm_kurve05,
                    "Fordybninger": dhm_bluespots,
                    "Corine Land Type": kms_Corine,
                    "Luftfoto": ortofoto,
                    "Skygger": hillshade,
                    "Hydrografi": hyinspire,
                    //"SVK": svk_all
                };

                //Radar Images
                for (var j = 0; j < $scope.mapImgOverlays.length; j++) {
                    overlays[$scope.mapImgOverlays[j].Name] =$scope.mapImgOverlays[j].radarImg;
                }


                // L.Icon.Default.imagePath = './images';
                L.control.scale({ maxWidth: 240, metric: true, imperial: false, position: 'bottomleft' }).addTo(map);
                layerControl = L.control.layers(baselayers, overlays).addTo(map);

                //initialize leaflet-geoman
                //L.PM.initialize({ optIn: true });
                // add leaflet-geoman controls with some options to the map
                map.pm.addControls({
                    position: 'topleft',
                    drawMarker: true, // adds button to draw markers
                    drawPolyline: false, // adds button to draw a polyline
                    drawRectangle: false, // adds button to draw a rectangle
                    drawPolygon: true, // adds button to draw a polygon
                    drawCircle: false, // adds button to draw a cricle
                    drawCircleMarker: false, // adds button to draw a cricle
                    cutPolygon: true, // adds button to cut a hole in a polygon
                    editMode: true, // adds button to toggle edit mode for all layers
                    removalMode: true // adds a button to remove layers
                });


                var mapOverlays = [];
                //if ($scope.loggedin) mapOverlays =getMapPoints(map);
                var lat, lng;
                var mouseUTM;
                var mouseDVR90;
                map.addEventListener('mousemove', function (ev) {
                    mouseUTM = proj4('WGS84', 'EPSG:3006', [ev.latlng.lng, ev.latlng.lat]);
                    mouseDVR90 = proj4('WGS84', 'EPSG:32632', [ev.latlng.lng, ev.latlng.lat]);
                    utmonmap.textContent = "UTM-33: " + mouseUTM[0].toFixed(1) + "m E, " + mouseUTM[1].toFixed(1) + "m N";
                    dvronmap.textContent = "UTM-32: " + mouseDVR90[0].toFixed(1) + "m E, " + mouseDVR90[1].toFixed(1) + "m N";
                    geoonmap.textContent = "WGS-84: " + ev.latlng.lat.toFixed(3) + "°N, " + ev.latlng.lng.toFixed(3) + "°E";
                }, { passive: true });
                //map.on('dblclick', function (e) { clickListenerTimeSeries(e) });

                //map.on('baselayerchange', function (e) {
                //    if (e.name === 'Skærmkort') {
                //        matrikelkort.setParams({
                //            styles: 'sorte_centroider,sorte_skel,default'
                //        });
                //    } else if (e.name === 'Flyfoto') {
                //        matrikelkort.setParams({
                //            styles: 'gule_centroider,gule_skel,Gul_OptagetVej,default'
                //        });
                //    }
                //});
                map.on('load', function (e) {
                    console.log("about to .. re-inititialising map");
                    setTimeout(function () { map.invalidateSize(); console.log("re inititialising map"); }, 500);
                });

                map.on('overlayadd', function (e) {
                    var radarLayer = $.grep($scope.mapImgOverlays, function (r) { return r.Name == e.name; })[0];

                    console.log(radarLayer);
                    if (radarLayer != null) {
                        radarLayer.Active = true;
                        SetImage();
                        console.log("Looking for : " + radarLayer.Name);
                    }
                });
                map.on('overlayremove', function (e) {
                    var radarLayer = $.grep($scope.mapImgOverlays, function (r) { return r.Name == e.name; })[0];
                    console.log(radarLayer);
                    if (radarLayer != null) {
                        radarLayer.Active = false;
                        console.log("Stop Looking for : " + radarLayer.Name);
                    }

                });

                map.on('pm:create', function (e) {
                    $scope.updateGeoSelection(e);

                   // $scope.geojsonPolygon.add(e.layer._latlngs[0]);

                });
                map.on('pm:markerdragend', function (e) {
                    //$scope.updateGeoSelection(e);

                    // $scope.geojsonPolygon.add(e.layer._latlngs[0]);

                });

                // listen to when global edit mode is toggled
                map.on('pm:globaleditmodetoggled', function (e) {
                    //$scope.updateGeoSelection(e);
                });

                console.log("inititialising map");
                map.setView(L.latLng(55.4, 10.9), 7);

                //http://geoserver.vcs.informetics.com/geoserver/aav/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=aav%3APROD_MU_OPLANDE_AKTIV_T_region&maxFeatures=5000&outputFormat=application%2Fjson&format_options=callback%3A%20getJson&srsName=EPSG%3A4326&minZoom=8&bbox=10.102604621095185%2C56.02909031864325%2C10.404041999024873%2C56.15626145688171
                //http://geoserver.vcs.informetics.com/geoserver/aav/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=aav:PROD_MU_OPLANDE_AKTIV_T_region&maxFeatures=50&
                //outputFormat=application/json&srsName=EPSG:4326
                         http://geoserver.vcs.informetics.com/geoserver/aav/ows?typeName=aav:PROD_MU_OPLANDE_AKTIV_T_region
                var geoJsonUrl = 'http://geoserver.vcs.informetics.com/geoserver/aav/wfs';
                var layerAav = 'aav:PROD_MU_OPLANDE_AKTIV_T_region'; 
                var defaultParameters = {
                    service: 'WFS',
                    version: '1.1.0',
                    request: 'GetFeature',
                    typeName: layerAav,
                    maxFeatures: 50,
                    outputFormat: 'text/ javascript',
                   // jsonp: 'format_options'
                    srsname: 'EPSG:4326',
//                    minZoom :8
                };
                var featureLayer = {};
                //load_wfs_http(geoJsonUrl, layerAav);
                load_wfs(geoJsonUrl, defaultParameters);
                //featureLayer = new L.GeoJSON(
                //    null, {
                //        //onEachFeature: onEachFeature
                //    }).addTo(map);
                

                //map.addEventListener('moveend', function (ev) {
                //    console.log("move end", ev);
                //    load_wfs(geoJsonUrl, defaultParameters);
                //});
                setTimeout(function () { map.invalidateSize(); }, 500);
                //initJsonData(map);
                
                return  map;

            }

            function calcImgBounds() {
                for (var j = 0; j < $scope.mapImgOverlays.length; j++) {
                    var r = $scope.mapImgOverlays[j];
                    r.imageBounds = [,];
                    r.imageBoundsUTM = [,];
                    r.imageBoundsGEO = [,];
                    if (r.geoCenter[0]) {
                        r.utmCenter = proj4('WGS84', r.epsg, r.geoCenter);
                        r.imageBoundsUTM[0] = [r.utmCenter[0] - r.imgRadius + r.shiftXY[0] + r.shiftWH[0], r.utmCenter[1] - r.imgRadius + r.shiftXY[1]];
                        r.imageBoundsUTM[1] = [r.utmCenter[0] + r.imgRadius + r.shiftXY[0] - r.shiftWH[0], r.utmCenter[1] + r.imgRadius + r.shiftXY[1]];
                    }
                    else {
                        r.imageBoundsUTM[0] = [r.utmLL[0] + r.shiftXY[0] + r.shiftWH[0], r.utmLL[1] + r.shiftXY[1]];
                        r.imageBoundsUTM[1] = [r.utmUR[0] + r.shiftXY[0] - r.shiftWH[0], r.utmUR[1] + r.shiftXY[1]];
                        r.utmCenter = [(r.imageBoundsUTM[1][0] - r.imageBoundsUTM[0][0]) / 2 + r.imageBoundsUTM[0][0], (r.imageBoundsUTM[1][1] - r.imageBoundsUTM[0][1]) / 2 + r.imageBoundsUTM[0][1]];
                        r.geoCenter = proj4(r.epsg, 'WGS84', r.utmCenter);
                    }
                    r.imageBounds[0] = proj4(r.epsg, 'WGS84', r.imageBoundsUTM[0]);
                    r.imageBounds[1] = proj4(r.epsg, 'WGS84', r.imageBoundsUTM[1]);

                    r.imageBoundsGEO[0] = [r.imageBounds[0][1], r.imageBounds[0][0]];
                    r.imageBoundsGEO[1] = [r.imageBounds[1][1], r.imageBounds[1][0]];
                    console.log("r.imageBoundsGEO: ", r.imageBoundsGEO);

                    var imageuRUrl = r.BaseURL + r.NoData;
                    r.radarImg = L.imageOverlay(imageuRUrl, r.imageBoundsGEO, {
                        opacity: 0.6,
                       // minZoom: 9

                    });
                }
            }



            $scope.updateGeoJson = function () {
                var json = JSON.parse($scope.geoSelection.GeoJSON);
                var type = json.features[0].geometry.type;
                if (type == 'Polygon') {
                    var seriesId = AddSeriesAndUpdatePlot('From text Polygon', 'polygon');
                    $scope.VeVaGetTimeSeriesPolygon(true, seriesId);
                }
                else if (type == 'Point') {
                    $scope.pointSelected = json.features[0].geometry.coordinates;
                    var seriesId = AddSeriesAndUpdatePlot('From text Point', 'marker');
                    VeVaGetTimeSeries(true, seriesId);
                }

            }

            $scope.swapJSONPolygons = function() {
                $scope.popupLayers = [];
                if ($scope.polygon_layer != null) {
                    map.removeLayer($scope.polygon_layer);
                }
                //for (var v = 0; v < $scope.allWaterWayVersions.length; v++) {
                $scope.polygon_layer = L.Proj.geoJson($scope.jsonData[$scope.polygonSourceId], {
                    style: function (feature) {
                            return { color: 'orange' };
                    },
                    // Build the popups for each feature
                    onEachFeature: function (feature, layer) {


                        var newpopup = L.popup({
                            closeOnClick: false,
                            autoClose: false
                        }).setContent(feature.properties);
                        layer.bindPopup(feature.properties.ID);
                        layer.on('click', function (e) {
                            if (e.layer !== undefined) {
                                if (e.layer._popup !== undefined) makeDraggable(e.layer._popup);
                                return;
                            }
                            if (e.target !== undefined) {
                                var geometry = e.target.feature.geometry;
                                if ($scope.selectedPolygonLayer != null && $scope.selectedPolygonLayer.options.color != null) {
                                    $scope.selectedPolygonLayer.setStyle({
                                        weight: 2,
                                        color: 'orange'
                                    });
                                }
                                $scope.selectedPolygonLayer = e.target;
                                e.target.setStyle({
                                    weight: 3,
                                    color: 'red'
                                });
                                var geoJson = getGeoJSON(false, geometry.coordinates);
                                $scope.steps[1].Done = true;
                                var geojsonFeature =
                                {
                                    "type": "FeatureCollection",
                                    "features": geoJson
                                };
                                $scope.geoSelection.GeoJSON = JSON.stringify(geojsonFeature);
                            }
                        });
                        $scope.popupLayers.push(layer);

                    }
                }).addTo($scope.mapstate);
 
            }

            //Geojson style file
            var myStyle = {
                'color': 'red'
            }
            // the ajax callback function
            function handleJson(data) {
                selectedArea = L.geoJson(data, {
                    style: myStyle,
                    onEachFeature: function (feature, layer) {
                        layer.bindPopup(`Name: ${feature.properties.name_of_your_property}`)
                    }
                }).addTo(map);
                map.fitBounds(selectedArea.getBounds());
            }


            function load_wfs(geoJsonUrl, defaultParameters) {
                console.log("Mapbounds :", map.getBounds().toBBoxString());
                var url = geoJsonUrl + L.Util.getParamString(defaultParameters);
                console.log("polygons", url);
                    $http({
                        url: url,
                        dataType: 'jsonp'
                    }).then(function successCallback(data) {
                        console.log(data);
                    }, function errorCallback(response) {
                            console.log(response);

                    });
                ////$.ajax({
                //    //jsonp: false,
                //    url: url, //geoJsonUrl + L.Util.getParamString(parameters),
                //    //dataType: 'jsonp',
                //    //jsonpCallback: 'getJson',
                //    success: function (result) {
                //        console.log(result);
                //    }
                //});
            }


            //$('#main-navigation').slideUp();


            var utmonmap = document.getElementById("utm_onmap");
            var dvronmap = document.getElementById("dvr_onmap");
            var geoonmap = document.getElementById("geo_onmap");


            $scope.generateFileLinksOld = function () {
                var fdateTime = $scope.fromDate;


                while (fdateTime < $scope.toDate) {
                    var utmOffset = fdateTime.getTimezoneOffset();
                    utcDateTime.setTime(fdateTime.getTime() + (utmOffset * 60 * 1000));
                    var dd = ("0" + utcDateTime.getDate()).slice(-2);
                    var MM = ("0" + (parseInt(utcDateTime.getMonth()) + 1)).slice(-2);
                    var hh = ("0" + (parseInt(utcDateTime.getHours()))).slice(-2);
                    var mm = ("0" + utcDateTime.getMinutes()).slice(-2);
                    var src = getFileSrc(utcDateTime.getFullYear(), MM, dd, hh, mm);

                    $scope.allFileSources.push({timestep: fdateTime, src : src, checked:false});

                    fdateTime.setTime(fdateTime.getTime() + ($scope.selectedDataSource.dt * 60 * 1000));
                }
            };


            function renderLegendIconHtml(icon) {
                return '<span class="half awesome-marker-icon-' + icon.markerColor + ' awesome-marker leaflet-clickable"><i class=" glyphicon ' + icon.icon + ' icon-white"></i></span>';
            }
        }]);

