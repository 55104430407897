'use strict';

/**
 * @ngdoc function
 * @name mapsApp.controller:ParameterctrlCtrl
 * @description
 * # ParameterctrlCtrl
 * Controller of the mapsApp
 */
angular.module('mapsApp')
    .controller('ParameterCtrl', ['$scope', '$http', '$q', '$mdEditDialog', '$mdToast', 'ngAuthSettings',
        function ($scope, $http, $q, $mdEditDialog, $mdToast, ngAuthSettings) {
   
      $scope.allParameters = [];

      refreshParameters();

      $scope.queryTableData = {
          order: 'Name',
          limit: 10,
          page: 1,
          limitOptions: [5, 10, 15]
      };


      $scope.editColor = _editColor;
      $scope.editMin = _editMin;
      $scope.editMax = _editMax;
      $scope.editDec = _editDec;

      function _editMin(event, param) {
          _edit(event, param, 'Min');
      }
      function _editMax(event, param) {
          _edit(event, param, 'Max');
      }
      function _editDec(event, param) {
          _edit(event, param, 'Dec');
      }

      function _edit(event, param, which) {
          event.stopPropagation();
          var dialog = {
              modelValue: param.min,
              type: "double",
              escToClose: false,
              save: function (input) {
                  var Value = $('md-input-container > input').val();
                  switch (which) {
                      case 'Min': param.DefaultMin = Value; break;
                      case 'Max': param.DefaultMax = Value; break;
                      case 'Dec': param.NoDecimals = Value; break;
                  }
                  $scope.tablePromise = updateParameterLimits(param);
                  $scope.tablePromise.then(function () {
                  });
              },
              targetEvent: event
          };
          var promise = $mdEditDialog.small(dialog);
          promise.then(function (ctrl) {
              var input = ctrl.getInput();

              input.$viewChangeListeners.push(function () {
                  input.$setValidity('test', input.$modelValue !== 0.69);
              });
          });

      }

      function _editColor(event, param) {
          event.stopPropagation();
          var dialog = {
              modelValue: param.Color,
              type: "text",
              escToClose: false,
              save: function (input) {
                  param.Color = $('md-input-container > input').val();
                  $('md-input-container > input').colorpicker('destroy');
                  $scope.tablePromise = updateParameterColor(param);
                  $scope.tablePromise.then(function () {

                  });
              },
              targetEvent: event
          };

          var promise = $mdEditDialog.small(dialog);
          promise.then(function (ctrl) {
              var inputQjuery = $('md-input-container > input');
              inputQjuery.colorpicker();
              inputQjuery.colorpicker('setValue', param.Color);

          });
      }

      function refreshParameters() {
          $scope.tablePromise = getAllParameters();
          $scope.tablePromise.then(function (response) {
              $scope.allParameters = response;
          })
      }

      function getAllParameters() {
          var deferred = $q.defer();
          $http({
              method: 'GET',
              url: ngAuthSettings.API_URL + 'values/GetAllParameters'
          }).then(function successCallback(response) {
              deferred.resolve(response.data);
          }/*, function errorCallback(response) { }*/);
          return deferred.promise;
      }

      function updateParameterColor(parameter) {
          var deferred = $q.defer();

          $http({
              method: 'POST',
              url: ngAuthSettings.API_URL + 'Values/UpdateParameterColor',
              data: parameter
          }).then(function successCallback(response) {
              $mdToast.show($mdToast.simple().textContent("Color succesfully updated.").position('right top'));
              deferred.resolve(response);
          }, function errorCallback(response) {
              var errorMessage = response && response.data && response.data.Message
                     ? response.data.Message
                     : "A error occured while trying to update the parameter color.";
              $mdToast.show($mdToast.simple().textContent(errorMessage).position('right top'));
              deferred.reject();
          });

          return deferred.promise;
      }

      function updateParameterLimits(parameter) {
          var deferred = $q.defer();

          $http({
              method: 'POST',
              url: ngAuthSettings.API_URL + 'Values/UpdateParameterLimits',
              data: parameter
          }).then(function successCallback(response) {
              $mdToast.show($mdToast.simple().textContent("Parameter limits succesfully updated.").position('right top'));
              deferred.resolve(response);
          }, function errorCallback(response) {
              var errorMessage = response && response.data && response.data.Message
                     ? response.data.Message
                     : "A error occured while trying to update the parameter limits.";
              $mdToast.show($mdToast.simple().textContent(errorMessage).position('right top'));
              deferred.reject();
          });

          return deferred.promise;
      }



  }]);
