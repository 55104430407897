'use strict';

/**
 * @ngdoc function
 * @name mapsApp.controller:LoginctrlCtrl
 * @description
 * # LoginctrlCtrl
 * Controller of the mapsApp
 */
angular.module('mapsApp')
  .controller('loginCtrl',
    [
      '$rootScope', '$scope', '$location', 'authFactory', 'localStorageService', '$stateParams', '$mdDialog',
      'profileFactory',
      function($rootScope,
        $scope,
        $location,
        authFactory,
        localStorageService,
        $stateParams,
        $mdDialog,
        profileFactory) {
        
        if (authFactory.authentication.isAuth) {
          $location.path('/');
          return;
        }

        $scope.loginData = {
              Email: "",
              Password: ""
        };

        $scope.loading = false;
        $scope.message = $stateParams.token;

        $scope.sendResetPasswordEmail = function(ev) {

          $scope.loading = true;
          profileFactory.sendResetPasswordEmail($scope.email).then(function(response) {

              $scope.loading = false;
              var confirm = $mdDialog.confirm()
                .title('Email succesfully sent')
                .textContent('A email containing instructions was sent to "' + $scope.email + '".')
                .ariaLabel('Email sent Prompt')
                .targetEvent(ev)
                .ok('Ok');
              $mdDialog.show(confirm).then(function() {
                $location.path("/");
              });
            },
            function(err) {
              $scope.loading = false;
              $scope.message = err.error_description;
            });
        };

        $scope.login = function() {
          $scope.loading = true;
          authFactory.login($scope.loginData).then(function(response) {

              $scope.loading = false;
              localStorageService.set('loginData', $scope.loginData);
              //$location.path('/home');
              if ($rootScope.previousLocation && $rootScope.previousLocation !== '/login') {
                $location.path($rootScope.previousLocation);
              }
              else {
                $location.path('');
              }
            },
            function(err) {
                $scope.loading = false;
                var confirm = $mdDialog.confirm()
                    .title('Login failed')
                    .textContent(err.Data)
                    .ariaLabel('Error')
 //                   .targetEvent(ev)
                    .ok('Ok');
                $mdDialog.show(confirm).then(function () {
                    $location.path("/");
                });
              $scope.message = err.error_description;
            });
        };

        $scope.publicLogin = function() {
          $scope.loginData = {
            userName: "public",
            password: "visiting"
          };

          $scope.login();
        }
      }
    ]);

