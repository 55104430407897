'use strict';

angular.module('mapsApp')
    .service('west2dTablesService', ['$translate', function ($translate) {
    
    var serv = {
      buildPopupTable: _buildPopupTable,
      getFeatureRiverColor: _getFeatureRiverColor,
      splitRiverSectionName: _splitRiverSectionName
    };
    
    return serv;

    function _buildPopupTable(allWaterwaySectionsDictionary, selectedScenarioUPMDataDictionary, feature, parameter, isO2, limitValues,isFirst) {

      var tableContainer = $('<span></span>');

        if (feature.properties && feature.properties.Name) {
        

        var table = $('<table width=100%></table>');
            var splittedName = _splitRiverSectionName(feature.properties.Name);
          if (splittedName != null) {
              table.append($('<tr></tr>').html($('<td colspan="4" class="hide-title"></td>').html(
                  $translate.instant(splittedName.riverName) // $translate.instant(splittedName.sectionName) // $translate.instant(splittedName.riverName)
                  + ' <br/> ' + parameter.name + '&nbsp' + splittedName.fromTo)));
                  //+ ' <br/> ' + parameter.name + '&nbsp' + splittedName.valueHigh + '&nbsp' + splittedName.valueLow)));
          }
          else {
              table.append($('<tr></tr>').html($('<td colspan="4" class="hide-title"></td>').html(
                  feature.properties.Name + ' <br/> ' + parameter.name )));

          }
        if (isFirst) table.append($('<tr><td class="hide-value"></td></tr>').append($('<td class="hide-value">1-6</td>')).append($('<td class="hide-value">6-24</td>')).append($('<td class="hide-value">\>24</td>')));
        else table.append($('<tr><td class="hide-first"></td></tr>').append($('<td class="hide-value">1-6</td>')).append($('<td class="hide-value">6-24</td>')).append($('<td class="hide-value">\>24</td>')));

        var waterwaySection = allWaterwaySectionsDictionary[feature.properties.Name];
          if (waterwaySection != null) {
              var tableData = selectedScenarioUPMDataDictionary[waterwaySection.Id] || {};
              if (tableData == null) {
                  console.log("UPM data dictionary missing Id:" + waterwaySection.Id)

              }
              table.append(buildPopupTableRow(isO2 ? 7 : 12, tableData.T1_1, tableData.T1_6, tableData.T1_24, limitValues, isFirst));
              table.append(buildPopupTableRow(isO2 ? 2.3 : 4, tableData.T3_1, tableData.T3_6, tableData.T3_24, limitValues, isFirst));
              table.append(buildPopupTableRow(isO2 ? 0.6 : 1, tableData.T12_1, tableData.T12_3, tableData.T12_24, limitValues, isFirst));
          }
          else {
              console.log("No table in dictionary: " + feature.properties.Name)
          }
          ;

        tableContainer.append(table);
      }
      return tableContainer;
    }


    function _getFeatureRiverColor(tableData, isO2, limitValues) {
      //var worst12 = getMapRiverWorstColor(12, tableData.T12_1, tableData.T12_3, tableData.T12_24);
      //var worst4 = getMapRiverWorstColor(4, tableData.T3_1, tableData.T3_6, tableData.T3_24);
      //var worst1 = getMapRiverWorstColor(1, tableData.T1_1, tableData.T1_6, tableData.T1_24);

      var worst12 = getMapRiverWorstColor(isO2 ? 7 : 12, tableData.T1_1, tableData.T1_6, tableData.T1_24, limitValues);
      var worst4 = getMapRiverWorstColor(isO2 ? 2.3 : 4, tableData.T3_1, tableData.T3_6, tableData.T3_24, limitValues);
      var worst1 = getMapRiverWorstColor(isO2 ? 0.6 : 1, tableData.T12_1, tableData.T12_3, tableData.T12_24, limitValues);

      var worst = Math.min(worst12, worst4, worst1);

      return getColorByClassWeight(worst);
    }

    function _splitRiverSectionName(riverSectionName) {
        var splittedRiverName = riverSectionName.split('_');
        var insertLenghts = [
            //{ sectionName: "Fraugde_3805_a", low: 0, high: 1631 },
            { sectionName: "Fraugde_new_trace_a", low: 1631, high: 1631+1531 },
            //{ sectionName: "Fraugde_3805_b", low: 1631 + 1531, high: 1631 +1531+845},
            { sectionName: "Fraugde_new_trace_b", low: 1631 + 1531 + 845, high: 1631 + 1531 + 845 + 824 },
            { sectionName: "VejrupA_New_trace", low: 12270, high: 12928 },
            //{ sectionName: "Fraugde_8735_4264", low: 1631 + 1531 + 845 + 824, high: 1631 + 1531 + 845 + 824+ 4047 }
        ];

      if (splittedRiverName.length < 3 || splittedRiverName[0] === '' || splittedRiverName[1] === '' || splittedRiverName[2] === '') {
        console.log('Could not parse correctly the feature name.');
        console.log(splittedRiverName);
        return;
        }
        for (var s = 0; s < insertLenghts.length; s++) {
            if (insertLenghts[s].sectionName == riverSectionName) {
                splittedRiverName[1] = insertLenghts[s].high;
                splittedRiverName[2] = insertLenghts[s].low;
            }
        }
      var result = {
        riverName: splittedRiverName[0],
        valueHigh: Number(splittedRiverName[1]),
          valueLow: Number(splittedRiverName[2]),
          sectionName: riverSectionName,
          fromTo: splittedRiverName[1] + ' ' + splittedRiverName[2]
      };

      if (result.valueHigh == NaN || result.valueLow == NaN) {
        console.log('Could not parse correctly the feature values.');
        return;
      }
      return result;
    }
    
    // private functions

    function getMapRiverWorstColor(threshold, value1_5, value6_24, value_24_plus, limitValues) {
      var c1_5 = whatClassIsIt(value1_5, threshold, limitValues);
      var c6_24 = whatClassIsIt(value6_24, threshold, limitValues);
      var c_24_plus = whatClassIsIt(value_24_plus, threshold, limitValues);

      var minValue = Math.min(getClassWeight(c1_5), getClassWeight(c6_24), getClassWeight(c_24_plus));
      return minValue;
    }

    function getClassWeight(className) {
      if (className === 'ClassE') { return 0; }
      if (className === 'ClassD') { return 1; }
      if (className === 'ClassC') { return 2; }
      if (className === 'ClassB') { return 3; }
      if (className === 'ClassA') { return 4; }
      return 5;
    }

    function getColorByClassWeight(classWeight) {
      if (classWeight === 0) { return 'firebrick'; }
      if (classWeight === 1) { return 'orange'; }
      if (classWeight === 2) { return 'yellow'; }
      if (classWeight === 3) { return 'YellowGreen'; }
      if (classWeight === 4) { return 'dodgerblue'; }
      return '#bbb';
    }
    
    function buildPopupTableRow(threshold, value1_5, value6_24, value_24_plus, limitValues, isFirst) {
      var row = $('<tr></tr>');
      if (isFirst) row.html($('<td class="hide-value"></td>').text(threshold));
      else row.html($('<td class="hide-first"></td>').text(threshold));
      var tdTag = '<td align="center"></td>';

      var td1_5 = $(tdTag).addClass(whatClassIsIt(value1_5, threshold, limitValues)).text(formatNumberValueForTable(value1_5));
      var td6_24 = $(tdTag).addClass(whatClassIsIt(value6_24, threshold, limitValues)).text(formatNumberValueForTable(value6_24));
      var td_24_plus = $(tdTag).addClass(whatClassIsIt(value_24_plus, threshold, limitValues)).text(formatNumberValueForTable(value_24_plus));
      row.append(td1_5);
      row.append(td6_24);
      row.append(td_24_plus);

      return row;
    }

    function formatNumberValueForTable(value) {
      if (value === undefined)
        return '.';
      return value === 0
        ? value
        : value.toFixed(1);
    }

    function whatClassIsIt(countValue, threshold, limitValues) {
      if (countValue === undefined || countValue === null)
        return "ClassMissingValue";
      var someValue = (1.0 *countValue) / threshold;
      if (someValue < ($.grep(limitValues, function (limitValue) { return limitValue.className === 'ClassA' })[0].limitValue)) {
        return "ClassA";
      }
      else if (someValue < ($.grep(limitValues, function (limitValue) { return limitValue.className === 'ClassB' })[0].limitValue)) {
        return "ClassB";
      }
      else if (someValue < ($.grep(limitValues, function (limitValue) { return limitValue.className === 'ClassC' })[0].limitValue)) {
        return "ClassC";
      }
      else if (someValue < ($.grep(limitValues, function (limitValue) { return limitValue.className === 'ClassD' })[0].limitValue)) {
        return "ClassD";
      }
      else {
        return "ClassE";
      }
    }
    
  }]);

