'use strict';

angular.module('mapsApp')
    .factory('templateFactory', ['$q', '$http', '$mdToast', 'ngAuthSettings',
        function ($q, $http, $mdToast, ngAuthSettings) {

    // Public API here
    return {
        getAllTemplates: _getAllTemplates,
        getTemplate: _getTemplate,
        newTemplate: _newTemplate,
        createTemplate: _createTemplate,
        updateTemplate: _updateTemplate,
        deleteTemplate: _deleteTemplate
    };

    function _getAllTemplates(onlyActive) {
      var deferred = $q.defer();

      $http({
        method: 'GET',
        url: ngAuthSettings.API_URL + 'Template/GetAllTemplates',
        params: {
          onlyActive: onlyActive
        }
      }).then(function successCallback(response) {
        deferred.resolve(response.data);
      }, function errorCallback(response) {
        var errorMessage = response && response.data && response.data.Message
          ? response.data.Message
          : "A error occured while trying to get the Templates.";
        $mdToast.show($mdToast.simple().textContent(errorMessage).position('right top'));
        deferred.reject(response);
      });

      return deferred.promise;
    }


        function _getTemplate(id) {
      var deferred = $q.defer();

      $http({
        method: 'GET',
        url: ngAuthSettings.API_URL + 'Template/GetTemplate',
        params: {
          id: id,
        }
      }).then(function successCallback(response) {
        deferred.resolve(response.data);
      }, function errorCallback(response) {
        var errorMessage = response && response.data && response.data.Message
          ? response.data.Message
          : "A error occured while trying to get the Template.";
        $mdToast.show($mdToast.simple().textContent(errorMessage).position('right top'));
        deferred.reject(response);
      });

      return deferred.promise;
            }

            function _newTemplate() {

            }

    function _createTemplate(newTemplate) {
      var deferred = $q.defer();
      if (newTemplate.ShareAll) newTemplate.scope = 'global';
      else newTemplate.scope = 'own';
      $http({
        method: 'POST',
        url: ngAuthSettings.API_URL + 'template/saveTemplate',
        data: newTemplate
      }).then(function successCallback(response) {
        $mdToast.show($mdToast.simple().textContent("Template succesfully created.").position('right top'));
        deferred.resolve(response);
      }, function errorCallback(response) {
        var errorMessage = response && response.data && response.data.Message
          ? response.data.Message
          : "A error occured while trying to create the new Template.";
        $mdToast.show($mdToast.simple().textContent(errorMessage).position('right top'));
        deferred.reject(response);
      });

      return deferred.promise;

    }

    function _updateTemplate(Template) {
      var deferred = $q.defer();
        if (Template.ShareAll) Template.scope = 'global';
        else Template.scope = 'own';
      $http({
        method: 'POST',
        url: ngAuthSettings.API_URL + 'template/updateTemplate',
        data: Template
      }).then(function successCallback(response) {
        $mdToast.show($mdToast.simple().textContent("Template succesfully updated.").position('right top'));
        deferred.resolve(response);
      }, function errorCallback(response) {
        var errorMessage = response && response.data && response.data.Message
          ? response.data.Message
          : "A error occured while trying to update the Template.";
        $mdToast.show($mdToast.simple().textContent(errorMessage).position('right top'));
        deferred.reject();
      });

      return deferred.promise;

    }

    function _deleteTemplate(deleteTemplate) {
      var deferred = $q.defer();

      $http({
        method: 'POST',
        url: ngAuthSettings.API_URL + 'Template/DeleteTemplate',
        data: deleteTemplate
      }).then(function successCallback(response) {
        $mdToast.show($mdToast.simple().textContent("Template succesfully deleted.").position('right top'));
        deferred.resolve(response);
      }, function errorCallback(response) {
        var errorMessage = response && response.data && response.data.Message
          ? response.data.Message
          : "A error occured while trying to delete the Template.";
        $mdToast.show($mdToast.simple().textContent(errorMessage).position('right top'));
        deferred.reject(response);
      });

      return deferred.promise;

    }


  }]);
