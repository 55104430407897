'use strict';

angular.module('mapsApp')
    .controller('LocalizationTranslationCtrl', ['$mdEditDialog', '$scope', '$mdToast', 'localizationService', function ($mdEditDialog, $scope, $mdToast, localizationService) {
      'use strict';

      $scope.limitOptions = [5, 10, 15];
      $scope.newTranslation = { LanguageId: null, Keyword: null, Translation: null };

      $scope.options = {
          rowSelection: false,
          multiSelect: false,
          autoSelect: false,
          decapitate: false,
          largeEditDialog: true,
          boundaryLinks: true,
          limitSelect: true,
          pageSelect: true
      };

      $scope.query = {
          order: 'Name',
          limit: 5,
          page: 1
      };


      $scope.languages = localizationService.languages;
      $scope.translations = localizationService.translations;
      $scope.languagesDictionary = localizationService.languagesDictionary;
      $scope.languageTranslationsDictionary = localizationService.languageTranslationsDictionary;

      $scope.edit = function (event, item, propertyName, maxLength) {
          event.stopPropagation(); // in case autoselect is enabled

          var editDialog = {
              modelValue: item[propertyName],
              save: function (input) {
                  item[propertyName] = input.$modelValue;
                  $scope.promise = localizationService.manageTranslation(item).then(function (response) {
                  });
              },
              targetEvent: event,
              title: ' ',
              validators: {
                  'md-maxlength': maxLength
              }
          };


          var promise;

          promise = $mdEditDialog.large(editDialog);

          promise.then(function (ctrl) {
              var input = ctrl.getInput();

              input.$viewChangeListeners.push(function () {
                  input.$setValidity('test', input.$modelValue !== 'test');
              });
          });
      };

      $scope.refresh = function () {
          $scope.promise = localizationService.refreshTranslations().then(function () {
              $mdToast.show($mdToast.simple().textContent('The translations were refreshed.'));

          });

      }

      $scope.remove = function (item) {
          var deleteItem = angular.copy(item);
          deleteItem.Translation = null;
          $scope.promise = localizationService.manageTranslation(deleteItem).then(function () {

          });
      };

      $scope.add = function (modalId) {

          $scope.promise = localizationService.manageTranslation($scope.newTranslation)
          .then(function () {

          });

          $scope.newTranslation = { LanguageId: null, Keyword: null, Translation: null };
          $(modalId).modal('hide');
      };


      $scope.logItem = function (item) {
      };

      $scope.logOrder = function (order) {
      };

      $scope.logPagination = function (page, limit) {
      }
  }]);