'use strict';

angular.module('mapsApp')
  .factory('authFactory', ['$http', '$q', '$rootScope', 'localStorageService', 'ngAuthSettings',
    function ($http, $q, $rootScope, localStorageService, ngAuthSettings) {

        var serviceBase = ngAuthSettings.apiServiceBaseUri;
        var authFactoryFactory = {};
        authFactoryFactory.someMethod = function () {
            return 12;
        };

        var _authentication = {
            id: undefined,
            isAuth: false,
            userName: "",
            useRefreshTokens: false,
            roles: []
        };

        var _externalAuthData = {
            provider: "",
            userName: "",
            externalAccessToken: ""
        };
      

        function _saveRegistration(registration) {
          var deferred = $q.defer();
          registration.WebAppBaseUrl = $rootScope.webAppBase;
          $http({
            method: 'POST',
            url: ngAuthSettings.API_URL + 'account/AddOrUpdateUser',
            data: registration
          }).then(function successCallback(response) {
            deferred.resolve(response.data);
          }, function errorCallback(error) {
            deferred.reject(error);
          });

          return deferred.promise;
        }

        var _deleteUser = function (user) {
            var deferred = $q.defer();
            return $http.post(serviceBase + 'api/account/DeleteUser', user).then(function (response) {
                deferred.resolve(response);
            });
            return deferred.promise;
        };

        var _login = function (loginData) {
            var data = {"Email": loginData.Email,"Password": loginData.Password };
            var dataJson = JSON.stringify(data);
            var deferred = $q.defer();

            var req = {
                method: 'POST',
                url: ngAuthSettings.apiServiceBaseUri + 'Account/Login',
                data: dataJson
            }

            $http.post(ngAuthSettings.apiServiceBaseUri + 'Account/Login', dataJson)
                .then(function (resp) {
                    var response = resp.data;
                    _saveTokensInLocalStorage(response.token, response.refreshToken);
                    var success = _setAuthDataFromLocalStorage();
                    if (success === true) {
                        deferred.resolve(resp);
                    } else {
                        deferred.reject('Could not parse the token.');
                    }

                }, function errorCallback(err) {
                    deferred.reject(err);
                });

            return deferred.promise;

        };

        function _setAuthDataFromLocalStorage() {
            var authData = localStorageService.get('authorizationData');
            if (!authData || !authData.token) {
                return false;
            }
            var token = authData.token;
            var parsedToken = parseJwt(token);
            console.log('parsedToken');
            console.log(parsedToken);
            if (!parsedToken || !parsedToken['sub'] || !parsedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier']) {
                console.log('not parsed!!!');
                return false;
            }

            var roles = [];

            var roleClaims = parsedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];

            if (roleClaims && roleClaims.length) {
                roles = roleClaims;
            }
            else if (roleClaims) {
                roles.push(roleClaims);
            }

            _authentication.roles = roles;
            _authentication.userName = parsedToken['sub'];
            _authentication.organization = parsedToken['Organization'];
            _authentication.id = parsedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
            _authentication.isAuth = true;

            return true;
        }

        function _saveTokensInLocalStorage(token, refreshToken) {
            localStorageService.set('authorizationData', { token: token, refreshToken: refreshToken });
        }


        function parseJwt(token) {

            try {
                return JSON.parse(atob(token.split('.')[1]));
            } catch (e) {
                return null;
            }
        }


        var _getAuthUserData = function () {

            var deferred = $q.defer();

            $http.get(ngAuthSettings.API_URL + 'Account/GetLoggedInUserData').then(function (response) {
                deferred.resolve(response.data);

            },function (err, status) {
                deferred.reject(err);
            });

            return deferred.promise;
        };

        var _isInRole = function(role) {
            return $rootScope.inArray(role, _authentication.roles);
        }

        var _logOut = function () {

            localStorageService.remove('authorizationData');
            localStorageService.remove('loginData');
            localStorageService.remove('analysisPageState');

            _authentication.id = undefined;
            _authentication.isAuth = false;
            _authentication.userName = "";
            _authentication.useRefreshTokens = false;
            _authentication.roles = [];

        };

        var _fillAuthData = function () {

            var authData = localStorageService.get('authorizationData');
            if (authData) {
                _authentication.isAuth = true;
                _authentication.id = authData.id;
                _authentication.userName = authData.userName;
                _authentication.useRefreshTokens = authData.useRefreshTokens;
                _authentication.roles = authData.roles;
            }

        };

        var _refreshToken = function () {
            var deferred = $q.defer();

            var authData = localStorageService.get('authorizationData');

            if (authData) {

                if (authData.useRefreshTokens) {

                    var data = "grant_type=refresh_token&refresh_token=" + authData.refreshToken + "&client_id=" + ngAuthSettings.clientId;

                    localStorageService.remove('authorizationData');

                    $http.post(serviceBase + 'token', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }).then(function (resp) {
                        var response = resp.data;
                        localStorageService.set('authorizationData', { token: response.access_token, userName: response.userName, refreshToken: response.refresh_token, useRefreshTokens: true });

                        deferred.resolve(response);

                    },function (err, status) {
                        _logOut();
                        deferred.reject(err);
                    });
                }
            }

            return deferred.promise;
        };

        var _obtainAccessToken = function (externalData) {

            var deferred = $q.defer();

            $http.get(serviceBase + 'api/account/ObtainLocalAccessToken', { params: { provider: externalData.provider, externalAccessToken: externalData.externalAccessToken } }).then(function (resp) {
                var response = resp.data;
                localStorageService.set('authorizationData', { token: response.access_token, userName: response.userName, refreshToken: "", useRefreshTokens: false });

                _authentication.isAuth = true;
                _authentication.userName = response.userName;
                _authentication.id = response.id;
                _authentication.useRefreshTokens = false;

                deferred.resolve(response);

            },function (err, status) {
                _logOut();
                deferred.reject(err);
            });

            return deferred.promise;

        };

        authFactoryFactory.saveRegistration = _saveRegistration;
        authFactoryFactory.login = _login;
        authFactoryFactory.logOut = _logOut;
        authFactoryFactory.fillAuthData = _fillAuthData;
        authFactoryFactory.authentication = _authentication;
        authFactoryFactory.refreshToken = _refreshToken;

        authFactoryFactory.obtainAccessToken = _obtainAccessToken;
        authFactoryFactory.externalAuthData = _externalAuthData;
        authFactoryFactory.deleteUser = _deleteUser;
        
        authFactoryFactory.isInRole = _isInRole;

        return authFactoryFactory;
    }]);
