'use strict';

var Highcharts = require("highcharts");
var proj4 = require("proj4").default;


angular.module('mapsApp')
    .controller('vevaOverviewCtrl', ['$http', '$scope', '$q', '$timeout', '$mdMedia', '$mdBottomSheet', '$mdToast', '$interval', '$mdSidenav', '$mdDialog', '$window', '$location', 'authFactory', 'localStorageService', '$filter', '$translate', 'ChartDefaultsService', 'ngAuthSettings',
        function ($http, $scope, $q, $timeout, $mdMedia, $mdBottomSheet, $mdToast, $interval, $mdSidenav, $mdDialog, $window, $location, authFactory, localStorageService, $filter, $translate, ChartDefaultsService, ngAuthSettings) {
            var dataArrayGroup = [], layerIndex = 0, numberOfFinishedRequests = 0, numberOfTotalRequests = 2; var dataArrayGroupCoordinates = []; var toggleCanvas = false;
            var reference, mouseClickEvent = {}, mouseClickLayer = [];
            $scope.loggedin = true;
            var mouseClickLayerIndex = 0;
            var radarLayer = [];
            var map, gauge3, transformfactor;
            //if (!authFactory.authentication.isAuth) {
            //  console.log("Welcome external user");
            //  $scope.loggedin = false;
            //  // $location.path('/login');
            //}

            //$scope.login = function () {
            //    console.log("Logging in");
            //    //        $scope.loggedin = !$scope.loggedin;
            //    //        if (!$scope.loggedin)$location.path('/login');
            //    //$scope.loggedin = true;
            //};

            var pageChart;
            var imagePath = ".\images";
            var polygonData = [];
            proj4.defs('EPSG:5799', '+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs');
            proj4.defs('EPSG:32632', '+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs');
            proj4.defs("EPSG:25832", "+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
            proj4.defs('EPSG:3008', '+proj=tmerc +lat_0=0 +lon_0=13.5 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');
            proj4.defs("EPSG:3006", "+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
            proj4.defs('WGS84', "+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees");
            var crs3575 = proj4.defs("EPSG:3575", "+proj=laea +lat_0=90 +lon_0=10 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs");//used by DMI 
            $scope.foundAnImage = false;
            
            var radarSpecsAll = [
                { Id: 1, Active: false, Name: 'HOFOR rain', dt: 1, Elevation: 6, Elevations: [4, 6], MaxElevation: 6, BaseURL: 'https://s3-eu-west-1.amazonaws.com/informetics/ftp2/biofos/Hvidovre/pnguR/', NoData: "WR2100-Hofor-no_data.png", geoCenter: [12.456968, 55.653227], zoomLevel : 4,  epsg: 'EPSG:32632', utmCenter: [,], dxdy: '500', x0: 0, y0: 400, imgRadius: 69850, shiftXY: [-3250, 750], shiftWH: [-9000, 0], imgPath: function (yyyy, YY, MM, dd, hh, mm, e) { return yyyy + "/" + MM + "/" + dd + "/" + yyyy + MM + dd + hh + mm + '_0' + e + "_R.png"; } },
                //{ Id: 3, Active: false, Name: 'WR110 Avedøre', dt: 1, Elevation: 3, Elevations: [2, 3], MaxElevation: 4, BaseURL: 'https://s3-eu-west-1.amazonaws.com/kbh1wr110/FurunoHQ/pnguR/', NoData: "WR110-no_data.png", geoCenter: [12.480966, 55.606683], epsg: 'EPSG:32632', utmCenter: [,], dxdy: '1000', x0: 0, y0: 100, imgRadius: 98349, shiftXY: [-5150, 1250], shiftWH: [-9000, 0], imgPath: function (yyyy, YY, MM, dd, hh, mm, e) { return yyyy + "/" + MM + "/" + dd + "/" + yyyy + MM + dd + hh + mm + '_0' + e + "_R.png"; } },
                //{ Id: 6, Active: false, Name: 'WR2100 Dalby', dt: 1, Elevation: 3, Elevations: [3, 4], MaxElevation: 4, BaseURL: 'https://s3-eu-west-1.amazonaws.com/dalby-wr2100/pnguR/', NoData: "WR2100-Dalby-no_data.png", geoCenter: [13.35908, 55.67086], epsg: 'EPSG:32632', utmCenter: [,], dxdy: '500', x0: 0, y0: 400, imgRadius: 70000, shiftXY: [-3500, 1000], shiftWH: [-960 * 15, 0], imgPath: function (yyyy, YY, MM, dd, hh, mm, e) { return yyyy + "/" + MM + "/" + dd + "/" + yyyy + MM + dd + hh + mm + '_0' + e +"_R.png"; } },
                { Id: 8, Active: true, Name: 'DMI gridded', dt: 1, Elevation: 1, Elevations: [1], MaxElevation: 1, API_URL: 'http', BaseURL: 'https://veva-processed-files.s3-eu-west-1.amazonaws.com/plots/dmi_rid8_25832/', NoData: "dmi_interpolated_missingdata.png", geoCenter: [12.2,55.6], zoomLevel: 2,epsg: 'EPSG:32632', utmCenter: [,], utmLL: [184941, 5803829], utmUR: [1155660, 6690579], dxdy: '500', x0: 0, y0: 0, imgRadius: 0, shiftXY: [-55000, 17000], shiftWH: [0, 0], imgPath: function (yyyy, YY, MM, dd, hh, mm,e) { return yyyy + "/" + MM + "/" + dd + "/dmi_interpolated_" + yyyy + MM + dd + hh + mm + ".png"; } },
                { Id: 103, Active: true, Name: 'DMI composite VeVa processing', dt: 1, Elevation: 1, Elevations: [1], MaxElevation: 1, API_URL: 'http', BaseURL: 'https://veva-processed-files.s3-eu-west-1.amazonaws.com/plots/dmi/interpolated/', NoData: "dmi_interpolated_missingdata.png", geoCenter: [12.2, 55.6], zoomLevel: 2, epsg: 'EPSG:32632', utmCenter: [,], utmLL: [184941, 5803829], utmUR: [1155660, 6690579], dxdy: '500', x0: 0, y0: 0, imgRadius: 0, shiftXY: [-55000, 17000], shiftWH: [0, 0], imgPath: function (yyyy, YY, MM, dd, hh, mm, e) { return yyyy + "/" + MM + "/" + dd + "/dmi_interpolated_" + yyyy + MM + dd + hh + mm + ".png"; } },

                { Id: 10, Active: false, Name: 'SMHI c-bånd', dt: 5, Elevation: 1, Elevations: [1], MaxElevation: 1, BaseURL: 'http://opendata-download-radar.smhi.se/api/version/latest/area/sweden/product/comp/', NoData: "WR2100-Hofor-no_data.png", geoCenter: [13.2, 55.8], zoomLevel: 2, epsg: 'EPSG:3006', utmCenter: [,], utmLL: [126648, 5983984], utmUR: [1075693, 7771252], dxdy: '2000', x0: 0, y0: 0, imgRadius: 69850, shiftXY: [-100000, -65000], shiftWH: [60000, 60000], imgPath: function (yyyy, YY, MM, dd, hh, mm) { return yyyy + "/" + MM + "/" + dd + "/radar_" + YY + MM + dd + hh + mm + ".png"; } },
                { Id: 11, Active: false, Name: 'Aarhus Sabro', dt: 1, Elevation: 6, Elevations: [4, 6], MaxElevation: 6, BaseURL: 'https://s3-eu-west-1.amazonaws.com/informetics/ftp2/biofos/Hvidovre/pnguR/', NoData: "WR2100-Hofor-no_data.png", geoCenter: [9.9999, 56.20703], zoomLevel: 4, epsg: 'EPSG:32632', utmCenter: [,], dxdy: '500', x0: 0, y0: 400, imgRadius: 69850, shiftXY: [-3250, 750], shiftWH: [-9000, 0], imgPath: function (yyyy, YY, MM, dd, hh, mm, e) { return yyyy + "/" + MM + "/" + dd + "/" + yyyy + MM + dd + hh + mm + '_0' + e + "_R.png"; } },
                { Id: 12, Active: false, Name: 'Aalborg St.Restrup', dt: 1, Elevation: 6, Elevations: [4, 6], MaxElevation: 6, BaseURL: 'https://s3-eu-west-1.amazonaws.com/informetics/ftp2/biofos/Hvidovre/pnguR/', NoData: "WR2100-Hofor-no_data.png", geoCenter: [9.78304, 57.00835], zoomLevel: 4, epsg: 'EPSG:32632', utmCenter: [,], dxdy: '500', x0: 0, y0: 400, imgRadius: 69850, shiftXY: [-3250, 750], shiftWH: [-9000, 0], imgPath: function (yyyy, YY, MM, dd, hh, mm, e) { return yyyy + "/" + MM + "/" + dd + "/" + yyyy + MM + dd + hh + mm + '_0' + e + "_R.png"; } },

                //{ Id: 99, Active: false, Name: 'WR2100 Hofor2', Elevation: 6, Elevations: [6], MaxElevation: 6, BaseURL: 'https://s3-eu-west-1.amazonaws.com/informetics/ftp2/biofos/Hvidovre/pnguR/', NoData: "WR2100-Hofor-no_data.png", geoCenter: [12.456968, 55.653227], epsg: 'EPSG:32632', utmCenter: [,], dxdy: '500', x0: 0, y0: 400, imgRadius: 69850, shiftXY: [-3250, 750], shiftWH: [-9000, 0], imgPath: function (yyyy, YY, MM, dd, hh, mm, e) { return yyyy + "/" + MM + "/" + dd + "/" + yyyy + MM + dd + hh + mm + '_0' + e + "_R.png"; } },
                //{ Id: 7, radarImg: {}, Active: false, Name: 'SMHI', Elevation: 1, MaxElevation: 1, BaseURL: 'https://s3-eu-west-1.amazonaws.com/informetics-vasyd/Dalby/pnguR/', NoData: "WR2100-Dalby-no_data.png", geoCenter: [16.9328655, 62.0173308], epsg: 'EPSG:3006', utmCenter: [,], dxdy: '500', x0: 0, y0: 400, imgRadius: 70000, shiftXY: [0, 0], shiftWH: [0, 0] }
            ];

            $scope.allRadars = radarSpecsAll
            for (var j = 0; j < radarSpecsAll.length; j++) {
                var r = radarSpecsAll[j];
                r.imageBounds = [,];
                r.imageBoundsUTM = [,];
                r.imageBoundsGEO = [,];
                if (r.geoCenter[0] && r.imgRadius > 0) {
                    //Given a center and radius, calculate the rest... 
                    r.utmCenter = proj4('WGS84', r.epsg, r.geoCenter);
                    r.imageBoundsUTM[0] = [r.utmCenter[0] - r.imgRadius + r.shiftXY[0] + r.shiftWH[0], r.utmCenter[1] - r.imgRadius + r.shiftXY[1]];
                    r.imageBoundsUTM[1] = [r.utmCenter[0] + r.imgRadius + r.shiftXY[0] - r.shiftWH[0], r.utmCenter[1] + r.imgRadius + r.shiftXY[1]];
                }
                else {
                    //Assume bounds given in UTM ... in radarSpecsAll above
                    r.imageBoundsUTM[0] = [r.utmLL[0] + r.shiftXY[0] + r.shiftWH[0], r.utmLL[1] + r.shiftXY[1]];
                    r.imageBoundsUTM[1] = [r.utmUR[0] + r.shiftXY[0] - r.shiftWH[0], r.utmUR[1] + r.shiftXY[1]];
                    r.utmCenter = [(r.imageBoundsUTM[1][0] - r.imageBoundsUTM[0][0]) / 2 + r.imageBoundsUTM[0][0], (r.imageBoundsUTM[1][1] - r.imageBoundsUTM[0][1]) / 2 + r.imageBoundsUTM[0][1]];
                    r.geoCenter = proj4(r.epsg, 'WGS84', r.utmCenter);
                }
                r.imageBounds[0] = proj4(r.epsg, 'WGS84', r.imageBoundsUTM[0]);
                r.imageBounds[1] = proj4(r.epsg, 'WGS84', r.imageBoundsUTM[1]);

                r.imageBoundsGEO[0] = [r.imageBounds[0][1], r.imageBounds[0][0]];
                r.imageBoundsGEO[1] = [r.imageBounds[1][1], r.imageBounds[1][0]];
                console.log("r.imageBoundsGEO: ", r.imageBoundsGEO);
                r.foundAnImage = false;
                var imageuRUrl = r.BaseURL + r.NoData;
                r.radarImg = L.imageOverlay(imageuRUrl, r.imageBoundsGEO, {
                    opacity: 0.6
                });
            }
            $scope.primaryRadarId = 103;
            $scope.showAdvanced = true;
            $scope.isGate21 = false;
            $scope.isIshoj = false;
            $scope.isVASyd = ($scope.primaryRadarId == 6);
            $scope.toggleAdmin = function () { $scope.showAdvanced = !$scope.showAdvanced; }
            $scope.obsStation = [];


            $scope.updatePrimary = function () {
                $scope.mainRadar = $.grep(radarSpecsAll, function (e) { return e.Id == $scope.primaryRadarId; })[0];
                $scope.mainRadar.Active = true;
                $scope.elevation = $scope.mainRadar.Elevation;
                $scope.maxElevation = $scope.mainRadar.MaxElevation;
                if ($scope.loggedin) getEventData();
            }

            $scope.updatePrimary();
            var selectedPoint = {};
            $scope.showingPoint = false;
            $scope.obsId = 0;
            console.log($scope.elevation);
            $scope.HoforElevation = 6;

            $scope.legendIcon = imagePath + 'RadarSkala-icon.png';
            $scope.layersState = {};
            $scope.pointTypes = {};
            $scope.parameterCollections = {};
            $scope.parameters = [
                { Color: 'blue', NoDecimals: 1, DefaultMin: 0, DefaultMax: null, Id: 204, UnitName: 'mm/time' },
                { Color: 'black', NoDecimals: 1, DefaultMin: 0, DefaultMax: null, Id: 314, UnitName: 'mm' },
                { Color: 'green', NoDecimals: 1, DefaultMin: 0, DefaultMax: null, Id: 314, UnitName: 'm3/m2' },
                { Color: 'blue', NoDecimals: 1, DefaultMin: 0, DefaultMax: null, Id: 189, UnitName: 'm' }

            ];
            $scope.chartParameters = {};
            $scope.currentMarker = {};

            $scope.isSideNavOpen = false;
            $scope.isPlotOpen = false;
            $scope.isLogoOpen = true;
            $scope.isInfoOpen = false;
            $scope.isForecastOpen = false;
            $scope.isAccumulateOpen = true;


            $scope.stepLength = 1;
            $scope.showstep = 0;
            $scope.nSteps = 30;

            $scope.toDate = new Date();
            $scope.toDate.setSeconds(0);
            $scope.toDate.setMilliseconds(0);
            //$scope.fromDate = new Date($scope.toDate.getTime() +30*60000);


            $scope.fromDate = $scope.toDate - 15*60*1000;

            $scope.fromTimeseriesDaysBack = 2;


            CalcSteps();
            $scope.autoscaleYaxis = true;
            $scope.timeSeriesLoding = false;
            $scope.currentParameter = {};
            $scope.layers = { baselayers: {} };
            $scope.chartConfig = {};
            var chartConfigObs = ChartDefaultsService.getConfig();
            $scope.tableHeaders = {};
            $scope.initedChartResults = 0;
            $scope.allChartsInit = false;
            $scope.autoscaleYaxis = false;
            $scope.plotActive = false;
            $scope.dataSelected = false;
            $scope.obsDataSelected = false;

            // $scope.AnimateStep = 0;
            $scope.markers = [];
            $scope.driftmsg = "no status";
            $scope.lblForecast = $translate.instant('test');

            $scope.useAccumulation = false;
            $scope.accuScaleMax = 1;

            var step = 0;

            var lookforSMHI = false;
            var lookforCart = false;
            var lookforDMI = true;
            var lookforuR = false;
            var lookforWR110 = false;
            var lookforDalby = false;

            var lookforBase = false;

            var utcDateTime = new Date();
            var firstDate = new Date(); //2017, 8, 17, 11, 40, 0, 0);//(););
            var showValuesCanvas = false;
            var commentsON = false;
            firstDate.setSeconds(0);
            firstDate.setMilliseconds(0);
            firstDate = firstDate - 60000;
            $scope.animateTime = { toDate: new Date(firstDate), maxDate: new Date() };
            $scope.rainEvents = {};
            $scope.numEvents = 2000;
            $scope.eventdaysback = 360;
            $scope.eventmindepth = Number(0);
            $scope.eventminduration = Number(0);
            $scope.mapstate = {};
            $scope.mapstate.radar_dg = {};
            $scope.dateText = "";
            $scope.timeText = "--:--";
            $scope.fabT = {};
            $scope.fabT.hidden = false;
            $scope.fabT.isOpen = false;
            $scope.fabT.hover = false;
            var markerLayers = [];
            var layerControl;
            var layersStateScope = [];
            var parameterCollectionsScope = [];
            var chartParametersScope = [];
            var parametersScope = [];
            //var IconsNew = {};
            var tsChart = {};

            $scope.timeseriesPoint = { Name: 'Not selected', IsRadar: true, Id: 0, utmX: null, utmY: null, Depth: -1 }
            //$('#main-navigation').slideUp();
            $scope.buttonPlayPress = buttonPlayPress;
            $scope.buttonSlowPress = buttonSlowPress;
            $scope.buttonFastPress = buttonFastPress;
            $scope.buttonStopPress = buttonStopPress;
            $scope.buttonBackPress = buttonBackPress;
            $scope.buttonPlotCanvasPress = buttonPlotCanvasPress;
            $scope.buttonForwardPress = buttonForwardPress;
            $scope.swapDirection = swapDirection;
            $scope.newStartDateTime = NewStartDate;
            $scope.sidste30min = true;
            $scope.direction = true;
            $scope.showAkkumulated = false;
            $scope.loadingAkkumulated = false;

            var controlNameElement = document.getElementById("control_text");
            var fieldNameElement = document.getElementById("time_text");
            var valueonmap = document.getElementById("value_onmap");
            var timeonmap = document.getElementById("time_onmap");
            var utmonmap = document.getElementById("utm_onmap");
            var dvronmap = document.getElementById("dvr_onmap");
            var geoonmap = document.getElementById("geo_onmap");

            var linkElement = document.getElementById("link_text");
            var dateElement = document.getElementById("date_text");
            var radarLayerIndex = 0;
            var state = 'stop';
            $scope.speed = 1200;
            var direction = 1;
            var datetime = new Date(firstDate);
            $scope.showingDateTime = datetime;
            var firstload = 1;

            var dot = {};
            var akkLayers = {};

            $scope.fromDateNN = new Date(2019, 4, 20);
            $scope.toDateNN = new Date(2019, 5, 30);
            angular.element(document).ready(function () {
                updateFrom();
                $scope.mapstate = initMap();
                console.log($scope.mapstate);
                $scope.chartConfig = ChartDefaultsService.getConfig();
                if ($scope.loggedin) getEventData();
                //getStatus();
                initBlankChart();
                console.log($scope.parameters);
                //tsChart = forecastCSV();
                SetImage();

            });

            $scope.getForecast = function () {
                forecastCSV();
            }


            $scope.greaterThan = function (prop, val) {
                return function (item) {
                    return Number(item[prop]) > val;
                }
            };
            $scope.toggleOpen = function (isOpen) {
                isOpen = !isOpen;
            };
            $scope.toggleAccumulate = function (isOpen) {
                $scope.isAccumulateOpen = !$scope.isAccumulateOpen;
            };
            $scope.toggleRight = function () {
                $mdSidenav('right').toggle()
                    .then(function () {
                        console.log("toggle RIGHT is done");
                    });
            };
            $scope.toggleMenuEvent = function () {
                $scope.isRightSideNavnOpen = !$scope.isRightSideNavnOpen;
                console.log("Clicked menu toggle")
            };

            $scope.toggleMenuRadar = function () {
                $scope.isSideNavOpen = !$scope.isSideNavOpen;
            };

            $scope.toggleMenuPlot = function () {
                $scope.isPlotOpen = !$scope.isPlotOpen;
            };

            $scope.toggleInfo = function () {
                $scope.isInfoOpen = !$scope.isInfoOpen;
            };
            $scope.toggleLogo = function () {
                $scope.isLogoOpen = !$scope.isLogoOpen;
            };

            $scope.toggleForecast = function () {
                $scope.isForecastOpen = !$scope.isForecastOpen;
            };

            $scope.isSidenavLockedOpen = function (windowSize) {

                var newLockedOpen = $scope.autoCloseSidenav
                    ? $mdMedia(windowSize)
                    : false;

                if (lockedOpen !== newLockedOpen) {
                    setTimeout(function () {
                        $scope.triggerWindowResize();
                    }, 3000);
                }
                lockedOpen = newLockedOpen;

                return newLockedOpen;
            }

            $scope.updateImage = function () {
                SetImage();
            }
            $scope.selectObservation = function (obs) {
                //console.log("Selected point : " + id)
                $scope.obsId = obs;
                console.log(obs);
                $scope.isPlotOpen = true;
                getObsData(obs);
            };


            function getObservationData(point) {
                var deferred = $q.defer();
                if (point.Type == 'svk') {
                    $http({
                        method: 'GET',
                        url: serviceBase + 'api/radar/GetRainGaugeDataByPointId',
                        params: {
                            id: point.Id,
                            fromUtc: $scope.fromDate,
                            toUtc: $scope.toDate
                        }
                    }).then(function successCallback(response) {
                        if (response.data) {
                            var sum = 0;
                            var seriesIndex = 1;
                            var utmOffset = datetime.getTimezoneOffset();
                            var data = [[], []];

                            if (response.data.length > 0) {
                                for (var j = 0; j < response.data.length; j++) {
                                    var obs = response.data[j].Value / 6; //mm/time -> mm/min
                                    sum = sum + (obs);
                                    console.log('Sum ' + sum + ' obs :' + obs);
                                    if (obs > 0) {
                                        console.log('Sum ' + sum + ' obs :' + obs);
                                    }
                                    var datetimestep = Date.parse(response.data[j].Time) - (utmOffset * 60 * 1000);
                                    data[0].push([datetimestep, obs]);
                                    data[1].push([datetimestep, sum]);
                                    //chartConfigObs.series[0].data.push([datetimestep, obs.Value * 3.6]);
                                    //chartConfigObs.series[1].data.push([datetimestep, sum]);
                                }
                                console.log(data);
                                $scope.obsSum = Math.round(sum * 10) / 10;
                                $scope.obsDataSelected = true;
                            };
                            deferred.resolve(data);
                        } else {
                            console.log('no data ...');
                            $mdToast.show($mdToast.simple().textContent('No observations for this point :' + pointId + ' in this period').position('right top').hideDelay(5000));
                        }
                    });
                }
                else {
                    $http({
                        method: 'GET',
                        url: API_URL + 'radar/GetObsData',
                        params: {
                            pointId: point.Id,
                            from: $scope.fromDate,
                            to: $scope.toDate
                        }
                    }).then(function successCallback(response) {
                        if (response.data) {
                            var sum = 0;
                            var seriesIndex = 1;
                            var utmOffset = datetime.getTimezoneOffset();
                            var data = [[], []];

                            if (response.data.length > 0) {
                                for (var j = 0; j < response.data.length; j++) {
                                    var obs = response.data[j].Value * 60 / 1000; //um/time -> mm/min
                                    sum = sum + (obs);
                                    console.log('Sum ' + sum + ' obs :' + obs);
                                    if (obs > 0) {
                                        console.log('Sum ' + sum + ' obs :' + obs);
                                    }
                                    var datetimestep = Date.parse(response.data[j].Time) - (utmOffset * 60 * 1000);
                                    data[0].push([datetimestep, obs]);
                                    data[1].push([datetimestep, sum]);
                                    //chartConfigObs.series[0].data.push([datetimestep, obs.Value * 3.6]);
                                    //chartConfigObs.series[1].data.push([datetimestep, sum]);
                                }
                                console.log(data);
                                $scope.obsSum = Math.round(sum * 10) / 10;
                                $scope.obsDataSelected = true;
                            };
                            $scope.isLoadingData = false;
                            deferred.resolve(data);
                        } else {
                            console.log('no data ...');
                            $mdToast.show($mdToast.simple().textContent('No observations for this point :' + pointId + ' in this period').position('right top').hideDelay(5000));
                        }
                    });
                };
                return deferred.promise;

            }

            function getObsData(obsId) {

                $scope.isLoadingData = true;
                chartConfigObs = ChartDefaultsService.getConfig();
                chartConfigObs.xAxis[1].visible = false;
                chartConfigObs.legend = {
                    layout: 'vertical',
                    x: 50,
                    y: 50,
                    align: 'left',
                    verticalAlign: 'top',
                    floating: true,
                    backgroundColor: '#FFFFFF'
                };

                var seriesIndex = 1;
                //$scope.chartConfig.series[seriesIndex].data = [];

                $scope.obsDepth = -0.1;
                var pointId = $scope.obsStation[obsId].Id;
                console.log(pointId);
                $scope.obsDataSelected = false;

                var point = [$scope.obsStation[obsId].lng, $scope.obsStation[obsId].lat];
                var utmPoint = proj4('WGS84', 'EPSG:32632', point);

                $scope.timeseriesPoint = { Name: 'Radar : ' + utmPoint[0] + ',' + utmPoint[1], IsRadar: true, Id: 0, utmX: utmPoint[0], utmY: utmPoint[1] };
                $scope.showingPoint = true;
                console.log("Primary radar : " + $scope.primaryRadarId + " , elevation: " + $scope.elevation);

                addSeries(chartConfigObs, 0, $scope.parameters[0], 'Obs');
                addSeries(chartConfigObs, 1, $scope.parameters[1], 'Radar');
                addSeries(chartConfigObs, 1, $scope.parameters[1], 'ObsAcc');
                $scope.ObsAkkumulatedTS = [];
                $scope.obsSum = 0;
                chartConfigObs.xAxis[0].min = Date.parse($scope.fromDate);
                chartConfigObs.xAxis[0].max = Date.parse($scope.toDate);

                $q.all([getRadarTSData($scope.primaryRadarId, $scope.elevation, $scope.timeseriesPoint, $scope.fromDate, $scope.toDate), getObservationData($scope.obsStation[obsId])])
                    .then(function (results) {
                        console.log(results[0]);
                        console.log(results[1]);
                        //console.log(results[2]);

                        //function (radarSum, dataRain, dataSum) {
                        //  var payload = {
                        //    radarSum: radarSum,
                        //    dataRain: dataRain,
                        //    dataSum: dataSum
                        //  };
                        chartConfigObs.series[0].data = results[1][0];
                        chartConfigObs.series[1].data = results[0];
                        chartConfigObs.series[2].data = results[1][1];
                        chartConfigObs.series[2].color = "red";
                        console.log(chartConfigObs);
                        angular.extend(chartConfigObs, chartConfigObs.options);
                        //$('#obs-chart-container').highcharts(chartConfigObs, function callback() {
                        //    $timeout(function () {
                        //        $('#obs-chart-container').highcharts().redraw();
                        //    }, 500);
                        //})
                    });
                Highcharts.charts.forEach(function (chart) {
                    //chart.reflow();
                });
            }



            $scope.selectElement = function (id, toggleSidebar, focus) {
                console.log("Selected point : " + id + "," + toggleSidebar)
                //     $mdSidenav('nav-plot').toggle();
                $scope.isPlotOpen = true;
                $scope.currentMarker = $scope.markers[id];
                $scope.markers[id].focus = true;

                if (focus === true) {
                    $scope.mapstate.map.setView($scope.currentMarker._leafletMarker._latlng);
                    $scope.currentMarker._leafletMarker.openPopup();
                }
                getChartData();
            };

            if ($scope.useAccumulation) {
                var dataArray = getPlotRadarData('2016-10-17 19:30:00.000');
            }


            function getPlotRadarData(time) {
                //------------------------------------------------------------------------------------
                // This function calls everything necessary to plot the image of a specific timeframe time
                //------------------------------------------------------------------------------------
                //var timeArray = ['2016-10-17 19:30:00.000', '2016-10-17 19:25:06.000'];

                requestRadarData(time, 0);

                console.log("Grabbing Data");
            }
            //------------------------------------------------------------------------------------
            $scope.callPlot = function (time) {
                getPlotRadarData(time);
            }
            $scope.callPlotSquare = function (eventIndex) {
                var from = new Date($scope.rainEvents[eventIndex].start);
                console.log(time);
                getRadarDataMySql(time);
                console.log("Grabbing Data");
            }

            function drawingOnCanvasSquare(canvasOverlay, params) {
                //------------------------------------------------------------------------------------
                // This function is responsible for the actual drawing of the canvas Radar Image
                //------------------------------------------------------------------------------------
                var tau = 2 * Math.PI;
                var coordinatesTopLeft = [56.10118539298188, 11.662568285703381],
                    coordinatesBottomRight = [55.23976793801097, 13.268154157682142],
                    coordinatesTopRight = [coordinatesTopLeft[0], coordinatesBottomRight[1]],
                    coordinatesCenter = [(coordinatesTopLeft[0] + coordinatesBottomRight[0]) / 2, (coordinatesTopLeft[1] + coordinatesBottomRight[1]) / 2];


                //-----------------------------------------------------------------------------------------
                //var RadarCenter = [12.456968, 55.653227]
                //proj4.defs('EPSG:5799', '+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs');
                //proj4.defs('EPSG:32632', '+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs');
                //proj4.defs('WGS84', "+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees");

                var dot = canvasOverlay._map.latLngToContainerPoint(coordinatesCenter);
                var ctx = params.canvas.getContext('2d');
                ctx.globalAlpha = 0.7;
                dataArray = dataArrayGroup[layerIndex];
                ctx.clearRect(0, 0, params.canvas.width, params.canvas.height);
                var plotTimer;
                var maxRadius = 1000,
                    innerRadius = 0,
                    color,
                    anglesRad,
                    numberOfRings,
                    stepRadius,
                    minvalue = 0,
                    offsetx = 0,
                    offsety = 0;
                color = palette(minvalue, $scope.accuScaleMax);
                stepRadius = (maxRadius - innerRadius) / 1000;
                //Transformfactor rescales Radarplot after Zoom
                transformfactor = (canvasOverlay._map.latLngToContainerPoint(coordinatesBottomRight).x - canvasOverlay._map.latLngToContainerPoint(coordinatesTopLeft).x) / maxRadius;
                if ($scope.mainRadar.dxdy == '1000') transformfactor = transformfactor * 2;

                dataArrayGroupCoordinates = [];
                for (var i = 0; i < dataArray.length; i++) {
                    if (dataArray[i].Value > minvalue) {
                        ctx.fillStyle = chooseColor(dataArray[i].Value, color);
                        ctx.beginPath();
                        ctx.alpha = 0.5;

                        var coordinatesLATLNG = proj4('EPSG:32632', 'WGS84', [dataArray[i].Xcoord, dataArray[i].Ycoord])
                        dataArray[i].ID = i;
                        var pixelCoords = canvasOverlay._map.latLngToContainerPoint([coordinatesLATLNG[1], coordinatesLATLNG[0]])
                        //console.log([dataArray[i].Xcoord, dataArray[i].Ycoord]);
                        //console.log(coordinatesLATLNG);
                        //console.log(pixelCoords);
                        ctx.rect(pixelCoords.x, pixelCoords.y, 5 * transformfactor, 5 * transformfactor)
                        dataArrayGroupCoordinates.push({ Value: dataArray[i].Value, x: pixelCoords.x, y: pixelCoords.y, transformfactor: transformfactor, xOrg: dataArray[i].Xcoord, yOrg: dataArray[i].Ycoord })
                        ctx.fill();
                        ctx.closePath();
                    }
                }


                function chooseColor(value, color) {
                    return color(value)
                }



                //function decideColor(dat, maxDat) {
                //    return rgb(0, 0, "+ dat/maxDat*10 + ");
                //}
                function palette(min, max) {
                    var d = (max - min) / 9;
                    return d3.scaleThreshold()
                        .range(['#2c7bb6', '#00a6ca', '#00ccbc', '#c90eb9d', '#ffff8c', '#f9d057', '#f29e2e', '#e76818', '#d7191c'])
                        .domain([min + 1 * d, min + 2 * d, min + 3 * d, min + 4 * d, min + 5 * d, min + 6 * d, min + 7 * d, min + 8 * d, min + 9 * d]);
                }
            }

            $scope.endDrawLayerSquare = function () {
                // Whenever you want to remove all overlays:
                console.log("Removing Layer");
                map.off("mousemove");
                $scope.showAkkumulated = false;
                map.removeLayer(radarLayer[0]);

            }

            $scope.showAccLayer = function (on) {
                console.log(radarLayer[0]);
                if (!on) radarLayer[0]._canvas.style.Opacity = 0;
                else radarLayer[0]._canvas.style.Opacity = 0.9;
            }



            function clickListenerTimeSeries(event) {
                //------------------------------------------------------------------------------------
                // gets called at every mousemove, determines position of the cursor and searches for the
                // corresponding value in the dataArray
                // also handles the deletion of older mouseover layers, every mouseovr generates a new layer
                // and deletes the old one
                //------------------------------------------------------------------------------------
                console.log(event);

                mouseClickEvent.x = event.x;
                mouseClickEvent.y = event.y;

                console.log("Center x: " + dot.x + "Center y: " + dot.y + " Click x: " + event.x + " Click y: " + event.y);

                console.log('Getting Radar Data Timeseries');
                var point = [event.latlng.lng, event.latlng.lat];
                var utmPoint = proj4('WGS84', 'EPSG:32632', point);
                var geoRect = getGridRectangle(point);
                selectedPoint.setBounds(geoRect);
                $scope.showingPoint = true;
                $scope.timeseriesPoint = { Name: 'Radar : ' + utmPoint[0] + ',' + utmPoint[1], IsRadar: true, Id: 0, utmX: utmPoint[0], utmY: utmPoint[1] };
                console.log("Primary radar : " + $scope.primaryRadarId + " , elevation: " + $scope.elevation);
                $scope.updatePlot(); //getRadarTSData($scope.primaryRadarId, $scope.elevation, $scope.timeseriesPoint, $scope.fromDate, $scope.toDate);

            }

            function getGridRectangle(geoPoint) {
                //Assume this is the primaryradar and that it is in UTM32
                var utmPoint = proj4('WGS84', 'EPSG:32632', geoPoint);

                var modx = (utmPoint[0] + $scope.mainRadar.x0) % $scope.mainRadar.dxdy;
                var mody = (utmPoint[1] + $scope.mainRadar.y0) % $scope.mainRadar.dxdy;
                //console.log("Correction " + modx + ", " + mody);
                //console.log(utmPoint);
                if (modx > 0) {
                    //var correctx = (int)(Math.Round((decimal)(modx) / dxdy) * dxdy);
                    utmPoint[0] = utmPoint[0] - modx;
                }
                if (mody > 0) {
                    //int correcty = (int)(Math.Round((decimal)(mody) / dxdy) * dxdy);
                    utmPoint[1] = utmPoint[1] - mody;
                    //y = y + correcty - mody;
                }
                //console.log(utmPoint);
                var UtmBoundsUR = [parseInt(utmPoint[0]) + parseInt($scope.mainRadar.dxdy), parseInt(utmPoint[1]) + parseInt($scope.mainRadar.dxdy)];
                //console.log(UtmBoundsUR)
                var WGSBoundsLL = proj4('EPSG:32632', 'WGS84', utmPoint);
                var WGSBoundsUR = proj4('EPSG:32632', 'WGS84', UtmBoundsUR);
                return [[WGSBoundsLL[1], WGSBoundsLL[0]], [WGSBoundsUR[1], WGSBoundsUR[0]]];
            }


            function getStatus() {
                var urlCalls = [];
                urlCalls.push(
                    $http({
                        method: 'GET',
                        url: API_URL + 'values/GetStatus',
                        params: {
                            maxMessages: 1
                        }
                    })
                );

                $q.all(urlCalls)
                    .then(function (msgs) {
                        console.log(msgs);
                        for (var iDat = 0; iDat < msgs.length; iDat++) {
                            var msg = msgs[iDat].data[0];
                            if (iDat > 0) $scope.driftmsg = $scope.driftmsg + "," + msg;
                            else $scope.driftmsg = msg;

                        }
                        $mdToast.show($mdToast.simple().textContent($scope.driftmsg).position('right top').hideDelay(5000));

                    });
                //return 'Alt ser ud til at fungere...';
            }

            $scope.getAccumulate = function (from, to) {
                $scope.loadingAkkumulated = true;
                var bounds = map.getBounds();
                requestRadarDataSquare($scope.primaryRadarId, $scope.elevation, from, to, bounds);
                $scope.loadingAkkumulated = false;


            }


            function requestRadarDataSquare(radarId, elevation, from, to, bounds) {
                //------------------------------------------------------------------------------------
                // Tis responsible for the server request and to get the incoming data into the necessary format
                //------------------------------------------------------------------------------------
                console.log('Getting Square Data')
                console.log(from);
                console.log(to);
                var urlCalls2 = [];
                urlCalls2.push(
                    $http({
                        method: 'GET',
                        url: API_URL + 'radar/GetRadarSquareSumFromMySQL',
                        params: {
                            fromLocal: from,
                            toLocal: to,
                            elevation: elevation,
                            radarId: radarId
                            //'2016-10-17 19:25:06.000'
                        }
                    })
                );

                $q.all(urlCalls2)
                    .then(function (data) {
                        for (var iDat = 0; iDat < data.length; iDat++) {
                            var responseData = data[iDat].data;
                        }
                        console.log(responseData)
                        dataArrayGroup[0] = responseData;
                        startDrawLayerSquare();
                        $scope.showAkkumulated = true;
                        console.log("Drawing Layer!");
                        $scope.loadingAkkumulated = false;
                    });
            }

            function startDrawLayerSquare() {
                //------------------------------------------------------------------------------------
                // This function handles the drawing of the layer and handles the cases of redrawing and
                // sets the event listener for the mousemove
                //------------------------------------------------------------------------------------
                dataArray = dataArrayGroup[0];
                radarLayer[0] = L.canvasOverlay(drawingOnCanvasSquare);
                radarLayer[0].addTo(map);
                map.addEventListener("mousemove", function (e) { clickListenerSquare(e.containerPoint) });
            }

            function clickListenerSquare(event) {
                //------------------------------------------------------------------------------------
                // gets called at every mousemove, determines position of the cursor and searches for the
                // corresponding value in the dataArray
                // also handles the deletion of older mouseover layers, every mouseovr generates a new layer
                // and deletes the old one
                //------------------------------------------------------------------------------------
                //console.log(event);
                mouseClickEvent.x = event.x;
                mouseClickEvent.y = event.y;
                if (commentsON) {
                    console.log("Center x: " + dot.x + "Center y: " + dot.y + " Click x: " + event.x + " Click y: " + event.y);
                }
                var result = $.grep(dataArrayGroupCoordinates, function (e) { return 3 * e.transformfactor > Math.sqrt((e.x + 3 * transformfactor - event.x) * (e.x + 3 * transformfactor - event.x) + (e.y + 3 * transformfactor - event.y) * (e.y + 3 * transformfactor - event.y)) });
                if (result[0] != undefined) {
                    valueonmap.textContent = result[0].Value.toFixed(2).toString() + " mm";
                }

            }
            function initBlankChart() {

                var timeseries = Highcharts.chart('overview-chart-container', ChartDefaultsService.getConfig());
                //$('#overview-chart-container').highcharts(ChartDefaultsService.getConfig(), function callback() {
                //    $timeout(function () {
                //        $('#overview-chart-container').highcharts().redraw();
                //    }, 1000);
                //});
                //$('#obs-chart-container').highcharts(ChartDefaultsService.getConfig(), function callback() {
                //    $timeout(function () {
                //        $('#obs-chart-container').highcharts().redraw();
                //    }, 1000);
                //});
                var useOnClick = true;
                if (useOnClick) {
                    Highcharts.setOptions({
                        chart: {
                            events: {
                                click: function (event) {
                                    highChartClicked(this, event);
                                }
                            }
                        }
                    });
                }
            }

            var sectionEnds = [];
            var lineChart = {};



            function updateHighChartsPlotLine(datetime) {
                //Plot a vertical red line in all highcharts plots with a timeline...
                for (var i = 0; i < Highcharts.charts.length; i++) {
                    if (Highcharts.charts[i] != null) {
                        Highcharts.charts[i].update({
                            xAxis: {
                                plotLines: [{
                                    color: '#FF0000',
                                    width: 2,
                                    value: datetime.getTime(),
                                }],
                            }
                        });
                    }
                }
            };

            function highChartClicked(chart, event) {
                datetime = new Date(event.xAxis[0].value);
                var clickDateTime = new Date(event.xAxis[0].value);
                console.log("Triggered Event " + event.xAxis[0].value);
                console.log(clickDateTime);
                console.log(clickDateTime.getTime());

                var label = chart.renderer.label(
                    //          'x: ' + Highcharts.numberFormat(event.xAxis[0].value, 2) + ', y: ' + Highcharts.numberFormat(event.yAxis[0].value, 2),
                    'Show: ' + clickDateTime.toLocaleString(),
                    event.xAxis[0].axis.toPixels(event.xAxis[0].value),
                    event.yAxis[0].axis.toPixels(event.yAxis[0].value)
                )
                    .attr({
                        fill: Highcharts.getOptions().colors[0],
                        padding: 10,
                        r: 5,
                        zIndex: 8
                    })
                    .css({
                        color: '#FFFFFF'
                    })
                    .add();

                setTimeout(function () {
                    label.fadeOut();
                }, 1000);
                updateHighChartsPlotLine(clickDateTime);
                SetImage();
            }

            function initChart(parameters, title) {
                var chartConfig = ChartDefaultsService.getConfig();
                chartConfig.title.text = title;
                chartConfig.title.x = 100;
                chartConfig.title.y = 10;

                // $scope.chartConfig.chart.zoomType = 'xy';
                chartConfig.xAxis[1].visible = false;
                chartConfig.xAxis[0].min = Date.parse($scope.fromDate);
                chartConfig.xAxis[0].max = Date.parse($scope.toDate);

                if (parameters != null) {
                    var colorPallete = [] = new ChartDefaultsService.ColorPalletes(parameters.length).colorStrings;
                    for (var j = 0; j < parameters.length; j++) {
                        addSeries(chartConfig, j, parameters[j]);
                    }
                }
                return chartConfig;

            }

            function addSeries(cc, j, parameter, description, overridecolor) {
                var paramColor = parameter.Color;
                var NumberDecimals = parameter.NoDecimals;
                var colorsString = !paramColor || paramColor === null || paramColor === '' ? colorPallete[j] : paramColor;
                if (overridecolor) {
                    colorsString = overridecolor;
                }
                cc.yAxis[j] = {
                    labels: {
                        NoDecimals: Math.max(0, NumberDecimals),
                        style: {
                            color: colorsString
                        },
                        formatter: function () {
                            return Highcharts.numberFormat(this.value, this.axis.userOptions.labels.NoDecimals);
                        }
                    },
                    title: {
                        text: $translate.instant('__short__param__' + parameter.Id) + ' (' + parameter.UnitName + ')',
                    },
                    min: $scope.autoscaleYaxis ? null : $scope.fixedscaleYaxis ? parameter.DefaultMin : 0,
                    max: $scope.autoscaleYaxis ? null : $scope.fixedscaleYaxis ? parameter.DefaultMax : null,
                    gridLineWidth: j === 0 ? 1 : 0,
                    //minorTickInterval : 3,
                    tickInterval: null,
                    reversed: ([204, 212, 213, 215, 252].indexOf(parameter.Id) > -1) ? true : false,
                    opposite: j === 0 ? false : true
                };
                cc.series.push({
                    parameterId: parameter.Id,
                    name: description + ' ' + $translate.instant('__short__param__' + parameter.Id) + ' (' + parameter.UnitName + ')',
                    stickyTracking: false,
                    tooltip: {
                        valueDecimals: NumberDecimals
                    },
                    type: ([204, 212, 213, 215, 252].indexOf(parameter.Id) > -1) ? 'column' : 'line',
                    pointWidth: ([212, 213, 215, 252].indexOf(parameter.Id) > -1) ? 2 : null,
                    yAxis: j,
                    data: [],
                    color: colorsString
                });

            }

            function buildAutoEnhance(from, to) {

                var expectedResultsPerGraph = Math.round(Math.abs(from - to) / 60000);
                var graphEnhanceOptions = {};

                if (expectedResultsPerGraph > 400 && $scope.resamplePlot) {
                    var minutesMultiplyer = Math.round(expectedResultsPerGraph / 400 + 0.4999999);
                    graphEnhanceOptions.groupTimeSpan = 2; //Minutter
                    graphEnhanceOptions.normalizeMethod = 4; //MinMax
                    graphEnhanceOptions.multiplyer = minutesMultiplyer;
                }
                else {
                    graphEnhanceOptions.groupTimeSpan = 0;
                    graphEnhanceOptions.normalizeMethod = 0;
                    graphEnhanceOptions.multiplyer = 1;
                }

                return graphEnhanceOptions;
            }

            $scope.updatePlot = function () {
                var tsFromDate = $scope.fromDate;
                var tsToDate = $scope.toDate;
                if ($scope.isGate21) {
                    tsToDate = moment(datetime).add(1, 'd').toDate();
                    tsFromDate = moment(tsToDate).subtract($scope.fromTimeseriesDaysBack, 'd').toDate();
                }
                console.log($scope.timeseriesPoint);
                if ($scope.timeseriesPoint.IsRadar) {
                    getRadarTSData($scope.primaryRadarId, $scope.elevation, $scope.timeseriesPoint, tsFromDate, tsToDate);
                }
                else getChartData();
            }

            $scope.showSpectrum = function () {
                getSpectrumData();
            }

            function CalcSteps() {
                $scope.nsteps = Math.round((($scope.toDate - $scope.fromDate) / 60000) / $scope.stepLength); // minutes

            }

            $scope.selectEvent = function (eventIndex) {
                console.log("Selected event :" + eventIndex + " start" + $scope.rainEvents[eventIndex].duration);
                datetime = $scope.rainEvents[eventIndex].from;
                $scope.fromDate = $scope.rainEvents[eventIndex].from;
                $scope.fromDate.setSeconds(0);
                $scope.toDate.setMilliseconds(0);
                $scope.toDate = $scope.rainEvents[eventIndex].to;// = $scope.rainEvents[eventIndex].end;
                CalcSteps();
                $scope.showstep = 0;
                if (showValuesCanvas) { buttonPlotCanvasPress($scope.fromDate); }
                $scope.isAccumulateOpen = true;
                $scope.sidste30min = false;
                SetImage();
                $scope.updatePlot();
            }
            $scope.initEventTable = function (index, key) {
                if (index == 0) {

                    $scope.isHidden = []
                    $scope.keyTable = []
                }

                $scope.outerIndex = index;
                $scope.isHidden.push(false)
                $scope.keyTable.push(key)


            }
            $scope.clickEventTable = function (index) {

                $scope.isHidden[index] = !$scope.isHidden[index];

            }
            $scope.printKey = function (key) {
                console.log(key)
            }
            $scope.checkHide = function (key) {

                return $scope.isHidden[$scope.keyTable.indexOf(key)]
            }
            function getEventData() {

                console.log("Getting events");
                var deferred = $q.defer();
                $http({
                    method: 'GET',
                    url: API_URL + 'radar/GetRainEvents',
                    params: {
                        radarId: $scope.primaryRadarId,
                        limit: $scope.numEvents,
                        daysback: $scope.eventdaysback,
                        mindepth: $scope.eventmindepth,
                    }
                }).then(function successCallback(response) {
                    if (response.data) {
                        var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                        var options = { day: 'numeric', month: 'numeric', hour: '2-digit', minute: '2-digit' };
                        for (var j = 0; j < response.data.length; j++) {
                            var event = response.data[j];
                            var eventStart = new Date(event.StartTime);
                            //console.log(eventStart.toLocaleDateString("en-US", options));
                            $scope.rainEvents[j] = {
                                id: j,
                                from: eventStart,
                                to: new Date(event.EndTime),
                                start: eventStart.toLocaleDateString("dk-DK", options),
                                month: eventStart.toLocaleDateString("dk-DK", { month: 'long', year: 'numeric' }), //monthNames[eventStart.getMonth()] + ' ' + eventStart.getFullYear(),
                                duration: event.Duration,
                                RainDepth: event.RainDepth.toFixed(1),
                                I10: event.I10,
                                I30: event.I30,
                                RadarRainDepth: event.RadarRainDepth
                            }
                        };
                    }
                    deferred.resolve();
                });
                return deferred.promise;
            }



            function getRadarTSData(radarId, elevation, timeseriesPoint, from, to) {
                $scope.timeSeriesLoding = true;
                $scope.timeseriesPoint.Depth = -1;
                $scope.chartConfig = initChart([$scope.parameters[0], $scope.parameters[1]], timeseriesPoint.Name);
                $scope.chartConfig.series[0].data = [];
                $scope.chartConfig.xAxis[0].min = Date.parse(from);
                $scope.chartConfig.xAxis[0].max = Date.parse(to);
                var utmOffset = 0; //datetime.getTimezoneOffset();
                var fromUTC = new Date();
                fromUTC.setTime(from.getTime() + (utmOffset * 60 * 1000));
                var toUTC = new Date();
                toUTC.setTime(to.getTime() + (utmOffset * 60 * 1000));
                console.log(to)
                console.log(toUTC)
                var urlCalls2 = [];
                var deferred = $q.defer();
                $http({
                    method: 'GET',
                    url: API_URL + 'radar/GetTimeSeries',
                    //url: API_URL + 'radar/ExportAsDFS0',
                    params: {
                        // tableFront: 'grid500m',
                        x: Math.floor(timeseriesPoint.utmX),
                        y: Math.floor(timeseriesPoint.utmY),
                        from: fromUTC,
                        to: toUTC,
                        elevation: elevation,
                        radarId: radarId,
                        AddZero: false
                    }
                }).then(function successCallback(response) {
                    if (response.data) {
                        console.log("Got data...");
                        $scope.timeSeriesLoding = false;
                        var responseData = response.data;
                        $scope.timeSeriesLoding = false;
                        $scope.plotActive = true;
                        $scope.dataSelected = true;
                        var seriesIndex = 0;
                        var utmOffset = datetime.getTimezoneOffset();
                        $scope.timeseriesPoint.Depth = 0;
                        $scope.chartConfig.series[seriesIndex].data = [];
                        console.log("tsdata:", $scope.chartConfig.series[seriesIndex].data);
                        for (var k = 0; k < responseData.length; k++) {
                            var datetimestep = Date.parse(responseData[k].Time) - (utmOffset * 60 * 1000);
                            $scope.timeseriesPoint.Depth = $scope.timeseriesPoint.Depth + (responseData[k].Value / 60); //mm/hour -> mm/minute
                            $scope.chartConfig.series[0].data.push([datetimestep, responseData[k].Value]);
                            $scope.chartConfig.series[1].data.push([datetimestep, $scope.timeseriesPoint.Depth]);
                        };
                        $scope.timeseriesPoint.Depth = (Math.round($scope.timeseriesPoint.Depth * 10)) / 10;
                        //console.log($scope.chartConfig.series[seriesIndex].data);
                        //angular.extend($scope.chartConfig, $scope.chartConfig.options);
                        console.log("ChartConfig: ", $scope.chartConfig);
                        $('#overview-chart-container').highcharts($scope.chartConfig, function callback() {
                            $timeout(function () {
                                $('#overview-chart-container').highcharts().redraw();
                            }, 500);

                        })
                        deferred.resolve($scope.chartConfig.series[1].data);
                    }
                });
                return deferred.promise;
            }

            // Gets the chart series for the current marker from the api and populates the chart
            function getChartData() {
                $scope.allChartsInit = false;
                $scope.timeSeriesLoding = true;
                $scope.chartConfig = initChart();
                if ($scope.currentMarker.type == null) return;
                var parameters = chartParametersScope[$scope.currentMarker.type];
                var urlCalls = [];
                var graphEnhanceOptions = buildAutoEnhance($scope.fromDate, $scope.toDate);
                //for (var p = 0; p < charts.length; p++) {
                //    charts[p].xAxis[0].min = Date.parse($scope.fromDate);
                //    charts[p].xAxis[0].max = Date.parse($scope.toDate);
                //}
                $scope.chartConfig.xAxis[0].min = Date.parse($scope.fromDate);
                $scope.chartConfig.xAxis[0].max = Date.parse($scope.toDate);
                for (var i = 0; i < parameters.length; i++) {
                    console.log("Getting :  pointId: " + $scope.currentMarker.id + " , parameterId: " + parameters[i].Id);
                    var parameter = parameters[i].Id;
                    if ($scope.currentMarker.id == 1343) parameter = 304;
                    urlCalls.push(
                        $http({
                            method: 'GET',
                            url: API_URL + 'values/GetGraphData',
                            params: {
                                pointId: $scope.currentMarker.id,
                                parameterId: parameter,
                                // dataSourceId: parameters[i].DataSourceId,
                                fromlocal: $scope.fromDate,
                                toLocal: $scope.toDate,
                                span: graphEnhanceOptions.groupTimeSpan,
                                multiplyer: graphEnhanceOptions.multiplyer,
                                method: graphEnhanceOptions.normalizeMethod

                            }
                        }));
                }

                $q.all(urlCalls)
                    .then(function (data) {
                        $scope.timeSeriesLoding = false;
                        $scope.plotActive = true;
                        $scope.dataSelected = true;
                        for (var iDat = 0; iDat < data.length; iDat++) {
                            for (var j = 0; j < parameters.length; j++) {
                                var responseData = data[iDat].data;
                                var seriesIndex = 0;

                                $scope.chartConfig.series[seriesIndex].data.length = 0;
                                for (var k = 0; k < responseData.length; k++) {
                                    var datetimestep = Date.parse(responseData[k].Time);
                                    //console.log("pre " + datetimestep);
                                    if ($scope.currentMarker.id == 1343) datetimestep = datetimestep + (2 * 60 * 60 * 1000);
                                    //console.log("post " + datetimestep);

                                    $scope.chartConfig.series[seriesIndex].data.push([
                                        datetimestep,
                                        responseData[k].Value
                                    ]);
                                };
                            }
                        }

                        angular.extend($scope.chartConfig, $scope.chartConfig.options);
                        $('#overview-chart-container').highcharts($scope.chartConfig, function callback() {
                            $timeout(function () {
                                $('#overview-chart-container').highcharts().redraw();
                            }, 500);
                        })
                    });

            }



            function getRadarDataTS() {
                $scope.allChartsInit = false;
                $scope.timeSeriesLoding = true;
                if ($scope.currentMarker.type == null) return;
                var parameters = chartParametersScope[$scope.currentMarker.type];
                var urlCalls = [];
                var graphEnhanceOptions = buildAutoEnhance($scope.fromDate, $scope.toDate);
                //for (var p = 0; p < charts.length; p++) {
                //    charts[p].xAxis[0].min = Date.parse($scope.fromDate);
                //    charts[p].xAxis[0].max = Date.parse($scope.toDate);
                //}
                $scope.chartConfig.xAxis[0].min = Date.parse($scope.fromDate);
                $scope.chartConfig.xAxis[0].max = Date.parse($scope.toDate);
                for (var i = 0; i < parameters.length; i++) {
                    console.log("Getting :  pointId: " + $scope.currentMarker.id + " , parameterId: " + parameters[i].Id);
                    var parameter = parameters[i].Id;
                    if ($scope.currentMarker.id == 1343) parameter = 304;
                    urlCalls.push(
                        $http({
                            method: 'GET',
                            url: API_URL + 'values/GetGraphData',
                            params: {
                                pointId: $scope.currentMarker.id,
                                parameterId: parameter,
                                // dataSourceId: parameters[i].DataSourceId,
                                fromlocal: $scope.fromDate,
                                toLocal: $scope.toDate,
                                span: graphEnhanceOptions.groupTimeSpan,
                                multiplyer: graphEnhanceOptions.multiplyer,
                                method: graphEnhanceOptions.normalizeMethod

                            }
                        }));
                }

                $q.all(urlCalls)
                    .then(function (data) {
                        $scope.timeSeriesLoding = false;
                        $scope.plotActive = true;
                        $scope.dataSelected = true;
                        for (var iDat = 0; iDat < data.length; iDat++) {
                            for (var j = 0; j < parameters.length; j++) {
                                var responseData = data[iDat].data;
                                var seriesIndex = 0;

                                $scope.chartConfig.series[seriesIndex].data.length = 0;
                                for (var k = 0; k < responseData.length; k++) {
                                    var datetimestep = Date.parse(responseData[k].Time);
                                    //console.log("pre " + datetimestep);
                                    if ($scope.currentMarker.id == 1343) datetimestep = datetimestep + (2 * 60 * 60 * 1000);
                                    //console.log("post " + datetimestep);

                                    $scope.chartConfig.series[seriesIndex].data.push([
                                        datetimestep,
                                        responseData[k].Value
                                    ]);
                                };
                            }
                        }

                        angular.extend($scope.chartConfig, $scope.chartConfig.options);
                        $('#overview-chart-container').highcharts($scope.chartConfig, function callback() {
                            $timeout(function () {
                                $('#overview-chart-container').highcharts().redraw();
                            }, 500);
                        })
                    });

            }

            function initJsonData(map) {
                var deferred = $q.defer();
                $.when(
                    $.getJSON('data/ledninger_slope.geojson', function (data) {
                        $scope.hofor_ledninger = data;
                    })
                ).then(function () {
                    $scope.rivers_sections = L.Proj.geoJson($scope.riverSectionsJsonData, {
                        style: function (feature) {
                            // Set the river section color
                            return { color: 'grey' };
                        }
                    }).addTo(map);
                    deferred.resolve();
                }, function errorCallback(err) {
                    console.log(err);
                    deferred.reject(err);
                });
                return deferred.promise;
            }
            function initMap() {

                var kmsusr = "InforMeticsTst";
                var kmspwd = "Hauser10";
                var kftoken = 'd12107f70a3ee93153f313c7c502169a';

                // Set the attribution (the copyright statement shown in the lower right corner)
                // We do this as we want the same attributions for all layers
                var myAttributionText = '&copy; <a target="_blank" href="https://download.kortforsyningen.dk/content/vilk%C3%A5r-og-betingelser">Styrelsen for Dataforsyning og Effektivisering</a>';

                var RadarCenter = $scope.mainRadar.geoCenter;
                //var RadarCenterDalby = radarSpecsAll[2].geoCenter;
                var viewCenter = [$scope.mainRadar.geoCenter[1], $scope.mainRadar.geoCenter[0]];
                var zoomLevel = 10;

                var baselayer = "";
                console.log('In init map');

                map = new L.Map('map', { zoom: 10, fullscreenControl: false });


                L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                }).addTo(map);


                //add zoom control with your options
                L.control.zoom({
                    position: 'topright'
                }); //.addTo(map);
                var offsetx = 0, offsety = 0;
                var matrikelkort = L.tileLayer.wms('http://{s}.services.kortforsyningen.dk/service', {
                    service: 'WMS',
                    transparent: true,
                    servicename: 'mat',
                    layers: 'Centroide,MatrikelSkel,OptagetVej',
                    version: '1.1.0',
                    login: kmsusr,
                    password: kmspwd,
                    styles: 'sorte_centroider,sorte_skel,default',
                    format: 'image/png',   //&sld_body=%3CStyledLayerDescriptor+version%3D%221.0.0%22+xmlns%3D%22http%3A%2F%2Fwww.opengis.net%2Fsld%22+xmlns%3Aogc%3D%22http%3A%2F%2Fwww.opengis.net%2Fogc%22%3E%3CNamedLayer%3E%3CName%3EJordstykke%3C%2FName%3E%3CUserStyle%3E%3CFeatureTypeStyle%3E%3CRule%3E%3CPolygonSymbolizer%3E%3CStroke%3E%3CCssParameter+name%3D%22stroke%22%3E%23FF0000%3C%2FCssParameter%3E%3C%2FStroke%3E%3C%2FPolygonSymbolizer%3E%3C%2FRule%3E%3C%2FFeatureTypeStyle%3E%3C%2FUserStyle%3E%3C%2FNamedLayer%3E%3C%2FStyledLayerDescriptor%3E
                    attribution: "Geodatastyrelsen",
                    continuousWorld: true,
                    minZoom: 9
                });
                var geo_riste = L.tileLayer.wms('http://{s}.services.kortforsyningen.dk/service', {
                    service: 'WMS',
                    transparent: true,
                    servicename: 'topo_geo',
                    layers: 'Nedloebsrist',
                    login: kmsusr,
                    password: kmspwd,
                    format: 'image/png',
                    attribution: "Geodatastyrelsen",
                    continuousWorld: true,
                    minZoom: 9
                });
                var geo_vandloeb = L.tileLayer.wms('http://{s}.services.kortforsyningen.dk/service', {
                    service: 'WMS',
                    transparent: true,
                    servicename: 'hy_inspire',
                    layers: ['HY.PhysicalWaters.WaterBodies', 'HY.Network'],
                    login: kmsusr,
                    password: kmspwd,
                    format: 'image/png',
                    attribution: "Geodatastyrelsen",
                    continuousWorld: true,
                    minZoom: 12
                });

                var miljo_udledning_maal = L.tileLayer.wms('https://arealinformation.miljoeportal.dk/gis/services/puls/MapServer/WmsServer?', {
                    service: 'WMS',
                    layers: ['regnbet_udledning'],   //, 'regnbet_udledning_maal
                    transparent: true,
                    format: 'image/png',
                    attribution: "Miljoeportalen",
                    continuousWorld: true,
                    minZoom: 12
                }).addTo(map);

                var dhm_bluespots = L.tileLayer.wms('http://{s}.services.kortforsyningen.dk/service', {
                    service: 'WMS',
                    transparent: true,
                    servicename: 'dhm',
                    //dtm_1_6m, dtm_1_6m_transparent, hillshade_1_6m, hillshade_1_6m_transparent, dhym_rain, dhym_rain_hillshade, dhym_searise, dhym_searise_hillshade, dhym_bluespot_max, dhm_kurve_0_5_m, dhm_kurve_1_0_mdhm_kurve_traditionel
                    layers: ['dhym_bluespot_max'],
                    login: kmsusr,
                    password: kmspwd,
                    format: 'image/png',
                    attribution: "Geodatastyrelsen",
                    continuousWorld: true,
                    minZoom: 5
                });
                var kms_Corine = L.tileLayer.wms('http://{s}.services.kortforsyningen.dk/corine_dissemination', {
                    service: 'WMS',
                    transparent: true,
                    servicename: 'corine_dissemination',
                    //dtm_1_6m, dtm_1_6m_transparent, hillshade_1_6m, hillshade_1_6m_transparent, dhym_rain, dhym_rain_hillshade, dhym_searise, dhym_searise_hillshade, dhym_bluespot_max, dhm_kurve_0_5_m, dhm_kurve_1_0_mdhm_kurve_traditionel
                    layers: ['CLC12_DK'],
                    login: kmsusr,
                    password: kmspwd,
                    format: 'image/png',
                    attribution: "Geodatastyrelsen",
                    continuousWorld: true
                });
                var dhm_kurve05 = L.tileLayer.wms('http://{s}.services.kortforsyningen.dk/service', {
                    service: 'WMS',
                    transparent: true,
                    servicename: 'dhm',
                    //dtm_1_6m, dtm_1_6m_transparent, hillshade_1_6m, hillshade_1_6m_transparent, dhym_rain, dhym_rain_hillshade, dhym_searise, dhym_searise_hillshade, dhym_bluespot_max, dhm_kurve_0_5_m, dhm_kurve_1_0_mdhm_kurve_traditionel
                    layers: ['dhm_kurve_0_5_m'],
                    login: kmsusr,
                    password: kmspwd,
                    format: 'image/png',
                    attribution: "Geodatastyrelsen",
                    continuousWorld: true,
                    minZoom: 8
                });

                var hofor_harestrupAa = L.tileLayer.wms('http://geoserver.vcs.informetics.com/vcs/wms', {
                    service: 'WMS',
                    transparent: true,
                    servicename: 'HarrestrupAa_oplande',
                    //dtm_1_6m, dtm_1_6m_transparent, hillshade_1_6m, hillshade_1_6m_transparent, dhym_rain, dhym_rain_hillshade, dhym_searise, dhym_searise_hillshade, dhym_bluespot_max, dhm_kurve_0_5_m, dhm_kurve_1_0_mdhm_kurve_traditionel
                    layers: ['the_geom'],
                    format: 'image/png',
                    attribution: "Hofor",
                    minZoom: 8
                });

                var biofos_ledninger = L.tileLayer.wms('http://geoserver.vcs.informetics.com/vcs/wms', {
                    service: 'WMS',
                    transparent: true,
                    servicename: 'Biofos_ledninger',
                    //dtm_1_6m, dtm_1_6m_transparent, hillshade_1_6m, hillshade_1_6m_transparent, dhym_rain, dhym_rain_hillshade, dhym_searise, dhym_searise_hillshade, dhym_bluespot_max, dhm_kurve_0_5_m, dhm_kurve_1_0_mdhm_kurve_traditionel
                    layers: ['BIOFOS_ledninger'],
                    format: 'image/png',
                    attribution: "Biofos",
                    minZoom: 4
                });
                var biofos_opland = L.tileLayer.wms('http://geoserver.vcs.informetics.com/vcs/wms', {
                    service: 'WMS',
                    transparent: true,
                    servicename: 'BIOFOS_Oplandsgrænser_adjusted',
                    //dtm_1_6m, dtm_1_6m_transparent, hillshade_1_6m, hillshade_1_6m_transparent, dhym_rain, dhym_rain_hillshade, dhym_searise, dhym_searise_hillshade, dhym_bluespot_max, dhm_kurve_0_5_m, dhm_kurve_1_0_mdhm_kurve_traditionel
                    layers: ['BIOFOS_Oplandsgrænser_adjusted'],
                    format: 'image/png',
                    attribution: "Biofos",
                    minZoom: 4
                });

                var toposkaermkortwmts = L.tileLayer.wms('https://services.kortforsyningen.dk/topo_skaermkort', {
                    layers: 'dtk_skaermkort',
                    token: kftoken,
                    format: 'image/png',
                    attribution: myAttributionText
                });

                var skaermkort = L.tileLayer('http://{s}.services.kortforsyningen.dk/topo_skaermkort?login=' + kmsusr + '&password=' + kmspwd + '&request=GetTile&version=1.0.0&service=WMTS&Layer=dtk_skaermkort&style=default&format=image/jpeg&TileMatrixSet=View1&TileMatrix={zoom}&TileRow={y}&TileCol={x}', {
                    attribution: 'Geodatastyrelsen',
                    continuousWorld: true,
                    maxZoom: 14,
                    zoom: function () {
                        var zoom = map.getZoom();
                        if (zoom < 10)
                            return 'L0' + zoom;
                        else
                            return 'L' + zoom;
                    }
                });

                var skaermkortgrey = L.tileLayer('http://{s}.services.kortforsyningen.dk/topo_skaermkort_daempet?login=' + kmsusr + '&password=' + kmspwd + '&request=GetTile&version=1.0.0&service=WMTS&Layer=dtk_skaermkort_daempet&style=default&format=image/jpeg&TileMatrixSet=View1&TileMatrix={zoom}&TileRow={y}&TileCol={x}', {
                    attribution: 'Geodatastyrelsen',
                    continuousWorld: true,
                    maxZoom: 14,
                    zoom: function () {
                        var zoom = map.getZoom();
                        if (zoom < 10)
                            return 'L0' + zoom;
                        else
                            return 'L' + zoom;
                    }
                });


                var dmiWMS = L.tileLayer.wms('https://beta.dmi.dk/ZoombareKort/map', {
                    service: 'WMS',
                    transparent: true,
                    servicename: 'map',
                    tms: false,
                    //          crs: 'EPSG:3575',
                    layers: 'nowcast_radar',
                    version: '1.1.1',
                    tileSize: 512,
                    styles: 'sorte_centroider,sorte_skel,default',
                    format: 'image/png',   //&sld_body=%3CStyledLayerDescriptor+version%3D%221.0.0%22+xmlns%3D%22http%3A%2F%2Fwww.opengis.net%2Fsld%22+xmlns%3Aogc%3D%22http%3A%2F%2Fwww.opengis.net%2Fogc%22%3E%3CNamedLayer%3E%3CName%3EJordstykke%3C%2FName%3E%3CUserStyle%3E%3CFeatureTypeStyle%3E%3CRule%3E%3CPolygonSymbolizer%3E%3CStroke%3E%3CCssParameter+name%3D%22stroke%22%3E%23FF0000%3C%2FCssParameter%3E%3C%2FStroke%3E%3C%2FPolygonSymbolizer%3E%3C%2FRule%3E%3C%2FFeatureTypeStyle%3E%3C%2FUserStyle%3E%3C%2FNamedLayer%3E%3C%2FStyledLayerDescriptor%3E
                    attribution: "DMI",
                    continuousWorld: true
                });


                var PrimaryCenterUTM = $scope.mainRadar.utmCenter;
                var dxdy = parseInt($scope.mainRadar.dxdy);

                var GeoInfoStrs = [];
                var GeoInfoObss = [];
                var Rectangles = [];
                var GridO = [0, 0];
                for (var j = -100; j <= 100; j++) {
                    for (var k = -100; k <= 100; k++) {
                        if (Math.sqrt(j * j + k * k) > 100) continue;
                        var UtmBoundsLL = [PrimaryCenterUTM[0] + j * dxdy, PrimaryCenterUTM[1] + k * dxdy];
                        var UtmBoundsUR = [PrimaryCenterUTM[0] + (j + 1) * dxdy, PrimaryCenterUTM[1] + (k + 1) * dxdy];
                        var WGSBoundsLL = proj4('EPSG:32632', 'WGS84', UtmBoundsLL);
                        var WGSBoundsUR = proj4('EPSG:32632', 'WGS84', UtmBoundsUR);
                        var bounds = [[WGSBoundsLL[1], WGSBoundsLL[0]], [WGSBoundsUR[1], WGSBoundsUR[0]]];
                        Rectangles.push(L.rectangle(bounds, { color: "#ff7800", weight: 1 }));
                    }
                }
                var grid = L.layerGroup(Rectangles);


                selectedPoint = L.rectangle(getGridRectangle($scope.mainRadar.geoCenter), { color: "orange", weight: 5 }).addTo(map);

                var svk_markers = [];


                var svk_all = L.layerGroup(svk_markers);

                var goodData = L.circle([$scope.mainRadar.geoCenter[1], $scope.mainRadar.geoCenter[0]], 40000);
                var radarRange = L.circle([$scope.mainRadar.geoCenter[1], $scope.mainRadar.geoCenter[0]], 50000);

                var tooClose = L.circle([$scope.mainRadar.geoCenter[1], $scope.mainRadar.geoCenter[0]], 5000);
                var CBandRanges = L.layerGroup([
                    L.circle([55.1731, 8.552], 240000),
                    L.circle([55.3262, 12.4493], 240000),
                    L.circle([56.024, 10.0246], 240000),
                    L.circle([57.4893, 10.1365], 240000),
                    L.circle([55.1127, 14.8875], 240000)]);
                var CBandLocations = L.layerGroup([
                    L.circle([55.1731, 8.552], 1000),
                    L.circle([55.3262, 12.4493], 1000),
                    L.circle([56.024, 10.0246], 1000),
                    L.circle([57.4893, 10.1365], 1000),
                    L.circle([55.1127, 14.8875], 1000)]);


                var imageBoundsGEO = [[52.294, 4], [60, 19.5]];
                var imageDMIUrl = '';
                var radarDMIImg = L.imageOverlay(imageDMIUrl, imageBoundsGEO, {
                    opacity: 0.8
                });


                var shiftXY = [-100000, -65000];
                var shiftWH = [60000, 60000]; //width bigger than height - can use pixels ?
                var imageBoundsUTM = [,];
                var imageBounds = [,];
                var imageBoundsGEO = [,];
                imageBoundsUTM[0] = [126648 + shiftXY[0] + shiftWH[0], 5983984 + shiftXY[1] + shiftWH[1]];//[5983984, 126648]; //[5983984, 126648];
                imageBoundsUTM[1] = [1075693 + shiftXY[0] - shiftWH[0], 7771252 + shiftXY[1] - shiftWH[1]];//[7771252, 1075693];
                imageBounds[0] = proj4('EPSG:3006', 'WGS84', imageBoundsUTM[0]);
                imageBounds[1] = proj4('EPSG:3006', 'WGS84', imageBoundsUTM[1]);
                imageBoundsGEO[0] = [imageBounds[0][1], imageBounds[0][0]];
                imageBoundsGEO[1] = [imageBounds[1][1], imageBounds[1][0]];

                //var SMHIurl = "";// "http://opendata-download-radar.smhi.se/api/version/latest/area/sweden/product/comp/2017/02/20/radar_1702201600.png";
                //var radarSMHI = L.imageOverlay(SMHIurl, imageBoundsGEO, {
                //  opacity: 0.5
                //});

                var markersLayer = L.featureGroup();
                console.log("RainIcon: ", imagePath + 'Netatmo_Rain_Module.svg');
                var rainIcon = L.icon({
                    iconUrl: imagePath + 'Netatmo_Rain_Module.svg',
                    //shadowUrl: imagePath + 'Netatmo_Rain_Module_shadow.svg',
                    iconSize: [40, 40], // size of the icon
                    //shadowSize: [40,40], // size of the shadow
                    iconAnchor: [20, 10], // point of the icon which will correspond to marker's location
                    //shadowAnchor: [20, 10],  // the same for the shadow
                    markerColor: 'red',
                    popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
                });


                //var rainIcon = IconsNew[1];

                    var svk_stations = ReadSVKstations();
                    console.log(svk_stations);
                    for (var i = 0; i < svk_stations.length; i++) {
                        var station = svk_stations[i];
                        var geoPoint = proj4('EPSG:32632', 'WGS84', [station.Latitude, station.Longitude]);
                        var obsStation = {
                            Name: station.Name,
                            Id: station.Id,
                            x: station.Longitude,
                            y: station.Latitude, idx: i,
                            lat: geoPoint[1], lng: geoPoint[0],
                            Type: 'svk'
                        };
                        $scope.obsStation.push(obsStation);
                        var newMarker = L.marker($scope.obsStation[i], { icon: rainIcon });
                        //var newMarker = L.marker($scope.obsStation[i], { icon: { type: 'extraMarker', icon: 'fa-coffee', markerColor: 'red', iconColor: 'black', prefix: 'fa', shape: 'circle' } });
                        newMarker.bindPopup('<h5>' + $scope.obsStation[i].Name + '</h5>');
                        newMarker.idx = i;
                        newMarker.addTo(markersLayer);
                        $scope.markers.push(newMarker);

                    }


                //if (!$scope.isGate21)markersLayer.addTo(map);
                var drypLayer = L.featureGroup();
                var controlsLayer = L.featureGroup();
                var hoforLayer = L.featureGroup();

                $scope.polygons = {};

                markersLayer.on('click', function (e) {

                    var attributes = e.layer;
                    console.log(attributes);
                    $scope.selectObservation(attributes.idx);
                });


                var baselayers = {
                    //"Flyfoto": ortofoto,
                    //   "DMI": dmiWMS,
                    //  "Hydro-base": Hydda_Base,
                    //"Hydro-fuld": Hydda_Full,
                    "Skærmkort": skaermkortgrey
                };

                var overlays = {
                    //"Grid 500m": grid,
                    //"SVK-regnmålere": markersLayer,
                    //"SMHI c-bånd": radarSMHI,
                    //"DMI c-bånd": CBandLocations,
                    //"50km radius radar": radarRange,
                    //"40km radius radar": goodData,
                    //"5km radius radar": tooClose,
                    //"Matrikelkort": matrikelkort,
                    //"nedløbsriste": geo_riste,
                    "Vandløb": geo_vandloeb,
                    "Udledninger": miljo_udledning_maal,
                    //"Højdekontur": dhm_kurve05,
                    "Blue-spot": dhm_bluespots
                    //"Corine Land Type": kms_Corine,
                    //"Luftfoto": ortofoto
                };


                //Radar Images
                for (var j = 0; j < radarSpecsAll.length; j++) {
                    overlays[radarSpecsAll[j].Name] = radarSpecsAll[j].radarImg;
                    if ($scope.primaryRadarId == radarSpecsAll[j].Id) radarSpecsAll[j].radarImg.addTo(map);
                }

                console.log(overlays);

                // L.Icon.Default.imagePath = './images';
                L.control.scale({ maxWidth: 240, metric: true, imperial: false, position: 'bottomleft' }).addTo(map);
                layerControl = L.control.layers(baselayers, overlays).addTo(map);
                var mapOverlays = [];
                //if ($scope.loggedin) mapOverlays =getMapPoints(map);
                var lat, lng;
                var mouseUTM;
                var mouseDVR90;
                map.addEventListener('mousemove', function (ev) {
                    mouseUTM = proj4('WGS84', 'EPSG:3006', [ev.latlng.lng, ev.latlng.lat]);
                    mouseDVR90 = proj4('WGS84', 'EPSG:32632', [ev.latlng.lng, ev.latlng.lat]);
                    //utmonmap.textContent = "UTM-33: " + mouseUTM[0].toFixed(1) + "m E, " + mouseUTM[1].toFixed(1) + "m N";
                    //dvronmap.textContent = "DVR90: " + mouseDVR90[0].toFixed(1) + "m E, " + mouseDVR90[1].toFixed(1) + "m N";
                    //geoonmap.textContent = "WGS-84: " + ev.latlng.lat.toFixed(3) + "°N, " + ev.latlng.lng.toFixed(3) + "°E";
                }, { passive: true });
                map.on('dblclick', function (e) { clickListenerTimeSeries(e) });
                map.on('baselayerchange', function (e) {
                    if (e.name === 'Skærmkort') {
                        matrikelkort.setParams({
                            styles: 'sorte_centroider,sorte_skel,default'
                        });
                    } else if (e.name === 'Flyfoto') {
                        matrikelkort.setParams({
                            styles: 'gule_centroider,gule_skel,Gul_OptagetVej,default'
                        });
                    }
                });
                map.on('load', function (e) {
                    console.log("about to .. re-inititialising map");
                    setTimeout(function () { map.invalidateSize(); console.log("re inititialising map"); }, 500);
                });

                map.on('overlayadd', function (e) {
                    console.log("overlay add event fired");
                    var radarLayer = $.grep(radarSpecsAll, function (r) { return r.Name == e.name; })[0];

                    console.log(radarLayer);
                    if (radarLayer != null) {
                        radarLayer.Active = true;
                        SetImage();
                        console.log("Looking for : " + radarLayer.Name);
                    }

                    console.log(e);
                    if (e.name == "SMHI c-bånd") {
                        lookforSMHI = true;
                        addControlsForLayers(5);
                    }
                    else if (e.name == "Gridded 500m") {
                        lookforCart = true;
                        console.log("Looking for cart now : " + lookforCart);
                    }
                });
                map.on('overlayremove', function (e) {
                    console.log("overlay remove event fired");
                    var radarLayer = $.grep(radarSpecsAll, function (r) { return r.Name == e.name; })[0];
                    console.log(radarLayer);
                    if (radarLayer != null) {
                        radarLayer.Active = false;
                        console.log("Stop Looking for : " + radarLayer.Name);
                    }
                    console.log(e);
                    if (e.name == "SMHI c-bånd") {
                        lookforSMHI = false;
                        removeControlsForLayers();
                    }
                    else if (e.name == "Gridded 500m") {
                        lookforCart = false;
                    }
                    else if (e.name == "DMI c-bånd") {
                        lookforDMI = false;
                    }
                });

                console.log("inititialising map");
                map.setView(viewCenter, zoomLevel);
                //initJsonData(map);
                setTimeout(function () { map.invalidateSize(); }, 500);
                return {
                    map: map, //radarSMHI: radarSMHI,          radarDMIImg: radarDMIImg
                };

            }


            function onEachFeature(feature, layer) {
                // does this feature have a property named popupContent?
                if (feature.properties) {
                    var content = "Testpolygon : " + feature.properties.my_polygon + "<br>" + feature.properties.TestValue + " % fill";
                    layer.bindPopup(content);
                }
            }
            var SectionLength = 0;
            var sectionStart = 0;
            var xCoords = [];
            var xSections = [];
            var sectionStartEnds = [];
            var sectionPointNames = [];
            var sectionData = [];
            var sectionBottomDatums = [];
            var sectionCriticalDatums = [];
            var sectionIds = 0;




            function onEachFeatureStream(feature, layer) {
                // does this feature have a property named popupContent?


                if (feature.properties) {
                    var content = "Testpolygon : " + feature.properties.seg_poly + "<br>Kote kritisk:" + feature.properties.Krit_kote + " m"
                        + "<br>Kote bund:" + feature.properties.Bund_kote + " m";
                    layer.bindPopup(content);
                    //Add feature = streatch of river - to Sections
                    //if ($scope.selectedSections[i].skip) continue;
                    var chainage = feature.properties.seg_poly;
                    var from = SectionLength;
                    var to = SectionLength + parseInt(feature.properties.Length);
                    var dist = to - from;
                    sectionStart = SectionLength;
                    SectionLength = to;
                    xCoords.push(from + dist / 2);
                    xSections.push(sectionStart);
                    xSections.push(length);
                    sectionStartEnds.push({ start: sectionStart, middel: (SectionLength - dist / 2), end: SectionLength, name: chainage });
                    sectionPointNames.push(chainage);
                    sectionEnds.push({
                        color: 'blue',
                        width: 2,
                        value: SectionLength,
                        label: {
                            align: 'right',
                            rotation: -90,
                            text: chainage,
                            verticalAlign: 'middle', //'middle''bottom'
                            x: -8
                        }
                    });
                    var bundkote = parseFloat(feature.properties.Bund_kote);
                    var kritkote = parseFloat(feature.properties.Krit_kote);
                    var initialVal = (kritkote - bundkote) / 2 + bundkote;


                    //sectionDatums.push({ name: 'Pointtest', id: sectionIds, x: xCoords[sectionIds], low: bundkote, high: kritkote});
                    sectionIds = sectionIds + 1;
                    //var colVals = $scope.columns.find(function (o) { return o.indx === i });
                    sectionBottomDatums.push([from, bundkote]);
                    sectionBottomDatums.push([to, bundkote]);
                    sectionCriticalDatums.push([from, kritkote]);
                    sectionCriticalDatums.push([to, kritkote]);
                    sectionData.push([from, bundkote, initialVal]);
                    sectionData.push([to, bundkote, initialVal]);

                }
            }


            // Gets the points from the api and populates the map
            function getMapPoints(mymap) {
                $http({
                    method: 'GET',
                    url: API_URL + 'values/GetStationsMetadata',
                    params: {
                        lstPointTypes: '0'
                    }
                }).then(function successCallback(response) {
                    markerLayers = {};
                    var overlaysMarkers = {};
                    if (response.data) {
                        for (var j = 0; j < response.data.length; j++) {

                            var pointType = response.data[j].PointType;
                            var points = response.data[j].Points;
                            var parameterCollections = response.data[j].ParameterCollections;
                            var parameters = response.data[j].Parameters;
                            var statistics = response.data[j].Statistics;
                            var statisticsTableHeaders = response.data[j].StatisticsTableHeaders;

                            markerLayers[pointType.Id] = [];

                            layersStateScope[pointType.Name] = true;
                            parameterCollectionsScope[pointType.Id] = {};
                            chartParametersScope[pointType.Id] = [];
                            $scope.pointTypes[pointType.Id] = pointType;
                            parametersScope[pointType.Id] = parameters;
                            $scope.tableHeaders[pointType.Id] = [];

                            //for (var iHeaders = 0; iHeaders < statisticsTableHeaders.length; iHeaders++) {
                            //    $scope.tableHeaders[pointType.Id].push(
                            //                statisticsTableHeaders[iHeaders].ColumnHeader +
                            //                    (statisticsTableHeaders[iHeaders].UnitName
                            //                        ? ' (' + statisticsTableHeaders[iHeaders].UnitName + ')'
                            //                        : ''));
                            //};
                            // Initialize the parameter collections
                            for (var k = 0; k < parameterCollections.length; k++) {
                                parameterCollectionsScope[pointType.Id][parameterCollections[k].Id] = parameterCollections[k];
                            }
                            // Initialize the parameters
                            for (var f = 0; f < parameters.length; f++) {
                                var param = parameters[f];
                                // Add chart series parameters
                                if (parameterCollectionsScope[pointType.Id][param.ParameterCollectionId].Name.endsWith('ChartParameters') ||
                                    (pointType.Id === 8 && $.inArray(param.Id, [111, 265, 266, 269]) !== -1)) { // parameters for Vandkvalitet 
                                    chartParametersScope[pointType.Id].push(param);
                                }
                            }

                            for (var i = 0; i < points.length; i++) {

                                $scope.markers[points[i].TypeId + '~' + points[i].Id] = {
                                    id: points[i].Id,
                                    idx: points[i].TypeId + '~' + points[i].Id,
                                    name: points[i].Name,
                                    type: points[i].TypeId,
                                    // layer: 'l' + points[i].TypeId,
                                    lat: points[i].Latitude,
                                    lng: points[i].Longitude,
                                    //icon: IconsNew[points[i].TypeId],

                                    getMessageScope: function () { return $scope; },
                                    message: points[i].Name,
                                    compileMessage: true,
                                    latestStatistics: statistics[points[i].Id]
                                };
                                var newMarker = L.marker($scope.markers[points[i].TypeId + '~' + points[i].Id], { icon: $scope.markers[points[i].TypeId + '~' + points[i].Id].icon });
                                // var newMarker = L.marker($scope.markers[points[i].TypeId + '~' + points[i].Id], { icon:gaugeIconArray[2]});
                                newMarker.id = points[i].TypeId + '~' + points[i].Id;
                                newMarker.on('click', function (data) {
                                    $scope.selectElement(this.id);
                                });

                                newMarker.bindPopup('<h5>' + points[i].Name + '</h5>');
                                $scope.markers[points[i].TypeId + '~' + points[i].Id]._leafletMarker = newMarker;
                                markerLayers[points[i].TypeId].push(newMarker);
                            }

                        }

                    }

                }, function errorCallback(response) {

                });
            }
            function swapDirection() {
                direction = direction * -1;
            }

            function NewStartDate() {
                datetime = new Date(Date.parse($scope.ts));
                SetImage();
                if (datetime < $scope.fromDate) {
                    $scope.fromDate = datetime;
                    updateTo();
                }
                else {
                    if (datetime > $scope.toDate) {
                        $scope.toDate = datetime;
                        updateFrom();
                    }
                }
            }

            function updateFrom() {
                $scope.fromDate = $scope.toDate;
                $scope.fromDate.setSeconds(0);
                $scope.fromDate.setMilliseconds(0);
                $scope.fromDate = new Date($scope.toDate.getTime() - $scope.nsteps * $scope.stepLength * 60000);
                CalcSteps();
                console.log($scope.fromDate);
            }
            function updateTo() {
                $scope.toDate.setDate($scope.fromDate.getDate());
                $scope.toDate.setSeconds(0);
                $scope.toDate.setMilliseconds(0);
                $scope.toDate = new Date($scope.fromDate.getTime() + $scope.nsteps * $scope.stepLength * 60000);
                CalcSteps();
            }

            $scope.updateIntervals = function () {
                CalcSteps();
                //updateTo();
            }

            $scope.stepchanged = function () {
                var step = Math.floor($scope.showstep / 100 * $scope.nsteps);
                datetime = new Date($scope.fromDate.getTime() + step * 60000);
                SetImage();
            }


            function buttonBackPress() {
                console.log("button back invoked.");
                state = 'stop';
                datetime = new Date(datetime - $scope.stepLength * 60000);
                SetImage();
            }

            function buttonForwardPress() {
                state = 'stop';
                datetime.setMinutes(datetime.getMinutes() + $scope.stepLength);
                SetImage();
            }

            function buttonFastPress() {
                // clearInterval(timer);
                state = 'stop';
                $scope.speed = - 150;
                // state = 'play'
                //animate();
            }
            function buttonSlowPress() {
                // clearInterval(timer);
                state = 'stop';
                $scope.speed = + 150;
                //animate();
            }
            function buttonPlotCanvasPress(eventDate) {
                console.log("Test")
                console.log("Event akk :" + eventDate);
                var fromDate = new Date();
                var MinutesBack = 5;
                fromDate.setTime(datetime.getTime() - MinutesBack * 60 * 1000);
                fromDate.setMilliseconds(0);
                fromDate.setSeconds(0);
                requestRadarDataSquare($scope.primaryRadarId, $scope.elevation, fromDate, datetime)
                toggleCanvas = true;
            }

            function buttonPlayPress() {

                if (state == 'stop') {
                    state = 'play';
                    $scope.animate(true);
                }
                else if (state == 'play') {
                    state = 'stop';
                }
                //console.log("button play pressed, play was " + state);

            }
            function buttonStopPress() {
                if (state == 'stop') {
                    state = 'play';
                    $scope.animate(false);

                }
                else state = 'stop';
                // console.log("button stop invoked.");
            }

            function addControlsForLayers(updateMin) {
                //Called when layers are added
                var minutes = datetime.getMinutes();
                var timeshift = datetime.getMinutes() % updateMin;
                $scope.stepLength = updateMin;
                console.log("Time before : " + datetime + " shift : " + timeshift);
                datetime.setTime(datetime.getTime() - (timeshift * 60 * 1000));
                SetImage();
            }
            function removeControlsForLayers() {
                //Called when layers are added
                $scope.stepLength = 1;
                SetImage();
            }

            //Update radar images - all radar file times in UTC.
            function SetImage() {
                $scope.ts = datetime;
                updateHighChartsPlotLine(datetime);
                
                if (datetime.getTime() > Date.now()) {
                    console.log("Oy - you are looking at the future ! ");
                    //linkElement.textContent = $translate.instant('fremtid');
                    //  $scope.AnimateStep = 0;
                    return;
                }
                var utmOffset = datetime.getTimezoneOffset();
                // $scope.AnimateStep = step;
                utcDateTime.setTime(datetime.getTime() + (utmOffset * 60 * 1000));
                var dd = ("0" + utcDateTime.getDate()).slice(-2);
                var MM = ("0" + (parseInt(utcDateTime.getMonth()) + 1)).slice(-2);
                var hh = ("0" + (parseInt(utcDateTime.getHours()))).slice(-2);
                var mm = ("0" + utcDateTime.getMinutes()).slice(-2);
                var YY = ("" + utcDateTime.getFullYear()).slice(-2);
                var scnPrefix = '0047';
                var prefix0002DTscn = new Date(2018, 1, 14, 19, 48, 0);
                //print(prefix0002DTscn);
                var timestr = utcDateTime.getFullYear() + MM + dd + '_' + hh + mm + '00';

                fieldNameElement.textContent = ("0" + datetime.getHours()).slice(-2) + ":" + mm;
                //timeonmap.textContent = datetime.toLocaleDateString() + " " + ("0" + datetime.getHours()).slice(-2) + ":" + mm;
                //document.getElementById("time_onmap2").textContent = datetime.toLocaleDateString() + " " + ("0" + datetime.getHours()).slice(-2) + ":" + mm;
                //$scope.timeText = ("0" + datetime.getHours()).slice(-2) + ":" + mm;
                //$scope.dateText = datetime.toLocaleDateString();
                //dateElement.textContent = datetime.toLocaleDateString();
                // var imageUrl = baseurl + timestr + '_0' + $scope.elevation + '_02.scn.png';

                if (utcDateTime > prefix0002DTscn) {
                    scnPrefix = '0002';
                }
                //var baseurl = "https://s3-eu-west-1.amazonaws.com/informetics/ftp2/biofos/Hvidovre/png/" + utcDateTime.getFullYear() + "/" + MM + "/" + dd + "/" + scnPrefix + "_";
                //var baseCarturl = "https://s3-eu-west-1.amazonaws.com/informetics/ftp2/biofos/Hvidovre/pnguG/" + utcDateTime.getFullYear() + "/" + MM + "/" + dd + "/";
                //var baseDMIurl = "https://s3-eu-west-1.amazonaws.com/informetics/ftp2/DK/png/dk/" + utcDateTime.getFullYear() + "/" + MM + "/" + dd + "/DK_interp_";
                //var baseuRurl = "https://s3-eu-west-1.amazonaws.com/informetics/ftp2/biofos/Hvidovre/pnguR/" + utcDateTime.getFullYear() + "/" + MM + "/" + dd + "/";
                //var baseWR110url = "https://s3-eu-west-1.amazonaws.com/kbh1wr110/FurunoHQ/pnguR/" + utcDateTime.getFullYear() + "/" + MM + "/" + dd + "/";
                //var baseDalbyurl = "https://s3-eu-west-1.amazonaws.com/informetics-vasyd/Dalby/pnguR/" + utcDateTime.getFullYear() + "/" + MM + "/" + dd + "/";
                

                for (var j = 0; j < radarSpecsAll.length; j++) {
                    var mmR = mm;
                    if (radarSpecsAll[j].dt > 1) {
                        var get5min = utcDateTime.getMinutes() % radarSpecsAll[j].dt;
                        mmR = ("0" + (utcDateTime.getMinutes() - get5min)).slice(-2);
                    }
                    if (radarSpecsAll[j].Active) {
                        var imageUrl = radarSpecsAll[j].BaseURL + radarSpecsAll[j].imgPath(utcDateTime.getFullYear(), YY, MM, dd, hh, mmR, radarSpecsAll[j].Elevation);
                        //var imageUrl = radarSpecsAll[j].BaseURL + utcDateTime.getFullYear() + "/" + MM + "/" + dd + "/" + utcDateTime.getFullYear() + MM + dd + hh + mm + '_0' + radarSpecsAll[j].Elevation + '_R.png';
                        if (radarSpecsAll[j].Id == 6 && utcDateTime.getFullYear() == 2018) imageUrl = 'https://s3-eu-west-1.amazonaws.com/informetics-vasyd/Dalby/pnguR/' + radarSpecsAll[j].imgPath(utcDateTime.getFullYear(), YY, MM, dd, hh, mmR, radarSpecsAll[j].Elevation);
                        var exists = false;
                        var id = j;
                        imageExists(imageUrl, id, function (exists, id, url) {
                            if (exists) {
                                radarSpecsAll[id].radarImg.setUrl(url);
                                $scope.foundAnImage = true;
                                r.foundAnImage = true;
                            }
                            else {
                                console.log(id);
                                var imageUrl = radarSpecsAll[id].BaseURL + utcDateTime.getFullYear() + "/" + MM + "/" + dd + "/" + utcDateTime.getFullYear() + MM + dd + "_" + hh + mm + '_0' + radarSpecsAll[id].Elevation + '_R.png';
                                imageExists(imageUrl, id, function (exists, id, url) {
                                    console.log(exists + ' Id:' + id + ' :' + url);
                                    if (exists) {
                                        radarSpecsAll[id].radarImg.setUrl(url);
                                        $scope.foundAnImage = true;
                                        r.foundAnImage = true;
                                    }
                                    else {
                                        radarSpecsAll[id].radarImg.setUrl(radarSpecsAll[id].BaseURL + radarSpecsAll[id].NoData);
                                    }
                                });

                            }
                        });
                    }
                }



                //console.log(imageUrl);
                if (firstload > 0) {
                    firstload = -1;
                    //linkElement.textContent = "";
                }
                else {
                    if (firstload > 10) {
                        firstload = firstload + 1;
                        if (firstload > 10) {
                            //linkElement.textContent = $translate.instant('no_images');
                            firstload = -1;
                            if (lookforBase) $scope.mapstate.radarImg.setUrl('https://s3-eu-west-1.amazonaws.com/informetics/ftp2/biofos/Hvidovre/png/nodata.png');

                            return;
                        }
                        else {
                            datetime = new Date(datetime - $scope.stepLength * 60000);
                            SetImage();
                            return;
                        }
                    }
                    //else linkElement.textContent = $translate.instant('no_image_stored');
                }

                if (state == 'play') {
                    //controlNameElement.textContent = $translate.instant('Afspildning') + " " + Math.round(10000 / speed) / 10 + " fps";
                } else {
                    //controlNameElement.textContent = $translate.instant('Animation_stopped');
                }

            }

            function imageExists(url, id, callback) {
                var img = new Image();
                console.log('Testing Id:' + id + ' :' + url);
                img.onload = function () { callback(true, id, url); };
                img.onerror = function () { callback(false, id, url); };
                img.src = url;
            }

            $scope.animate = function (rewind) {
                if (rewind) datetime = new Date($scope.fromDate.getTime());
                var timer = setInterval(function () {
                    datetime.setMinutes(datetime.getMinutes() + $scope.stepLength);
                    SetImage(datetime);
                    if (state == 'stop') {
                        clearInterval(timer);
                    }
                    if ($scope.sidste30min) {
                        $scope.toDate = new Date();
                        $scope.toDate.setSeconds(0);
                        $scope.toDate.setMilliseconds(0);
                        $scope.fromDate = moment($scope.toDate).subtract($scope.nSteps, 'm').toDate();
                    }
                    if (datetime >= $scope.toDate) {
                        datetime = new Date($scope.fromDate.getTime());
                    }
                }, $scope.speed);
            };


            function renderLegendIconHtml(icon) {
                return '<span class="half awesome-marker-icon-' + icon.markerColor + ' awesome-marker leaflet-clickable"><i class=" glyphicon ' + icon.icon + ' icon-white"></i></span>';
            };

            L.Icon.Default.imagePath = './images';

            L.CanvasOverlay = L.Class.extend({

                initialize: function (userDrawFunc, options) {
                    this._userDrawFunc = userDrawFunc;
                    L.setOptions(this, options);
                },

                drawing: function (userDrawFunc) {
                    this._userDrawFunc = userDrawFunc;
                    return this;
                },

                params: function (options) {
                    L.setOptions(this, options);
                    return this;
                },

                canvas: function () {
                    return this._canvas;
                },

                redraw: function () {
                    if (!this._frame) {
                        this._frame = L.Util.requestAnimFrame(this._redraw, this);
                    }
                    return this;
                },



                onAdd: function (map) {
                    this._map = map;
                    this._canvas = L.DomUtil.create('canvas', 'leaflet-heatmap-layer');

                    var size = this._map.getSize();
                    this._canvas.width = size.x;
                    this._canvas.height = size.y;

                    var animated = this._map.options.zoomAnimation && L.Browser.any3d;
                    L.DomUtil.addClass(this._canvas, 'leaflet-zoom-' + (animated ? 'animated' : 'hide'));


                    map._panes.overlayPane.appendChild(this._canvas);

                    map.on('moveend', this._reset, this);
                    map.on('resize', this._resize, this);

                    if (map.options.zoomAnimation && L.Browser.any3d) {
                        map.on('zoomanim', this._animateZoom, this);
                    }

                    this._reset();
                },

                onRemove: function (map) {
                    map.getPanes().overlayPane.removeChild(this._canvas);

                    map.off('moveend', this._reset, this);
                    map.off('resize', this._resize, this);

                    if (map.options.zoomAnimation) {
                        map.off('zoomanim', this._animateZoom, this);
                    }
                    this_canvas = null;

                },

                addTo: function (map) {
                    map.addLayer(this);
                    return this;
                },

                _resize: function (resizeEvent) {
                    this._canvas.width = resizeEvent.newSize.x;
                    this._canvas.height = resizeEvent.newSize.y;
                },
                _reset: function () {
                    var topLeft = this._map.containerPointToLayerPoint([0, 0]);
                    L.DomUtil.setPosition(this._canvas, topLeft);
                    this._redraw();
                },

                _redraw: function () {
                    var size = this._map.getSize();
                    var bounds = this._map.getBounds();
                    var zoomScale = (size.x * 180) / (20037508.34 * (bounds.getEast() - bounds.getWest())); // resolution = 1/zoomScale
                    var zoom = this._map.getZoom();

                    // console.time('process');

                    if (this._userDrawFunc) {
                        this._userDrawFunc(this,
                            {
                                canvas: this._canvas,
                                bounds: bounds,
                                size: size,
                                zoomScale: zoomScale,
                                zoom: zoom,
                                options: this.options
                            });
                    }


                    // console.timeEnd('process');

                    this._frame = null;
                },

                _animateZoom: function (e) {
                    var scale = this._map.getZoomScale(e.zoom),
                        offset = this._map._getCenterOffset(e.center)._multiplyBy(-scale).subtract(this._map._getMapPanePos());

                    this._canvas.style[L.DomUtil.TRANSFORM] = L.DomUtil.getTranslateString(offset) + ' scale(' + scale + ')';

                }
            });

            L.canvasOverlay = function (userDrawFunc, options) {
                console.log("New Canvas...");
                return new L.CanvasOverlay(userDrawFunc, options);
            }

        }]);
