'use strict';

angular.module('mapsApp')
    .controller('userCtrl', ['$scope', '$location', '$timeout', '$http', 'authFactory', '$mdDialog', 'profileFactory', '$mdToast', '$translate', 'ngAuthSettings',
        function ($scope, $location, $timeout, $http, authFactory, $mdDialog, profileFactory, $mdToast, $translate, ngAuthSettings) {


        if (!authFactory.isInRole('SysAdmin') && !authFactory.isInRole('admin')) {
            $location.path('/home');
            return;
        }
        $scope.showStackTrace = false;
        $scope.allRoles = [];
        $scope.limitOptions = [5, 10, 15, 30];
        $scope.registration = {  };
        $scope.options = {
            rowSelection: false,
            multiSelect: false,
            autoSelect: false,
            decapitate: false,
            largeEditDialog: true,
            boundaryLinks: true,
            limitSelect: true,
            pageSelect: true
        };

        $scope.query = {
            order: 'UserName',
            limit: 10,
            page: 1
        };


        $scope.loading = false;
        $scope.savedSuccessfully = false;
        $scope.message = "";
        $scope.users = [];

        $scope.registration = {
            userName: "",
            email: "",
            password: "",
            confirmPassword: "",
            Roles: []
        };
        $scope.searchTerm;
        $scope.clearSearchTerm = function () {
            $scope.searchTerm = '';
        };
        getUsers();
        var originatorEv;
        $scope.openMenu = function ($mdOpenMenu, ev) {
            originatorEv = ev;
            $mdOpenMenu(ev);
        };
        $scope.sendConfirmEmail = function (ev, user) {
            $scope.loading = true;
            profileFactory.sendConfirmEmail(user).then(function (response) {

                $scope.loading = false;
                var confirm = $mdDialog.confirm()
                      .title($translate.instant('email_succesfully_sent'))
                      .textContent($translate.instant('confirm_email_succesfully_sent_message'))
                      .ariaLabel('Email Sent Prompt')
                      .targetEvent(ev)
                      .ok($translate.instant('ok'));
                $mdDialog.show(confirm).then(function () {

                });
            }, onError);
        };


        $scope.sendResetPasswordEmail = function (ev, user) {
            $scope.loading = true;
            profileFactory.sendResetPasswordEmail(user.Email).then(function (response) {
                $scope.loading = false;

                var confirm = $mdDialog.confirm()
                      .title($translate.instant('email_succesfully_sent'))
                      .textContent($translate.instant('reset_password_email_succesfully_sent_message'))
                      .ariaLabel('Email Sent Prompt')
                      .targetEvent(ev)
                      .ok($translate.instant('ok'));
                $mdDialog.show(confirm).then(function () {

                });
            }, onError);
        };

        $scope.deleteUser = function (ev, user) {
            

            var confirm = $mdDialog.confirm()
                  .title($translate.instant('delete') + ' ' + user.UserName)
                  .textContent($translate.instant('confirm_user_delete_message'))
                  .ariaLabel('Confirm Delete')
                  .targetEvent(ev)
                  .ok($translate.instant('Yes'))
                  .cancel($translate.instant('cancel'));
            $mdDialog.show(confirm).then(function () {
                $scope.loading = true;
                $scope.promise = authFactory.deleteUser(user);
                $scope.promise.then(function (response) {
                    $scope.loading = false;
                    $mdToast.show($mdToast.simple().textContent($translate.instant('user_succesfully_deleted_message')).position('left bottom'));
                    getUsers();
                }, function () {
                    $scope.loading = false;
                });
            }, function () {

            });
        };

        function onError(error) {
            $scope.loading = false;
            var message = '';
            if (error) {
                if (error.Message) {
                    message = message + error.Message
                }
                else {
                    var errors = [];
                    for (var key in error) {
                        for (var i = 0; i < error[key].length; i++) {
                            errors.push(error[key][i]);
                        }
                    }

                    message = message + ' ' + errors.join(' ');
                }

            }


            console.log(error);

            var confirm = $mdDialog.confirm()
                  .title('A error occured')
                  .textContent(message)
                  .ariaLabel('Email Sent Prompt Fail')
                  .targetEvent(ev)
                  .ok('Ok');
            $mdDialog.show(confirm).then(function () {

            });
        }
        $scope.currentUserName = authFactory.authentication.userName;

        $scope.edit = function (modalId, item) {
            $scope.message = undefined;
            $scope.errorMessage = undefined;
            $scope.exceptionMessage = undefined;
            $scope.registration = angular.copy(item);
            $(modalId).modal('show');

        };
        $scope.refresh = function () {
            getUsers();
            $mdToast.show($mdToast.simple().textContent($translate.instant('user_succesfully_refreshed_message')).position('left bottom'));

        };
        $scope.add = function (modalId) {
            $scope.message = undefined;
            $scope.errorMessage = undefined;
            $scope.exceptionMessage = undefined;
            $scope.registration = {};
            $(modalId).modal('show');
        };

        $scope.registerUser = function (modalId) {

            $scope.loading = true;
            $scope.promise = authFactory.saveRegistration($scope.registration);

            $scope.promise
                .then(function (response) {
                    $scope.loading = false;
                    $mdToast.show($mdToast.simple().textContent($translate.instant('user_succesfully_saved_message')).position('left bottom'));
                    $(modalId).modal('hide');
                    $scope.savedSuccessfully = true;
                    getUsers();
                },
                function (error) {
                    $scope.loading = false;
                    var message = '';
                    if (error.data) {
                        if (error.data.Message) {
                            message = message + error.data.Message
                        }
                        if (error.data.ModelState) {
                            var errors = [];
                            for (var key in error.data.ModelState) {
                                for (var i = 0; i < error.data.ModelState[key].length; i++) {
                                    errors.push(error.data.ModelState[key][i]);
                                }
                            }

                            message = message + ' ' + errors.join(' ');
                        }
                        
                    }
                    
                    $scope.errorMessage = message;
                    console.log(error);
                });
        };

        function getUsers() {
            $scope.promise =
                $http({
                    method: 'GET',
                url: ngAuthSettings.API_URL + 'Account/GetUsersIndex'
                });
            $scope.promise.then(function successCallback(response) {
                console.log("got users...");
                console.log(response);
                if (response.data) {
                    $scope.users = response.data.AllUsers;
                    $scope.rolesDictionary = response.data.AllRolesDictionary;
                    $scope.allRoles = [];
                    for (var roleId in $scope.rolesDictionary) {
                        $scope.allRoles.push({ Id: roleId.toString(), Name: $scope.rolesDictionary[roleId] });
                    }
                }

            }, function errorCallback(response) {
            });
        }

    }]);
