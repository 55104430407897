'use strict';

/**
 * @ngdoc function
 * @name mapsApp.controller:LocalizationctrlCtrl
 * @description
 * # LocalizationctrlCtrl
 * Controller of the mapsApp
 */
angular.module('mapsApp')
  .controller('LocalizationCtrl', function () {
     
  });
  