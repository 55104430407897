'use strict';

var Highcharts = require("highcharts");

angular.module('mapsApp')
    .factory('analysisChartsFactory', ['$q', '$http', '$timeout', 'ChartDefaultsService', '$mdToast', '$translate', 'labFactory', 'ngAuthSettings',
    function ($q, $http, $timeout, ChartDefaultsService, $mdToast, $translate, labFactory, ngAuthSettings) {
      return {
          getCharts: _getCharts,
          newRainGauge: _newRainGauge,
          exportData: _exportData
      };

      function _getCharts(pageState) {
          var deferred = $q.defer();
          buildAllCharts(pageState)
            .then(function successCallback(response) {
                deferred.resolve(response);
            }/*, function errorCallback(response) { }*/);
          return deferred.promise;
      }

      function _exportData(pageState) {
          var deferred = $q.defer();
          getExportData(pageState)
            .then(function successCallback(response) {
                deferred.resolve(response);
            }/*, function errorCallback(response) { }*/);
          return deferred.promise;
      }

      function _newRainGauge() {
          //call api, get data, build graph
      }


      function removeItemFromArray(sourceArray, item) {
          var resArray = [];
          for (var i = 0; i < sourceArray.length; i++) {
              if (sourceArray[i] !== item) {
                  resArray.push(sourceArray[i]);
              }
          }
          return resArray;
      }
      function removeItemAtIndexFromArray(sourceArray, itemIndex) {
          var resArray = [];
          for (var i = 0; i < sourceArray.length; i++) {
              if (i !== itemIndex) {
                  resArray.push(sourceArray[i]);
              }
          }
          return resArray;
      }

      function buildAllCharts(analysisState) {
          var deferred = $q.defer();
          var charts = {};
          var urlCalls = [];
          var stateError = undefined;
//          console.log(analysisState.parameters)
          if (analysisState.stationsCheckboxes.selected.length === 0) {
              stateError = $translate.instant('select_station');
          }
          if (analysisState.parametersCheckboxes.selected.length === 0) {
              stateError = stateError
                ? stateError + " & " + $translate.instant('select_parameter')
                : $translate.instant('select_parameter');
          }

          if (stateError) {
              $mdToast.show($mdToast.simple().textContent(stateError).position('right top'));
              deferred.resolve(charts);
              return deferred.promise;
          }

          var selectedParametersIds = angular.copy(analysisState.parametersCheckboxes.selected);
          var selectedParametersDataSourcesIds = [];
          for (var i = 0; i < selectedParametersIds.length; i++) {
              selectedParametersDataSourcesIds.push(analysisState.parameters[selectedParametersIds[i]].DataSourceId);
          }

          if (analysisState.labCheckboxes.selected.indexOf(273) != -1) {
              console.log('analysisState.labCheckboxes.selected.indexOf(273) != -1');
              selectedParametersIds.push(273);
              selectedParametersDataSourcesIds.push(analysisState.labParameters[273].DataSourceId);
          }

          if (analysisState.autoEnhanceCharts === true) {
              analysisState.graphEnhanceOptions =
                  buildAutoEnhance(analysisState.graphTimeInterval.fromDate, analysisState.graphTimeInterval.toDate, analysisState.graphEnhanceOptions);
          }

          var tidalIndex = selectedParametersIds.indexOf(186);
          var getTidal = tidalIndex !== -1;


          if (getTidal) {
              selectedParametersIds = removeItemFromArray(selectedParametersIds, 186);
              selectedParametersDataSourcesIds = removeItemAtIndexFromArray(selectedParametersDataSourcesIds, tidalIndex);
          }

          for (i = 0; i < analysisState.stationsCheckboxes.selected.length; i++) {
              charts[analysisState.stationsCheckboxes.selected[i]] = initChart(analysisState.stationsCheckboxes.selected[i], analysisState);
              
              if (analysisState.compactCharts) {
                console.log(charts[analysisState.stationsCheckboxes.selected[i]]);
                charts[analysisState.stationsCheckboxes.selected[i]].options.chart.height = analysisState.compactHeight;
                charts[analysisState.stationsCheckboxes.selected[i]].options.credits = { enabled: false };
                
                
              }
              if (analysisState.labCheckboxes.selected.length > (analysisState.labCheckboxes.selected.indexOf(273) == -1 ? 0 : 1)) {
                  urlCalls.push(labFactory.getWaterQualitySampleData(
                    analysisState.stationsCheckboxes.selected[i],
                    analysisState.graphTimeInterval.fromDate,
                    analysisState.graphTimeInterval.toDate
                  ));
              };

              if (analysisState.getServiceData === true) {
                  urlCalls.push(labFactory.getWaterQualityServiceData(
                    analysisState.stationsCheckboxes.selected[i],
                    analysisState.graphTimeInterval.fromDate,
                    analysisState.graphTimeInterval.toDate
                  ));
              };
              //if (analysisState.enableFlagEdit === true) {
              //    urlCalls.push(labFactory.getWaterQualityServiceData(
              //        analysisState.stationsCheckboxes.selected[i],
              //        analysisState.graphTimeInterval.fromDate,
              //        analysisState.graphTimeInterval.toDate
              //    ));
              //};

              urlCalls.push(
                  getWaterwaysGraphData(
                    analysisState.stationsCheckboxes.selected[i],
                    selectedParametersIds.toString(),
                    selectedParametersDataSourcesIds.toString(),
                    analysisState.graphTimeInterval.fromDate,
                    analysisState.graphTimeInterval.toDate,
                    analysisState.graphEnhanceOptions.groupTimeSpan,
                    analysisState.graphEnhanceOptions.multiplyer,
                    analysisState.graphEnhanceOptions.normalizeMethod
                  )
              );
          }

          if (getTidal) {
              urlCalls.push(
                  getWaterwaysGraphData(
                    300,
                    186,
                    -1,
                    analysisState.graphTimeInterval.fromDate,
                    analysisState.graphTimeInterval.toDate,
                    analysisState.graphEnhanceOptions.groupTimeSpan,
                    analysisState.graphEnhanceOptions.multiplyer,
                    analysisState.graphEnhanceOptions.normalizeMethod
                  )
              );
          }

          $q.all(urlCalls)
              .then(function (data) {
                  if (data) {
                      //console.log(data);
                      //console.log(charts);
                      var populateTidalData = false;
                      var tidalDataIndex = -1;
                      for (var i = 0; i < data.length; i++) {
                          if ($.isArray(data[i])) { // If the result is a array, it is waterquality lab data result
                              if (data[i].length > 0) {
                                  var stationId = data[i][0].PointId;
                                  var labDataType = data[i][0].AmmoniumKorr !== undefined
                                    ? 'service~'
                                    : 'lab~';
                                  addWaterQualityLabDataToChart(charts[stationId], data[i], analysisState, labDataType);
                              }
                          } else { // If the result is a object, we have a chart series result

                              if (data[i].PointId == 300) {// Check if this is a tidal series result(common to all charts)
                                  populateTidalData = true;
                                  var tidalDataIndex = Number(i.toString());
                              }
                              else {// If this is a normal series result, populate the correspondinc chart with the data
                              charts[data[i].PointId] = addSeriesDataToChart(charts[data[i].PointId], data[i], i === 0, analysisState);
                              charts[data[i].PointId].order = analysisState.allStations[data[i].PointId].Sort;
                              charts[data[i].PointId].pointId = data[i].PointId;
                          }
                      }
                  }
                      if (populateTidalData) {
                          for (var pointIdKey in charts) {
                              addSeriesDataToChart(charts[pointIdKey], data[tidalDataIndex], false, analysisState)
                          }
                      }
                  }
                  console.log(charts);
                  deferred.resolve(charts);
              }, function (reason) {
                  alert('Failed: ' + reason);
              });

          return deferred.promise;
      }

      function getWaterwaysGraphData(pointId, selectedParametersIds, selectedParametersDataSourcesIds, from, to, span, multiplyer, method) {
          var deferred = $q.defer();
          console.log("Point: " + pointId + " Parameters: " + selectedParametersIds + " DataSources : " + selectedParametersDataSourcesIds);
          $http({
              method: 'GET',
              url: ngAuthSettings.API_URL + 'values/GetWaterwaysGraphData',
              params: {
                  pointId: pointId,
                  selectedParametersIds: selectedParametersIds,
                  selectedParametersDataSourcesIds: selectedParametersDataSourcesIds,
                  from: from,
                  to: to,
                  span: span,
                  multiplyer: multiplyer,
                  method: method
              }
          }).then(function successCallback(response) {
              deferred.resolve(response.data);
          }/*, function errorCallback(response) { }*/);

          return deferred.promise;
      }

      function getExportData(analysisState) {
          console.log("Getting data " + ngAuthSettings.API_URL + 'values/ExportData')
          var deferred = $q.defer();
          console.log('Frequency ' + analysisState.frequency);
          $http({
              method: 'GET',
              url: ngAuthSettings.API_URL + 'values/ExportData',
              params: {
                  selectedPointIds: analysisState.stationsCheckboxes.selected.toString(),
                  selectedParametersIds: analysisState.parametersCheckboxes.selected.toString(),
                  from: analysisState.graphTimeInterval.fromDate,
                  to: analysisState.graphTimeInterval.toDate,
                  dataSourceIds: 10,
                  multiplyer : analysisState.frequency,
                  sortByWaterWay: true,
                  interpolateGaps: analysisState.interpolateExport,
                  servicePeriodsNULL:analysisState.exportServicePeriodsNull
              },
              headers : {
              'Content-type' : 'application/pdf',
              },
              responseType : 'arraybuffer'
          }).then(function successCallback(response) {
              deferred.resolve(response);
          }/*, function errorCallback(response) { }*/);

          return deferred.promise;
      }

      var replotedCharts = [];

      function getSeriesType(chrt) {
          var seriesType = 'line';
          for (var i = 0; i < chrt.series.length; i++) {
              seriesType = chrt.series[i].type;
              break;
          }
          return seriesType;
      }
      
      function initChart(pointId, analysisState) {
          analysisState.graphTimeInterval.normalizeToDate();

          var newChart = ChartDefaultsService.getConfig(true, analysisState.graphTimeInterval.fromDate, analysisState.graphTimeInterval.toDate, analysisState.graphEnhanceOptions);
          //var flagSensor = analysisState.flagCreaterLinks.find(function (o) { return (o.pointId === Number(pointId) && o.parameterId === analysisState.parametersCheckboxes.selected[0]) ; });
          //var epochFromTo =  "/" + analysisState.graphTimeInterval.fromDate.getTime() + "/" + analysisState.graphTimeInterval.toDate.getTime() + "/VCS";
          newChart.id = 'chart' + pointId;
          newChart.navigator = {
              enabled: false,
          };
          newChart.options.legend = {
              enabled: false
          };
          
          newChart.scrollbar = {
              liveRedraw: false
          };
          newChart.title.text = "<b>" + analysisState.allStations[pointId].Name;
          //if (analysisState.enableEditFlags) {
          //    newChart.title.text = newChart.title.text + "</b> <a href='" + flagSensor.url + epochFromTo + "'>Rediger</a> - "; 
          //    newChart.title.text = newChart.title.text + " <a href='" + flagSensor.urlInspect + epochFromTo + "'>Inspicer</a>"; 
          //};
              
          newChart.title.useHTML = true;
          newChart.title.align = 'left';
          newChart.title.x = 70;
          console.log(newChart);
          console.log(analysisState);

          
          newChart.xAxis[0].minRange = 60 * 1000;
          newChart.xAxis[0].events = {
              afterSetExtremes: function (event) { // sync charts zoom
                  var eventChart = event.target.chart;

                  if (!eventChart) {
                      throw new Error("event chart not found.");
                  }
                  var xMin = event.min;
                  var xMax = event.max;
                  if (analysisState.autoEnhanceCharts === true) {
                      var fromDate = new Date(Math.round(xMin));
                      var toDate = new Date(Math.round(xMax));

                      analysisState.graphEnhanceOptions = buildAutoEnhance(fromDate, toDate, analysisState.graphEnhanceOptions);
                      var currentChartType = getSeriesType(eventChart);
                      var newChartType = analysisState.graphEnhanceOptions &&
                          analysisState.graphEnhanceOptions.normalizeMethod === 4 &&
                          analysisState.graphEnhanceOptions.groupTimeSpan !== 4 &&
                          analysisState.graphEnhanceOptions.multiplyer >= 1
                              ? 'arearange'
                              : 'line';

                      if (!(currentChartType === 'line' && newChartType === 'line') && !(currentChartType === 'column' && newChartType === 'line')) {

                          var selectedParametersIds = angular.copy(analysisState.parametersCheckboxes.selected);

                          var selectedParametersDataSourcesIds = [];
                          for (var j = 0; j < selectedParametersIds.length; j++) {
                              selectedParametersDataSourcesIds.push(analysisState.parameters[selectedParametersIds[j]].DataSourceId);
                          }
                          eventChart.showLoading();
                          getWaterwaysGraphData(
                                    pointId,
                                    selectedParametersIds.toString(),
                                    selectedParametersDataSourcesIds.toString(),
                                    fromDate,
                                    toDate,
                                    analysisState.graphEnhanceOptions.groupTimeSpan,
                                    analysisState.graphEnhanceOptions.multiplyer,
                                    analysisState.graphEnhanceOptions.normalizeMethod)
                              .then(function (response) {
                                  var responseChart = getChartById('chart' + response.PointId);
                                  //var responseChart = Highcharts.chart('chart' + response.PointId);
                                  console.log('responseChart');
                                  console.log(responseChart);
                                      //var responseChart = $('#chart' + response.PointId).highcharts();
                                      if (responseChart) {
                                          var rainChartType = response.GraphType === 'arearange' ? 'columnrange' : 'column';

                                          for (var seriesId in response.GraphValues) {
                                              var seriesResponse = response.GraphValues[seriesId];
                                              var chSeries = responseChart.get(response.PointId + '~' + seriesId);
                                              var newData = [];
                                              for (var l = 0; l < seriesResponse.length; l++) {
                                                  newData.push(response.GraphType === 'arearange'
                                                      ? [Date.parse(seriesResponse[l].Time),
                                                         seriesResponse[l].Value,
                                                         seriesResponse[l].Value2]
                                                      : [Date.parse(seriesResponse[l].Time),
                                                         seriesResponse[l].Value]);
                                              }

                                              var rainDepthString = response.RainDepth
                                                ? ' ' + $translate.instant('rain_depth') + ': ' + response.RainDepth + 'mm'
                                                : '';

                                              chSeries.update({
                                                  type: seriesId == 204 ? rainChartType : response.GraphType,
                                                  data: newData,
                                                  tooltip: {
                                                      useHTML: true,
                                                      valueSuffix: '',
                                                      headerFormat: '<span style="font-size: 13px;">{point.key} </span>' + rainDepthString + '<br/>',
                                                      pointFormat: response.GraphType === 'arearange'
                                                          ? '<span style="color:{series.color}"> ●</span> {series.name}: {point.low}/{point.high} ' + analysisState.parameters[seriesId].UnitName// + analysisState.parameters[seriesId].UnitName  // hovered point min and max values (y axis)
                                                          : '<span style="color:{point.color}"> ●</span> {series.name}: <b>{point.y}</b>. ' + analysisState.parameters[seriesId].UnitName// hovered point value (y axis)

                                                  }
                                              }, false);
                                              chSeries.redraw();

                                              responseChart.hideLoading();
                                          }
                                          $timeout(function () {
                                              var chart = getChartById('chart' + response.PointId);
                                              //var chart = Highcharts.chart('chart' + response.PointId);
                                              //var chart = $('#chart' + response.PointId).highcharts();
                                              chart.redraw();
                                              if (!chart.resetZoomButton) {
                                                  chart.showResetZoom();
                                              }
                                          }, 500);
                                      }
                                  });
                      }
                      else {
                          console.log('else 1');
                          console.log('eventChart');
                          console.log(eventChart);
                          
                          var chartSeries = eventChart.options.series.length
                            ? eventChart.options.series
                            : [eventChart.options.series];

                          var rainFallSeriesIndex = undefined;

                          if (chartSeries && chartSeries.length) {
                              console.log('chartSeries');
                              for (var chIndx = 0; chIndx < chartSeries.length; chIndx++) {
                                  if (chartSeries[chIndx].id !== undefined && chartSeries[chIndx].id.indexOf('~204') != -1) {
                                      console.log('found regn');
                                      rainFallSeriesIndex = Number(chIndx);
                                      console.log(rainFallSeriesIndex);
                                      break;
                                  }
                  }
                              if (rainFallSeriesIndex !== undefined) {
                                  //console.log('rainFallSeriesIndex');
                                  //var rainfallSeries = chartSeries[rainFallSeriesIndex];

                                  //var totalRainFall2 = Number(0);
                                  //for (var seriesDataIndex = 0; seriesDataIndex < rainfallSeries.data.length; seriesDataIndex++) {
                                  //    if (rainfallSeries.data[seriesDataIndex].length >= 3) {
                                  //        var valueTime = rainfallSeries.data[seriesDataIndex][0];
                                          
                                  //        if (valueTime >= event.min && valueTime <= event.max) {
                                  //            totalRainFall2 = Number(Number(totalRainFall2) + Number(rainfallSeries.data[seriesDataIndex][2] || 0));
                                  //        }

                                  //    }

                                  //}
                                  //var newRainDepthString = '(Rain Depth: ' + totalRainFall2 * 3 / 50 + 'mm new)';

                                  //console.log('totalRainFall2');
                                  //console.log(totalRainFall2);
                                  //console.log('newRainDepthString');
                                  //console.log(newRainDepthString);

                                  var newRainDepthString = '';

                                  var drawnSeries = eventChart.series;
                                  
                                  if (drawnSeries.length > 0)
                                  {

                                      drawnSeries[0].tooltipOptions.headerFormat = '<span style="font-size: 13px;">{point.key} </span>' + newRainDepthString + '<br/>';
                                     
                                  }


                              }
                          }
                      }
                  }

                  for (var i = 0; i < analysisState.stationsCheckboxes.selected.length; i++) {
                      if (analysisState.stationsCheckboxes.selected[i] == newChart.id) {
                          continue;
                      }
                      var toSetExtremesChart = getChartById('chart' + analysisState.stationsCheckboxes.selected[i]);
                      //var toSetExtremesChart = Highcharts.chart('chart' + analysisState.stationsCheckboxes.selected[i]);
                      //var toSetExtremesChart = $('#chart' + analysisState.stationsCheckboxes.selected[i]).highcharts();

                      if (!toSetExtremesChart) {
                          throw new Error("toSetExtremesChart chart not found.");
                      }

                      var currentExtremes = toSetExtremesChart.xAxis[0].getExtremes();
                      if (currentExtremes.min !== xMin || currentExtremes.max !== xMax) {
                          toSetExtremesChart.xAxis[0].setExtremes(xMin, xMax, true, false);
                      }
                  }
              }
          };
          var mappedAxisData = {};
          var colorsPallete = new ChartDefaultsService.ColorPalletes(analysisState.parametersCheckboxes.selected.length);
          var newChartType = analysisState.graphEnhanceOptions &&
                                    analysisState.graphEnhanceOptions.normalizeMethod === 4 &&
                                    analysisState.graphEnhanceOptions.groupTimeSpan !== 0 &&
                                    analysisState.graphEnhanceOptions.multiplyer >= 1
                                        ? 'arearange'
                                        : 'line';

          var rainChartType = newChartType === 'arearange' ? 'columnrange' : 'column';

          for (var iParams = 0; iParams < analysisState.parametersCheckboxes.selected.length; iParams++) {
              var parId = analysisState.parametersCheckboxes.selected[iParams];
              var paramColor = analysisState.parameters[parId].Color;
              var colorsString = !paramColor || paramColor === '' || paramColor === null ? colorsPallete.colorStrings[iParams] : paramColor;
              var NumberDecimals = analysisState.parameters[parId].NoDecimals;
   //           console.log('Factory Scale :' + analysisState.scaleYaxis + ' autoscale: ' + analysisState.autoscaleYaxis + ' fixed: ' + analysisState.fixedscaleYaxis);
   //           console.log(analysisState.parameters[parId].Id + ' ymin:' + analysisState.parameters[parId].DefaultMin + ' ymax:' + analysisState.parameters[parId].DefaultMax);

              mappedAxisData[parId] = {
                  color: colorsString,
                  yAxisIndex: iParams
              };
              newChart.yAxis[iParams] = {
                  labels: {
                      NoDecimals: Math.max(0, NumberDecimals-1),
                      style: {
                          color: colorsString,
                          padding: '0'
                      },
                      formatter: function () {
                          return Highcharts.numberFormat(this.value, this.axis.userOptions.labels.NoDecimals);
                      }
                  },
                  title: {
                      text: '',
                  },
                  endOnTick: false,
                  maxPadding: 0,
                  tickInterval: null,
                  min: analysisState.fixedscaleYaxis ? analysisState.parameters[parId].DefaultMin : analysisState.autoscaleYaxis ? (parId == 204 ? 0 : null) : 0,
                  max: analysisState.fixedscaleYaxis ? analysisState.parameters[parId].DefaultMax : null,
                  tickAmount: null, //analysisState.fixedscaleYaxis ? 3:null,
                  tickWidth: null,
                  tickLength: null,
                  allowDecimals: true,
                  //showLastLabel: false,
                  //padding: 1,
                  gridLineWidth: 1,
                  //allowDecimals: parId == 271 ? true : false, // parId == 204 ? false : true,
                  valueDecimals:  0,
                  opposite: iParams == 0 ? false : true ,
                  reversed: parId === 204
              };


              newChart.series.push({
                  id: pointId + '~' + parId,
                  data: [],
                  name: $translate.instant('__short__param__' + parId),
                  yAxis: iParams,
                  tooltip: {
                      share: true,
                      useHTML: true,
                      valueSuffix: ' ', //parId == 204 ? 'µm/s' : ' ' + analysisState.parameters[parId].UnitName,
                      valueDecimals: NumberDecimals,
                      pointFormat: '<span style="color:{series.color}"> ●</span> {series.name}: <b>{point.low}</b> - <b>{point.high}</b>' + analysisState.parameters[parId].UnitName  // hovered point min and max values (y axis)
                       // : '<span style="color:{point.color}"> ●</span> {series.name}: <b>{point.y}</b>. ' + analysisState.parameters[parId].UnitName// hovered point value (y axis)
                  },
                  color: colorsString,
                  type: parId == 204 ? rainChartType : newChartType

              });

          }
          for (var jParams = 0; jParams < analysisState.labCheckboxes.selected.length; jParams++) {
              var parId = analysisState.labCheckboxes.selected[jParams];
              var paramColor = analysisState.labParameters[parId].Color;
              var colorsString = !paramColor || paramColor === '' || paramColor === null ? 'green' : paramColor;
              newChart.series.push({
                  type: 'line',
                  id: 'lab~' + pointId + '~' + parId,
                  data: [],
                  name: 'lab~' + analysisState.labParameters[parId].Name,
                  yAxis: mappedAxisData[parId] ? mappedAxisData[parId].yAxisIndex : undefined,
                  tooltip: {
                      valueSuffix: ' ' + analysisState.labParameters[parId].UnitName,
                      valueDecimals: 2,
                      pointFormat: '<span style="color:' + colorsString + ';"> ●</span> {series.name}: <b>{point.y}</b>. ' // hovered point value (y axis)

                  },
                  marker: {
                      enabled: true,
                      fillColor: colorsString,
                      states: {
                          hover: {
                              radiusPlus: 1,
                              lineWidthPlus: 12,
                              lineColor: "rgba(188,188,188, .3)"
                          }
                      }
                  },
                  color: 'transparent'
              });

          }
          newChart.options.chart.marginLeft = 30 * Math.max(2,analysisState.parametersCheckboxes.selected.length); //Force same start of y-axis - at the risk of not having enough room to draw....
          newChart.options.chart.zoomType = 'xy';
          return newChart;
      }

      function getSeriesIndexByName(chart, seriesName) {
          for (var i = 0; i < chart.series.length; i++) {
              if (chart.series[i].name === seriesName) {
                  return i;
              }
          }
          return -1;
      }

      function addWaterQualityLabDataToChart(chart, data, analysisState, labDataType) {
          if (!data || data.length === data) {
              return;
          }

          var stationId = data[0].PointId;

          //console.log('lab type ====' + labDataType + '============================================================================================================================');
          //console.log(data);
          if (labDataType === 'lab~') {
              //console.log('lab type ================================================================================================================================');
              var checkboxes = analysisState.labCheckboxes;

              var selectedAmmonium = checkboxes.selected.indexOf(265) !== -1
                ? getSeriesIndexByName(chart, labDataType + analysisState.labParameters['265'].Name)
                : undefined;
              var selectedIlt = checkboxes.selected.indexOf(266) !== -1
                ? getSeriesIndexByName(chart, labDataType + analysisState.labParameters['266'].Name)
                : undefined;
              var selectedKalium = checkboxes.selected.indexOf(267) !== -1
                ? getSeriesIndexByName(chart, labDataType + analysisState.labParameters['267'].Name)
                : undefined;
              var selectedPH = checkboxes.selected.indexOf(269) !== -1
                ? getSeriesIndexByName(chart, labDataType + analysisState.labParameters['269'].Name)
                : undefined;

              if (selectedAmmonium) {
                  chart.series[selectedAmmonium].data = [];
              }
              if (selectedIlt) {
                  chart.series[selectedIlt].data = [];
              }
              if (selectedKalium) {
                  chart.series[selectedKalium].data = [];
              }
              if (selectedPH) {
                  chart.series[selectedPH].data = [];
              }
              for (var i = 0; i < data.length; i++) {
                  chart.xAxis[0].plotLines.push({
                      color: 'red', // Color value
                      value: Date.parse(data[i].DateTime), // Value of where the line will appear
                      width: 1, // Width of the line  
                      zIndex: 3,
                      dashStyle: 'longdashdot',
                      label: {
                          text: 'Lab',
                          verticalAlign: 'bottom',
                          textAlign: 'right',
                          y: -10
                      }
                  });

                  if (selectedAmmonium) {
                      chart.series[selectedAmmonium].data.push([Date.parse(data[i].DateTime), data[i].AmmoniumLab]);
                  }
                  if (selectedIlt) {
                      chart.series[selectedIlt].data.push([Date.parse(data[i].DateTime), data[i].IltLab]);
                  }
                  if (selectedKalium) {
                      chart.series[selectedKalium].data.push([Date.parse(data[i].DateTime), data[i].KaliumLab]);
                  }
                  if (selectedPH) {
                      chart.series[selectedPH].data.push([Date.parse(data[i].DateTime), data[i].PHLab]);
                  }
              }

          } else {
              chart.xAxis[0].plotBands = [];

              for (var i = 0; i < data.length; i++) {
                  chart.xAxis[0].plotBands.push({
                      color: 'rgba(0,0,0, .2)', // Color value
                      from: Date.parse(data[i].DateTime), // Value of where the line will appear
                      to: Date.parse(data[i].EndDateTime), // Value of where the line will appear
                  });
              }

          }

      }


      function addSeriesDataToChart(chart, waterwayGraphDataModel, first, analysisState) {
          for (var paremeterId in waterwayGraphDataModel.GraphValues) {
              var parnr = -1;
              for (var j = 0; j < chart.series.length; j++) {
                  if (chart.series[j].id === waterwayGraphDataModel.PointId + '~' + paremeterId || 
                      waterwayGraphDataModel.PointId == 300 && chart.series[j].id.indexOf('186') !== -1||
                      chart.series[j].id === 'lab~' + waterwayGraphDataModel.PointId + '~' + paremeterId) {
                      parnr = j;
                      break;
                  }
              }
              if (parnr === -1) {
                  $mdToast.show($mdToast.simple().textContent('no series found!!!'));
                  console.log('');
                  console.log('=========================');
                  console.log('no series found!!!');
                  console.log('=========================');
                  console.log('');
                  console.log('chart');
                  console.log(chart);
                  console.log('waterwayGraphDataModel');
                  console.log(waterwayGraphDataModel);
                  console.log('waterwayGraphDataModel.GraphValues');
                  console.log(waterwayGraphDataModel.GraphValues);
                  console.log('paremeterId');
                  console.log(paremeterId);
                  console.log('=========================');
                  console.log('');
                  console.log('');
              }

              if (paremeterId.toString() === '204' && waterwayGraphDataModel.RainDepth) {
                  chart.rainDepth = waterwayGraphDataModel.RainDepth;
                  console.log('rainDepth');
                  console.log(waterwayGraphDataModel.RainDepth);
              }
              
              if (parnr !== -1) {
              chart.series[parnr].data = [];
              for (var i = 0; i < waterwayGraphDataModel.GraphValues[paremeterId].length; i++) {
                  chart.series[parnr].data.push(
                          waterwayGraphDataModel.GraphType !== 'arearange'
                              ? [Date.parse(waterwayGraphDataModel.GraphValues[paremeterId][i].Time),
                                waterwayGraphDataModel.GraphValues[paremeterId][i].Value]
                              : [Date.parse(waterwayGraphDataModel.GraphValues[paremeterId][i].Time),
                                waterwayGraphDataModel.GraphValues[paremeterId][i].Value,
                                  waterwayGraphDataModel.GraphValues[paremeterId][i].Value2]);
                  }
              }
          }
          return chart;
      }

      function buildAutoEnhance(from, to, graphEnhanceOptions) {

          var expectedResultsPerGraph = Math.round(Math.abs(from - to) / 60000);


          if (expectedResultsPerGraph > 400) {
              var minutesMultiplyer = Math.round(expectedResultsPerGraph / 400 + 0.4999999);
              graphEnhanceOptions.groupTimeSpan = 2; //Minutter
              graphEnhanceOptions.normalizeMethod = 4; //MinMax
              graphEnhanceOptions.multiplyer = minutesMultiplyer;
          }
          else {
              graphEnhanceOptions.groupTimeSpan = 0;
              graphEnhanceOptions.normalizeMethod = 0;
              graphEnhanceOptions.multiplyer = 1;
          }

          return graphEnhanceOptions;
        }

        function getChartById(chartId) {
            console.log(Highcharts.charts);
            var retChart = null;
            for (var i = 0; i < Highcharts.charts.length; i++) {
                if (!Highcharts.charts[i]) {
                    continue;
                }
                if (chartId === Highcharts.charts[i].container.parentNode.id) {
                    retChart = Highcharts.charts[i];
                    break;
                }
            }
            return retChart;
        }

  }]);

