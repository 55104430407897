'use strict';

/**
 * @ngdoc service
 * @name mapsApp.extractStateService
 * @description
 * # analisysStateService
 * Service in the mapsApp.
 */
angular.module('mapsApp')
    .service('extractStateService', ['$q', '$http', 'localStorageService', '$mdToast', 'ngAuthSettings',
        function ($q, $http, localStorageService, $mdToast, ngAuthSettings) {
      // AngularJS will instantiate a singleton by calling "new" on this function       
      var localStorageState = localStorageService.get('extractPageState');
      var serv = {
          pageState: localStorageState ? angular.copy(localStorageState) : new extractPageState(),
          resetState: _resetState,
          setStateInLocalStorage: _setStateInLocalStorage,
          deleteStateFromLocalStorage: _deleteStateFromLocalStorage,
          getEnhanceGraphType: _getEnhanceGraphType,
          usedDefaultValues: false
      };
      if (localStorageState) {
          $mdToast.show(
            $mdToast.simple()
              .textContent('Local storage saved on ' + localStorageState.timeStamp + ' was loaded succesfully!')
              .position('right top')
              .hideDelay(5000)
          );

      }
      else {
          serv.pageState.stationsCheckboxes = new GroupedCheckboxes(serv.pageState.stationsCheckboxes);
          serv.pageState.parametersCheckboxes = new GroupedCheckboxes(serv.pageState.parametersCheckboxes);
          serv.pageState.graphTimeInterval = new GraphTimeInterval(
              new Date(Date.parse(serv.pageState.graphTimeInterval.fromDate)),
              new Date(Date.parse(serv.pageState.graphTimeInterval.toDate)),
              new Date(Date.parse(serv.pageState.graphTimeInterval.maxDate))
          );
      }

      return serv;

      function _setStateInLocalStorage() {
          var now = new Date();
          serv.pageState.timeStamp = now;
          localStorageService.set('extractPageState', angular.copy(serv.pageState));

      }

      function _deleteStateFromLocalStorage(showMessage) {
          localStorageService.remove('extractPageState');
          if (showMessage) {
              $mdToast.show(
                $mdToast.simple()
                  .textContent('Local storage was deleted succesfully!')
                  .position('right top')
              );
          }
      }

      function _resetState() {
          _deleteStateFromLocalStorage();
          serv.pageState = new extractPageState();
          updateextractState(serv.pageState)
            .then(function successCallback(response) {
                serv.pageState = response;
            }, function errorCallback(response) {

            });
      }

      function _getEnhanceGraphType() {
          var graphType = serv.pageState.graphEnhanceOptions &&
              serv.pageState.graphEnhanceOptions.normalizeMethod === 4 &&
              serv.pageState.graphEnhanceOptions.groupTimeSpan !== 0 &&
              serv.pageState.graphEnhanceOptions.multiplyer >= 1
                  ? 'arearange'
                  : 'line';

          return graphType;
      }

      function updateextractState(currentState) {
          var deferred = $q.defer();

          $http({
              method: 'GET',
              url: ngAuthSettings.API_URL + 'values/GetActiveDataEntitites'
          }).then(function successCallback(response) {
              if (response.data) {
                  var analisysPageMetadata = populatePageState(currentState, response.data);
                  deferred.resolve(analisysPageMetadata);
              }

          }, function errorCallback(response) {

          });
          return deferred.promise;
      }

      function populatePageState(pageState, data) {

          pageState.parameters = data.Parameters;

        
          var point = '';
          var newPoint = true;
          for (var rw in data) {
              if (point != rw.name) {
                  newPoint = true;
              }
              pageState.stationsCheckboxes.selectedCheckGroups[rw.PointId] = [];
          }

          serv.usedDefaultValues = undefined;

          pageState.populated = true;

          return pageState;
      }

      function extractPageState() {
          var st = this;
          st.keepStateInLocalStorage = false;
          st.autoCloseSidenav = true;
          st.autoEnhanceCharts = true;
          st.populated = false;
          st.useDefaultValues = true;

          st.graphEnhanceOptions = new GraphEnhanceOptions();
          st.graphTimeInterval = new GraphTimeInterval();
          st.stationsCheckboxes = new GroupedCheckboxes();
          st.parametersCheckboxes = new GroupedCheckboxes();
          st.labCheckboxes = new GroupedCheckboxes();

          st.panTimeSpan = 1;
          st.panTimeSpanOptions = [
              {
                  name: '1_day',
                  value: 1
              }, {
                  name: '3_days',
                  value: 3
              }, {
                  name: '7_days',
                  value: 7
              },
          ];

          st.chartConfigs = [];

          st.waterways = {};
          st.raingauges = {};
          st.raingaugePointId = 0;

          st.allStations = {};
          st.parameterCollections = {};
          st.parameters = {};
          st.parametersDataSourceIds = {};
      };
      function GraphEnhanceOptions(source) {
          this.timespans = [
                      {
                          id: 0,
                          name: 'None'
                      }, {
                          id: 1,
                          name: 'Sekunder'
                      }, {
                          id: 2,
                          name: 'Minutter'
                      }, {
                          id: 3,
                          name: 'Timer'
                      }, {
                          id: 4,
                          name: 'Dag'
                      }];
          this.normalizeMethods = [
              {
                  id: 0,
                  type: null,
                  name: 'None'
              }, {
                  id: 1,
                  type: 'line',
                  name: 'Min'
              }, {
                  id: 2,
                  type: 'line',
                  name: 'Average'
              }, {
                  id: 3,
                  type: 'line',
                  name: 'Max'
              }, {
                  id: 4,
                  type: 'arearange',
                  name: 'MinMax'
              }
          ];
          this.groupTimeSpan = 0;
          this.normalizeMethod = 0;
          this.multiplyer = 1;
          this.getSelectedName = function () {
              for (var i = 0; i < this.timespans.length; i++) {
                  if (this.timespans[i].id == this.groupTimeSpan){return this.timespans[i].name}
              }
          }

          // toolbar items
          this.isOpen = false;
          this.count = 0;
      }

      function GraphTimeInterval(from, to, max) {
          var now = new Date(Date.now());
          this.toDate = to ? to : now;
          this.fromDate = from ? from : new Date(now.getFullYear(), now.getMonth(), now.getDate());
          if(!from){
              this.fromDate.setDate(this.fromDate.getDate() - 2);
          }
          this.maxDate = max ? max : this.toDate;

          this.isToday = function (value) {
              var today = new Date(Date.now());
              var rest =
                  (value.getFullYear() === today.getFullYear()) &&
                  (value.getMonth() === today.getMonth()) &&
                  (value.getDate() === today.getDate());
              return rest;
          };
          this.addSubstractNowTime = function (value, s) {
              var now = new Date(Date.now());

              value.setSeconds(value.getSeconds() + (s * now.getSeconds()));
              value.setMinutes(value.getMinutes() + (s * now.getMinutes()));
              value.setHours(value.getHours() + (s * now.getHours()));

              return value;
          };
          this.normalizeToDate = function () {
              if (this.isToday(this.toDate)) {
                  this.toDate = this.addSubstractNowTime(new Date(this.toDate.getFullYear(), this.toDate.getMonth(), this.toDate.getDate()), 1);
              }
          };
      }

      function getSelectedItemGroupId(itemId) {
          for (var key in serv.pageState.parameterCollections) {
              for (var paramKey in serv.pageState.parameterCollections[key].parameters) {
                  if (itemId == paramKey) {
                      return key;
                  }
              }
          }
          return undefined;
      }

      function GroupedCheckboxes(src) {
          this.selected = src ? src.selected : [] ;
          this.selectedCheckGroups = src ? src.selectedCheckGroups : {};
          
          this.toggle = function (item, groupId, link) {
              var list = this.selected;
              var idx = list.indexOf(item);
              if (idx > -1) {
                  list.splice(idx, 1);
              }
              else {
                  list.push(item);
              }
              if (!this.selectedCheckGroups[groupId])
                  return;
              var idxGroup = this.selectedCheckGroups[groupId].indexOf(item);
              if (idxGroup > -1) {
                  this.selectedCheckGroups[groupId].splice(idxGroup, 1);
              }
              else {
                  this.selectedCheckGroups[groupId].push(item);
              }

              if (link) {
                  var isSelectedInLinked = link.linkedCheckbox.selected.indexOf(item) != -1;
                  if (link.select === true) {
                      if (!isSelectedInLinked && this.selected.indexOf(item) !== -1) {
                          var selectedGroupId = getSelectedItemGroupId(item);
                          link.linkedCheckbox.toggle(item, selectedGroupId);
                      }
                  }
                  else {
                      var selectedGroupId = link.groupId;
                      if (isSelectedInLinked && !this.selected.indexOf(item) != -1) {
                          link.linkedCheckbox.toggle(item, selectedGroupId);
                      }

                  }
              }
          };     
          this.getPropertyCount = function (source) {
              var res = 0;
              for (var prop in source) {
                  res++;
              }
              return res;
          };
          this.exists = function (item, groupId) {
              if (this.selectedCheckGroups[groupId] === undefined)
                  return false;
              return this.selectedCheckGroups[groupId].indexOf(item) > -1;
          };
          this.isIndeterminate = function ($event, groupId, allValues) {

              var groupList = this.selectedCheckGroups[groupId];

              if (!groupList) {
                  return;
              }

              return (groupList.length !== 0 &&
                  groupList.length !== this.getPropertyCount(allValues));
          };
          this.isChecked = function (groupId, allValues) {
              var groupList = this.selectedCheckGroups[groupId];
              if (!groupList) {
                  return;
              }
              return groupList.length === this.getPropertyCount(allValues);
          };
          this.toggleAll = function (groupId, allValues, link) {
              if (this.selectedCheckGroups[groupId].length === this.getPropertyCount(allValues)) {
                  do {
                      this.toggle(this.selectedCheckGroups[groupId][0], groupId, link);
                  } while (this.selectedCheckGroups[groupId].length > 0);
              } else if (this.selectedCheckGroups[groupId].length === 0 || this.selectedCheckGroups[groupId].length > 0) {
                  for (var pointId in allValues) {
                      var pointIdNumber = Number(pointId);
                      if (this.selected.indexOf(pointIdNumber) === -1) {
                          this.toggle(pointIdNumber, groupId, link);
                          //this.selected.push(Number(pointIdNumber));
                          //this.selectedCheckGroups[groupId].push(Number(pointIdNumber));
                      }

                  }

              }
          };
      }

  }]);

