'use strict';

angular.module('mapsApp')
    .service('statsFactory', ['$q', '$http', '$mdToast', 'ngAuthSettings',
        function ($q, $http, $mdToast, ngAuthSettings) {

    return {
      getRainStats: _getRainStats,
      getPoints:_getPoints
    };


    function _getRainStats(from, to) {
      console.log("Getting stats :" + from + " to " + to);
      var deferred = $q.defer();
      $http({
        method: 'GET',
        url: ngAuthSettings.API_URL + 'values/GetRainStatistics',
        params: { from: from, to: to, pointCollectionId: 29, parameterId: 204 }

      }).then(function successCallback(response) {
        if (response && response.data) {
          deferred.resolve(response.data);
        }
      }, function errorCallback(response) {
        var errorMessage = response && response.data && response.data.ExceptionMessage
          ? response.data.ExceptionMessage
          : "A error occured while trying to get the statistics.";
        $mdToast.show($mdToast.simple().textContent(errorMessage).position('right top'));
        deffered.reject(response);
      });
      return deferred.promise;
    }
    function _getPoints() {
      var deferred = $q.defer();
      $http({
        method: 'GET',
        url: ngAuthSettings.API_URL + 'values/GetPoints',
        params: { pointTypeId: 3}
      }).then(function successCallback(response) {
        if (response && response.data) {
          deferred.resolve(response.data);
        }
      }, function errorCallback(response) {
        var errorMessage = response && response.data && response.data.ExceptionMessage
          ? response.data.ExceptionMessage
          : "A error occured while trying to get the Points.";
        $mdToast.show($mdToast.simple().textContent(errorMessage).position('right top'));
        deffered.reject(response);
      });
      return deferred.promise;

    }

  }]);

