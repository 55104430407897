'use strict';

var Highcharts = require("highcharts");
var proj4 = require("proj4").default;

angular.module('mapsApp')
    .controller('ValidateController', ['$http', 'localStorageService', '$scope', 'authFactory', '$location', '$q', '$compile', '$mdToast', '$window', '$translate', 'awsAthenaChartsFactory', 'ChartDefaultsService', 'ngAuthSettings',
        function ($http, localStorageService, $scope, authFactory, $location, $q, $compile, $mdToast, $window, $translate, awsAthenaChartsFactory, ChartDefaultsService, ngAuthSettings) {
            //if (!authFactory.isAuth()) {
            //    authFactory.login;
            //}
            if (!authFactory.authentication.isAuth) {
                console.log("Welcome external user");
                $location.path('/login');
            }

            var pageChart;

            $scope.showFilters = true;
            $scope.mapDataEntities = true;
            var OneChart = !$scope.mapDataEntities;

            $scope.markers = {};
            $scope.currentMarker = {};
            $scope.dataSources = [{ id: 0, Name: 'SRO', dataSourceId: 10, Color: '#0000FF' }, { id: 1, Name: 'Danova', dataSourceId: 14, Color: '#7FFF00' }, { id: 2, Name: 'Døgnmodel', dataSourceId: 5, Color: '#FF0000' }]; //
            $scope.stationDataEntities = [];
            $scope.chartConfig = {};
            $scope.tableHeaders = {};
            $scope.allChartsInit = false;
            $scope.toDate = new Date();
            $scope.fromDate = addDays($scope.toDate, -1 * 90);
            var timeIntervals = { fromDate: $scope.fromDate, toDate: $scope.toDate };
            $scope.autoscaleYaxis = false;//
            $scope.fixedscaleYaxis = false;//
            $scope.scaleYaxis = 1;

            $scope.parameters = [];
            var ShowParameters = ['189', '191', '204'];  //These parameterIds will populate the parameters array
            var layersScope = { baselayers: {}, overlays: {} };
            var IconsNew = {};
            var layerControl;
            var map = {};
            var markerLayers = [];
            var utmonmap = document.getElementById("utm_onmap");
            var geoonmap = document.getElementById("geo_onmap");
            var adressonmap = document.getElementById("adresse");
            $scope.charts = [];

            $scope.showingPlot = false;
            $scope.filterMapMU = false;
            $scope.filterMapSRO = false;

            $scope.panTimeSpan = 1;
            $scope.panTimeSpanOptions = [
                {
                    name: '1_day',
                    value: 1
                }, {
                    name: '3_days',
                    value: 3
                }, {
                    name: '7_days',
                    value: 7
                },
            ];


            setTimeout(function () {
                initBlankChart();
            }, 100);


            angular.element(document).ready(function () {
                getParameters();
                map = initMap();
            });
            $scope.datesBetween = function (prop) {
                return function (item) {
                    if (item.DataSourceId == 5) return true; //Models should always be included...and in Feb 2019 DataLast is not updated
                    var check = Date.parse(item[prop]);
                    //var dt = new Date(tst);
                    return (check > $scope.fromDate.getTime() && check < $scope.toDate.getTime());
                }
            };
            $scope.dataSourceActive = function (prop) {
                return function (item) {
                    if (prop) {
                        if (item.DataSourceId == prop) return true;
                    }
                    else return true;
                    return false;
                }
            };

            $scope.hasMUMap = function (prop) {
                return function (item) {
                    if (!$scope.filterMapMU) return true;
                    return (item[prop] !== null);
                }
            };
            $scope.hasCampaignMap = function (prop) {
                return function (item) {
                    if (!$scope.filterMapCampaign) return true;
                    return (item[prop] !== null);
                }

            }

            $scope.hasSROMap = function (prop) {
                return function (item) {
                    if (!$scope.filterMapSRO) return true;
                    console.log(item);
                    console.log(item[prop]);

                    return (item[prop] !== null);
                }
            };
            //$scope.toggledMapping = function () {
            //    console.log('Changed mapping .... want to do something ? ')
            //}
            $scope.toggleYaxisScaling = function () {
                if ($scope.scaleYaxis > 0) {
                    $scope.autoscaleYaxis = true;

                    if ($scope.scaleYaxis === 2) {
                        $scope.fixedscaleYaxis = true;
                    } else { $scope.fixedscaleYaxis = false; }
                } else {
                    $scope.autoscaleYaxis = false;
                    $scope.fixedscaleYaxis = false;
                }

                var parameters = $scope.parameters[0];

                for (var j = 0; j < parameters.length; j++) {
                    //                $scope.chartConfig.yAxis[j].min = $scope.autoscaleYaxis ? null : 0;
                    $scope.chartConfig.yAxis[j].min = $scope.fixedscaleYaxis ? parameters[j].DefaultMin : $scope.autoscaleYaxis ? null : 0;
                    $scope.chartConfig.yAxis[j].max = $scope.fixedscaleYaxis ? parameters[j].DefaultMax : null;
                    $scope.chartConfig.yAxis[j].tickAmount = 3;
                    $scope.chartConfig.yAxis[j].isDirty = true;
                }

                if (pageChart) {
                    pageChart.destroy();
                }
                pageChart = Highcharts.chart('overview-chart-container', $scope.chartConfig);
            };

            $scope.toggleFixedYaxisScaling = function () {
                $scope.fixedscaleYaxis = !$scope.autoscaleYaxis;
                var parameters = $scope.parameters[0];
                for (var j = 0; j < parameters.length; j++) {
                    if ($scope.fixedscaleYaxis) {
                        $scope.chartConfig.yAxis[j].min = parameters[j].DefaultMin;
                        $scope.chartConfig.yAxis[j].max = parameters[j].DefaultMax;
                    }
                    else {
                        $scope.chartConfig.yAxis[j].min = $scope.autoscaleYaxis ? null : 0;
                        $scope.chartConfig.yAxis[j].max = null;
                    }
                    $scope.chartConfig.yAxis[j].isDirty = true;
                }

                if (pageChart) {
                    pageChart.destroy();
                }
                pageChart = Highcharts.chart('overview-chart-container', $scope.chartConfig);
            };

            $scope.selectElement = function (id) {
                $scope.currentMarker = $scope.markers[id];
                $scope.markers[id].focus = true;
                var stationObject = $scope.stationDataEntities.find(function (o) { return o.DataEntityId === $scope.currentMarker.id; });
                stationObject.checked = true;
                map.setView($scope.currentMarker._leafletMarker._latlng);
                $scope.currentMarker._leafletMarker.openPopup();
                //getChartData(stationObject);
            };

            $scope.resetSelections = function () {
                for (var i = 0; i < $scope.stationDataEntities.length; i++) {
                    $scope.stationDataEntities[i].checked = false;
                }
                destroyCharts(true);
            }



            // Initializes the scope with the chart
            function initBlankChart() {
                $scope.chartConfig = ChartDefaultsService.getConfig();
            }

            function initChart() {
                $scope.chartConfig = ChartDefaultsService.getConfig();
                $scope.chartConfig.title.text = $scope.currentMarker.name;
                $scope.chartConfig.title.x = 0;
                $scope.chartConfig.xAxis[0].min = Date.parse($scope.fromDate);
                $scope.chartConfig.xAxis[0].max = Date.parse($scope.to);


                var parameters = $scope.parameters[0];
                var colorPallete = new ChartDefaultsService.ColorPalletes(parameters.length).colorStrings;
                for (var j = 0; j < parameters.length; j++) {
                    var paramColor = parameters[j].Color;
                    var type = parameters[j].Id == 204 ? 'column' : 'line';

                    var NumberDecimals = parameters[j].NoDecimals;
                    var colorsString = !paramColor || paramColor === null || paramColor === '' ? colorPallete[j] : paramColor;
                    $scope.chartConfig.yAxis[j] = {
                        labels: {
                            NoDecimals: Math.max(0, NumberDecimals),
                            style: {
                                color: colorsString
                            },
                            formatter: function () {
                                return Highcharts.numberFormat(this.value, this.axis.userOptions.labels.NoDecimals);
                            }
                        },
                        title: {
                            text: '',
                        },
                        min: $scope.autoscaleYaxis ? null : $scope.fixedscaleYaxis ? parameters[j].DefaultMin : 0,
                        max: $scope.autoscaleYaxis ? null : $scope.fixedscaleYaxis ? parameters[j].DefaultMax : null,
                        gridLineWidth: j === 0 ? 1 : 0,
                        //minorTickInterval : 3,
                        tickInterval: null,
                        opposite: j === 0 ? false : true
                    };
                    $scope.chartConfig.series.push({
                        parameterId: parameters[j].Id,
                        name: $translate.instant('__short__param__' + parameters[j].Id) + ' (' + parameters[j].UnitName + ')',
                        stickyTracking: false,
                        tooltip: {
                            valueDecimals: NumberDecimals
                        },
                        type: type,
                        yAxis: j,
                        data: [],
                        color: colorsString
                    });
                    $scope.showingPlot = true;
                }

            }


            function addDays(date, days) {
                console.log(date);
                var result = new Date(date);
                console.log(result);

                result.setDate(result.getDate() + days);
                return result;
            }

            $scope.panTime = function (days, multiplyer) {
                console.log("Pan : " + days + " * " + multiplyer);
                if (days) {
                    console.log("to : " + $scope.toDate);
                    var newToDate = addDays($scope.toDate, days * (multiplyer || 1));
                    if (newToDate < addDays(new Date(), 1)) {
                        $scope.toDate = newToDate;
                        console.log("to now : " + $scope.toDate);
                        $scope.fromDate = addDays($scope.fromDate, days * (multiplyer || 1));
                        console.log("Getting Chart data " + $scope.toDate + ", " + $scope.fromDate);
                        getChartData();
                    }
                    else console.log("The future will come - be patient .. you are looking for " + newToDate)

                }
            };
            var highlight = {};
            var highLightedPoints = [];
            $scope.numberOfSelectedStations = 0;

            $scope.toggleStation = function (sourceId) {
                console.log(sourceId);
                for (var i = 0; i < $scope.dataSources.length; i++) {
                    if (sourceId) {
                        if ($scope.dataSources[i].dataSourceId !== sourceId) {
                            map.removeLayer($scope.dataSources[i].layerGroup);
                        }
                        else $scope.dataSources[i].layerGroup.addTo(map);
                    } else $scope.dataSources[i].layerGroup.addTo(map);
                }
            };


            $scope.toggledStation = function (station) {
                //if (forceRemove) {
                //  map.removeLayer(station.highLight);  //from map
                //  $scope.numberOfSelectedStations--;
                //  return;
                //}
                if (station.checked) {
                    $scope.numberOfSelectedStations++;
                    if (station) {
                        //station.marker.openPopup();
                        station.highLight.addTo(map);
                    }
                }
                else {
                    //station.marker.closePopup();
                    map.removeLayer(station.highLight);  //from map
                    //station.highLight.removeFrom(map);
                    $scope.numberOfSelectedStations--;
                }
            };

            $scope.updateGraph = function () {
                $scope.showLoading = true;
                //$scope.showFilters = false;
                destroyCharts(true);
                if (initCharts()) {
                    timeIntervals = { fromDate: $scope.fromDate, toDate: $scope.toDate };
                    $scope.allowZoomEvent = false;
                    renderChartContainers();
                    var calls = buildAllUpdateChartCalls(timeIntervals, $scope.charts, undefined, false);
                    $q.all(calls).then(function (responses) {
                        $scope.allowZoomEvent = true;
                        $scope.activeplot = true;
                        $scope.showLoading = false;
                    }, function (err) {
                        $scope.allowZoomEvent = true;
                        $scope.activeplot = true;
                        $scope.showLoading = false;
                    });
                }
                else {
                    $mdToast.show($mdToast.simple().textContent($translate.instant('select_scenarioes_toshow')).position('left top'));
                    $scope.showLoading = false;
                    $scope.allowZoomEvent = true;
                };
            };
            function buildAllUpdateChartCalls(timeIntervals, charts, zoomTimeIntervals, isZoomed) {
                var calls = [];
                var toUseIntervals = zoomTimeIntervals === undefined || zoomTimeIntervals === null
                    ? timeIntervals
                    : zoomTimeIntervals;
                //calls.push(_getAWSDataAllCharts(awsSelections, charts, selectedDataSource, graphEnhanceOptions, toUseIntervals, isZoomed));
                var graphEnhanceOptions = awsAthenaChartsFactory.buildAutoEnhanceStandard(true, toUseIntervals);
                for (var c = 0; c < charts.length; c++) {
                    charts[c].isZoomed = isZoomed;
                    charts[c].graphEnhanceOptions = graphEnhanceOptions;
                    calls.push(getChartData(charts[c], toUseIntervals));
                    // console.log(charts[c]);
                }
                return calls;
            }


            function getChartData(chart, timeIntervals) {
                var deferred = $q.defer();
                var chartId = chart.Id;
                var seriesUpdateCalls = [];
                for (var s = 0; s < chart.series.length; s++) {
                    var dsO = chart.series[s].dataSourceObject;
                    chart.series[s].graphEnhanceOptions = chart.graphEnhanceOptions;
                    chart.series[s].graphType = chart.graphEnhanceOptions.graphType;
                    // change to column type if rain....
                    if (dsO.parameterId == 204) chart.series[s].graphType = chart.series[s].graphType === 'arearange' ? 'columnrange' : 'column';
                    chart.series[s].tooltipPointFormat = chart.graphEnhanceOptions.tooltipPointFormat;

                    if (dsO.parameterId == 0 || dsO.pointId == 0) {
                        $mdToast.show($mdToast.simple().textContent('SRO/Model point and paramter Ids zero').position('left bottom'));
                    }
                    var pointParameterSourceids = { pointId: dsO.pointId, parameterId: dsO.parameterId, dataSourceId: dsO.dataSourceId };
                    var seriesId = angular.copy(s);
                    if (chart.series[s].dataSourceObject.dataSourceId == 5) { //'mu'
                        seriesUpdateCalls.push(awsAthenaChartsFactory.getModelDataAndUpdateSeries(chart, seriesId, s, pointParameterSourceids, timeIntervals, chart.graphEnhanceOptions));
                    }
                    else if (chart.series[s].dataSourceObject.dataSourceId == 10) { // 'sro'
                        seriesUpdateCalls.push(awsAthenaChartsFactory.getSRODataAndUpdateSeries(chart, seriesId, s, pointParameterSourceids, timeIntervals, chart.graphEnhanceOptions));
                    }
                    else if (chart.series[s].dataSourceObject.dataSourceId == 14) { // 'kampagne'
                        seriesUpdateCalls.push(awsAthenaChartsFactory.getCampaignDataAndUpdateSeries(chart, seriesId, s, pointParameterSourceids, timeIntervals, chart.graphEnhanceOptions));
                    }
                }

                $q.all(seriesUpdateCalls).then(function (responses) {
                    deferred.resolve();
                });

                return deferred.promise;
            }
            function renderChartContainers() {
                //Prepare the stuff around the actual highchart object - ie. show/hide and popup etc.
                for (var c = 0; c < $scope.charts.length; c++) {
                    renderChartContainer($scope.charts[c], c);
                    //console.log($scope.pageState.charts[c]);
                }
            }
            function renderChartContainer(chart, index) {
                //Chart container and associated HighChart object - each aware of the other !
                var chartDivId = 'chart_container_' + index;//charts[indx].idx;
                var chartDiv = angular.element($("<div class='chart-rendered-holder' id='" + chartDivId + "' style= 'height: " + ($scope.compactHeight - 20).toString() + "px;'></div>"));
                // var chartDivHeader = angular.element($("<div id='Head_" + chartDivId + "' ng-show='enablePopUpHide'></div>"));
                var chartDivHolder = angular.element($('<md-content class="chart-complete-container" id="parent_chart_container_' + index + '"></md-content>'));


                // add title
                var title = angular.element($('<span class="chart-complete-container-title" ng-if="charts[' + index + '].visible === false">' + chart.chartOptions.title.text + '</span>'));
                //var title = angular.element($('<span class="chart-complete-container-title">' + chart.chartOptions.title.text + '</span>'));
                var angularCompiledTitle = $compile(title)($scope);
                chartDivHolder.append(angularCompiledTitle);

                // add hide button
                var hideButton = angular.element($('<md-button class="md-mini md-default chartButton" ng-click="toggleChart(' + index + ')"></md-button>'));
                //hideButton.append(angular.element($('<i class="material-icons">visibility</i>')));
                hideButton.append(angular.element($('<i class="material-icons" ng-if="charts[' + index + '].visible === false">visibility</i>')));
                hideButton.append(angular.element($('<i class="material-icons" ng-if="charts[' + index + '].visible === true">visibility_off</i>')));
                var angularCompiledHideButton = $compile(hideButton)($scope);
                chartDivHolder.append(angularCompiledHideButton);

                // add popup button
                var popupButton = angular.element($('<md-button class="md-mini md-default chartButton" ng-click="openPopupChart(' + index + ')"></md-button>'));
                popupButton.append(angular.element($('<i class="material-icons">add_to_queue</i>')));
                var angularCompiledPopupButton = $compile(popupButton)($scope);
                chartDivHolder.append(angularCompiledPopupButton);

                chartDivHolder.append(angular.element($('<br/>')));

                // add chart container
                chartDivHolder.append(chartDiv);

                // add new chart section to plot area
                angular.element($(".charts-container")).append(chartDivHolder);

                var newchart = Highcharts.chart(chartDivId, chart.chartOptions);
                newchart.InfId = index;
                chart.highchart = newchart;
            }

            function initCharts() {
                if (!$scope.mapDataEntities) {
                    initOneChart($scope.stationDataEntities.filter(function (s) { return s.checked; }));
                    $scope.compactHeight = 900;
                }
                else {
                    for (var i = 0; i < $scope.stationDataEntities.length; i++) {
                        if ($scope.stationDataEntities[i].checked) {
                            initChartStation($scope.stationDataEntities[i]);
                        }
                    }
                }
                return true;
            }
            function buildSeriesObjects(station) {
                var selectedDataEntities = [];
                selectedDataEntities.push(station);
                //todo  - get the associated/coupled dataEntities and add them to the array
                if (station.SroDataEntityId !== null) {
                    var coupledSRO = $scope.stationDataEntities.find(function (o) { return o.DataEntityId === station.SroDataEntityId; });
                    if (coupledSRO != null) selectedDataEntities.push(coupledSRO);
                    else $mdToast.show($mdToast.simple().textContent($translate.instant('Coupled_SRO_Not_Found : ') + station.SroDataEntityId).position('left top'));
                }
                if (station.ModelDataEntityId !== null) {
                    var coupledMU = $scope.stationDataEntities.find(function (o) { return o.DataEntityId === station.ModelDataEntityId; });
                    if (coupledMU != null) selectedDataEntities.push(coupledMU);
                    else $mdToast.show($mdToast.simple().textContent($translate.instant('Coupled_MU_Not_Found : ') + station.ModelDataEntityId).position('left top'));
                }
                if (station.CampaingDataEnityId !== null) {
                    var coupledCP = $scope.stationDataEntities.find(function (o) { return o.DataEntityId === station.CampaingDataEnityId; });
                    if (coupledCP != null) selectedDataEntities.push(coupledCP);
                    else $mdToast.show($mdToast.simple().textContent($translate.instant('Coupled_MU_Not_Found : ') + station.ModelDataEntityId).position('left top'));
                }

                return selectedDataEntities;
            }
            function getDataSourceObject(dataEntity, ScnearioNr, scenario) {
                var type = $scope.dataSources[dataEntity.dataSourcesIdx];
                var dataSourceObject = {
                    type: type.Name, dataSourceId: dataEntity.DataSourceId, scenarioNr: ScnearioNr, scenario: scenario,
                    pointId: dataEntity.PointId, parameterId: dataEntity.ParameterId, dataEntityId: dataEntity.DataEntityId,
                    athenaDerivedColumn: '', athenaColumn: '', conversionFactor: 1
                };
                return dataSourceObject;
            }

            function checkIsFlow(ParameterId) {
                if ([196, 236, 293].indexOf(ParameterId) > -1) return true;
                return false;
            }

            function initOneChart(selectedDataEntites) {
                var chartId = undefined;
                var newChart = undefined;
                var graphEnhanceOptions = awsAthenaChartsFactory.buildAutoEnhance(true, timeIntervals);

                //var selectedDataEntites = buildSeriesObjects(st);

                var chartOptions = awsAthenaChartsFactory.getChartOptions(graphEnhanceOptions, 'Multiple stations');
                chartOptions.xAxis.events.afterSetExtremes = function (event) {
                    afterSetExtremes(event);
                };
                var colorsPallete = awsAthenaChartsFactory.getColorPalletes(selectedDataEntites.length + 1);
                chartOptions.yAxis = [];
                newChart = awsAthenaChartsFactory.buildChart($scope.charts.length, timeIntervals.fromDate, timeIntervals.toDate);
                $scope.charts.push(newChart);
                newChart.chartOptions = angular.copy(chartOptions);
                var yAxis = -1;
                var plotParameters = [];
                for (var e = 0; e < selectedDataEntites.length; e++) {
                    //yAxis = e;
                    var selectedParameter = $scope.parameters.find(function (o) { return o.Id === selectedDataEntites[e].ParameterId; });
                    if (!selectedParameter) {
                        //If it is modeldata : dataSourceId=5, then replace various flows with "generic flow parameterId = 191
                        if (checkIsFlow(selectedDataEntites[e].ParameterId)) {
                            selectedParameter = $scope.parameters.find(function (o) { return o.Id === 191; });
                        }

                    }
                    var thisAxis = plotParameters.findIndex(function (o) {
                        return o.Id === selectedParameter.Id;
                    });
                    var colorsString = colorsPallete[e];
                    if (thisAxis == -1) {
                        yAxis++;
                        newChart.chartOptions.yAxis[yAxis] = awsAthenaChartsFactory.getYaxisChartOptions($scope.scaleYaxis, selectedParameter, yAxis, colorsString);
                        newChart.chartOptions.yAxis[yAxis].name = selectedParameter.Name;
                        plotParameters.push(selectedParameter);
                    } else yAxis = thisAxis;

                    //Add next series to newChart
                    var newSeries = buildSeries(newChart);
                    newSeries.dataSourceObject = getDataSourceObject(selectedDataEntites[e], 0);
                    //newSeries.opacity = 0.3;
                    newChart.chartOptions.series[newSeries.Id] = awsAthenaChartsFactory.getSeriesChartOptions(graphEnhanceOptions, selectedParameter, yAxis, colorsString);
                    //newChart.chartOptions.series[newSeries.Id].opacity = 0.5;
                    //newChart.chartOptions.series[newSeries.Id].fillOpacity = 0.5;
                    newChart.chartOptions.series[newSeries.Id].name += "," + selectedDataEntites[e].PointName + "," + $scope.dataSources[selectedDataEntites[e].dataSourcesIdx].Name;
                }
                return true;
            }


            function initChartStation(st) {
                var chartId = undefined;
                var newChart = undefined;
                var graphEnhanceOptions = awsAthenaChartsFactory.buildAutoEnhance(true, timeIntervals);
                var selectedDataEntites = buildSeriesObjects(st);

                var chartOptions = awsAthenaChartsFactory.getChartOptions(graphEnhanceOptions, st.PointName);
                chartOptions.xAxis.events.afterSetExtremes = function (event) {
                    afterSetExtremes(event);
                };
                var colorsPallete = awsAthenaChartsFactory.getColorPalletes(4);
                chartOptions.yAxis = [];
                newChart = awsAthenaChartsFactory.buildChart($scope.charts.length, timeIntervals.fromDate, timeIntervals.toDate);
                $scope.charts.push(newChart);
                newChart.chartOptions = angular.copy(chartOptions);
                var yAxis = 0;
                for (var e = 0; e < selectedDataEntites.length; e++) {
                    //yAxis = e;
                    var selectedParameter = $scope.parameters.find(function (o) { return o.Id === selectedDataEntites[e].ParameterId; });
                    var selectedDataSource = $scope.dataSources[selectedDataEntites[e].dataSourcesIdx];
                    if (!selectedParameter) {
                        //If it is modeldata : dataSourceId=5, then replace various flows with "generic flow parameterId = 191
                        if (checkIsFlow(selectedDataEntites[e].ParameterId)) {
                            selectedParameter = $scope.parameters.find(function (o) { return o.Id === 191; });
                        }

                    }
                    //Add next series to newChart
                    var newSeries = buildSeries(newChart);
                    //newSeries.opacity = 0.3;
                    newSeries.dataSourceObject = getDataSourceObject(selectedDataEntites[e], 0);
                    newChart.chartOptions.yAxis[yAxis] = awsAthenaChartsFactory.getYaxisChartOptions($scope.scaleYaxis, selectedParameter, yAxis, selectedDataSource.Color);
                    newChart.chartOptions.yAxis[yAxis].title.text = selectedParameter.Name;
                    newChart.chartOptions.series[newSeries.Id] = awsAthenaChartsFactory.getSeriesChartOptions(graphEnhanceOptions, selectedParameter, yAxis, selectedDataSource.Color);
                    //newChart.chartOptions.series[newSeries.Id].opacity = 0.5;
                    //newChart.chartOptions.series[newSeries.Id].fillOpacity = 0.5;
                    if (selectedDataEntites[e].Description.length > 0) newChart.chartOptions.series[newSeries.Id].name = selectedDataSource.Name + "," + selectedDataEntites[e].Tag;
                    else newChart.chartOptions.series[newSeries.Id].name = selectedDataSource.Name + "," + selectedDataEntites[e].Description;
                }
                return true;
            }
            function buildSeries(chart) {
                var idx = chart.series.length;
                var newSeries = {
                    Id: idx, parentChartId: chart.Id, Order: idx, dataSourceObject: undefined
                };
                chart.series.push(newSeries);
                return newSeries;
            }

            function destroyCharts(removeCurrentCharts) {
                for (var i = 0; i < Highcharts.charts.length; i++) {

                    if (!Highcharts.charts[i] || Highcharts.charts[i] === null) {
                        continue;
                    }
                    Highcharts.charts[i].destroy();
                }
                console.log('destroying charts');
                var chartsContainer = angular.element(document.querySelector('.charts-container'));
                if (chartsContainer) { chartsContainer.empty(); }
                if (removeCurrentCharts === true) {
                    $scope.charts = [];
                    $scope.activeplot = false;
                }

            }
            function getParameters() {
                var deferred = $q.defer();

                $http({
                    method: 'GET',
                    url: ngAuthSettings.API_URL + 'values/GetParametersFromList',
                    params: {
                        strParameters: ShowParameters.join()
                    }

                }).then(function successCallback(response) {
                    if (response.data) {
                        for (var i = 0; i < response.data.length; i++) {
                            var parameter = response.data[i];
                            $scope.parameters.push(parameter);
                        }
                        deferred.resolve($scope.parameters);
                    }

                }, function errorCallback(response) {

                });
                return deferred.promise;
            }

            $scope.getParameter = function (parameterId) {
                return $scope.parameters.find(function (o) { return o.Id === parameterId; });
            }

            function initMap() {
                var kmsusr = "InforMeticsTst";
                var kmspwd = "Hauser10";

                var baselayer = "";
                proj4.defs('EPSG:32633', '+proj=utm +zone=33 +ellps=GRS80 +units=m +no_defs');
                var crs = new L.Proj.CRS.TMS('EPSG:32632',
                    '+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs', [120000, 5900000, 1000000, 6500000], {
                        resolutions: [1638.4, 819.2, 409.6, 204.8, 102.4, 51.2, 25.6, 12.8, 6.4, 3.2, 1.6, 0.8, 0.4, 0.2, 0.1]
                    });


                var map = new L.Map('map', {
                    crs: crs
                });



                var ortofoto = L.tileLayer('http://{s}.services.kortforsyningen.dk/orto_foraar?login=' + kmsusr + '&password=' + kmspwd + '&request=GetTile&version=1.0.0&service=WMTS&Layer=orto_foraar&style=default&format=image/jpeg&TileMatrixSet=View1&TileMatrix={zoom}&TileRow={y}&TileCol={x}', {
                    attribution: 'Geodatastyrelsen',
                    continuousWorld: true,
                    maxZoom: 14,
                    zoom: function () {
                        var zoom = map.getZoom();
                        if (zoom < 10)
                            return 'L0' + zoom;
                        else
                            return 'L' + zoom;
                    }
                });

                var skaermkort = L.tileLayer('http://{s}.services.kortforsyningen.dk/topo_skaermkort?login=' + kmsusr + '&password=' + kmspwd + '&request=GetTile&version=1.0.0&service=WMTS&Layer=dtk_skaermkort&style=default&format=image/jpeg&TileMatrixSet=View1&TileMatrix={zoom}&TileRow={y}&TileCol={x}', {
                    attribution: 'Geodatastyrelsen',
                    continuousWorld: true,
                    maxZoom: 14,
                    zoom: function () {
                        var zoom = map.getZoom();
                        if (zoom < 10)
                            return 'L0' + zoom;
                        else
                            return 'L' + zoom;
                    }
                });

                var skaermkortgrey = L.tileLayer('http://{s}.services.kortforsyningen.dk/topo_skaermkort_daempet?login=' + kmsusr + '&password=' + kmspwd + '&request=GetTile&version=1.0.0&service=WMTS&Layer=dtk_skaermkort_daempet&style=default&format=image/jpeg&TileMatrixSet=View1&TileMatrix={zoom}&TileRow={y}&TileCol={x}', {
                    attribution: 'Geodatastyrelsen',
                    continuousWorld: true,
                    maxZoom: 14,
                    zoom: function () {
                        var zoom = map.getZoom();
                        if (zoom < 10)
                            return 'L0' + zoom;
                        else
                            return 'L' + zoom;
                    }
                }).addTo(map);


                var owsrootUrl = 'http://geoserver.vcs.informetics.com/geoserver/VCS/ows';
                var defaultParameters = {
                    service: 'WFS',
                    version: '2.0',
                    request: 'GetFeature',
                    typeName: 'VCS:msm_Weir_DMjan2019',
                    outputFormat: 'text/javascript',
                    format_options: 'callback:getJson'
                };

                //var parameters = L.Util.extend(defaultParameters);
                //var URL = owsrootUrl + L.Util.getParamString(parameters);
                ////Initialize empty layer
                //var weirs = L.geoJson(false, {
                //  coordsToLatLng: function (feature) {
                //    var point = proj4('EPSG:32632', 'WGS84', feature);
                //    return [point[1], point[0]];
                //  },
                //  style: function (feature) {
                //    return {
                //      stroke: true,
                //      fill : true,
                //      color: 'red',
                //      weight : 4,
                //      fillOpacity: 0.5
                //    };
                //  },
                //  onEachFeature: function (feature, layer) {
                //    if (feature.properties) {
                //      layer.bindPopup(Object.keys(feature.properties).map(function (k) {
                //        return k + ": " + feature.properties[k];
                //      }).join("<br />"), {
                //          maxHeight: 200
                //        });
                //    }
                //  }
                //});

                //var ajax = $.ajax({
                //  url: URL,
                //  dataType: 'jsonp',
                //  jsonpCallback: 'getJson',
                //  success: function (response) {
                //    weirs.addData(response);
                //  }
                //});

                //layersScope.overlays['Weirs'] = weirs;

                var geojsonMarkerOptions = {
                    radius: 3,
                    fillColor: "#ff7800",
                    color: "#000",
                    weight: 1,
                    opacity: 0.5,
                    fillOpacity: 0.8
                };
                var parameters2 = L.Util.extend({
                    service: 'WFS',
                    version: '2.0',
                    request: 'GetFeature',
                    typeName: 'VCS:msm_Node_DMjan2019',
                    outputFormat: 'text/javascript',
                    format_options: 'callback:getJson'
                });
                var URLnode = owsrootUrl + L.Util.getParamString(parameters2);
                //Initialize empty layer
                var nodes = L.geoJson(false, {
                    pointToLayer: function (feature, latlng) {
                        return L.circleMarker(latlng, geojsonMarkerOptions);
                    },
                    coordsToLatLng: function (feature) {
                        var point = proj4('EPSG:32632', 'WGS84', feature);
                        return [point[1], point[0]];
                    },
                    onEachFeature: function (feature, layer) {
                        if (feature.properties) {
                            layer.bindPopup(Object.keys(feature.properties).map(function (k) {
                                return k + ": " + feature.properties[k];
                            }).join("<br />"), {
                                    maxHeight: 200
                                });
                        }
                    }
                });

                $.ajax({
                    url: URLnode,
                    dataType: 'jsonp',
                    jsonpCallback: 'getJson',
                    success: function (response) {
                        nodes.addData(response);
                    }
                });

                layersScope.overlays['Nodes'] = nodes;

                defaultParameters.typeName = 'VCS:msm_Link_DMjan2019';

                var parameters3 = L.Util.extend(defaultParameters);
                var URLlink = owsrootUrl + L.Util.getParamString(parameters3);
                //Initialize empty layer
                var links = L.geoJson(false, {
                    coordsToLatLng: function (feature) {
                        var point = proj4('EPSG:32632', 'WGS84', feature);
                        return [point[1], point[0]];
                    },
                    style: function (feature) {
                        return {
                            stroke: true,
                            fill: true,
                            color: 'blue',
                            weight: 4,
                            fillOpacity: 0.5
                        };
                    },
                    onEachFeature: function (feature, layer) {
                        if (feature.properties) {
                            layer.bindPopup(Object.keys(feature.properties).map(function (k) {
                                return k + ": " + feature.properties[k];
                            }).join("<br />"), {
                                    maxHeight: 200
                                });
                        }
                    }
                });

                $.ajax({
                    url: URLlink,
                    dataType: 'jsonp',
                    jsonpCallback: 'getJson',
                    success: function (response) {
                        links.addData(response);
                    }
                });

                layersScope.overlays['Links'] = links;


                //var weirs = L.tileLayer.wms('http://geoserver.vcs.informetics.com/geoserver/VCS/wms?service=WMS&version=1.1.0&request=GetMap&layers=VCS:msm_Weir_DMjan2019', {
                //      service: 'WMS',
                //      transparent: true,
                //      servicename: 'topo_geo',
                //     // layers: ['Vandloebsbred', 'Vandloeb_brudt', 'Vandloeb', 'Bassin', 'Soe'],
                //      format: 'image/png',
                //      attribution: "VCS",
                //      continuousWorld: true,
                //      minZoom: 4
                //  });


                layersScope.baselayers = {
                    "Skærmkort": skaermkort,
                    "Dæmpet": skaermkortgrey,
                    "Flyfoto": ortofoto
                };

                layerControl = L.control.layers(layersScope.baselayers, layersScope.overlays).addTo(map);
                getMapPoints(map);
                var lat, lng;
                var mouseUTM, mouseUTM33;
                map.addEventListener('mousemove', function (ev) {
                    //mouseUTM = proj4('WGS84', 'EPSG:32632', [ev.latlng.lng, ev.latlng.lat]);
                    //utmonmap.textContent = "UTM-32: " + mouseUTM[0].toFixed(1) + "m E, " + mouseUTM[1].toFixed(1) + "m N";
                    mouseUTM = proj4('WGS84', 'EPSG:32632', [ev.latlng.lng, ev.latlng.lat]);
                    mouseUTM33 = proj4('WGS84', 'EPSG:32633', [ev.latlng.lng, ev.latlng.lat]);
                    utmonmap.textContent = "UTM-32N: " + mouseUTM[0].toFixed(1) + "m E, " + mouseUTM[1].toFixed(1) + "m N";
                    //                                        "UTM-33N: " + mouseUTM33[0].toFixed(1) + "m E, " + mouseUTM33[1].toFixed(1) + "m N";
                    geoonmap.textContent = "WGS-84: " + ev.latlng.lat.toFixed(3) + "°N, " + ev.latlng.lng.toFixed(3) + "°E";
                    //console.log("pos " + mouseUTM);
                });

                map.addEventListener('dblclick', function (e) {
                    var url = "http://dawa.aws.dk/adgangsadresser/reverse?x=" + e.latlng.lng.toString() + "&y=" + e.latlng.lat.toString();
                    $.ajax({
                        url: url,
                        dataType: "jsonp",
                        success: function success(data) {
                            var popup = L.popup();
                            popup.setLatLng(new L.LatLng(data.adgangspunkt.koordinater[1], data.adgangspunkt.koordinater[0])).setContent(data.vejstykke.navn + " " + data.husnr + "<br>" + (data.supplerendebynavn ? data.supplerendebynavn + "<br>" : "") + data.postnummer.nr + " " + data.postnummer.navn).openOn(map);
                        }
                    });
                });



                map.on('load', function (e) {
                    setTimeout(function () { map.invalidateSize(); }, 500);

                });
                map.setView(L.latLng(55.4, 10.4), 5);
                map.on('moveend', function (e) {
                    checkInView();
                });

                return map;


            }

            $scope.nodeJsonData = undefined;
            function initJsonData() {
                var deferred = $q.defer();
                $.when(
                    $.getJSON('shapes/msm_Node_DMjan2019.json', function (data) {
                        $scope.nodeJsonData = data;
                    })
                ).then(function () {
                    deferred.resolve();
                }, function errorCallback(err) {
                    console.log(err);
                    deferred.reject(err);
                });
                return deferred.promise;
            }

            // Gets the points from the api and populates the map
            function getMapPoints(mymap) {
                var dataSourceIds = [];
                for (var i = 0; i < $scope.dataSources.length; i++) {
                    dataSourceIds.push($scope.dataSources[i].dataSourceId);
                    markerLayers[dataSourceIds[i]] = [];
                }
                $http({
                    method: 'GET',
                    //url: API_URL + 'values/GetStationsMetadata',
                    //url: API_URL + 'values/GetStationsMetadataOnly',
                    url: ngAuthSettings.API_URL + 'values/GetStationDataEntities',
                    params: {
                        parametersCsv: ShowParameters.join(),
                        dataSourcesCsv: dataSourceIds.toString()
                    }
                })
                    .then(function successCallback(response) {
                        if (response.data) {
                            for (var i = 0; i < response.data.length; i++) {
                                var dataEntity = response.data[i];
                                dataEntity.checked = false;
                                dataEntity.visible = true;

                                var dataSourceObject = $scope.dataSources.find(function (o) { return o.dataSourceId === dataEntity.DataSourceId; });
                                dataEntity.dataSourcesIdx = dataSourceObject.id;
                                dataEntity.Geo = [dataEntity.Latitude, dataEntity.Longitude];
                                var markerId = dataEntity.DataSourceId + '~' + dataEntity.DataEntityId;
                                $scope.markers[markerId] = {
                                    id: dataEntity.DataEntityId,
                                    idx: markerId,
                                    name: dataEntity.PointName,
                                    type: dataEntity.DataSourceId,
                                    lat: dataEntity.Latitude,
                                    lng: dataEntity.Longitude,
                                    icon: IconsNew[dataEntity.DataSourceId],
                                    getMessageScope: function () { return $scope; },
                                    message: dataEntity.PointName, compileMessage: true
                                };
                                var newMarker = L.marker($scope.markers[markerId], { icon: $scope.markers[markerId].icon, opacity: 0.8, riseOnHover: true });
                                newMarker.id = markerId;
                                newMarker.on('click', function (data) {
                                    $scope.selectElement(this.id);

                                });
                                newMarker.bindPopup('<h5>' + dataEntity.PointName + '</h5><br>' + dataEntity.Description + '<br>' + dataEntity.Tag + '<br>(PointId: ' + dataEntity.PointId + ", Parameterid: " + dataEntity.ParameterId + ')');
                                newMarker.on('mouseover', function (e) {
                                    this.openPopup();
                                });
                                newMarker.on('mouseout', function (e) {
                                    this.closePopup();
                                });
                                dataEntity.highLight = L.circleMarker(dataEntity.Geo,
                                    { radius: 23, stroke: true, color: '#66ff33', weight: 3, opacity: 0.7 });
                                dataEntity.marker = newMarker;
                                $scope.stationDataEntities.push(dataEntity);
                                $scope.markers[markerId]._leafletMarker = newMarker;
                                markerLayers[dataEntity.DataSourceId].push(newMarker);
                            }
                            for (var i = 0; i < $scope.dataSources.length; i++) {
                                var dataSourceId = $scope.dataSources[i].dataSourceId;
                                //var newLayerGroup = L.layerGroup(markerLayers[dataSourceId]).addTo(mymap);
                                $scope.dataSources[i].layerGroup = L.layerGroup(markerLayers[dataSourceId]).addTo(mymap);
                                layerControl.addOverlay($scope.dataSources[i].layerGroup, $scope.dataSources[i].Name + renderLegendIconHtml(IconsNew[dataSourceId]));
                                //if (pointType.Id == $scope.defaultPointType) { newLayerGroup.addTo(mymap); }
                            }
                            console.log($scope.stationDataEntities);
                        }
                    }
                        , function errorCallback(response) {
                            $mdToast.show($mdToast.simple().textContent("Error getting station data...").position('right bottom'));
                        });
            }


            function checkInView() {
                var latlngbox = map.getBounds();
                var numVis = 0;
                if ($scope.stationDataEntities != null) {
                    for (var i = 0; i < $scope.stationDataEntities.length; i++) {
                        if ($scope.stationDataEntities[i].Latitude !== null) {
                            //var latlng = [$scope.stationDataEntities[i].Latitude, $scope.stationDataEntities[i].Longitude];
                            $scope.stationDataEntities[i].visible = latlngbox.contains($scope.stationDataEntities[i].Geo);
                            if ($scope.stationDataEntities[i].visible) numVis++;
                        }
                    }
                }
                //console.log("Now visible : " + numVis);
                $scope.$apply();
            };

            $scope.zoomToPoint = function (point) {
                if (point) {
                    map.setView(point.Geo, 11);
                }
            }

            $scope.selectedPointChanged = function (point) {
                for (var h = 0; h < highLightedPoints.length; h++) {
                    var Options = highLightedPoints[h].options;
                    Options.opacity = Options.opacity - 0.2;
                    Options.color = 'orange';
                    highLightedPoints[h].setStyle(Options);
                    highLightedPoints[h].setRadius(highLightedPoints[h].options.radius - 1);
                    //highLightedPoints[h].redraw();

                    if (Options.opacity < 0.1) {
                        map.removeLayer(highLightedPoints[h]);  //from map
                        highLightedPoints.splice(h, 1); //remove from array
                    }
                }
                if (point) {
                    $scope.selectedPointIndex = parseInt(point.Index);
                    map.setView(point.Geo, 11);
                    highlight = L.circleMarker(point.Geo,
                        { radius: 10, stroke: true, color: 'green', fillColor: "#FFFF00", weight: 5, opacity: 0.9 }).bindPopup(point.Code).addTo(map);
                    //markers.addLayer(marker);
                    highLightedPoints.push(highlight);
                    $scope.isMapOpen = true;
                }
                else { //First time
                    map.setView($scope.ModelMapData[0].Geo, 6);
                }
            };


            function renderLegendIconHtml(icon) {
                var spanStr = '<span class="half ' + icon.options.className + '">' + icon.options.html + '</span>'
                return spanStr;
            }

            function afterSetExtremes(event) { // sync charts zoom
                if (!$scope.allowZoomEvent) {
                    return;
                }
                if (event.trigger === undefined) {
                    return;
                }
                var eventChart = event.target.chart;
                var parentChartZoom = (event.callother === undefined);
                if (!eventChart) {
                    throw new Error("afterSetExtremes: event chart not found.");
                    //return;
                }
                if (!(event.min)) {
                    return;
                }
                if (parentChartZoom && event.trigger === 'zoom') {
                    var fromMSecs = event.min;
                    var toMSecs = event.max;
                    var isZoomed = true;
                    if (event.userMax === undefined && event.userMin === undefined) {
                        isZoomed = false;
                        fromMSecs = $scope.fromDate.getTime();
                        toMSecs = $scope.toDate.getTime();
                    }
                    // Trigger other chart zooms
                    for (var i = 0; i < $scope.charts.length; i++) {
                        $scope.charts[i].highchart.xAxis[0].setExtremes(fromMSecs, toMSecs, true, false, { callother: false });
                    }

                    $scope.showLoading = true;
                    var xMin = new Date(fromMSecs);
                    var xMax = new Date(toMSecs);
                    var timeIntervals = { fromDate: $scope.fromDate, toDate: $scope.toDate };
                    var newTimeIntervals = { fromDate: xMin, toDate: xMax };
                    var graphEnhanceOptions = awsAthenaChartsFactory.buildAutoEnhance(true, newTimeIntervals);

                    var calls = buildAllUpdateChartCalls(timeIntervals, $scope.charts, newTimeIntervals, isZoomed);
                    $q.all(calls).then(function (responses) {
                        $scope.allowZoomEvent = true;
                        $scope.activeplot = true;
                        $scope.showLoading = false;
                    }, function (err) {
                        $scope.allowZoomEvent = true;
                        $scope.activeplot = true;
                        $scope.showLoading = false;
                    });


                }
            }


            IconsNew = {
                '14': L.divIcon({
                    iconSize: new L.Point(37, 45),
                    className: 'awesome-marker-icon-green awesome-marker leaflet-zoom-animated leaflet-clickable',
                    html: '<i class=" glyphicon glyphicon-share-alt  icon-white"></i>'
                }),
                '5': L.divIcon({
                    iconSize: new L.Point(37, 45),
                    className: 'awesome-marker-icon-red awesome-marker leaflet-zoom-animated leaflet-clickable',
                    html: '<i class=" glyphicon glyphicon-dashboard  icon-white"></i>'
                }),
                '10': L.divIcon({
                    iconSize: new L.Point(37, 45),
                    className: 'awesome-marker-icon-blue awesome-marker leaflet-zoom-animated leaflet-clickable',
                    html: '<i class=" glyphicon glyphicon-tint  icon-white"></i>'
                }),
                '1': L.divIcon({
                    iconSize: new L.Point(37, 45),
                    className: 'awesome-marker-icon-beige awesome-marker leaflet-zoom-animated leaflet-clickable',
                    html: '<i class=" glyphicon glyphicon-menu-hamburger  icon-white"></i>'
                }),
                '2': L.divIcon({
                    iconSize: new L.Point(37, 45),
                    className: 'awesome-marker-icon-pink awesome-marker leaflet-zoom-animated leaflet-clickable',
                    html: '<i class=" glyphicon glyphicon-export  icon-white"></i>'
                }),
                '6': L.divIcon({
                    iconSize: new L.Point(37, 45),
                    className: 'awesome-marker-icon-orange awesome-marker leaflet-zoom-animated leaflet-clickable',
                    html: '<i class=" glyphicon glyphicon-transfer  icon-white"></i>'
                }),
                '7': L.divIcon({
                    iconSize: new L.Point(37, 45),
                    className: 'awesome-marker-icon-gray awesome-marker leaflet-zoom-animated leaflet-clickable',
                    html: '<i class=" glyphicon glyphicon-cloud  icon-white"></i>'
                }),
                '8': L.divIcon({
                    iconSize: new L.Point(37, 45),
                    className: 'awesome-marker-icon-green awesome-marker leaflet-zoom-animated leaflet-clickable',
                    html: '<i class=" glyphicon glyphicon-dashboard  icon-white"></i>'
                }),
                '9': L.divIcon({
                    iconSize: new L.Point(37, 45),
                    className: 'awesome-marker-icon-orange awesome-marker leaflet-zoom-animated leaflet-clickable',
                    html: '<i class=" glyphicon glyphicon-eye-close  icon-white"></i>'
                }),
                '12': L.divIcon({
                    iconSize: new L.Point(37, 45),
                    className: 'awesome-marker-icon-orange awesome-marker leaflet-zoom-animated leaflet-clickable',
                    html: '<i class=" glyphicon glyphicon-record icon-white"></i>'
                })
            };

            $scope.toggleChart = function (chartIndex) {
                if ($('#chart_container_' + chartIndex).is(":visible")) {
                    $('#chart_container_' + chartIndex).hide();
                    $scope.charts[chartIndex].visible = false;
                } else {
                    $('#chart_container_' + chartIndex).show();
                    $scope.charts[chartIndex].visible = true;
                }
            };

            $scope.openPopupChart = function (chartIndex) {
                if ($('#chart_container_' + chartIndex).is(":visible")) {
                    $('#chart_container_' + chartIndex).hide();
                    $scope.charts[chartIndex].visible = false;
                }
                var chart = $scope.charts[chartIndex];
                var newChartObject = angular.copy(chart);
                console.log(newChartObject);
                newChartObject.highchart = {};
                localStorageService.set('popup-chart', {
                    chartObject: newChartObject, timeIntervals: timeIntervals
                });
                $window.open("/#!/chart", "_blank", 'width=1200,height=420');
            };
        }
    ]);
