'use strict';

var Highcharts = require("highcharts");
var proj4 = require("proj4").default;
var moment = require('moment');

angular.module('mapsApp')
    .controller('vevaSeeDataCtrl', ['$http', '$scope', '$q', '$timeout', '$mdMedia', '$mdBottomSheet', '$mdToast', '$interval', '$mdSidenav', '$mdDialog', '$window', '$location', 'authFactory', 'localStorageService', '$filter', '$translate', 'ChartDefaultsService', 'ngAuthSettings', 'vevaFactory',
        function ($http, $scope, $q, $timeout, $mdMedia, $mdBottomSheet, $mdToast, $interval, $mdSidenav, $mdDialog, $window, $location, authFactory, localStorageService, $filter, $translate, ChartDefaultsService, ngAuthSettings, vevaFactory) {
            var dataArrayGroup = [], layerIndex = 0, numberOfFinishedRequests = 0, numberOfTotalRequests = 2; var dataArrayGroupCoordinates = []; var toggleCanvas = false;
            var reference, mouseClickEvent = {}, mouseClickLayer = [];
            //$scope.loggedin = true;
            var mouseClickLayerIndex = 0;
            var radarLayer = [];
            var map, gauge3, transformfactor;
            if (!authFactory.authentication.isAuth) {
                $location.path('/login');
                return;
            }
            $scope.dataSources = [];

            $scope.accumulate = [{ Minutes: 1, Name: '1 minut' }, { Minutes: 30, Name: '30 minutter' }];
            $scope.accumulateMinutes = 1;
            var pageChart;
            var DataLayerExtent = [250000, 5900000, 1100000, 6600000]; 	// EPSG:32632 projection (UTM 32N - WGS84)

            $scope.coverages = [{ Id: 1, Name: 'Vejrradar og SVK' }];
            $scope.toDate = new Date(); //2020-10-04T06:00
            $scope.toDate.setSeconds(0);
            $scope.toDate.setMilliseconds(0);
            $scope.fromDate = new Date();
            $scope.fromDate.setDate($scope.toDate.getDate());
            $scope.fromDate.setSeconds(0);
            $scope.fromDate.setMilliseconds(0);

            $scope.stepLength = 1;
            $scope.fromDate.setTime($scope.fromDate.getTime() - 20 * $scope.stepLength * 60000);
            //$scope.fromDate = new Date(2000, 0, 1, 0, 0, 0, 0);
            $scope.mFromDate = moment($scope.fromDate);
            updateHoursMinutes();
            //$scope.fromHours.setHours($scope.fromDate.getHours());

            var crs_utm32 = ['EPSG:25832', '+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs'];
            proj4.defs(crs_utm32[0], crs_utm32[1]);

            proj4.defs('EPSG:32632', '+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs');
            proj4.defs('EPSG:5799', '+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs');
            proj4.defs('WGS84', "+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees");


            $scope.mapReady = false;
            $scope.layersState = {};
            $scope.pointTypes = {};
            $scope.parameterCollections = {};
            $scope.parameters = {};
            $scope.chartParameters = {};
            $scope.currentMarker = {};
            $scope.elevation = 3;
            $scope.rainEvents = {};
            $scope.autoscaleYaxis = true;
            $scope.timeSeriesLoding = false;
            $scope.currentParameter = {};
            $scope.layers = { baselayers: {} };
            $scope.chartConfig = {};
            $scope.tableHeaders = {};
            $scope.initedChartResults = 0;
            $scope.allChartsInit = false;
            $scope.autoscaleYaxis = false;
            $scope.plotActive = false;
            $scope.dataSelected = false;
            $scope.isSideNavOpen = true;
            $scope.isPlotOpen = false;
            $scope.isInfoOpen = false;
            $scope.isForecastOpen = false;
            $scope.AnimateStep = 0;
            $scope.markers = {};
            $scope.driftmsg = "no status";
            $scope.useAccumulation = false;
            $scope.showLoading = false;
            var step = 0;
            $scope.nsteps = 15;
            var lookforBase = false;

            var utcDateTime = new Date();
            var firstDate = new Date(); //2017, 8, 17, 11, 40, 0, 0);//(););
            var showValuesCanvas = false;
            var commentsON = false;
            firstDate.setSeconds(0);
            firstDate.setMilliseconds(0);
            $scope.animateTime = { toDate: new Date(firstDate), maxDate: new Date() };
            $scope.eventdaysback = 360;
            $scope.eventmindepth = Number(0);
            $scope.eventminduration = Number(0);
            $scope.greaterThan = function (prop, val) {
                return function (item) {
                    return Number(item[prop]) > val;
                };
            };
            $scope.mapstate = {};
            $scope.mapstate.radar_dg = {};
            $scope.dateText = "";
            $scope.timeText = "--:--";
            $scope.fabT = {};
            $scope.fabT.hidden = false;
            $scope.fabT.isOpen = false;
            $scope.fabT.hover = false;

            $scope.img = {};
            var imgoptions = { "opacity": 0.5, "errorOverlayUrl" : 'err' };
            $scope.updateImage = function () {
                SetImage();
            }
            $scope.imgopacity = 50;

            $scope.updateOpacity = function () {
                for (var i = 0; i < $scope.dataSources.length; i++) {
                    if ($scope.dataSources[i].show) {
                        $scope.dataSources[i].img.setOpacity(1 - $scope.imgopacity / 100);
                    }
                }

            }

            angular.element(document).ready(function () {
                vevaFactory.getAvailableDataSources()
                    .then(function successCallback(data) {
                        var okdata = [];
                        for (var i = 0; i < data.length; i++) {
                            if (data[i].identifier.indexOf("Bias") !== -1) {
                                data[i].active = false;
                            };
                            if (data[i].identifier.indexOf("min") !== -1) {
                                data[i].active = false;
                            };
                            if (data[i].active) {
                                data[i].timespace = true
                                okdata.push(data[i]);
                            }
                        }
                        var dmi_komposit = { id: 102, active: true, name: 'DMI Komposit', dt: 1, elevations: null, baseURL: 'veva-processed-files/plots/dmi/interpolated', epsg: '32632', timespace: false, identifier: 'dmi_interpolated', points: [{ x: 7.4693, y: 54.1384 }, { x: 15.7508, y: 57.922 }] };
                        okdata.push(dmi_komposit);
                        for (var i = 0; i < okdata.length; i++) {
                            var Accumulated = data.find(function (o) { return (o.id === okdata[i].id && o.epsg === '32632') && o.identifier.indexOf("30min") !== -1 });
                            if (Accumulated && Accumulated.identifier.indexOf("30min") !== -1) {
                                okdata[i].Accumulated = true;
                                okdata[i].AccumulatedPath = Accumulated.baseURL;
                                okdata[i].AccumulatedFileName = Accumulated.identifier;
                                okdata[i].altUrl = getUrl(okdata[i], new Date(2000, 0, 1, 0, 0, 0, 0));
                            }
                            else okdata[i].Accumulated = false;
                            okdata[i].show = false;
                        }


                        $scope.dataSources = okdata;
                        //$scope.dataSources[0].timespace = false;
                        $scope.selectedDataSource = $scope.dataSources[0];
                        $scope.selectedDataSourceId = $scope.selectedDataSource.id;
                        //$scope.selectedDataSourceId.show = false;
                        $scope.mapstate = initMap();

                    })
                    .catch(function (msg) {
                        $scope.Status_info = msg;
                    });
                //$scope.mapstate.invalidateSize();
            });


            function initMap() {

                // We do this as we want the same attributions for all layers
                var myAttributionText = '&copy; <a target="_blank" href="https://download.kortforsyningen.dk/content/vilk%C3%A5r-og-betingelser">Styrelsen for Dataforsyning og Effektivisering</a>';
                var USERNAME = 'Dryp'
                var password = 'Sundkaj72tv.'
                var kftoken = "0c7acaa8b3b4efd4dee5ad52f96cc15e"
                console.log('In init map');
                if (window.navigator.userAgent.indexOf('Chrome') > -1) {
                    var originalInitTile = L.GridLayer.prototype._initTile;
                    L.GridLayer.include({
                        _initTile: function (tile) {
                            originalInitTile.call(this, tile);
                            var tileSize = this.getTileSize();
                            tile.style.width = tileSize.x + 1 + 'px';
                            tile.style.height = tileSize.y + 1 + 'px';
                        }
                    });
                }
                // create a red polyline from an array of LatLng points
                var latlngsUTM32 = [
                    [55.7439847, 8.0442575],
                    [55.5207928, 16.4516727]
                ];

                var polyline = L.polyline(latlngsUTM32, { color: 'red' }); //.addTo(map);



                var crs = new L.Proj.CRS(crs_utm32[0], crs_utm32[1], {
                    resolutions: [1638.4, 819.2, 409.6, 204.8, 102.4, 51.2, 25.6, 12.8, 6.4, 3.2, 1.6, 0.8, 0.4, 0.2]
                });


                // Make the map object using the custom projection
                const mapDiv = document.getElementById("map");
                const map = new L.Map(mapDiv, {
                    crs: crs,
                    center: [56, 11], // Set center location
                    zoom: 3, // Set zoom level
                    fullscreenControl: true,
                    minzoom: 0,
                    maxzoom: 14
                });

                const resizeObserver = new ResizeObserver(() => {
                    map.invalidateSize();
                });

                resizeObserver.observe(mapDiv);

                var topoDF = L.tileLayer.wms('https://api.dataforsyningen.dk/topo_skaermkort_DAF?token=0c7acaa8b3b4efd4dee5ad52f96cc15e', {
                    layers: 'dtk_skaermkort',
                    format: 'image/png',
                    attribution: myAttributionText
                });

                var greyWMSlayer = new L.tileLayer.wms('https://services.datafordeler.dk/Dkskaermkort/topo_skaermkort/1.0.0/wms?password=Jotu*5jellefikadt&username=LCTNUGNNHG', {
                    layers: 'dtk_skaermkort_daempet',
                    format: 'image/png',
                    maxZoom: 16,
                    minZoom: 0,
                    transparent: 'TRUE',
                    //getMatrix: getTileMatrix,
                    attribution: '&copy; Styrelsen for Dataforsyning og Effektivisering'
                }).addTo(map);

                var dhm_05 = new L.tileLayer.wms('https://services.datafordeler.dk/DHMNedboer/dhm/1.0.0/WMS?password=Jotu*5jellefikadt&username=LCTNUGNNHG', {
                    layers: 'dhm_kurve_0_5_m',
                    format: 'image/png',
                    styles: "",
                    transparent: 'TRUE',
                    //getMatrix: getTileMatrix,
                    //attribution: '&copy; Styrelsen for Dataforsyning og Effektivisering'
                });

                //EPSG: 25832
                var utmX0 = 400000; // - 970000 mE
                var utmY0 = 6000000; /// mN
                var step = 10000; // m
                var numYs = (400000 / step) + 2;

                var length = 500000; //m

                var DKGridMains = [];

                for (var i = 0; i < numYs; i++) {
                    var utmX = utmX0;
                    var utmY = utmY0 + i * step;
                    var latlngs = [];

                    latlngs = proj4('EPSG:32632', 'WGS84', [utmX + length, utmY]);
                    DKGridMains.push([latlngs[1], latlngs[0]]);
                    latlngs = proj4('EPSG:32632', 'WGS84', [utmX, utmY]);
                    DKGridMains.push([latlngs[1], latlngs[0]]);
                    latlngs = proj4('EPSG:32632', 'WGS84', [utmX, utmY + step]);
                    DKGridMains.push([latlngs[1], latlngs[0]]);


                }
                var polylineDKGridMains = L.polyline(DKGridMains, { color: 'red' });

                var overLays = {
                    //   u31Grid: u31Grid,
                    //   u32Grid: u32Grid
                    "DKN_10km_ETRS89": polylineDKGridMains,
                    "TestLine": polyline
                }




                // var imageBounds = [[46.966635, 7.415942], [46.998849, 7.470108]];
                //imageBounds = [[DataLayerExtent[0], DataLayerExtent[1]], [DataLayerExtent[2], DataLayerExtent[3]]];

                //var imageUrl = getUrl($scope.fromDate); //  $scope.selectedDataSource.baseURL; //"https://veva-processed-files.s3.eu-west-1.amazonaws.com/plots/quality/Quality_WR_SVK.png";
                //imageBounds = [[53.1904695, 5.2575562], [59.1126005, 19.5057463]];
                //var imageBounds = [[53.1904695, 4.7575562], [59.1126005, 19.0057463]];


                //$scope.img = L.imageOverlay(imageUrl, imageBounds, imgoptions).addTo(map);
                //var LL = L.latLng($scope.selectedDataSource.points[0].y, $scope.selectedDataSource.points[0].x);
                //var UR = L.latLng($scope.selectedDataSource.points[1].y, $scope.selectedDataSource.points[1].x);
                //var imageBounds = L.latLngBounds(LL, UR);
                //$scope.img.setBounds(imageBounds);
                $scope.mapReady = true;

                //var opacitySlider = new L.Control.opacitySlider();
                //map.addControl(opacitySlider);
                //opacitySlider.setOpacityLayer(img);
                // Define layer groups for layer control

                var baseLayers = {
                    "Skærmkort": topoDF,
                    //  "StreetMap": streetmap,
                    "Dæmpet": greyWMSlayer,
                    "dhm_05": dhm_05
                    //  "OSM": osmLayer                    
                };

                // Add layer control to map
                L.control.layers(baseLayers, overLays).addTo(map);

                // Add scale line to map
                L.control.scale({ imperial: false }).addTo(map); // disable feet units

                var mouseUTM;
                var mouseDVR90;
                var dvronmap = document.getElementById("dvr_onmap");
                var geoonmap = document.getElementById("geo_onmap");
                map.addEventListener('mousemove', function (ev) {
                    mouseDVR90 = proj4('WGS84', 'EPSG:32632', [ev.latlng.lng, ev.latlng.lat]);
                    dvronmap.textContent = "UTM32: " + mouseDVR90[0].toFixed(1) + "m E, " + mouseDVR90[1].toFixed(1) + "m N";
                    geoonmap.textContent = "WGS-84: " + ev.latlng.lat.toFixed(3) + "°N, " + ev.latlng.lng.toFixed(3) + "°E";
                }, { passive: true });

                map.on('load', function (e) {
                    console.log("about to .. re-inititialising map");
                    setTimeout(function () { map.invalidateSize(); console.log("re inititialising map"); }, 500);
                });


                return map;

            }


            //$('#main-navigation').slideUp();
            $scope.animate = animate;
            $scope.buttonPlayPress = buttonPlayPress;
            $scope.buttonSlowPress = buttonSlowPress;
            $scope.buttonFastPress = buttonFastPress;
            $scope.buttonStopPress = buttonStopPress;
            $scope.buttonBackPress = buttonBackPress;
            $scope.buttonPlotCanvasPress = buttonPlotCanvasPress;
            $scope.buttonForwardPress = buttonForwardPress;
            $scope.swapDirection = swapDirection;
            $scope.newStartDateTime = NewStartDateTime;
            $scope.sidste30min = true;
            $scope.direction = true;




            var controlNameElement = document.getElementById("control_text");
            var fieldNameElement = document.getElementById("time_text");
            var timeonmap = document.getElementById("time_onmap");
            var dvronmap = document.getElementById("dvr_onmap");
            var geoonmap = document.getElementById("geo_onmap");

            var linkElement = document.getElementById("link_text");
            var dateElement = document.getElementById("date_text");
            var state = 'stop';
            var speed = 600;
            var direction = 1;
            var datetime = new Date(firstDate);
            var firstload = 1;
            SetImage();

            function swapDirection() {
                direction = direction * -1;
            }

            $scope.setHourDateTime = function () {
                var m = moment($scope.fromDate);
                m.set({ hour: $scope.fromHours, second: 0, millisecond: 0 }); // UTCHours($scope.fromHours, $scope.fromMinutes, 0);
                if ($scope.fromHours == 24) {
                    $scope.fromHours = 0;
                }
                if ($scope.fromHours == -1) {
                    $scope.fromHours = 23;
                }
                $scope.fromDate = m.toDate();
                SetImage();
            }

            $scope.setMinsDateTime = function () {
                var m = moment($scope.fromDate);
                m.set({ minute: $scope.fromMins, second: 0, millisecond: 0 }); // UTCHours($scope.fromHours, $scope.fromMinutes, 0);
                if ($scope.fromMins == 60) {
                    $scope.fromMins = 0;
                }
                if ($scope.fromMins == -1) {
                    $scope.fromMins = 59;
                }
                $scope.fromDate = m.toDate();
                SetImage();
            }

            function NewStartDateTime() {
                //datetime = new Date(Date.parse($scope.animateTime.toDate));
                var m = moment($scope.fromDate);
                m.set({ hour: $scope.fromHours, minute: $scope.fromMinutes, second: 0, millisecond: 0 }); // UTCHours($scope.fromHours, $scope.fromMinutes, 0);
                $scope.fromDate = m.toDate();
                SetImage();
            }

            $scope.dvr_onmap = function () {
                console.log($scope.fromHours);
                console.log($scope.fromMins);
                if ($scope.fromHours == -1) {
                    $scope.fromHours = 23;
                    $scope.fromDate.setHours($scope.fromDate.getHours() - 24);
                }
                if ($scope.fromHours == 24) {
                    $scope.fromHours = 0;
                    $scope.fromDate.setHours($scope.fromDate.getHours() + 24);
                }
                if ($scope.fromMins == -1) {
                    $scope.fromMins = 59;
                    $scope.fromHours = $scope.fromHours - 1;
                    $scope.fromDate.setHours($scope.fromDate.getHours() - 1);
                }
                if ($scope.fromMins == 60) {
                    $scope.fromMins = 0;
                    $scope.fromHours = $scope.fromHours + 1;
                    $scope.fromDate.setHours($scope.fromDate.getHours() + 1);
                }

                $scope.fromDate.setHours($scope.fromHours); //, $scope.minutes, 0
                $scope.fromDate.setMinutes($scope.fromMins); //, $scope.minutes, 0
                SetImage();
                //$scope.fromDate.setTime($scope.fromDate.getTime());

            }

            function updateHoursMinutes() {
                $scope.fromHours = $scope.fromDate.getHours();
                $scope.fromMins = $scope.fromDate.getMinutes();
            }

            function buttonBackPress() {
                console.log("button back invoked.");
                state = 'stop';
                $scope.fromDate.setTime($scope.fromDate.getTime() - $scope.stepLength * 60000);
                updateHoursMinutes();
                SetImage();
            }

            function buttonForwardPress() {
                console.log("button forward invoked.");
                state = 'stop';
                $scope.fromDate.setTime($scope.fromDate.getTime() + $scope.stepLength * 60000);
                updateHoursMinutes();
                SetImage();
            }

            function buttonFastPress() {
                // clearInterval(timer);
                state = 'stop';
                speed = speed - 150;
                // state = 'play'
                animate();
            }
            function buttonSlowPress() {
                // clearInterval(timer);
                state = 'stop';
                speed = speed + 150;
                animate();
            }
            function buttonPlotCanvasPress(eventDate) {
                console.log("Test")
                console.log("Event akk :" + eventDate);
                var fromDate = new Date();
                var MinutesBack = 5;
                fromDate.setTime(datetime.getTime() - MinutesBack * 60 * 1000);
                requestRadarDataSquare(fromDate, datetime)
                toggleCanvas = true;
            }
            function getCurrentTimeinStrFormat() {
                var currentDate = new Date(Date.now())
                var datestring = currentDate.toLocaleString("en-gb")
                var datestringchars = datestring.split("")
                return datestringchars[6] + datestringchars[7] + datestringchars[8] + datestringchars[9] + '-' + datestringchars[3] + datestringchars[4] + '-' + datestringchars[0] + datestringchars[1] + ' ' + datestringchars[12] + datestringchars[13] + datestringchars[14] + datestringchars[15] + datestringchars[16] + datestringchars[17] + datestringchars[18] + datestringchars[19]
            }
            function getPrevious24hTimeinStrFormat() {
                var currentDate = new Date(Date.now())
                currentDate.setHours(currentDate.getHours() - 24)
                var datestring = currentDate.toLocaleString("en-gb")
                var datestringchars = datestring.split("")
                return datestringchars[6] + datestringchars[7] + datestringchars[8] + datestringchars[9] + '-' + datestringchars[3] + datestringchars[4] + '-' + datestringchars[0] + datestringchars[1] + ' ' + datestringchars[12] + datestringchars[13] + datestringchars[14] + datestringchars[15] + datestringchars[16] + datestringchars[17] + datestringchars[18] + datestringchars[19]
            }
            function buttonPlayPress() {

                if (state == 'stop') {
                    state = 'play';
                    animate();
                }
                else if (state == 'play') {
                    state = 'stop';
                }
                //console.log("button play pressed, play was " + state);

            }
            function buttonStopPress() {
                state = 'stop';
                // console.log("button stop invoked.");
            }

            function addControlsForLayers(updateMin) {
                //Called when layers are added
                var minutes = datetime.getMinutes();
                var timeshift = datetime.getMinutes() % updateMin;
                $scope.stepLength = updateMin;
                console.log("Time before : " + datetime + " shift : " + timeshift);
                datetime.setTime(datetime.getTime() - (timeshift * 60 * 1000));
                console.log("Time after : " + datetime);
                SetImage();
            }
            function removeControlsForLayers() {
                //Called when layers are added
                $scope.stepLength = 1;
                SetImage();
            }

            function SetImage() {
                
                if ($scope.fromDate > Date.now()) {
                    console.log("Oy - you are looking at the future ! ");
                    //linkElement.textContent = $translate.instant('fremtid');
                    $scope.AnimateStep = 0;
                    return;
                }
                if (!$scope.mapReady) return;
                var datetime = moment($scope.fromDate);
                var utmOffset = $scope.fromDate.getTimezoneOffset();
                datetime.add(utmOffset, "minutes");
                var utcDateTime = datetime.toDate();
                $scope.AnimateStep = step;

                for (var i = 0; i < $scope.dataSources.length; i++) {
                    if ($scope.dataSources[i].show) {
                        SetImageForLayer($scope.dataSources[i], utcDateTime);
                    }

                }
            }
            function SetImageForLayer(datasource, utcDateTime) {
                if (datasource.img) {
                    if (datasource.img._url) {
                        datasource.img.visibility = "hidden"; //visible|
                    }
                }
                var url = getUrl(datasource, utcDateTime);
                console.log('url :', url);
                if (datasource.img) {
                    if (datasource.img.setUrl(url)) datasource.img.visibility = "visible"; //visible|
                }
            }

            function getUrl(datasource, utcDateTime) {
                var dd = ("0" + utcDateTime.getDate()).slice(-2);
                var MM = ("0" + (parseInt(utcDateTime.getMonth()) + 1)).slice(-2);
                var hh = ("0" + (parseInt(utcDateTime.getHours()))).slice(-2);
                var mm = ("0" + utcDateTime.getMinutes()).slice(-2);
                var YY = ("" + utcDateTime.getFullYear()).slice(-2);
                var yyyy = ("" + utcDateTime.getFullYear());
                var e = "0";
                //https://veva-processed-files.s3.eu-west-1.amazonaws.com/png/adjusted/2022/06/20/TemporalComposite_20220620_0931.png
                var url = getS3baseUrl(datasource.baseURL) + "/" + imgName(datasource, yyyy, YY, MM, dd, hh, mm, e);
                if ($scope.accumulateMinutes > 1) {
                    if (datasource.Accumulated)
                        url = getS3baseUrl(datasource.AccumulatedPath) + "/" + AccumulatedImgName(datasource, yyyy, YY, MM, dd, hh, mm, e);
                    else {
                        $mdToast.show(
                            $mdToast.simple()
                                .textContent('No accumulated images for ' + datasource.name)
                                .position('left')
                                .hideDelay(3000));
                    }
                }
                return url;
            }

            function getS3baseUrl(s3path) {
                var ix = s3path.indexOf("/");
                var bucket = s3path.substr(0, ix) + ".s3.eu-west-1.amazonaws.com" + s3path.substr(ix);
                return "https://" + bucket;

            }

            function imgName(datasource, yyyy, YY, MM, dd, hh, mm, e) {
                if (datasource.timespace) return yyyy + "/" + MM + "/" + dd + "/" + datasource.identifier + "_" + yyyy + MM + dd + "_" + hh + mm + ".png";
                else return yyyy + "/" + MM + "/" + dd + "/" + datasource.identifier + "_" + yyyy + MM + dd + hh + mm + ".png";

            }

            function AccumulatedImgName(datasource, yyyy, YY, MM, dd, hh, mm, e) {

                if (datasource.timespace) return yyyy + "/" + MM + "/" + dd + "/" + datasource.AccumulatedFileName + yyyy + MM + dd + "_" + hh + mm + ".png";
                else return yyyy + "/" + MM + "/" + dd + "/" + datasource.AccumulatedFileName + yyyy + MM + dd + hh + mm + ".png";

            }

            $scope.toggleDataSource = function () {

                if ($scope.img) $scope.img.remove();
                $scope.selectedDataSource = $scope.dataSources.find(function (o) { return o.id === $scope.selectedDataSourceId; });
                var LL = L.latLng($scope.selectedDataSource.points[0].y, $scope.selectedDataSource.points[0].x);
                var UR = L.latLng($scope.selectedDataSource.points[1].y, $scope.selectedDataSource.points[1].x);
                var imageBounds = L.latLngBounds(LL, UR);
                var imageUrl = getUrl($scope.selectedDataSource, $scope.fromDate);
                $scope.selectedDataSource.timespace = true;
                if ($scope.selectedDataSource.id < 104 && $scope.accumulateMinutes < 2) $scope.selectedDataSource.timespace = false;
                $scope.img = L.imageOverlay(imageUrl, imageBounds, imgoptions).addTo($scope.mapstate);
                SetImage();
            }
            $scope.toggleDataSourceCheck = function (id) {

                //if ($scope.img) $scope.img.remove();
                $scope.selectedDataSource = $scope.dataSources.find(function (o) { return o.id === id; });
                console.log($scope.selectedDataSource.name, $scope.selectedDataSource.show);
                if ($scope.selectedDataSource.show === false) {
                    var imageBounds = checkCorners([[$scope.selectedDataSource.points[0].x, $scope.selectedDataSource.points[0].y], [$scope.selectedDataSource.points[1].x, $scope.selectedDataSource.points[1].y]]);
                    var imageUrl = getUrl($scope.selectedDataSource, $scope.fromDate);
                    $scope.selectedDataSource.timespace = true;
                    //$scope.selectedDataSource.show = true;

                    if ($scope.selectedDataSource.id < 104 && $scope.accumulateMinutes < 2) $scope.selectedDataSource.timespace = false;
                    imgoptions.errorOverlayUrl = $scope.selectedDataSource.altUrl;
                    $scope.selectedDataSource.img = L.imageOverlay(imageUrl, imageBounds, imgoptions).addTo($scope.mapstate);
                } else {
                    //$scope.selectedDataSource.show = false;
                    $scope.selectedDataSource.img.remove();
                }
                SetImage();
            }

            function checkCorners(imageBounds) {
                //Konverter hjørner til UTM-32 og sikre UL og LR
                var ULtst = proj4('WGS84', 'EPSG:32632', imageBounds[0]);
                var LRtst = proj4('WGS84', 'EPSG:32632', imageBounds[1]);
                var UL = [Math.min(ULtst[0], LRtst[0]), Math.max(ULtst[1], LRtst[1])];
                var LR = [Math.max(ULtst[0], LRtst[0]), Math.min(ULtst[1], LRtst[1])];
                var ULgeo = proj4('EPSG:32632','WGS84',  UL);
                var LRgeo = proj4('EPSG:32632', 'WGS84', LR);
                var UL = L.latLng(ULgeo[1], ULgeo[0]);
                var LR = L.latLng(LRgeo[1], LRgeo[0]);
                return L.latLngBounds(UL, LR);
            }


            function imageExists(url, callback) {
                var img = new Image();
                img.onload = function () { callback(true); };
                img.onerror = function () { callback(false); };
                img.src = url;
            }
            function animate() {
                // Move the element 10px on the right every 16ms
                var timer = setInterval(function () {
                    step += 1;
                    $scope.fromDate.setTime($scope.fromDate.getTime() + $scope.stepLength * 60000);
                    updateHoursMinutes();
                    SetImage();
                    if (state == 'stop') {
                        clearInterval(timer);
                    }
                }, speed);
            }


            function renderLegendIconHtml(icon) {
                return '<span class="half awesome-marker-icon-' + icon.markerColor + ' awesome-marker leaflet-clickable"><i class=" glyphicon ' + icon.icon + ' icon-white"></i></span>';
            }
        }]);

angular.module('mapsApp').config(['$mdDateLocaleProvider', function ($mdDateLocaleProvider) {
    $mdDateLocaleProvider.formatDate = function (date) {
        return moment(date).format('DD/MM/YYYY');
    };
}]);