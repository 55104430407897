String.prototype.endsWith = function (suffix) {
    return this.indexOf(suffix, this.length - suffix.length) !== -1;
};

Array.prototype.removeItem = function (item, compare) {
    var index = -1;

    for (var i = 0; i < this.length; i++) {
        if (compare(this[i], item) === true) {
            index = i;
            break;
        }
    }

    if (index > -1) {
        this.splice(index, 1);
    }
};

AwsDataSourceTypesEnum = Object.freeze({
  "Flow": 1, "River": 2, "RiverNew": 3, getDisplayName: function (awsDataSourceType) {
    if (awsDataSourceType === 1) return 'Flow';
    if (awsDataSourceType === 2) return 'River';
    if (awsDataSourceType === 3) return 'RiverNew';
    return undefined;
  } });
