'use strict';

var Highcharts = require("highcharts");

angular.module('mapsApp').service('ChartDefaultsService', function () {


    this.ColorPalletes = function (count) {

        this.colorStrings = [];
        var offset = 50;

        if (count <= 3) {
            this.colorStrings = [
                'rgb(255, ' + offset + ', ' + offset + ')',
                'rgb(' + offset + ', 255, ' + offset + ')',
                'rgb(' + offset + ', ' + offset + ', 255)'
            ];
        }
        else {
            count = count < 6 ? 6 : count;
            var pace = (5 * (255 - offset)) / (count - 1);
            var rgb = [255, Math.round(offset), Math.round(offset)];
            var iCur = 0;
            var add = true;
            for (var i = 0; i < count; i++) {
                this.colorStrings.push('rgb(' + Math.round(rgb[0] - offset) + ', ' + Math.round(rgb[1] - offset) + ', ' + Math.round(rgb[2] - offset) + ')');
                if (i == count - 1) break;

                var next = iCur == 2 ? 0 : iCur + 1;
                var nextNext = next == 2 ? 0 : next + 1;
                if (add) {
                    rgb[next] += pace;
                    if (rgb[next] >= 255) {
                        var rest = rgb[next] - 255;
                        rgb[next] = 255;
                        rgb[iCur] -= rest;
                        add = false;
                    }
                }
                else {
                    rgb[iCur] -= pace;
                    if (rgb[iCur] <= offset) {
                        var rest = rgb[iCur] + pace;
                        rgb[iCur] = offset;
                        rgb[nextNext] += rest;
                        add = true;
                        iCur++;
                    }
                }
            }

        }
    };

    this.getConfig = function (skipMinMax, fromDate, toDate, graphEnhanceOptions) {

        var graphType = graphEnhanceOptions &&
            graphEnhanceOptions.normalizeMethod === 4 &&
            graphEnhanceOptions.groupTimeSpan !== 0 &&
            graphEnhanceOptions.multiplyer >= 1
                ? 'arearange'
                : 'line';
        return {

            options: {
                //lang: {
                //    resetZoom: $translate.instant("resetZoom")
                //},
                chart: {
                    type: graphType,
                    zoomType: 'x',
                    animation: false,
                    spacingTop: 30
                },
              plotOptions: {
                series: {
                  turboThreshold: 1,
                  softThreshold : true,
                  lineWidth: 1,
                  animation: false,
                  opacity: 0.8,
                  fillOpacity: 0.4
                }
              },
                tooltip: {
                    headerFormat: '<span style="font-size: 13px;">{point.key}</span><br/>',
                    xDateFormat: '%d %B %Y %H:%M',// hovered point datetime
                    borderRadius: 0,
                    borderWidth: 1,
                    enabled: true,
                    useHtml: true,
                    shared: true,
                    animation: false,
                    positioner: function () {
                        return {
                            x: this.chart.chartWidth - this.label.width - 40, // right aligned
                            y: 5 // top aligned
                        };
                    },
                    //valueSuffix: ' ' + parameters[j].UnitName,
                    shadow: false,
                    style: {
                        padding: '5px'
                    },
                    hideDelay: 0,
                    valueDecimals: 2,
                    pointFormat: graphType === 'arearange'
                        ? '<span style="color:{series.color}"> ●</span> {series.name}: <b>{point.low}</b> - <b>{point.high}</b>'  // hovered point min and max values (y axis)
                        : '<span style="color:{point.color}"> ●</span> {series.name}: <b>{point.y}</b>. ' // hovered point value (y axis)
                },
                legend: {},

          },
            title: {
                text: undefined,
                floating: true,
                align: "left",
                x: 0,
                y: 0
            },
            credits: {
                text: 'VCS - InforMetics',
                href: 'http://www.informetics.com'
            },
            subtitle: {
                text: undefined
            },
            xAxis: [{
                type: 'datetime',
                max: toDate ? Date.parse(toDate) : Date.now(),
                min: fromDate ? Date.parse(fromDate) : Date.now() - 48 * 36e5,
                gridLineWidth: 1,
                gridLineColor: (Highcharts.theme && Highcharts.theme.background2) || '#F0F0F0',
                startOnTick: false,
                endOnTick: false,
                minPadding: 0,
                maxPadding: 0,
                offset: 0,
                showLastLabel: true,
                plotLines: [],
                crosshair: {
                    dashStyle: "Solid",
                    snap: true,
                    width: 1,
                    zIndex: 2
                },
                labels: {
                    
                }
            }, { // Top X axis
                linkedTo: 0,
                type: 'datetime',
                tickInterval: 24 * 3600 * 1000,
                labels: {
                    format: '{value:<span style="font-size: 12px; font-weight: bold">%a</span> %b %e}',
                    align: 'left',
                    x: 3,
                    y: -5
                },
                opposite: true,
                tickLength: skipMinMax ? undefined : 20,
                visible : false,
                gridLineWidth: 1
            }],
          exporting: {
            formAttributes: {
              target: '_blank'
            }
          },
            yAxis: [],
            series: []
        };

    };

    this.getConfigXY = function (fromDate, toDate) {

        return {

            options: {
                chart: {
                    type: 'scatter',
                    zoomType: 'x',
                    animation: false,
                    spacingTop: 30
                },
                plotOptions: {
                    series: {
                        turboThreshold: 1,
                        lineWidth: 1,
                        animation: false
                    }
                },
                tooltip: {
                    headerFormat: '<span style="font-size: 13px;">{point.key}</span><br/>', // hovered point datetime
                    borderRadius: 0,
                    borderWidth: 1,
                    enabled: true,
                    useHtml: true,
                    shared: true,
                    animation: false,
                    positioner: function () {
                        return {
                            x: this.chart.chartWidth - this.label.width - 40, // right aligned
                            y: 5 // top aligned
                        };
                    },
                    //valueSuffix: ' ' + parameters[j].UnitName,
                    shadow: false,
                    style: {
                        padding: '5px'
                    },
                    hideDelay: 0,
                    valueDecimals: 2,
                    pointFormat:  '<span style="color:{point.color}"> ●</span> {series.name}: <b>{point.y}</b>. ' // hovered point value (y axis)
                },
                legend: {},

            },
            title: {
                text: undefined,
                floating: true,
                align: "left",
                x: 0,
                y: 0
            },
            credits: {
                text: 'VCS - InforMetics',
                href: 'http://www.informetics.com'
            },
            subtitle: {
                text: undefined
            },
            xAxis: [{
                gridLineWidth: 1,
                gridLineColor: (Highcharts.theme && Highcharts.theme.background2) || '#F0F0F0',
                startOnTick: false,
                endOnTick: false,
                minPadding: 0,
                maxPadding: 0,
                offset: 0,
                showLastLabel: true,
                plotLines: [],
                crosshair: {
                    dashStyle: "Solid",
                    snap: true,
                    width: 1,
                    zIndex: 2
                },
                labels: {

                }
            }],
            yAxis: [],
            series: [],
            exporting: {
                sourceWidth: 2480,
                //    sourceHeight: 200,
                // scale: 2 (default)
                chartOptions: {
                    subtitle: ''
                }
            }
        };

    };


});
