'use strict';

/**
 * @ngdoc service
 * @name mapsApp.authInterceptorFactory
 * @description
 * # authInterceptorFactory
 * Factory in the mapsApp.
 */
'use strict';
angular.module('mapsApp')
  .factory('authInterceptorFactory', ['$q', '$injector', '$location', 'localStorageService', function ($q, $injector, $location, localStorageService) {

    var authInterceptorFactoryFactory = {};

    var _request = function (config) {

        config.headers = config.headers || {};

        var authData = localStorageService.get('authorizationData');
        if (authData) {
            config.headers.Authorization = 'Bearer ' + authData.token;
        }

        return config;
    }

    var _responseError = function (rejection) {
        if (rejection.status === 401) {
            var authFactory = $injector.get('authFactory');
            var authData = localStorageService.get('authorizationData');

            if (authData) {
                if (authData.useRefreshTokens) {
                    $location.path('/refresh');
                    return $q.reject(rejection);
                }
            }

            authFactory.logOut();

            $location.path('/login');
        }
        return $q.reject(rejection);
    }

    authInterceptorFactoryFactory.request = _request;
    authInterceptorFactoryFactory.responseError = _responseError;

    return authInterceptorFactoryFactory;
}]);
