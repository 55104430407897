'use strict';

var Highcharts = require("highcharts");
var moment = require('moment');

angular.module('mapsApp')
    .controller('LayoutCtrl', [ '$scope', '$location', '$mdDialog', 'authFactory', 'analysisStateService', '$translate', '$locale', 'tmhDynamicLocale', '$mdDateLocale', 'localizationService',
      function ($scope, $location, $mdDialog, authFactory, analysisStateService, $translate, $locale, tmhDynamicLocale, $mdDateLocale, localizationService) {
          //if (!authFactory.authentication.isAuth) {
          //    $location.path('/login');
          //}


          $scope.navigationBar = require('../../views/_navigation.html');
          $scope.currentLanguage = $translate.proposedLanguage();

          $scope.changeToLanguage = function (lang) {

              $translate.use(lang);
              $scope.currentLanguage = lang;
              resetLocale();
          };
          $scope.location = $location;

          var helpPages = ['/home', '/analysis', '/laboratory'];
          $scope.hasHelp = function () {
              return helpPages.indexOf($location.$$path) !== -1;
          };
          $scope.manageProfile = function (ev) {
              $mdDialog.show({
                  controller: 'ManageProfileCtrl',
                  templateUrl: require('../../views/profile/manageProfile.html'),
                  parent: angular.element(document.body),
                  targetEvent: ev,
                  clickOutsideToClose: false
              })
            .then(function (answer) {
                $scope.status = 'You said the information was "' + answer + '".';
            }, function () {
                $scope.status = 'You cancelled the dialog.';
            });
          };
          $scope.showHelp = function ($event) {

              var helpData = {
                  componnents: {
                      '<plus-button/>': '<md-button aria-label="menu" md-whiteframe="24" class="md-fab md-primary md-mini"> + </md-button>',
                      '<update-plot-button/>': '<md-button aria-label="update" md-whiteframe="24" class="md-primary md-raised"> Update Plot </md-button>',
                      '<layer-icon/>': '<a class="leaflet-control-layers-toggle" href="#" title="Layers" style="display: inline-block;"></a>',
                      '<graph-menu-button/>': '<svg style="width: 22px; height: 18px;"><path fill="#E0E0E0" d="M 6 6.5 L 20 6.5 M 6 11.5 L 20 11.5 M 6 16.5 L 20 16.5" stroke="#666" stroke-width="3"></path></svg>',
                      '<toggle-button/>': '<md-switch aria-label="Auto Enhance" role="checkbox" class="md-docs-dark-theme md-checked mock-switch" tabindex="0" aria-checked="true" aria-invalid="false"></md-switch>'
                  },
                  texts: {
                      '/home':
                        $translate.instant('_help_translation_/home'),
                      '/analysis':
                        $translate.instant('_help_translation_/analysis'),
                      '/laboratory':
                        $translate.instant('_help_translation_/laboratory'),
                  }
              };


              if (helpData.texts[$location.$$path] === undefined) {
                  return;
              }

              var parentEl = angular.element(document.body);
              var toUseText = helpData.texts[$location.$$path];

              for (var helpPageComponnentKey in helpData.componnents) {
                  while (toUseText.indexOf(helpPageComponnentKey.toString()) !== -1) {
                      toUseText = toUseText.replace(helpPageComponnentKey.toString(), helpData.componnents[helpPageComponnentKey]);
                  }
              }


              $mdDialog.show({
                  parent: parentEl,
                  targetEvent: $event,
                  template:
                    '<md-dialog aria-label="dialog">' +
                    '  <md-dialog-content layout-padding>' +
                    '   <md-content>' +
                           toUseText +
                    '   </md-content>' +
                    '  </md-dialog-content>' +
                    '  <md-dialog-actions>' +
                    '    <md-button ng-click="closeDialog()" class="md-primary">' +
                            $translate.instant('close_button_text') +
                    '    </md-button>' +
                    '  </md-dialog-actions>' +
                    '</md-dialog>',
                  locals: {
                      items: $scope.items
                  },
                  controller: DialogController
              });
              function DialogController($scope, $mdDialog, items) {
                  $scope.items = items;
                  $scope.closeDialog = function () {
                      $mdDialog.hide();
                  };
              }

          };

          $scope.languages = localizationService.languages;

          function bindLocales() {

              function defaultFormatDate(date) {
                  if (!date) {
                      return '';
                  }

                  var localeTime = date.toLocaleTimeString();
                  var formatDate = date;
                  if (date.getHours() === 0 &&
                      (localeTime.indexOf('11:') !== -1 || localeTime.indexOf('23:') !== -1)) {
                      formatDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 1, 0, 0);
                  }

                  return formatDate.toLocaleDateString();
              }

              function defaultParseDate(dateString) {
                  return new Date(dateString);
              }

              function defaultIsDateComplete(dateString) {
                  dateString = dateString.trim();

                  var re = /^(([a-zA-Z]{3,}|[0-9]{1,4})([ \.,]+|[\/\-])){2}([a-zA-Z]{3,}|[0-9]{1,4})$/;
                  return re.test(dateString);
              }

              function defaultMonthHeaderFormatter(date) {
                  return $mdDateLocale.shortMonths[date.getMonth()] + ' ' + date.getFullYear();
              }

              function defaultWeekNumberFormatter(number) {
                  return 'Week ' + number;
              }


              function defaultLongDateFormatter(date) {
                  // Example: 'Thursday June 18 2015'
                  return [
                    $mdDateLocale.days[date.getDay()],
                    $mdDateLocale.months[date.getMonth()],
                    $mdDateLocale.dates[date.getDate()],
                    date.getFullYear()
                  ].join(' ');
              }

              var defaultShortDays = $locale.DATETIME_FORMATS.DAY.map(function(day) {
                  return day[0];
              });

              var defaultDates = Array(32);
              for (var i = 1; i <= 31; i++) {
                  defaultDates[i] = i;
              }

              var defaultMsgCalendar = 'Calendar';
              var defaultMsgOpenCalendar = 'Open calendar';

              var dayIndex = $locale.DATETIME_FORMATS.shortDate.indexOf('d');
              var monthIndex = $locale.DATETIME_FORMATS.shortDate.indexOf('M');

              var shortDateFormat = dayIndex <= monthIndex? 'DD/MM/YYYY': 'MM/DD/YYYY';

              angular.extend($mdDateLocale, {
                  months: $locale.DATETIME_FORMATS.MONTH,
                  shortMonths: $locale.DATETIME_FORMATS.SHORTMONTH,
                  days: $locale.DATETIME_FORMATS.DAY,
                  shortDays: defaultShortDays,
                  dates: defaultDates,
                  firstDayOfWeek: 0,
                  //formatDate: defaultFormatDate,
                  //parseDate: defaultParseDate,
                  formatDate: function (date) {
                      return date ? moment(date).format(shortDateFormat) : '';
                  },
                  parseDate: function (dateString) {
                      var m = moment(dateString, shortDateFormat, true);
                      return m.isValid() ? m.toDate() : new Date(NaN);
                  },
                  isDateComplete: defaultIsDateComplete,
                  monthHeaderFormatter: defaultMonthHeaderFormatter,
                  weekNumberFormatter: defaultWeekNumberFormatter,
                  longDateFormatter: defaultLongDateFormatter,
                  msgCalendar: defaultMsgCalendar,
                  msgOpenCalendar: defaultMsgOpenCalendar
              });
          }
          resetLocale();

          function resetLocale() {
              var locale = $translate.proposedLanguage();
              tmhDynamicLocale.set(locale).then(function () {
                  bindLocales();
                  Highcharts.setOptions({
                      lang: {
                          months: $locale.DATETIME_FORMATS.MONTH,
                          shortMonths: $locale.DATETIME_FORMATS.SHORTMONTH,
                          shortWeekdays: $locale.DATETIME_FORMATS.SHORTDAY,
                          weekdays: $locale.DATETIME_FORMATS.DAY,
                          thousandsSep: $locale.NUMBER_FORMATS.GROUP_SEP,
                          decimalPoint: $locale.NUMBER_FORMATS.DECIMAL_SEP
                      }
                  });
              });

          }


      $scope.resetLocalStorageState = function () {
          analysisStateService.deleteStateFromLocalStorage(true);
      };

      $scope.logOut = function () {
          authFactory.logOut();
          $location.path('/login');
      };
      $scope.toggleMainNav = function () {
          var attrStyle = $('#main-navigation').attr('style');
          if (attrStyle && attrStyle.indexOf('display: none;') !== -1) {
              $('#main-navigation').slideDown();
          }
          else {
              $('#main-navigation').slideUp();
          }
      };

      $scope.authentication = authFactory.authentication;

      $scope.isCurrentPath = function (path) {
          return $location.path() === path;
      };
      $scope.containsPath = function (path) {
          return $location.path().indexOf(path) !== -1;
      };
      var originatorEv;
      $scope.openMenu = function ($mdOpenMenu, ev) {
          originatorEv = ev;
          $mdOpenMenu(ev);
      };

  }]);