'use strict';

/**
 * @ngdoc filter
 * @name mapsApp.filter:parameterValue
 * @function
 * @description
 * # parameterValue
 * Filter in the mapsApp.
 */
angular.module('mapsApp')
    .filter('parameterValue', ['$filter', function ($filter) {
    return function (input, isTooltip) {
        if (!input.Value)
            return null;

        if (isTooltip) {
            var header = input.ColumnHeader ? input.ColumnHeader + ': ' : '';
            switch (input.UnitName) {
                case null: return header + $filter('date')(Date.parse(input.Value), 'medium');
                default: return header + input.Value + ' (' + input.UnitName + ')';

            }
        }

        switch (input.UnitName) {
            case null: return $filter('date')(Date.parse(input.Value), 'short');
            default: return $filter('number')(input.Value);

        }
    };
  }]);
