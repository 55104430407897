'use strict';

angular.module('mapsApp')
    .controller('LocalizationLanguageCtrl', ['$mdEditDialog', '$scope', '$mdToast', 'localizationService', function ($mdEditDialog, $scope, $mdToast, localizationService) {
      'use strict';
  
      $scope.limitOptions = [5, 10, 15];
      $scope.newLanguage = { Id: 0 };
      $scope.options = {
          rowSelection: false,
          multiSelect: false,
          autoSelect: false,
          decapitate: false,
          largeEditDialog: true,
          boundaryLinks: true,
          limitSelect: true,
          pageSelect: true
      };
  
      $scope.query = {
          order: 'Name',
          limit: 5,
          page: 1
      };

  
      $scope.languages = localizationService.languages;
  
      $scope.edit = function (event, item, propertyName, maxLength) {
          event.stopPropagation(); // in case autoselect is enabled
    
          var editDialog = {
              modelValue: item[propertyName],
              save: function (input) {
                  item[propertyName] = input.$modelValue;
                  $scope.promise = localizationService.updateLanguage(item).then(function (response) {
                  });
              },
              targetEvent: event,
              title: ' ',
              validators: {
                  'md-maxlength': maxLength
              }
          };

    
          var promise;

          promise = $mdEditDialog.large(editDialog);
    
          promise.then(function (ctrl) {
              var input = ctrl.getInput();
      
              input.$viewChangeListeners.push(function () {
                  input.$setValidity('test', input.$modelValue !== 'test');
              });
          });
      };
  
      $scope.refresh = function () {
          $scope.promise = localizationService.refreshLanguages().then(function () {
              $mdToast.show($mdToast.simple().textContent('The languages were refreshed.'));

          });

      }

      $scope.removeLanguage = function (language) {
          $scope.promise = localizationService.removeLanguage(language).then(function () {

          });
      };

      $scope.addLanguage = function (modalId) {

          $scope.promise = localizationService.addLanguage($scope.newLanguage)
          .then(function () {
              $scope.newLanguage = { Id: 0 };
              $(modalId).modal('hide');
          });

      };

  }]);
