'use strict';



var isRelease = true; // true
var version = isRelease ? '' : 'tst.'; //'tst.';


//var serviceBase = 'http://localhost:54505/';
//var serviceBase = 'http://localhost:49955/';
//var serviceBase = 'http://api.vcs.informetics.dk/';
var serviceBase = 'https://api.veva.live/';

//var serviceBase = 'http://' + version +'api.vcs.informetics.dk/';

//var webAppBase = 'http://localhost:9000/';
//var webAppBase = 'http://localhost:4300/';

//var webAppBase = 'http://west.vcs.informetics.dk/';
var webAppBase = 'https://data.veva.live/';

var isWest = false;
var showWQ = false;
var isVeVa = true;
var showCampaign = false;
var showValidate = false;

var defaultPage = 'home';
if (isWest) defaultPage = 'cloudAnalysis';
if (showCampaign) defaultPage = 'campaignMain';
if (showValidate) defaultPage = 'validate';
if (isVeVa) defaultPage = 'vevaintro';

//var API_URL = serviceBase + '/api/';
var API_URL = serviceBase;

// require all plugins


var angular = require("angular");

require("angular-animate");
require("angular-aria");
require("angular-bootstrap");
require("angular-chrome-autofill-fix");
require("angular-digest-hud");
require("angular-dynamic-locale");
require("angular-local-storage");
require("angular-material");
require("angular-material-data-table");
require("angular-messages");
require("angular-order-object-by");
require("angular-sanitize");
require("angular-translate");
require("angular-translate-loader-static-files");
require("angular-translate-loader-url");
require("angular-ui-router");
require("ng-file-upload");

require("file-saver");

require("bootstrap");
require("bootstrap-colorpicker");
require("jquery");
require("moment");
var angular = require('angular');
require('angular-moment');
var ngModule = angular.module('ngApp', ['angularMoment']);
ngModule.constant('moment', require('moment-timezone'));



require("leaflet");
require('proj4');
require('proj4leaflet');
require("leaflet.awesome-markers");
require("leaflet.label");
require('leaflet.beautifymarker/leaflet-beautify-marker-icon.js');
require('leaflet-geoman-free');

// leaflet-global.js
define(['leaflet'], function (L) {
    window.L = L;
    return L;
});

var Highcharts = require("highcharts");

require('highcharts/highcharts-more.src.js')(Highcharts);
require('highcharts/modules/data.src.js')(Highcharts);

require('highcharts/modules/boost-canvas.src.js')(Highcharts);
require('highcharts/modules/boost.src.js')(Highcharts);

require('highcharts/modules/exporting.src.js')(Highcharts);
require('highcharts/modules/export-data.src.js')(Highcharts);

require('highcharts/modules/heatmap.src.js')(Highcharts);
require("highcharts/modules/annotations.src.js")(Highcharts);



Highcharts.setOptions({
    global: {
        useUTC: false
    }
});


// styles
require('bootstrap/dist/css/bootstrap.css');
require('leaflet/dist/leaflet.css');
require('leaflet.awesome-markers/dist/leaflet.awesome-markers.css');
require('angular-material/angular-material.css');
require('bootstrap-colorpicker/dist/css/bootstrap-colorpicker.css');
require('angular-material-data-table/dist/md-data-table.css');
require('leaflet.beautifymarker/leaflet-beautify-marker-icon.css');
require('leaflet-geoman-free/dist/leaflet-geoman.css');
//require('highcharts/css/highcharts.css');

require('../styles/main.css');
require('../styles/fixes.css');




angular
    .module('mapsApp', ['pascalprecht.translate', 'ngMessages', 'digestHud', 'ui.router', 'ngAnimate', 'ngMaterial',
        'LocalStorageModule', 'ui.bootstrap', 'md.data.table', 'ngLocale', 'tmh.dynamicLocale', 'ngSanitize', 'chrome-autofill-fix', 'ngOrderObjectBy', 'ngFileUpload']);

importAll(require.context('./../scripts', true, /.js$/));


angular
    .module('mapsApp').run(['$rootScope', '$state', '$stateParams', 'authFactory',
        function ($rootScope, $state, $stateParams, authFactory) {
            $rootScope.$state = $state;
            $rootScope.isWest = isWest;
            $rootScope.isVeVa = isVeVa;
            $rootScope.showWQ = showWQ;
            $rootScope.webAppBase = webAppBase;

            $rootScope.showCampaign = showCampaign;
            $rootScope.showValidate = showValidate;

            $rootScope.isRelease = isRelease;

            $rootScope.$stateParams = $stateParams;
            $rootScope.inArray = function (item, array) {
                if (array == null) return -1;
                return (-1 !== array.indexOf(item));
            };

            $rootScope.AwsDataSourceTypesEnum = AwsDataSourceTypesEnum;

            $rootScope.$on('$locationChangeSuccess', function (evt, absNewUrl, absOldUrl) {
                var previousLocation = absOldUrl.split('/#!')[1];
                if (previousLocation) {
                    $rootScope.previousLocation = previousLocation;
                }
            });

            $rootScope.$on('$stateChangeStart', function (evt, to, params) {
                if (to.redirectTo) {
                    evt.preventDefault();
                    $state.go(to.redirectTo, params, { location: 'replace' })
                }
            });
            authFactory.fillAuthData();

        }]
);

angular
    .module('mapsApp').config(
        ['$logProvider',
            '$stateProvider',
            '$urlRouterProvider',
            '$httpProvider',
            '$sceProvider',
            '$mdThemingProvider',
            '$translateProvider',
            'tmhDynamicLocaleProvider',
            function ($logProvider,
                $stateProvider,
                $urlRouterProvider,
                $httpProvider,
                $sceProvider,
                $mdThemingProvider,
                $translateProvider,
                tmhDynamicLocaleProvider) {
                $mdThemingProvider.theme('default')
                    .primaryPalette('blue')
                    .accentPalette('light-blue');
                $mdThemingProvider.theme('docs-dark', 'default')
                    .dark();
                //digestHudProvider.enable();
                //// Optional configuration settings:
                //digestHudProvider.setHudPosition('bottom left'); // setup hud position on the page: top right, bottom left, etc. corner
                //digestHudProvider.numTopWatches = 20;  // number of items to display in detailed table
                //digestHudProvider.numDigestStats = 25;  // number of most recent digests to use for min/med/max stats
                $httpProvider.interceptors.push('authInterceptorFactory');

                $sceProvider.enabled(false);
                $logProvider.debugEnabled(false);
                //$locationProvider.html5Mode({
                //    enabled: true
                //});

                $translateProvider.useSanitizeValueStrategy('sanitizeParameters');
                $translateProvider.useStaticFilesLoader({
                    prefix: API_URL + 'language/GetTranslations/?code=',
                    suffix: ''
                });

                $translateProvider.preferredLanguage('da-DK');

                tmhDynamicLocaleProvider.localeLocationPattern('./node_modules/angular-i18n/angular-locale_{{locale}}.js');

                $urlRouterProvider
                    .otherwise(defaultPage);

                $stateProvider
                    .state('home',
                        {
                            url: '/home',
                            templateUrl: require('../views/main.html'),
                            controller: 'MainController'
                        });

                $stateProvider
                    .state('comments',
                        {
                            url: '/comments',
                            templateUrl: require('../views/comment.html'),
                            controller: 'CommentCtrl'
                        });
                $stateProvider
                    .state('stats',
                        {
                            url: '/stats',
                            controller: 'RainStatsCtrl',
                            templateUrl: require('../views/rainStats.html')
                        });
                $stateProvider
                    .state('vevaCoverage',
                        {
                            url: '/vevaCoverage',
                            controller: 'radarCtrl',
                            templateUrl: require('../views/radar.html')
                        });
                $stateProvider
                    .state('vevaintro',
                        {
                            url: '/vevaintro',
                            controller: 'vevaintroCtrl',
                            templateUrl: require('../views/vevaIntro.html')
                        });
                $stateProvider
                    .state('veva',
                        {
                            url: '/veva',
                            controller: 'vevaCtrl',
                            templateUrl: require('../views/veva.html')
                        });
                $stateProvider
                    .state('vevaOverview',
                        {
                            url: '/vevaOverview',
                            controller: 'vevaOverviewCtrl',
                            templateUrl: require('../views/vevaOverview.html')
                        });
                $stateProvider
                    .state('seedata',
                        {
                            url: '/seedata',
                            controller: 'vevaSeeDataCtrl',
                            templateUrl: require('../views/vevaSeeData.html')
                        });
                //$stateProvider
                //    .state('vevaStats',
                //        {
                //            url: '/vevaStats',
                //            controller: 'radarCtrl',
                //            templateUrl: require('../views/radar.html')
                //        });
                //$stateProvider
                //    .state('vevaTools',
                //        {
                //            url: '/vevaTools',
                //            controller: 'radarCtrl',
                //            templateUrl: require('../views/radar.html')
                //        });

                $stateProvider
                    .state('login',
                        {
                            url: '/login',
                            controller: 'loginCtrl',
                            templateUrl: require('../views/profile/login.html')
                        });
                $stateProvider
                    .state('confirm',
                        {
                            url: '/confirm?token?id',
                            controller: 'ConfirmEmailCtrl',
                            templateUrl: require('../views/profile/confirmEmail.html')
                        });
                $stateProvider
                    .state('reset',
                        {
                            url: '/reset?token?id',
                            controller: 'ResetPasswordCtrl',
                            templateUrl: require('../views/profile/resetPassword.html')
                        });

                $stateProvider
                    .state('autoLogin',
                        {
                            url: '/autoLogin',
                            controller: 'autoLoginCtrl',
                            templateUrl: require('../views/profile/autoLogin.html')
                        });

                $stateProvider
                    .state('profile',
                        {
                            url: '/profile',
                            controller: 'profileCtrl',
                            templateUrl: require('../views/profile.html')
                        });

                $stateProvider
                    .state('admin',
                        {
                            url: '/admin',
                            redirectTo: 'adminUsers',
                            templateUrl: require('../views/admin/_admin.html'),
                            controller: 'AdminCtrl'
                        });

                $stateProvider
                    .state('adminUsers',
                        {
                            parent: 'admin',
                            url: '/users',
                            controller: 'userCtrl',
                            templateUrl: require('../views/admin/user.html')
                        });
                $stateProvider
                    .state('adminParameters',
                        {
                            parent: 'admin',
                            url: '/parameters',
                            controller: 'ParameterCtrl',
                            templateUrl: require('../views/admin/parameter.html')
                        });
                $stateProvider
                    .state('adminLocalization',
                        {
                            parent: 'admin',
                            url: '/localization',
                            redirectTo: 'adminLocalizationLanguage',
                            templateUrl: require('../views/admin/localization/_localization.html'),
                            controller: 'LocalizationCtrl'
                        });
                $stateProvider
                    .state('adminLocalizationLanguage',
                        {
                            parent: 'adminLocalization',
                            url: '/language',
                            templateUrl: require('../views/admin/localization/localizationLanguage.html'),
                            controller: 'LocalizationLanguageCtrl'
                        });
                $stateProvider
                    .state('adminLocalizationParameterTranslations',
                        {
                            parent: 'adminLocalization',
                            url: '/parameterTranslations',
                            templateUrl: require('../views/admin/localization/localizationParameterTranslation.html'),
                            controller: 'LocalizationParameterTranslationCtrl'
                        });
                $stateProvider
                    .state('adminLocalizationTranslations',
                        {
                            parent: 'adminLocalization',
                            url: '/translations',
                            templateUrl: require('../views/admin/localization/localizationTranslation.html'),
                            controller: 'LocalizationTranslationCtrl'
                        });

            }]
    );


angular
    .module('mapsApp').constant('ngAuthSettings', {
        API_URL: API_URL,
        apiServiceBaseUri: serviceBase,
        apiWebAppBaseUri: webAppBase,

        clientId: 'ngAuthApp'
    });


angular
    .module('mapsApp').directive('mdOption', function () {
        return {
            link: function (scope, elem) {
                scope.$on('$destroy', function () {
                    elem.detach();
                });
            }
        };
    });



// util
function importAll(r) {
    r.keys().forEach(r);
}

