'use strict';

angular.module('mapsApp')
    .factory('profileFactory', ['$http', '$q', '$rootScope', 'localStorageService', 'ngAuthSettings', function ($http, $q, $rootScope, localStorageService, ngAuthSettings) {

        var serviceBase = ngAuthSettings.apiServiceBaseUri;
        var webAppBase = ngAuthSettings.apiWebAppBaseUri;

        var profileFactory = {};

        var _updateOwnProfileData = function (profileData) {

            var deferred = $q.defer();
            $http.post(serviceBase + 'Profile/UpdateOwnData', profileData)
                .then(function (response) {
                    deferred.resolve(response.data);
                },function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        };

        var _confirmEmail = function (userId, token) {

            var deferred = $q.defer();
            $http.post(serviceBase + 'Profile/ConfirmEmail', { UserId: userId, Token: token })
                .then(function (response) {
                    deferred.resolve(response.data);
                },function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        };

        var _sendResetPasswordEmail = function (email) {

            var deferred = $q.defer();
            $http.post(serviceBase + 'Profile/SendResetPasswordEmail', { Email: email, WebAppBaseUrl: webAppBase })
                .then(function (response) {
                    deferred.resolve(response.data);
                },function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        };

        var _resetPassword = function (resetPasswordModel) {

            var deferred = $q.defer();
            $http.post(serviceBase + 'Profile/ResetPassword', resetPasswordModel)
                .then(function (response) {
                    deferred.resolve(response.data);
                },function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        };

        var _sendConfirmEmail = function (user) {
          user.WebAppBaseUrl = webAppBase;
            var deferred = $q.defer();
            $http.post(serviceBase + 'Profile/SendConfirmEmail', user)
                .then(function (response) {
                    deferred.resolve(response.data);
                },function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        };

        var _updateOwnPassword = function (passwordData) {

            var deferred = $q.defer();
            $http.post(serviceBase + 'Profile/UpdateOwnPassword', passwordData)
                .success(function (response) {
                    deferred.resolve(response.data);
                },function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        };

        var _getOwnProfileData = function () {

            var deferred = $q.defer();
            $http.get(serviceBase + 'Profile/GetOwnProfileData')
                .then(function (response) {
                    deferred.resolve(response.data);
                },function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        };

        profileFactory.updateOwnPassword = _updateOwnPassword;
        profileFactory.updateOwnProfileData = _updateOwnProfileData;
        profileFactory.getOwnProfileData = _getOwnProfileData;
        profileFactory.confirmEmail = _confirmEmail;
        profileFactory.sendResetPasswordEmail = _sendResetPasswordEmail;
        profileFactory.resetPassword = _resetPassword;
        profileFactory.sendConfirmEmail = _sendConfirmEmail;


        return profileFactory;
    }]);
