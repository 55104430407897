'use strict';

/**
 * @ngdoc function
 * @name mapsApp.controller:LocalizationctrlCtrl
 * @description
 * # LocalizationctrlCtrl
 * Controller of the mapsApp
 */
angular.module('mapsApp')
    .controller('AdminCtrl', ['$scope', '$mdSidenav', '$timeout', function ($scope, $mdSidenav, $timeout) {
      $scope.toggleLeft = buildDelayedToggler('left');
      function debounce(func, wait, context) {
          var timer;
          return function debounced() {
              var context = $scope,
                  args = Array.prototype.slice.call(arguments);
              $timeout.cancel(timer);
              timer = $timeout(function () {
                  timer = undefined;
                  func.apply(context, args);
              }, wait || 10);
          };
      }
      /**
       * Build handler to open/close a SideNav; when animation finishes
       * report completion in console
       */
      function buildDelayedToggler(navID) {
          return debounce(function () {
              $mdSidenav(navID)
                .toggle()
                .then(function () {
                });
          }, 200);
      }

      $scope.close = function () {
          $mdSidenav('left').close()
            .then(function () {
            });
      };
  }]);
  