'use strict';

/**
 * @ngdoc function
 * @name mapsApp.controller:LayoutctrlCtrl
 * @description
 * # LayoutctrlCtrl
 * Controller of the mapsApp
 */
angular.module('mapsApp')
    .controller('ManageProfileCtrl', ['$scope', '$mdDialog', '$translate', 'profileFactory', '$mdToast',
      function ($scope, $mdDialog, $translate, profileFactory, $mdToast) {
          console.log('ctrl init');
          $scope.loading = false;

          getOwnProfileData();

          $scope.cancel = function () {
              $mdDialog.cancel(); 
          };

          $scope.sendOwnConfirmEmail = function () {
              $scope.loading = true;
              profileFactory.sendConfirmEmail({}).then(function () {
                  $scope.loading = false;
                  $mdToast.show($mdToast.simple().textContent($translate.instant('confirm_email_succesfully_sent_message')).position('left bottom'));

              }, function (error) {
                  var message = '';
                      if (error) {
                          if (error.Message) {
                              message = message + error.Message
                          }
                          else {
                              var errors = [];
                              for (var key in error) {
                                  for (var i = 0; i < error[key].length; i++) {
                                      errors.push(error[key][i]);
                                  }
                              }

                              message = message + ' ' + errors.join(' ');
                          }

                      }

                  $scope.errorMessageProfile = message;
                  console.log(error);
                  $scope.loading = false;
              });
          };
          $scope.updateProfileData = function () {
              $scope.loading = true;
              profileFactory.updateOwnProfileData($scope.profileData).then(function (response) {
                  $mdToast.show($mdToast.simple().textContent($translate.instant('profile_data_succesfully_saved_message')).position('left bottom'));
                  $scope.loading = false;
              }, function (error) {
                  var message = '';
                    if (error) {
                        if (error.Message) {
                            message = message + error.Message
                        }
                        else {
                            var errors = [];
                            for (var key in error) {
                                for (var i = 0; i < error[key].length; i++) {
                                    errors.push(error[key][i]);
                                }
                            }

                            message = message + ' ' + errors.join(' ');
                        }

                    }

                  $scope.errorMessageProfile = message;
                  console.log(error);
                  $scope.loading = false;
              });
          };

          $scope.updatePassword = function () {
              $scope.loading = true;
              profileFactory.updateOwnPassword($scope.passwordData).then(function (response) {
                  $mdToast.show($mdToast.simple().textContent($translate.instant('password_succesfully_changed_message')).position('left bottom'));
                  $scope.loading = false;
              }, function (error) {
                  var message = '';
                    if (error) {
                        if (error.Message) {
                            message = message + error.Message
                        }
                        else {
                            var errors = [];
                            for (var key in error) {
                                for (var i = 0; i < error[key].length; i++) {
                                    errors.push(error[key][i]);
                                }
                            }

                            message = message + ' ' + errors.join(' ');
                        }

                    }

                  $scope.errorMessage = message;
                  console.log(error);
                  $scope.loading = false;
              });
          };

          function getOwnProfileData() {
              $scope.loading = true;
              profileFactory.getOwnProfileData().then(function (response) {
                  $scope.loading = false;
                  $scope.profileData = response;
                  console.log(response);
              }, function (error) {
                  $scope.loading = false;
                  console.log(error);
              }, function (data) {
                  console.log('wat');
                  console.log(data);

              });
          }

          $scope.cancel = function () {
              $mdDialog.cancel();
          };

  }]);