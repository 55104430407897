'use strict';

angular.module('mapsApp')
    .controller('LocalizationParameterTranslationCtrl', ['$mdEditDialog', '$q', '$scope', '$mdToast', '$http', 'localizationService', 'ngAuthSettings' , function ($mdEditDialog, $q, $scope, $mdToast, $http, localizationService, ngAuthSettings) {
      'use strict';

      $scope.limitOptions = [5, 10, 15, 30];
      $scope.newParameterTranslation = { LanguageId: null, ParameterId: null, Translation: null };

      $scope.options = {
          rowSelection: false,
          multiSelect: false,
          autoSelect: false,
          decapitate: false,
          largeEditDialog: true,
          boundaryLinks: true,
          limitSelect: true,
          pageSelect: true
      };

      $scope.query = {
          order: 'Name',
          limit: 5,
          page: 1
      };


      $scope.parameters = [];
      $scope.parametersDictionary = {};
      $scope.languages = localizationService.languages;
      $scope.parameterTranslations = localizationService.parameterTranslations;
      $scope.languagesDictionary = localizationService.languagesDictionary;

      getAllParameters().then(function (response) {
          $scope.parameters = response;
          for (var i = 0; i < $scope.parameters.length; i++) {
              $scope.parametersDictionary[$scope.parameters[i].Id] = $scope.parameters[i];
          }
      })

      function getAllParameters() {
          var deferred = $q.defer();
          $http({
              method: 'GET',
              url: ngAuthSettings.API_URL + 'values/GetAllParameters'
          }).then(function successCallback(response) {
              deferred.resolve(response.data);
          }/*, function errorCallback(response) { }*/);
          return deferred.promise;
      }

      $scope.edit = function (event, item, propertyName, maxLength) {
          event.stopPropagation(); // in case autoselect is enabled

          var editDialog = {
              modelValue: item[propertyName],
              save: function (input) {
                  item[propertyName] = input.$modelValue;
                  $scope.promise = localizationService.updateParameterTranslation(item).then(function (response) {
                  });
              },
              targetEvent: event,
              title: ' ',
              validators: {
                  'md-maxlength': maxLength
              }
          };


          var promise;

          promise = $mdEditDialog.large(editDialog);

          promise.then(function (ctrl) {
              var input = ctrl.getInput();

          });
      };

      $scope.refresh = function () {
          //$scope.promise = localizationService.refreshParameterTranslations().then(function () {
          //    $mdToast.show($mdToast.simple().textContent('The parameter translations were refreshed.'));

          //});

      }

      $scope.remove = function (item) {
          $scope.promise = localizationService.removeParameterTranslation(item).then(function () {

          });
      };

      $scope.add = function (modalId) {

          $scope.promise = localizationService.addParameterTranslation($scope.newParameterTranslation)
          .then(function () {

          });

          $scope.newParameterTranslation = { LanguageId: null, ParameterId: null, Translation: null };
          $(modalId).modal('hide');
      };


      $scope.logItem = function (item) {
      };

      $scope.logOrder = function (order) {
      };

      $scope.logPagination = function (page, limit) {
      }
  }]);