'use strict';


angular.module('mapsApp')
    .factory('vevaFactory', ['$q', '$http', '$mdToast', 'ngAuthSettings',
        function ($q, $http, $mdToast, ngAuthSettings) {
      var noStationSelectedErrorMessage = "Please select a station first."
            var vevaBase = ngAuthSettings.API_URL; //'http://apiveva.waterinfo.io/';
            //vevaBase = 'http://localhost:49955/';
    // Public API here
    return { 
        getTimeSeries: _getTimeSeries,
        getDfs0FromPoint: _getDfs0FromPoint,
        getJsonFromPoint: _getJsonFromPoint,
        getRadarImages: _getRadarImages,
        getRadarH5Files: _getRadarH5Files,
        getTimeSeriesPolygon: _getTimeSeriesPolygon,
        getAreaTimeSeriesPolygon: _getAreaTimeSeriesPolygon,
        getRadarSquareSum: _getRadarSquareSum,
        getDfs0FromPolygon: _getDfs0FromPolygon,
        getDfs2FromPolygon: _getDfs2FromPolygon,
        getSVKRainGauges: _getSVKRainGauges,
        getRainGaugeDataByPointId: _getRainGaugeDataByPointId,
        getAvailableDataSources: _getAvailableDataSources 
    };


            function _getTimeSeries(x, y, fromUtc, toUtc, radarId, epsg, elevation, addZero) {
                var deferred = $q.defer();
                $http({
                    method: 'GET',
                    url: vevaBase + 'Radar/GetTimeSeries',
                    params: {
                        radarId: radarId,
                        parameterId: 204,
                        epsg: epsg,
                        addZero: addZero,
                        x: x,
                        y: y,
                        fromUtc: fromUtc,
                        toUtc: toUtc,
                        elevation : elevation
                    }
                }).then(function successCallback(response) {
                    deferred.resolve(response.data);
                }, function error(response) {
                    deferred.reject(response);
                });

                return deferred.promise;
            }

            function _getJsonFromPoint(x, y, fromUtc, toUtc, radarId, epsg) {
                var deferred = $q.defer();
                $http({
                    method: 'GET',
                    url: vevaBase + 'Radar/GetJsonTimeseriesFromPoint',
                    params: {
                        radarId: radarId,
                        epsg: epsg,
                        x: x,
                        y: y,
                        fromUtc: fromUtc,
                        toUtc: toUtc
                    }
                }).then(function successCallback(response) {
                    deferred.resolve(response.data);
                }, function error(response) {
                    deferred.reject(response);
                });

                return deferred.promise;
            }

            function _getDfs0FromPoint(x, y, fromUtc, toUtc, radarId, epsg) {
                var deferred = $q.defer();
                $http({
                    method: 'GET',
                    url: vevaBase + 'Radar/GetDfs0FromPoint',
                    params: {
                        radarId: radarId,
                        epsg: epsg,
                        x: x,
                        y: y,
                        fromUtc: fromUtc,
                        toUtc: toUtc
                    }
                }).then(function successCallback(response) {
                    deferred.resolve(response.data);
                }, function error(response) {
                    deferred.reject(response);
                });

                return deferred.promise;
            }


            function _getRadarImages(fromUtc, toUtc, radarId) {
                var deferred = $q.defer();
                $http({
                    method: 'GET',
                    url: vevaBase + 'Radar/GetRadarImages',
                    params: {
                        radarId: radarId,
                        fromUtc: fromUtc,
                        toUtc: toUtc
                    }
                }).then(function successCallback(response) {
                    deferred.resolve(response.data);
                }, function error(response) {
                    deferred.reject(response);
                });

                return deferred.promise;
            }

            function _getRadarH5Files(fromUtc, toUtc, radarId) {
                var deferred = $q.defer();
                $http({
                    method: 'GET',
                    url: vevaBase + 'Radar/GetRadarH5Files',
                    params: {
                        radarId: radarId,
                        fromUtc: fromUtc,
                        toUtc: toUtc
                    }
                }).then(function successCallback(response) {
                    deferred.resolve(response.data);
                }, function error(response) {
                    deferred.reject(response);
                });

                return deferred.promise;
            }



            function _getTimeSeriesPolygon(fromUtc, toUtc, radarId,geojson) {
                var deferred = $q.defer();
                $http({
                    method: 'POST',
                    url: vevaBase + 'Radar/GetTimeSeriesFileForPolygon',
                    params: {
                        radarId: radarId,
                        fromUtc: fromUtc,
                        toUtc: toUtc
                    },
                    data: geojson
                }).then(function successCallback(response) {
                    deferred.resolve(response.data);
                }, function error(response) {
                    deferred.reject(response);
                });

                return deferred.promise;
            }

            
            function _getAreaTimeSeriesPolygon(fromUtc, toUtc, radarId, geojson) {
                var deferred = $q.defer();
                $http({
                    method: 'POST',
                    url: vevaBase + 'Radar/GetJsonFileAreaTimeSeriesPolygon',
                    params: {
                        radarId: radarId,
                        fromUtc: fromUtc,
                        toUtc: toUtc
                    },
                    data: geojson
                }).then(function successCallback(response) {
                    deferred.resolve(response.data);
                }, function error(response) {
                    deferred.reject(response);
                });

                return deferred.promise;
            }


            function _getRadarSquareSum(fromUtc, toUtc, radarId, geojson) {
                var deferred = $q.defer();
                $http({
                    method: 'POST',
                    url: vevaBase + 'Radar/GetRadarSquareSum',
                    params: {
                        radarId: radarId,
                        fromUtc: fromUtc,
                        toUtc: toUtc
                    },
                    data: geojson
                }).then(function successCallback(response) {
                    deferred.resolve(response.data);
                }, function error(response) {
                    deferred.reject(response);
                });

                return deferred.promise;
            }

            function _getDfs0FromPolygon(fromUtc, toUtc, radarId, geojson) {
                var deferred = $q.defer();
                $http({
                    method: 'POST',
                    url: vevaBase + 'Radar/GetDfs0FromPolygon',
                    params: {
                        radarId: radarId,
                        fromUtc: fromUtc,
                        toUtc: toUtc
                    },
                    data: geojson
                }).then(function successCallback(response) {
                    deferred.resolve(response.data);
                }, function error(response) {
                    deferred.reject(response);
                });

                return deferred.promise;
            }

            function _getDfs2FromPolygon(fromUtc, toUtc, radarId, geojson) {
                var deferred = $q.defer();
                $http({
                    method: 'POST',
                    url: vevaBase + 'Radar/GetDfs2FromPolygon',
                    params: {
                        radarId: radarId,
                        fromUtc: fromUtc,
                        toUtc: toUtc
                    },
                    data: geojson
                }).then(function successCallback(response) {
                    deferred.resolve(response.data);
                }, function error(response) {
                    deferred.reject(response);
                });

                return deferred.promise;
            }

            function _getRainGaugeDataByPointId(fromUtc, toUtc, id) {
                var deferred = $q.defer();
                $http({
                    method: 'GET',
                    url: vevaBase + 'Radar/GetRainGaugeDataByPointId',
                    params: {
                        id: id,
                        fromUtc: fromUtc,
                        toUtc: toUtc
                    }
                }).then(function successCallback(response) {
                    deferred.resolve(response.data);
                }, function error(response) {
                    deferred.reject(response);
                });

                return deferred.promise;
            }

            function _getSVKRainGauges() {
                var deferred = $q.defer();
                $http({
                    method: 'GET',
                    url: vevaBase + 'Radar/getSVKRainGauges',
                    params: {
                    }
                }).then(function successCallback(response) {
                    deferred.resolve(response.data);
                }, function error(response) {
                    deferred.reject(response);
                });

                return deferred.promise;
            }

            //http://api.veva.live/Radar/GetAvailableDataSources
            function _getAvailableDataSources() {
                var deferred = $q.defer();
                $http({
                    method: 'GET',
                    url: vevaBase + 'Radar/GetAvailableDataSources',
                    params: {
                    }
                }).then(function successCallback(response) {
                    deferred.resolve(response.data);
                }, function error(response) {
                    deferred.reject(response);
                });

                return deferred.promise;
            }

  }]);
