(function () {
  "use strict";

  angular.module('myApp', ['ngResource'])
      .controller('GroupCtrl', ['$scope', function ($scope) {
      var self = this;
      self.groups = Groups.query();

      self.expandAll = function (expanded) {
        // $scope is required here, hence the injection above, even though we're using "controller as" syntax
        $scope.$broadcast('onExpandAll', {
          expanded: expanded
        });
      };

    }])
    .directive('expand', function () {
      function link(scope, element, attrs) {
        scope.$on('onExpandAll', function (event, args) {
          scope.expanded = args.expanded;
        });
      }
      return {
        link: link
      };
    });
}());
