'use strict';

/**
 * @ngdoc function
 * @name mapsApp.controller:LoginctrlCtrl
 * @description
 * # LoginctrlCtrl
 * Controller of the mapsApp
 */
angular.module('mapsApp')
  .controller('ResetPasswordCtrl', ['$scope', '$location', 'profileFactory', '$stateParams', '$mdDialog', '$translate',
      function ($scope, $location, profileFactory, $stateParams, $mdDialog, $translate) {

          console.log($stateParams);

          var userId = $stateParams.id;
          var token = $stateParams.token;

          $scope.message = undefined;
          $scope.loading = false;

          $scope.passwordData = {
              UserId: userId,
              Token: token,
              Password: undefined,
              ConfirmPassword: undefined,
          };
          

          $scope.resetPassword = function (ev) {
              $scope.loading = true;
              profileFactory.resetPassword($scope.passwordData).then(function (response) {
                  $scope.loading = false;
                  var confirm = $mdDialog.confirm()
                        .title($translate.instant('password_succesfully_changed'))
                        .textContent($translate.instant('password_succesfully_changed_message'))
                        .ariaLabel('Password Change Prompt')
                        .targetEvent(ev)
                        .ok('Ok');
                  $mdDialog.show(confirm).then(function () {
                      $location.path("/");
                  });
                  console.log(response);
              },
                function (error) {
                  console.log('error');
                   $scope.loading = false;
                   var message = '';
                   if (error) {
                       if (error.Message) {
                           message = message + error.Message
                       }
                       else {
                           var errors = [];
                           for (var key in error) {
                               for (var i = 0; i < error[key].length; i++) {
                                   errors.push(error[key][i]);
                               }
                           }

                           message = message + ' ' + errors.join(' ');
                       }

                   }

                   $scope.message = message;
                   console.log(error);
               });
          };

      }]);

