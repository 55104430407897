'use strict';

/**
 * @ngdoc function
 * @name mapsApp.controller:LoginctrlCtrl
 * @description
 * # LoginctrlCtrl
 * Controller of the mapsApp
 */
angular.module('mapsApp')
    .controller('autoLoginCtrl', ['$scope', '$location', '$translate', 'authFactory', 'localStorageService',
        function ($scope, $location, $translate, authFactory, localStorageService) {
          $scope.loginData = {
              userName: "",
              password: "",
              useRefreshTokens: false,
              autoLogin: true,
              attempt: 1
          };
          $scope.message = $translate.instant("auto_login_wait_message");
          login();
          function login() {
              authFactory.login($scope.loginData).then(function (response) {
                  localStorageService.set('loginData', $scope.loginData);
                  $location.path('/home');
              },
               function (err) {
                   console.log(err);
                   $scope.loginData.attempt = $scope.loginData.attempt + 1;
                   if ($scope.loginData.attempt > 1) {
                       $scope.message = $translate.instant("Beklager vi genkendte ikke din IP adresse - kontakt info@informetics.com");
                       $scope.message = err.message;
                       $location.path('/home');
                   }
                   else login();
               })
              .catch(function (response) {
                  $scope.loginData.attempt = $scope.loginData.attempt + 1;
                  if ($scope.loginData.attempt > 3) {
                      console.error('Got error', response.status, response.data);
                  }
                  else login();
              })
             .finally(function () {
              })
              ;
          };

      }]);

